import Footer from '../../components/Footer/Footer';
import { withRouter } from '../../redux/withRouter';
import { Helmet } from 'react-helmet-async';
import SearchOffOutlinedIcon from '@mui/icons-material/SearchOffOutlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { Avatar, Grid } from '@mui/material';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const NotFound = ({ user404, params }) => {
	const mode = useSelector((state) => state.UI.mode);
	const { t } = useTranslation();
	const pathname = window.location.href;
	const title = t('page_404', {
		content: user404 ? t('user') : t('post'),
	});
	const subtitle = user404
		? t('content_404', {
				content: t('account').toLowerCase(),
		  })
		: t('page_not_available');
	const message = user404 ? t('check_url') : t('broken_link');

	let notFoundJsx = (
		<div>
			<Helmet>
				<title>Not found - 404</title>
			</Helmet>
			<div className='skyBackground'>
				<div className='pd2Media'>
					<div className={`card pd2B1 ${mode === 'dark' && 'dbc'}`}>
						<Avatar id='cardMainIcon' className='flexAuto'>
							<SearchOffOutlinedIcon id='cardMainIconSize' />
						</Avatar>

						<p className='title mgH center capitalize'>{title}</p>
						<p className='subtitle mg0'>{subtitle}</p>
						<hr />

						<CustomAlerts
							error={user404}
							alertTitle={`${t('username')}: ${params.handle}`}
							message={message}
							icon={<AccountCircleRoundedIcon />}
							noCenter
							margin='0 0 0.5rem'
						/>
						<CustomAlerts
							noCenter={user404}
							info={`${user404 ? '' : t('invalid')} URL (${pathname}) - 404`}
							margin='0'
						/>

						<hr />
						<Grid container className='center mgt'>
							<Grid item xs={6} sm={6} md={6}>
								<div className='fpass'>
									<Link to='/feed' id='link'>
										<span className='text bold'>{t('home')}</span>
									</Link>
								</div>
							</Grid>
							<Grid item xs={6} sm={6} md={6} id='baseline'>
								<div className='signup'>
									<Link to={`/users/${user404}`} id='link'>
										<span className='text bold'>{t('profile')}</span>
									</Link>
								</div>
							</Grid>
						</Grid>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
	return notFoundJsx;
};

export default withRouter(NotFound);
