import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function RefundPolicy() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Refund Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Refund Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>
					<p>
						<b>Purpose of Donations:</b> All funds raised through donations are
						directly allocated to support the development, maintenance, and
						improvement of our platform. Your contributions enable us to cover
						operational, storage and database costs, enhance user experience,
						implement new features, and ensure the sustainability of our
						services.
					</p>
					<p>
						<b>Voluntary Donations:</b> We deeply appreciate your generous
						donations which help us in maintaining and enhancing our platform.
						However, please note that donations are entirely voluntary and are
						not required to access or use our services. Our primary goal is to
						provide a valuable service to all users, regardless of their ability
						to donate.
					</p>
					<p>
						Thank you for your donation. We appreciate your support. If you need
						to request a refund, please provide the following information:
					</p>

					<h2>Eligibility for Refunds</h2>
					<p>To request a refund for your donation, you must provide:</p>

					<ol className='text'>
						<li className='bold mgb'>Your email address.</li>
						<li className='bold mgb'>The PayPal transaction ID.</li>
						<li className='bold mgb'>The donation amount.</li>
					</ol>
					<h2>Refund Request Process</h2>

					<ol className='text'>
						<li className='mgb'>
							<b>Submit a Refund Request:</b> To request a refund, please fill
							out the form on our{' '}
							<Link to='/contact' id='link' className='bold'>
								Contact Page
							</Link>{' '}
							and select the "Refunds" topic from the dropdown menu.
						</li>
						<li className='mgb'>
							<b>Processing Time:</b> Refund requests can take up to 30 days to
							process. We will notify you via email once your request has been
							reviewed and processed.
						</li>
						<li className='mgb'>
							<b>Conditions:</b> Refunds will only be considered if all the
							required information (amount, email, and transaction ID) is
							provided. Incomplete requests will not be processed.
						</li>
					</ol>

					<h2>Contact Us</h2>
					<p>
						If you have any questions about our refund policy, please contact us
						through our{' '}
						<Link to='/contact' id='link' className='bold'>
							Contact Page
						</Link>
						.
					</p>
					<p>
						<b>Note:</b> Refunds are issued at the discretion of our
						organization and are subject to our review and approval process. All
						refund requests must comply with our refund policy.
					</p>

					<p>Thank you for your understanding and cooperation.</p>

					<p className='mg0 bold'>
						Also you can read PayPal's refund policy at:{' '}
						<a
							href='https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-refund-policy'
							target='_blank'
							rel='noopener noreferrer'
							className='link underlineHover bold breakWord'
						>
							https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-refund-policy
						</a>
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default RefundPolicy;
