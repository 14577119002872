import { useEffect, useState } from 'react';
import { Radio } from '@mui/material';
import ReactPlayer from 'react-player';
import Media from 'react-media';
import { useTranslation } from 'react-i18next';
export default function YoutubeVideos({ mode, intro, esp, eng, setPlay }) {
	const [language, setLanguage] = useState('en');
	const { t } = useTranslation();
	useEffect(() => {
		// Get the user's preferred language from the browser
		const userLang =
			localStorage.getItem('i18nextLng') ||
			navigator.language ||
			navigator.languages[0];
		// Set the language state based on the browser language
		if (userLang.startsWith('es')) {
			setLanguage('es');
		} else if (userLang.startsWith('pt')) {
			setLanguage('pt');
		} else {
			setLanguage('en');
		}
	}, []);

	const onPlay = () => {
		if (setPlay) {
			setPlay();
		}
	};
	// useEffect(() => {
	// 	window.addEventListener('storage', () => {
	// 		const theme = localStorage.getItem('i18nextLng');
	// 		console.log(theme);
	// 	});
	// }, []);

	const introUrl =
		language === 'es'
			? 'https://youtu.be/qzyPQA5inhI'
			: language === 'pt'
			? 'https://youtu.be/tp3vRdnDt5k'
			: 'https://youtu.be/1-xATA1lZUc';
	const howToUrl = language === 'es' ? esp : eng;

	const fileUrl = intro ? introUrl : howToUrl;
	return (
		<div>
			<Media
				queries={{
					isMobile: '(max-width: 1064px)',
				}}
			>
				{(matches) => (
					<div>
						<div className='flex spaceEvenly pdbH fullWidth'>
							<div
								className={`flex center alignItemsCenter pointer ${
									intro ? 'width1-3' : 'widthHalf'
								}`}
								onClick={() => setLanguage('en')}
							>
								<Radio checked={language === 'en'} />
								<p className='mg0 bold'>
									{matches.isMobile ? 'EN' : t('english')}
								</p>
							</div>
							<div
								className={`flex center alignItemsCenter pointer ${
									intro ? 'width1-3' : 'widthHalf'
								}`}
								onClick={() => setLanguage('es')}
							>
								<Radio checked={language === 'es'} />
								<p className='mg0 bold'>
									{matches.isMobile ? 'ES' : t('spanish')}
								</p>
							</div>
							{intro && (
								<div
									className='flex center alignItemsCenter pointer width1-3'
									onClick={() => setLanguage('pt')}
								>
									<Radio checked={language === 'pt'} />
									<p className='mg0 bold'>
										{matches.isMobile ? 'PT' : t('portuguese')}
									</p>
								</div>
							)}
						</div>

						<div className='yt-wrapper'>
							<ReactPlayer
								onPlay={onPlay}
								className='yt-player'
								autoPlay={false}
								controls
								url={fileUrl}
								width='100%'
								height='100%'
							/>
						</div>
					</div>
				)}
			</Media>
		</div>
	);
}
