import '../src/css/main.css';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import store from './redux/store';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import Home from './pages/HOME/Home';
import Login from './pages/LOGIN/Login';
import User from './pages/USERPAGE/User';
import Signup from './pages/SIGNUP/Signup';
import Donate from './pages/DONATE/Donate';
import ForgotPassword from './pages/FORGOT_PASSWORD/ForgotPassword';
import Feed from './pages/USERFEED/Feed';
import NotFound from './pages/NOTFOUND/NotFound';
import Verify from './pages/VERIFYUSER/Verify';
import PostDialog from './components/SocialComponents/PostDialog';
import Likes from './components/SocialComponents/Likes';
import Notifications from './components/SocialComponents/Notifications';
import FollowerCount from './components/SocialComponents/FollowerCount';
import FollowingCount from './components/SocialComponents/FollowingCount';
import AuthLoadingComponent from './util/AuthLoadingComponent';
import AccountActions from './pages/AUTH_ACTIONS/AccountActions';
import Settings from './components/AccountSettings/Settings';
import Report from './components/Report/Report';
import Admin from './pages/ADMIN/Admin';
import Contact from './pages/CONTACT/Contact';
import TermsOfService from './pages/SERVICE_TERMS/TermsOfService';
import EnableCamera from './pages/USEFUL/EnableCamera';
import EnableMicrophone from './pages/USEFUL/EnableMicrophone';
import EnableNotifications from './pages/USEFUL/EnableNotifications';
import PrivacyPolicy from './pages/SERVICE_TERMS/PrivacyPolicy';
import relativeTime from 'dayjs/plugin/relativeTime';
import CommunityGuidelines from './pages/SERVICE_TERMS/CommunityGuidelines';
import OnlineStatus from './util/OnlineStatus';
import OnlyComments from './components/SocialComponents/OnlyComments';
import Appeal from './components/SocialComponents/Appeal';
import GoodbyeLetter from './components/GoodbyeLetter/GoodbyeLetter';
import ViewedPosts from './components/SocialComponents/ViewedPosts';
import Logout from './pages/LOGOUT/Logout';
import ToastContainers from './components/SocialComponents/ToastContainers';
import ColorModeProvider from './ColorModeProvider';
import { CssBaseline } from '@mui/material';
import AudioLetter from './pages/AUDIO_LETTER/AudioLetter';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import ViolentSpeech from './pages/LEGAL/ViolentSpeech';
import SexualExplotation from './pages/LEGAL/SexualExplotation';
import AbuseHarrasment from './pages/LEGAL/AbuseHarassment';
import OffensiveBehaviour from './pages/LEGAL/OffensiveBehaviour';
import Perpetrators from './pages/LEGAL/Perpetrators';
import SuicideHarm from './pages/LEGAL/SuicideHarm';
import SensitiveContent from './pages/LEGAL/SensitiveContent';
import IllegalGoodsAndServices from './pages/LEGAL/IllegalGoodsAndServices';
import PrivateInformation from './pages/LEGAL/PrivateInformation';
import Spam from './pages/LEGAL/Spam';
import CivicIntegrity from './pages/LEGAL/CivicIntegrity';
import DeceptiveIdentities from './pages/LEGAL/DeceptiveIdentities';
import AlteredContent from './pages/LEGAL/AlteredContent';
import Trademark from './pages/LEGAL/Trademark';
import FairUse from './pages/LEGAL/FairUsePolicy';
import Copyright from './pages/LEGAL/Copyright';
import RepeatInfringer from './pages/LEGAL/RepeatInfringer';
import { onMessageListener } from './firebase/firebaseInit';
import ContactDirectories from './pages/CONTACT/Directories';
import Logo from './pages/LOGO/Logo';
import Slogan from './pages/SLOGAN/Slogan';
import Website from './pages/WEB/Website';
import Download from './pages/DOWNLOAD/Download';
import CCPA from './pages/LEGAL/CCPA';
import DPA from './pages/LEGAL/DPA';
import SubmittingAppeals from './pages/LEGAL/SubmittingAppeals';
import DeleteAccount from './pages/LEGAL/DeleteAccount';
import CookieUse from './pages/LEGAL/CookieUse';
import RefundPolicy from './pages/LEGAL/RefundPolicy';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import countdown from './components/Countdownjs/countdown';

axios.defaults.baseURL =
	'https://us-central1-gbapp-b859e.cloudfunctions.net/api';

const App = () => {
	const helmetContext = {};
	dayjs.extend(LocalizedFormat);
	dayjs.extend(relativeTime);
	dayjs.extend(duration);
	const { t, i18n } = useTranslation(); // dont remove!!! if not class component translations stop working on reload
	useEffect(() => {
		// Set initial locale
		dayjs.locale(i18n.resolvedLanguage);
		setCountdownLabels(i18n.resolvedLanguage);

		// Define the language change handler
		const handleLanguageChange = (lng) => {
			// console.log('Language Changed:', lng);
			setCountdownLabels(lng);
			setDayjsLocale(lng);
		};

		// Attach the event listener only once
		i18next.on('languageChanged', handleLanguageChange);

		// Cleanup function to avoid multiple event listeners
		return () => {
			i18next.off('languageChanged', handleLanguageChange);
		};
	}, [i18n]);

	const setDayjsLocale = (lng) => {
		// console.log('setDayjsLocale', lng);
		switch (lng) {
			case 'es':
				import('dayjs/locale/es').then(() => {
					dayjs.locale(lng);
				});
				break;
			case 'pt':
				import('dayjs/locale/pt').then(() => {
					dayjs.locale(lng);
				});
				break;
			case 'fr':
				import('dayjs/locale/fr').then(() => {
					dayjs.locale(lng);
				});
				break;
			case 'ja':
				import('dayjs/locale/ja').then(() => {
					dayjs.locale(lng);
				});
				break;
			case 'en':
			default:
				dayjs.locale('en'); // Default to English if no match
				break;
		}
	};

	const setCountdownLabels = (lng) => {
		// console.log('setCountdownLabels', lng);
		switch (lng) {
			case 'es':
				countdown.setLabels(
					' milisegundo| segundo| minuto| hora| día| semana| mes| año| década| siglo| milenio',
					' milisegundos| segundos| minutos| horas| días| semanas| meses| años| décadas| siglos| milenios',
					' y ',
					', ',
					'ahora',
				);
				break;
			case 'pt':
				countdown.setLabels(
					' milissegundo| segundo| minuto| hora| dia| semana| mês| ano| década| século| milênio',
					' milissegundos| segundos| minutos| horas| dias| semanas| meses| anos| décadas| séculos| milênios',
					' e ',
					' + ',
					'agora',
				);
				break;
			case 'fr':
				countdown.setLabels(
					' milliseconde| seconde| minute| heure| jour| semaine| mois| année| décennie| siècle| millénaire',
					' millisecondes| secondes| minutes| heures| jours| semaines| mois| années| décennies| siècles| millénaires',
					' et ',
					', ',
					'maintenant',
				);
				break;
			case 'ja':
				countdown.setLabels(
					' ミリ秒| 秒| 分| 時間| 日| 週間| 月| 年| 十年| 世紀| 千年',
					' ミリ秒| 秒| 分| 時間| 日| 週間| 月| 年| 十年| 世紀| 千年',
					' と ',
					'、',
					'今',
				);
				break;
			case 'en':
			default:
				countdown.setLabels(
					' millisecond| second| minute| hour| day| week| month| year| decade| century| millennium',
					' milliseconds| seconds| minutes| hours| days| weeks| months| years| decades| centuries| millennia',
					' and ',
					', ',
					'now',
				);
				break;
		}
	};

	useEffect(() => {
		localStorage.setItem('scrollPosition', 0);
		let observer = null;
		// removes scroll on dialog open for mobile safari
		if (window?.document) {
			const body = window.document.body;
			observer = new MutationObserver(() => {
				body.style.touchAction = body.style.overflow === 'hidden' ? 'none' : '';
				body.style.position = body.style.overflow === 'hidden' ? 'fixed' : '';
			});
			observer.observe(body, {
				attributes: true,
				attributeFilter: ['style'],
			});
		}
		return () => {
			if (observer) {
				observer.disconnect();
			}
			// unmount onMessageListener
			onMessageListener();
		};
	}, []);

	const location = useLocation();
	useEffect(() => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.ready.then((registration) => {
				registration.update();
			});
		}
	}, [location]);

	return (
		<div className='App'>
			<HelmetProvider context={helmetContext}>
				<Provider store={store}>
					<ColorModeProvider>
						<CssBaseline />
						<OnlineStatus />
						<ViewedPosts />
						<ToastContainers />
						<Routes>
							{/* fullAccess */}
							<Route
								exact
								path='/community-guidelines'
								element={
									<AuthLoadingComponent
										Component={CommunityGuidelines}
										fullAccess
									/>
								}
							/>
							<Route
								exact
								path='/terms-of-service'
								element={
									<AuthLoadingComponent Component={TermsOfService} fullAccess />
								}
							/>
							<Route
								exact
								path='/refund-policy'
								element={
									<AuthLoadingComponent Component={RefundPolicy} fullAccess />
								}
							/>
							<Route
								exact
								path='/privacy-policy'
								element={
									<AuthLoadingComponent Component={PrivacyPolicy} fullAccess />
								}
							/>
							<Route
								exact
								path='/submitting-appeals'
								element={
									<AuthLoadingComponent
										Component={SubmittingAppeals}
										fullAccess
									/>
								}
							/>
							<Route
								exact
								path='/cookie-use'
								element={
									<AuthLoadingComponent Component={CookieUse} fullAccess />
								}
							/>
							<Route
								exact
								path='/delete-account'
								element={
									<AuthLoadingComponent Component={DeleteAccount} fullAccess />
								}
							/>
							<Route
								exact
								path='/abuse-harassment'
								element={
									<AuthLoadingComponent
										Component={AbuseHarrasment}
										fullAccess
									/>
								}
							/>
							<Route
								exact
								path='/repeat-infringer'
								element={
									<AuthLoadingComponent
										Component={RepeatInfringer}
										fullAccess
									/>
								}
							/>
							<Route
								exact
								path='/platform-manipulation-and-spam'
								element={<AuthLoadingComponent Component={Spam} fullAccess />}
							/>
							<Route
								exact
								path='/offensive-behaviour'
								element={
									<AuthLoadingComponent
										Component={OffensiveBehaviour}
										fullAccess
									/>
								}
							/>
							<Route
								exact
								path='/misleading-and-deceptive-identities'
								element={
									<AuthLoadingComponent
										Component={DeceptiveIdentities}
										fullAccess
									/>
								}
							/>
							<Route
								exact
								path='/trademark-policy'
								element={
									<AuthLoadingComponent Component={Trademark} fullAccess />
								}
							/>
							<Route
								exact
								path='/fair-use'
								element={
									<AuthLoadingComponent Component={FairUse} fullAccess />
								}
							/>
							<Route
								exact
								path='/ccpa'
								element={<AuthLoadingComponent Component={CCPA} fullAccess />}
							/>
							<Route
								exact
								path='/dpa'
								element={<AuthLoadingComponent Component={DPA} fullAccess />}
							/>
							<Route
								exact
								path='/copyright-policy'
								element={
									<AuthLoadingComponent Component={Copyright} fullAccess />
								}
							/>
							<Route
								exact
								path='/falsified-and-altered-content'
								element={
									<AuthLoadingComponent Component={AlteredContent} fullAccess />
								}
							/>
							<Route
								exact
								path='/civic-integrity'
								element={
									<AuthLoadingComponent Component={CivicIntegrity} fullAccess />
								}
							/>
							<Route
								exact
								path='/violent-speech'
								element={
									<AuthLoadingComponent Component={ViolentSpeech} fullAccess />
								}
							/>
							<Route
								exact
								path='/illegal-goods-or-services'
								element={
									<AuthLoadingComponent
										Component={IllegalGoodsAndServices}
										fullAccess
									/>
								}
							/>
							<Route
								exact
								path='/perpetrators-of-violent-attacks'
								element={
									<AuthLoadingComponent Component={Perpetrators} fullAccess />
								}
							/>
							<Route
								exact
								path='/sensitive-content'
								element={
									<AuthLoadingComponent
										Component={SensitiveContent}
										fullAccess
									/>
								}
							/>
							<Route
								exact
								path='/private-information'
								element={
									<AuthLoadingComponent
										Component={PrivateInformation}
										fullAccess
									/>
								}
							/>
							<Route
								exact
								path='/sexual-exploitation'
								element={
									<AuthLoadingComponent
										Component={SexualExplotation}
										fullAccess
									/>
								}
							/>
							<Route
								exact
								path='/suicide-and-self-harm'
								element={
									<AuthLoadingComponent Component={SuicideHarm} fullAccess />
								}
							/>

							{/* //fullAccess */}
							<Route
								path='/letterAudios/*'
								element={
									<AuthLoadingComponent Component={AudioLetter} fullAccess />
								}
							/>
							<Route
								exact
								path='/contact'
								element={
									<AuthLoadingComponent Component={Contact} fullAccess />
								}
							/>
							<Route
								exact
								path='/contact-directories'
								element={
									<AuthLoadingComponent
										Component={ContactDirectories}
										fullAccess
									/>
								}
							/>

							<Route
								exact
								path='/enableCamera'
								element={
									<AuthLoadingComponent Component={EnableCamera} fullAccess />
								}
							/>
							<Route
								exact
								path='/enableMicrophone'
								element={
									<AuthLoadingComponent
										Component={EnableMicrophone}
										fullAccess
									/>
								}
							/>
							<Route
								exact
								path='/enableNotifications'
								element={
									<AuthLoadingComponent
										Component={EnableNotifications}
										fullAccess
									/>
								}
							/>

							<Route
								exact
								path='/report'
								element={<AuthLoadingComponent Component={Report} useAuth />}
							/>
							<Route
								exact
								path='/report/:boundaryId'
								element={<AuthLoadingComponent Component={Report} useAuth />}
							/>
							<Route exact path='/logout' element={<Logout />} />
							<Route
								exact
								path='/feature'
								element={<AuthLoadingComponent Component={Report} />}
							/>

							<Route
								exact
								path='/accountActions'
								element={
									<AuthLoadingComponent Component={AccountActions} useAuth />
								}
							/>

							<Route
								exact
								path='/donate/:received?'
								element={<AuthLoadingComponent Component={Donate} fullAccess />}
							/>
							<Route
								exact
								path='/appeal'
								element={
									<AuthLoadingComponent
										Component={<Appeal socialComponent='user' />}
										componentName='appeal'
									/>
								}
							/>

							<Route
								exact
								path='/gbadmins'
								element={<AuthLoadingComponent Component={Admin} />}
							>
								<Route exact path='post/:postId' element={<PostDialog />}>
									<Route exact path='likes' element={<Likes />} />
								</Route>
								<Route
									exact
									path='unreleasedPost/:postId'
									element={<PostDialog />}
								>
									<Route exact path='likes' element={<Likes />} />
								</Route>
							</Route>
							<Route
								exact
								path='/login'
								element={<AuthLoadingComponent navigate Component={Login} />}
							/>
							<Route
								exact
								path='/'
								element={<AuthLoadingComponent navigate Component={Home} />}
							/>
							<Route
								exact
								path='/signup'
								element={<AuthLoadingComponent navigate Component={Signup} />}
							/>
							<Route
								exact
								path='/logo'
								element={<AuthLoadingComponent navigate Component={Logo} />}
							/>
							<Route
								exact
								path='/slogan'
								element={<AuthLoadingComponent navigate Component={Slogan} />}
							/>
							<Route
								exact
								path='/web'
								element={<AuthLoadingComponent navigate Component={Website} />}
							/>
							<Route
								exact
								path='/download'
								element={<AuthLoadingComponent navigate Component={Download} />}
							/>
							<Route
								exact
								path='/forgotPassword'
								element={
									<AuthLoadingComponent navigate Component={ForgotPassword} />
								}
							/>

							<Route
								exact
								path='feed'
								element={<AuthLoadingComponent Component={Feed} />}
							>
								<Route exact path='post/:postId' element={<PostDialog />}>
									<Route exact path='likes' element={<Likes />} />
								</Route>
								<Route
									exact
									path='post/:postId/:paramsCommentId'
									element={<PostDialog />}
								>
									<Route exact path='likes' element={<Likes />} />
								</Route>
								<Route
									exact
									path='post/:postId/:paramsCommentId/:repliedId'
									element={<PostDialog />}
								>
									<Route exact path='likes' element={<Likes />} />
								</Route>

								<Route exact path='notifications' element={<Notifications />}>
									<Route
										exact
										path='post/:postId/:paramsCommentId'
										element={<PostDialog />}
									>
										<Route exact path='likes' element={<Likes />} />
									</Route>
									<Route
										exact
										path='post/:postId/:paramsCommentId/:repliedId'
										element={<PostDialog />}
									>
										<Route exact path='likes' element={<Likes />} />
									</Route>
									<Route exact path='post/:postId' element={<PostDialog />}>
										<Route exact path='likes' element={<Likes />} />
									</Route>
									<Route
										exact
										path='removedPost/:postId'
										element={<PostDialog />}
									/>
									<Route
										exact
										path='unreleasedPost/:postId'
										element={<PostDialog />}
									>
										<Route exact path='likes' element={<Likes />} />
									</Route>
									<Route
										exact
										path='post/:postId/removedComment/:paramsCommentId'
										element={<PostDialog />}
									/>
									<Route
										exact
										path='post/:postId/removedComment/:paramsCommentId/:repliedId'
										element={<PostDialog />}
									/>
								</Route>
							</Route>

							<Route
								exact
								path='settings'
								element={<AuthLoadingComponent Component={Settings} />}
							>
								<Route
									exact
									path='comments'
									element={<OnlyComments onlyComments />}
								>
									<Route exact path='likes' element={<Likes />} />
								</Route>
								<Route exact path='liked' element={<OnlyComments onlyLiked />}>
									<Route exact path='likes' element={<Likes />} />
								</Route>
							</Route>

							<Route
								exact
								path='letter/:letterId?'
								element={
									<AuthLoadingComponent
										Component={GoodbyeLetter}
										fullAuthComponent
									/>
								}
							/>

							<Route
								exact
								path='users/:handle'
								element={<AuthLoadingComponent Component={User} />}
							>
								<Route
									exact
									path='notifications'
									element={<Notifications showBadge={false} />}
								>
									<Route exact path='post/:postId' element={<PostDialog />}>
										<Route exact path='likes' element={<Likes />} />
									</Route>
									<Route
										exact
										path='post/:postId/:paramsCommentId'
										element={<PostDialog />}
									>
										<Route exact path='likes' element={<Likes />} />
									</Route>
									<Route
										exact
										path='post/:postId/:paramsCommentId/:repliedId'
										element={<PostDialog />}
									>
										<Route exact path='likes' element={<Likes />} />
									</Route>
									<Route
										exact
										path='removedPost/:postId'
										element={<PostDialog />}
									/>
									<Route
										exact
										path='unreleasedPost/:postId'
										element={<PostDialog />}
									>
										<Route exact path='likes' element={<Likes />} />
									</Route>
									<Route
										exact
										path='post/:postId/removedComment/:paramsCommentId'
										element={<PostDialog />}
									/>
									<Route
										exact
										path='post/:postId/removedComment/:paramsCommentId/:repliedId'
										element={<PostDialog />}
									/>
								</Route>
								<Route exact path='post/:postId' element={<PostDialog />}>
									<Route exact path='likes' element={<Likes />} />
								</Route>
								<Route
									exact
									path='removedPost/:postId'
									element={<PostDialog />}
								/>
								<Route
									exact
									path='unreleasedPost/:postId'
									element={<PostDialog />}
								>
									<Route exact path='likes' element={<Likes />} />
								</Route>
								<Route
									exact
									path='post/:postId/removedComment/:paramsCommentId'
									element={<PostDialog />}
								/>
								<Route
									exact
									path='post/:postId/:paramsCommentId'
									element={<PostDialog />}
								>
									<Route exact path='likes' element={<Likes />} />
								</Route>
								<Route
									exact
									path='post/:postId/removedComment/:paramsCommentId/:repliedId'
									element={<PostDialog />}
								/>
								<Route
									exact
									path='post/:postId/:paramsCommentId/:repliedId'
									element={<PostDialog />}
								>
									<Route exact path='likes' element={<Likes />} />
								</Route>

								<Route exact path='following' element={<FollowingCount />} />
								<Route exact path='followers' element={<FollowerCount />} />
							</Route>

							<Route exact path='/verification' element={<Verify />} useAuth />
							<Route
								exact
								path='*'
								element={
									<AuthLoadingComponent Component={NotFound} fullAccess />
								}
							/>
						</Routes>
					</ColorModeProvider>
				</Provider>
			</HelmetProvider>
		</div>
	);
};

export default App;
