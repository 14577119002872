import React, { Component } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { notIcon, notPath, notVerb } from '../../firebase/firebaseInit';
import {
	Dialog,
	Toolbar,
	DialogContent,
	CircularProgress,
	IconButton,
	Badge,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Grow,
	ToggleButtonGroup,
	ToggleButton,
	BottomNavigationAction,
	Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import SearchIcon from '@mui/icons-material/Search';
import { connect } from 'react-redux';
import { markNotificationsRead } from '../../redux/actions/userActions';
import {
	getMoreNotifications,
	filterNotifications,
	setOpenedDialog,
} from '../../redux/actions/dataActions';
import { Link, Navigate, Outlet } from 'react-router-dom';
import ImgSrc from './ImgSrc';
import QueryUsersSkeleton from '../Skeletons/QueryUsersSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Helmet } from 'react-helmet-async';
import { withRouter } from '../../redux/withRouter';
import CustomAlerts from './CustomAlerts';
import NotificationsIcon from '@mui/icons-material/Notifications';
import logo from '../../util/Images/logo.svg';
import CustomButton from './CustomButton';
import FollowButton from './FollowButton';
import CustomTooltip from './CustomTooltip';
import PullToRefresh from 'react-simple-pull-to-refresh';
import Media from 'react-media';
import NotificationPermission from './NotificationPermission';
import { t } from 'i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Grow ref={ref} {...props} />;
});

class Notifications extends Component {
	state = {
		refreshing: false,
		dialogOpen: false,
		notRead: true,
		viewReadNotifications: false,
		lastNotCreatedAtKey: '',
		over10nots: false,
		initialLoadingNots: false,
		notificationCollection: 'showAll',
		showFilterOptions: false,
		filterOption: '',
		order: '',
	};

	componentDidMount = () => {
		this.getNots();
	};
	openNots = (path) => {
		this.props.history(path);
	};
	componentWillUnmount = () => {
		this.setState({
			dialogOpen: false,
		});
		if (this.state.dialogOpen) {
			this.handleClose(true);
		}
	};

	getNots = async () => {
		if (!this.props.showBadge) {
			this.setState({
				dialogOpen: true,
				lastNotCreatedAtKey: '',
			});
			let notificationsLength = this.props.user.notifications.length;
			if (notificationsLength === 0) {
				try {
					await this.props.getMoreNotifications({
						key: '',
						limit: 10,
					});
					this.setState({ initialLoadingNots: true });
				} catch (err) {
					// console.log(err);
				}
			} else if (notificationsLength < 10) {
				let limit = 10 - notificationsLength;
				let createdAtKey =
					this.props.user.notifications[notificationsLength - 1].createdAt;
				if (this.props.user.errorLoadingNotifications === null) {
					this.props.getMoreNotifications({ key: createdAtKey, limit: limit });
				}
			} else {
				this.setState({ over10nots: true });
			}
			this.props.setOpenedDialog(true);
		}
	};

	markOneNotRead = (not, readOne) => {
		let id = [not.notificationId];
		let read = not.read;
		let allNotificationIds = not.allNotificationIds;

		if (read === false) {
			this.props.markNotificationsRead(
				id,
				readOne && 'markOneNotRead',
				allNotificationIds,
			);
		}
		return;
	};

	setFilterOptions = () => {
		this.setState((prevState) => ({
			showFilterOptions: !prevState.showFilterOptions,
		}));
	};
	handleChange = (e) => {
		e.preventDefault();
		let value = e.target.value;
		if (value === 'asc' || value === 'desc') {
			this.setState({
				order: value,
			});
		} else if (
			value !== this.state.notificationCollection &&
			value !== '' &&
			(value === 'showAll' || value === 'filtered')
		) {
			this.setState({
				notificationCollection: value,
			});
		} else {
			this.setState({
				filterOption: value,
			});
			if (this.state.order === '') {
				this.setState({
					order: 'desc',
				});
			}
		}
	};

	filter = () => {
		this.setState((prevState) => ({
			notificationCollection: 'filtered',
		}));
		let state = this.state;
		if (state.filterOption && state.order) {
			let queryData = {
				type: state.filterOption,
				order: state.order,
			};

			this.props.filterNotifications(queryData);
		}
	};
	clickNot = (not) => {
		const path = notPath(
			not,
			this.props.params.handle,
			this.props.location.pathname.split('/')[1],
		);
		if (typeof path !== 'string' && Object.keys(path).length > 0) {
			this.props.history(path.pathname, { state: path.state });
		} else {
			this.props.history(path);
		}
		this.markOneNotRead(not, true);
	};

	handleClose = (onlyRead) => {
		this.setState({ dialogOpen: false });
		if (this.props.data.openedDialog) {
			this.props.setOpenedDialog(false);
		}
		let unreadNotificationsIds = this.props.user.notifications
			.filter((not) => !not.read)
			.map((not) =>
				Array.isArray(not.allNotificationIds)
					? not.allNotificationIds
					: [not.notificationId] || [],
			)
			.reduce((acc, ids) => acc.concat(ids), []);

		unreadNotificationsIds = Array.from(new Set(unreadNotificationsIds));
		this.props.markNotificationsRead(unreadNotificationsIds);
		if (onlyRead !== true) {
			const { pathname } = this.props.location;
			if (pathname.split('/')[1] === 'feed') {
				this.props.history('/feed');
			} else {
				this.props.history(`/users/${pathname.split('/')[2]}`);
			}
		}
	};

	refresh = () => {
		return new Promise((res) => {
			this.setState({
				refreshing: true,
			});
			setTimeout(() => {
				this.setState({
					refreshing: false,
				});
				res(true);
			}, 500);
		});
	};
	fetchMoreNotifications = (createdAtKey, limit) => {
		this.setState({
			viewReadNotifications: true,
		});
		if (
			createdAtKey !== this.state.lastNotCreatedAtKey ||
			this.state.over10nots
		) {
			this.setState({
				lastNotCreatedAtKey: createdAtKey,
				initialLoadingNots: true,
			});
			if (this.state.notificationCollection === 'filtered') {
				this.props.filterNotifications({
					key: createdAtKey,
					type: this.state.filterOption,
					order: this.state.order,
				});
			} else {
				this.props.getMoreNotifications({ key: createdAtKey, limit: limit });
			}
		}
	};

	render() {
		const urlLocation = this.props.location.pathname.split('/')[1];
		const {
			dialogOpen,
			viewReadNotifications,
			initialLoadingNots,
			notificationCollection,
			showFilterOptions,
			filterOption,
			order,
			refreshing,
		} = this.state;
		const {
			user: {
				authenticated,
				notifications,
				filteredNotifications,
				loadingNotifications,
				loadingFilteredNotifications,
				errorLoadingNotifications,
				unreadNotificationsCount,
				credentials: {
					userId,
					handle,
					followingUserIds,
					imageUrl,
					thumbnail,
					imageColor,
				},
			},

			UI: { mode },
			showBadge,
			navMobile,
		} = this.props;
		const notificationBackgroundColor =
			mode === 'light' ? 'notificationReadLight' : 'notificationReadDark';

		let urlHandle = this.props.params.handle;
		let path =
			urlHandle !== undefined
				? `/${urlLocation}/${urlHandle}/notifications`
				: window.location.pathname !== '/feed'
				? `/users/${handle}/notifications`
				: `/${urlLocation}/notifications`;

		let notificationsIcon;
		let collection =
			notificationCollection === 'filtered'
				? filteredNotifications
				: notifications;
		let notificationLoader =
			notificationCollection === 'filtered'
				? loadingFilteredNotifications
				: loadingNotifications;
		if (unreadNotificationsCount > 0) {
			notificationsIcon = (
				<Badge
					badgeContent={unreadNotificationsCount}
					sx={{
						'& .MuiBadge-badge': {
							color: '#fff',
							backgroundColor: '#0a90dd',
							fontSize: 17,
							fontFamily: 'sans-serif',
						},
					}}
					max={999}
				>
					<NotificationsIcon className='white ' />
				</Badge>
			);
		} else {
			notificationsIcon = <NotificationsIcon className='white' />;
		}

		const lastNotificationCreatedAtKey =
			collection[collection.length - 1]?.createdAt;
		const notificationsMarkup = authenticated ? (
			loadingNotifications &&
			initialLoadingNots === false &&
			collection.length === 0 ? (
				<QueryUsersSkeleton showHr fulline number={1} />
			) : (collection && collection.length >= 1) ||
			  notificationCollection === 'filtered' ||
			  loadingFilteredNotifications ? (
				<div>
					<PullToRefresh
						onRefresh={this.refresh}
						isPullable={!loadingNotifications && !refreshing}
						pullDownThreshold={50}
						resistance={1}
						refreshingContent={
							<div id='circularProgressQuery pd2 mg2'>
								<CircularProgress
									variant='indeterminate'
									id='queryUserAuthProgress'
									size={30}
									thickness={3}
								/>
							</div>
						}
						pullingContent={
							<h5 className='center' id='mgt'>
								{t('pull_to_refresh')}
							</h5>
						}
					>
						<InfiniteScroll
							dataLength={collection.length}
							next={() =>
								this.fetchMoreNotifications(lastNotificationCreatedAtKey, 10)
							}
							hasMore={
								viewReadNotifications &&
								!notificationLoader &&
								!errorLoadingNotifications
							}
							scrollableTarget='scrollableDiv'
							loader={
								loadingNotifications && (
									<div>
										<QueryUsersSkeleton showHr fulline number={10} />
										<div id='circularProgressQuery'>
											<CircularProgress
												variant='indeterminate'
												id='queryUserAuthProgress'
												size={30}
												thickness={3}
											/>
										</div>
									</div>
								)
							}
						>
							{notifications.length > 0 && (
								<div>
									<CustomButton
										onClick={this.setFilterOptions}
										fullWidth
										btnText={t('filter')}
										variant='contained'
										// color={mode === 'light' ? 'primary' : 'inherit'}
										margin='0'
										borderRadius='0'
										id={
											showFilterOptions
												? 'customInfoButton'
												: 'customFilterButton'
										}
										startIcon={<LowPriorityIcon id='mediumIconBtn' />}
									/>
									{showFilterOptions && (
										<div className='pd'>
											<Grow in={true}>
												<FormControl fullWidth size='small'>
													<InputLabel>{t('options')}</InputLabel>
													<Select
														labelId='Notifications filter options.'
														value={filterOption}
														label='Options'
														onChange={this.handleChange}
														MenuProps={{
															PaperProps: {
																style: {
																	maxHeight: '255px',
																	width: 250,
																},
															},
														}}
													>
														<MenuItem value='follow'>
															{t('followed_you')}
														</MenuItem>
														<MenuItem value='post'>
															{t('wanted_to_see_post')}
														</MenuItem>
														<MenuItem value='letterRelease'>
															{t('wanted_to_see_letter')}
														</MenuItem>
														<MenuItem value='like'>
															{t('liked_comment_post')}
														</MenuItem>
														<MenuItem value='comment'>
															{t('commented_on_post')}
														</MenuItem>
														<MenuItem value='commentLike'>
															{t('liked_comment')}
														</MenuItem>
														<MenuItem value='mention'>
															{t('mentioned_you')}
														</MenuItem>
														<MenuItem value='commentReply'>
															{t('replied_to_comment')}
														</MenuItem>
														<MenuItem value='commentReplyMention'>
															{t('mentioned_in_reply')}
														</MenuItem>
														<MenuItem value='commentReplyPost'>
															{t('replied_on_post')}
														</MenuItem>
														<MenuItem value='postUpload'>
															{t('post_released')}
														</MenuItem>
														<MenuItem value='letterUpload'>
															{t('goodbye_letter_sent')}
														</MenuItem>
														<MenuItem value='app'>
															{t('goodbye_app_notifications')}
														</MenuItem>
														<MenuItem value='legal'>
															{t('updated_terms')}
														</MenuItem>
														<MenuItem value='infringements'>
															{t('infringements')}
														</MenuItem>
														<MenuItem value='removedInfringements'>
															{t('removed_infringements')}
														</MenuItem>
													</Select>
												</FormControl>
											</Grow>
											<Grow in={true} id='mgt'>
												<FormControl fullWidth size='small'>
													<InputLabel>{t('order')}</InputLabel>
													<Select
														labelId={t('order_opt')}
														value={order}
														label={t('order')}
														onChange={this.handleChange}
													>
														<MenuItem value='desc'>
															{t('newest_on_top')}
														</MenuItem>
														<MenuItem value='asc'>{t('first_on_top')}</MenuItem>
													</Select>
												</FormControl>
											</Grow>

											{showFilterOptions && (
												<CustomButton
													startIcon={<SearchIcon id='mediumIconBtn' />}
													onClick={this.filter}
													fullWidth
													btnText={t('search')}
													variant='outlined'
													margin='1rem 0 0 0'
													borderRadius='0'
													loading={loadingFilteredNotifications}
													disabled={
														loadingFilteredNotifications ||
														(filterOption === '' && order === '')
													}
												/>
											)}
										</div>
									)}
									{(filteredNotifications.length > 0 ||
										notificationCollection === 'filtered' ||
										loadingFilteredNotifications) && (
										<ToggleButtonGroup
											name='Notification List'
											color='info'
											value={notificationCollection}
											exclusive
											onChange={this.handleChange}
											aria-label='Platform'
											fullWidth
										>
											<ToggleButton
												sx={{ borderRadius: '0' }}
												value='showAll'
												id={
													notificationCollection === 'showAll' ? 'noBorder' : ''
												}
											>
												{t('all')}
											</ToggleButton>
											<ToggleButton
												sx={{ borderRadius: '0' }}
												value='filtered'
												id={
													notificationCollection === 'filtered'
														? 'noBorder'
														: ''
												}
											>
												{t('filtered')}
											</ToggleButton>
										</ToggleButtonGroup>
									)}
								</div>
							)}
							{collection.map((not, index) => {
								const { verb, sender_s } = notVerb(not);

								const time = dayjs(not.createdAt).fromNow();
								const read = not.read;
								const icon = not.thumbnail ? not.thumbnail : notIcon(not);
								const newNotifications = not.sendersInfo ? true : false;
								const bool = not.recipient === userId && !not.sender;

								const sender = newNotifications
									? not.sendersInfo[0].sender
									: not.sender;

								const senderHandle = newNotifications
									? not.sendersInfo[0].senderHandle
									: bool
									? handle
									: not.senderHandle;
								const senderImg = newNotifications
									? not.sendersInfo[0].senderImg
									: bool
									? imageUrl
									: not.senderImg;
								const senderThumbnail = newNotifications
									? not.sendersInfo[0].senderThumbnail
									: bool
									? thumbnail
									: not.senderThumbnail;

								const senderImgColor = newNotifications
									? not.sendersInfo[0].senderImgColor
									: bool
									? imageColor
									: not.senderImgColor;

								return (
									<div
										key={
											not.allNotificationIds
												? not.allNotificationIds[0]
												: not.notificationId
										}
									>
										{not.type === 'follow' ? (
											<div>
												<li
													className={`followList spaceBetween pd ${notificationBackgroundColor} ${
														read ? '' : 'newNot'
													}`}
												>
													<div
														className={`flex ${
															followingUserIds &&
															followingUserIds.includes(sender)
																? 'fullWidth'
																: 'width70'
														}`}
													>
														<Link
															to={`/users/${senderHandle}`}
															state={{ data: sender }}
															className='flex'
														>
															<ImgSrc
																img={senderImg}
																thumbnail={senderThumbnail}
																imgColor={senderImgColor}
																handle={senderHandle}
																userId={sender}
																css='mediaImg'
																fontSize='20px'
															/>
														</Link>

														<div
															className='listItemText'
															onClick={() => this.clickNot(not)}
														>
															<div className='flex alignItemsCenter'>
																{icon}
																<span className='mglH'>{time}</span>
															</div>
															<div className='flex alignItemsCenter'>
																<p className='mg0 textStart'>
																	<span className='bold'>{senderHandle}</span>{' '}
																	{verb}
																</p>
															</div>
														</div>
													</div>

													{userId && (
														<FollowButton
															userHandle={senderHandle}
															isFollowingBackBool={true}
															userId={sender}
															onClick={() => this.markOneNotRead(not, true)}
														/>
													)}
												</li>
												{collection.length !== index + 1 && (
													<hr className='mg0' />
												)}
											</div>
										) : (
											<div>
												<li
													className={`flex pd pointer ${notificationBackgroundColor} ${
														read ? '' : 'newNot'
													}`}
												>
													{not.type === 'app' || not.type === 'legal' ? (
														<div
															className='flex alignItemsCenter'
															onClick={() => this.clickNot(not)}
														>
															<div className='smallBackgroundLogo'>
																<img
																	className='tinyLogo'
																	src={logo}
																	alt='Logo'
																/>
															</div>
														</div>
													) : not.sendersInfo ? (
														<div className='flex'>
															{not.sendersInfo.map(
																(info, index) =>
																	index === 0 && (
																		<Link
																			to={`/users/${senderHandle}`}
																			state={{ data: info.sender }}
																			className='flex'
																			key={index}
																		>
																			<div>
																				<ImgSrc
																					img={info.senderImg}
																					thumbnail={info.senderThumbnail}
																					imgColor={info.senderImgColor}
																					handle={info.senderHandle}
																					userId={info.sender}
																					css='mediaImg'
																					fontSize='20px'
																				/>
																			</div>
																		</Link>
																	),
															)}
														</div>
													) : (
														<Link
															to={`/users/${senderHandle}`}
															state={{ data: sender }}
															className='flex'
														>
															<ImgSrc
																img={senderImg}
																thumbnail={senderThumbnail}
																imgColor={senderImgColor}
																handle={senderHandle}
																userId={sender}
																css='mediaImg'
																fontSize='20px'
															/>
														</Link>
													)}

													<div
														className='flex spaceBetween fullWidth'
														onClick={() => this.clickNot(not)}
													>
														<div className='listItemText'>
															<span>{time}</span>
															<div className='flex alignItemsCenter'>
																<p className='mg0'>
																	{senderHandle !== handle && (
																		<span className='bold'>{sender_s}</span>
																	)}

																	{verb}
																	{((not.type === 'like' &&
																		not.body &&
																		not.body.length > 0) ||
																		not.type === 'commentLike') && (
																		<span>{not.body}</span>
																	)}

																	{not.body &&
																		not.type !== 'like' &&
																		not.type !== 'commentLike' &&
																		!not.comments && <span> {not.body}</span>}
																</p>
															</div>
															<div>
																{not.senders && not.comments && (
																	<ul
																		className={`${
																			not.sendersInfo.length === 1
																				? 'pdl'
																				: 'pd0'
																		} flexColumn`}
																		style={{
																			listStyleType:
																				not.sendersInfo.length > 1
																					? 'none'
																					: 'disc',
																		}}
																	>
																		{not.comments.map((comment, index) => {
																			let userData = not.sendersInfo[index];

																			return (
																				<li key={index} className='mg0 mgtH '>
																					<div className='flex alignItemsCenter'>
																						{not.sendersInfo.length > 1 && (
																							<ImgSrc
																								img={userData.senderImg}
																								thumbnail={
																									userData.senderThumbnail
																								}
																								imgColor={
																									userData.senderImgColor
																								}
																								noMargin
																								margin='0 6px 0 0'
																								handle={userData.senderHandle}
																								userId={userData.sender}
																								css='notCommentImg'
																								fontSize='10px'
																							/>
																						)}
																						<span>
																							{comment.length > 100
																								? comment.slice(0, 100) + '...'
																								: comment}
																						</span>
																					</div>
																				</li>
																			);
																		})}
																	</ul>
																)}
															</div>
														</div>

														{not.thumbnail ? (
															<img
																src={icon}
																alt='thumbnail'
																className='listThumbnail'
															/>
														) : (
															<span className='listIcon'>{icon}</span>
														)}
													</div>
												</li>
												{collection.length !== index + 1 && (
													<hr className='mg0' />
												)}
											</div>
										)}
									</div>
								);
							})}
							{notificationLoader && (
								<div>
									{notifications.length > 0 && <hr className='mg0' />}

									<QueryUsersSkeleton
										fulline
										number={
											notificationCollection === 'filtered' &&
											loadingFilteredNotifications
												? 1
												: 5
										}
										showHr
									/>
									{loadingNotifications && (
										<div id='circularProgressQuery'>
											<CircularProgress
												variant='indeterminate'
												id='queryUserAuthProgress'
												size={30}
												thickness={3}
											/>
										</div>
									)}
								</div>
							)}
							{/* {collection.length >= 10 &&
							!viewReadNotifications &&
							errorLoadingNotifications !== 'No more results found.' ? (
								<button
									className='moreResultsBtn'
									onClick={() =>
										this.fetchMoreNotifications(
											notificationCollection === 'filtered'
												? collection[collection.length - 1].createdAt
												: lastNotificationCreatedAtKey,
											// setting limit to match 10 for next not fetch
											10 - (collection.length % 10),
										)
									}
								>
									{notificationCollection === 'filtered' ||
									notifications.length !== unreadNotificationsCount
										? 'View more'
										: 'View all read notifications'}
								</button>
							) : */}
							{collection.length >= 10 &&
							(errorLoadingNotifications ||
								collection.length % 10 !== 0 ||
								(this.state.over10nots && collection.length % 10 !== 0)) ? (
								<CustomAlerts
									info={true}
									message={
										errorLoadingNotifications
											? errorLoadingNotifications
											: t('no_more_results')
									}
									noMargin
								/>
							) : errorLoadingNotifications &&
							  notificationCollection === 'filtered' &&
							  collection.length === 0 ? (
								<CustomAlerts
									info={t('no_nots_404')}
									icon={<NotificationsIcon />}
									noMargin
								/>
							) : null}
						</InfiniteScroll>
					</PullToRefresh>
				</div>
			) : (
				<CustomAlerts
					onClick={this.handleClose}
					info={t('no_nots_yet')}
					noMargin
				/>
			)
		) : (
			<Navigate to='/Login' />
		);

		return navMobile && showBadge ? (
			<BottomNavigationAction
				onClick={() => this.openNots(path)}
				id='navMobileAction'
				value='notifications'
				icon={notificationsIcon}
			/>
		) : showBadge ? (
			<CustomTooltip title={t('notifications')} arrow>
				<IconButton
					onClick={() => this.openNots(path)}
					aria-owns={true ? 'simple-menu' : undefined}
					aria-haspopup='true'
					id='mgX'
					color='primary'
					// style={{ height: unreadNotificationsCount > 0 ? '60px' : '' }}
				>
					{notificationsIcon}
				</IconButton>
			</CustomTooltip>
		) : dialogOpen ? (
			<div>
				<Helmet>
					<title>Notifications - Goodbye App</title>
				</Helmet>
				<Media
					queries={{
						isMobile: '(max-width: 1064px)',
					}}
				>
					{(matches) => (
						<div>
							{dialogOpen && (
								<Dialog
									disableEnforceFocus
									open={dialogOpen}
									onClose={this.handleClose}
									keepMounted
									fullWidth
									maxWidth={!matches.isMobile ? 'xl' : false}
									fullScreen={matches.isMobile}
									style={{ zIndex: '4' }}
									TransitionComponent={Transition}
								>
									<Toolbar id='dialogBar'>
										<div className='titleToolbar'>{t('notifications')}</div>
										<CloseIcon
											id='closeDialogButton'
											onClick={this.handleClose}
											aria-label='close'
										/>
									</Toolbar>

									<DialogContent id='scrollableDiv' sx={{ padding: 0 }}>
										<NotificationPermission />
										<ul className='ul_list'>{notificationsMarkup}</ul>
									</DialogContent>
								</Dialog>
							)}
						</div>
					)}
				</Media>
				<Outlet />
			</div>
		) : null;
	}
}

Notifications.propTypes = {
	markNotificationsRead: PropTypes.func.isRequired,
	getMoreNotifications: PropTypes.func.isRequired,
	filterNotifications: PropTypes.func.isRequired,
	setOpenedDialog: PropTypes.func.isRequired,

	notifications: PropTypes.array,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI,
});

const mapActionsToProps = {
	markNotificationsRead,
	getMoreNotifications,
	filterNotifications,
	setOpenedDialog,
};

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(Notifications));
