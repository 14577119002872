import logo from '../../util/Images/logo.svg';
import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { useSelector } from 'react-redux';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import CakeIcon from '@mui/icons-material/Cake';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Groups2Icon from '@mui/icons-material/Groups2';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Avatar, Fade, Grid, Grow, Slide } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import YoutubeVideos from './YoutubeVideos';
// import HowToRegIcon from '@mui/icons-material/HowToReg';
import Media from 'react-media';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material';
import YTCarousel from './YTCarousel';
const Home = () => {
	const { t } = useTranslation();
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Home - Goodbye App</title>
			</Helmet>
			<Media
				queries={{
					isMobile: '(max-width: 1064px)',
				}}
			>
				{(matches) => (
					<div className='skyBackground'>
						<div className='pd2Media'>
							<div
								className={`card pdH ${mode === 'dark' && 'dbc'} flexColumn`}
							>
								{/* <div className='flexColumn pdt alignItemsCenter pdtOnlyMedia'>
									<div className='backgroundHomeLogo'>
										<img className='homeLogo' src={logo} alt='Logo' />
									</div>
									<h3 className='GBA slogan center mgt pdbH notranslate'>
										Every person has a legacy™
									</h3>
								</div> */}
								<Grow
									in={true}
									style={{
										transformOrigin: 'top',
									}}
									{...{ timeout: 600 }}
								>
									<div>
										<CustomAlerts
											icon={<ScheduleIcon />}
											info={<b>{t('schedule_posts')}</b>}
											noCenter={matches.isMobile}
											severity='warning'
											margin='0 0 0.2rem 0'
										/>
									</div>
								</Grow>

								<Grow
									in={true}
									style={{
										transformOrigin: 'top',
									}}
									{...{ timeout: 600 }}
								>
									<div>
										<CustomAlerts
											icon={<HistoryEduIcon />}
											info={<b>{t('goodbye_letter')}</b>}
											severity='success'
											margin='0.2rem 0'
											noCenter={matches.isMobile}
										/>
									</div>
								</Grow>
								<Grow
									in={true}
									style={{
										transformOrigin: 'top',
									}}
									{...{ timeout: 600 }}
								>
									<div>
										<CustomAlerts
											icon={<Groups2Icon />}
											info={<b>{t('funeral_speech')}</b>}
											noCenter={matches.isMobile}
											severity='brown'
											margin='0.2rem 0'
										/>
									</div>
								</Grow>
								<Grow
									in={true}
									style={{
										transformOrigin: 'top',
									}}
									{...{ timeout: 600 }}
								>
									<div>
										<CustomAlerts
											icon={<CakeIcon />}
											info={<b>{t('happy_birthday_message')}</b>}
											severity='secondary'
											margin='0.2rem 0'
											noCenter={matches.isMobile}
										/>
									</div>
								</Grow>
								<Slide in={true} direction='up' {...{ timeout: 600 }}>
									<div>
										<CustomAlerts
											icon={<AllInclusiveIcon />}
											info={<b>{t('sign_up_prompt')}</b>}
											noCenter={matches.isMobile}
											severity='info'
											margin='0.2rem 0 0'
										/>
									</div>
								</Slide>
							</div>

							<YTCarousel mode={mode} />

							<div className='pdb2Media'>
								<div className={`card pdtH ${mode === 'dark' && 'dbc'}`}>
									<YoutubeVideos mode={mode} intro />
								</div>
							</div>
							<div className={`card pd2t1b1Media ${mode === 'dark' && 'dbc'}`}>
								<Avatar id='cardMainIcon2' className='flexAuto'>
									<EditIcon id='cardMainIconSize2' />
								</Avatar>
								<h1 className='subtitle bold center mgYH'>{t('our_goal')}</h1>

								<Fade in={true} {...{ timeout: 1000 }}>
									<div>
										<h2 className='mgbH'>{t('empowering_goodbye_messages')}</h2>
										<p className='mgbOnly'>
											{t('empowering_goodbye_messages_desc')}
										</p>
										<h2 className='mgbH'>{t('honoring_life_s_transitions')}</h2>

										<p className='mgbOnly'>
											{t('honoring_life_s_transitions_desc')}
										</p>
										<h2 className='mgbH'>
											{t('promoting_healing_and_closure')}
										</h2>

										<p className='mgbOnly'>
											{t('promoting_healing_and_closure_desc')}
										</p>
										<h2 className='mgbH'>{t('celebrating_legacies')}</h2>

										<p className='mgbOnly'>{t('celebrating_legacies_desc')}</p>
										<h2 className='mgbH'>
											{t('facilitating_connections_beyond_borders')}
										</h2>
										<p className='mgbOnly'>
											{t('facilitating_connections_beyond_borders_desc')}
										</p>
										<h2 className='mgbH'>
											{t('promoting_reflection_and_gratitude')}
										</h2>
										<p className='mgbOnly'>
											{t('promoting_reflection_and_gratitude_desc')}
										</p>
									</div>
								</Fade>
								<hr className='mgb' />
								<CustomAlerts
									info={t('goal_conclusion')}
									icon={<AllInclusiveIcon />}
									margin='0'
									noCenter={matches.isMobile}
								/>
								<CustomAlerts
									success={<b>{t('goal_final')}</b>}
									icon={<PublicIcon />}
									margin='1rem 0 0'
									noCenter={matches.isMobile}
								/>
							</div>
						</div>
						<Footer />
					</div>
				)}
			</Media>
		</div>
	);
};

export default Home;
