import { useState } from 'react';
import { useSelector } from 'react-redux';
import CopyText from '../../components/SocialComponents/CopyText';
import countdown from '../../components/Countdownjs/countdown';
import dayjs from 'dayjs';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import CustomButton from '../../components/SocialComponents/CustomButton';
import { useTranslation } from 'react-i18next';

export default function AccountData() {
	const setUserAdmin = useSelector((state) => state.data.setUserAdmin);
	const { t } = useTranslation();
	const [showPostArray, setPostArray] = useState(false);
	const [showLetterArray, setLetterArray] = useState(false);
	const [showImageData, setImageData] = useState(false);
	return Object.values(setUserAdmin).length > 0 ? (
		<div key='accountData'>
			<div className='flexColumn pdb'>
				<table className='reportDialogTable mgb2 minFont'>
					<tbody>
						<tr className='trTitles'>
							<td style={{ width: '40%' }}>User ID</td>
							<td style={{ width: '20%' }}>{t('username')}</td>
							<td style={{ width: '20%' }}>Name</td>
							<td style={{ width: '20%' }}>Age</td>
						</tr>
						<tr className='trContent'>
							<td>
								<CopyText
									placement='bottom'
									info={true}
									text={setUserAdmin.userId}
								/>
							</td>
							<td>@{setUserAdmin.handle}</td>
							<td>{setUserAdmin.name}</td>
							<td>Born {dayjs(setUserAdmin.birthDate).fromNow()}</td>
						</tr>
					</tbody>
				</table>
				<table className='reportDialogTable mgb2 minFont'>
					<tbody>
						<tr className='trTitles'>
							<td style={{ width: '40%' }}>{t('email_address')}</td>
							<td style={{ width: '20%' }}>Birth Date</td>
							<td style={{ width: '20%' }}>Disabled</td>
							<td style={{ width: '20%' }}>Disabled For</td>
						</tr>
						<tr className='trContent'>
							<td>
								<CopyText
									placement='bottom'
									info={true}
									text={setUserAdmin.email}
								/>
							</td>
							<td>{setUserAdmin.birthDate}</td>
							<td>{setUserAdmin.disabled ? 'True' : 'False'}</td>

							<td>
								{setUserAdmin.disabledFor > new Date().toISOString() ? (
									<b>{dayjs(setUserAdmin.disabledFor).fromNow()}</b>
								) : (
									'LIVE'
								)}
							</td>
						</tr>
					</tbody>
				</table>
				<table className='reportDialogTable mgb2 minFont'>
					<tbody>
						<tr className='trTitles'>
							<td style={{ width: '33%' }}>Last Active Day</td>
							<td style={{ width: '33%' }}>Last Active Check</td>
							<td style={{ width: '33%' }}>Admin</td>
						</tr>
						<tr className='trContent'>
							<td style={{ width: '33%' }}>{setUserAdmin.lastActionDate}</td>
							<td style={{ width: '33%' }}>
								{countdown(
									new Date(setUserAdmin.lastActionDate),
									new Date(),
									countdown.DECADES |
										countdown.YEARS |
										countdown.MONTHS |
										countdown.WEEKS |
										countdown.DAYS |
										countdown.HOURS |
										countdown.MINUTES,
								).toString()}{' '}
								ago.
							</td>
							<td style={{ width: '33%' }}>
								{setUserAdmin.admin ? 'True' : 'False'}
							</td>
						</tr>
					</tbody>
				</table>
				<table className='reportDialogTable mgb2 minFont'>
					<tbody>
						<tr className='trTitles'>
							<td style={{ width: '33%' }}>Posts</td>
							<td style={{ width: '33%' }}>Letters</td>
							<td style={{ width: '33%' }}>Followers</td>
						</tr>
						<tr className='trContent'>
							<td style={{ width: '33%' }}>{setUserAdmin.postCount}</td>
							<td style={{ width: '33%' }}>{setUserAdmin.letters.length}</td>
							<td style={{ width: '33%' }}>{setUserAdmin.followerCount}</td>
						</tr>
					</tbody>
				</table>
				<table className='reportDialogTable mgb2 minFont'>
					<tbody>
						<tr className='trTitles'>
							<td style={{ width: '33%' }}>Unreleased Posts</td>
							<td style={{ width: '33%' }}>Unreleased Letters</td>
							<td style={{ width: '33%' }}>Following</td>
						</tr>
						<tr className='trContent'>
							<td style={{ width: '33%' }}>
								{setUserAdmin.unreleasedPostCount}
							</td>
							<td style={{ width: '33%' }}>
								{setUserAdmin.unreleasedLetters.length}
							</td>
							<td style={{ width: '33%' }}>{setUserAdmin.followingCount}</td>
						</tr>
					</tbody>
				</table>
				<table className='reportDialogTable minFont'>
					<tbody>
						<tr className='trTitles'>
							<td style={{ width: '33%' }}>Next Username Change</td>
							<td style={{ width: '33%' }}>Popup Notifications</td>
							<td style={{ width: '33%' }}>Spammed Daily Limit</td>
						</tr>
						<tr className='trContent'>
							<td>
								<div>
									{setUserAdmin.nextUsernameChange && (
										<span>
											In{' '}
											{countdown(
												new Date(setUserAdmin.nextUsernameChange),
												new Date(),
												countdown.MONTHS |
													countdown.WEEKS |
													countdown.DAYS |
													countdown.HOURS |
													countdown.MINUTES,
											).toString()}
											.
										</span>
									)}
								</div>
								{setUserAdmin.nextUsernameChange}
							</td>
							<td>{setUserAdmin.popupNotifications ? 'True' : 'False'}</td>
							<td>
								{setUserAdmin.spammedCount ? setUserAdmin.spammedCount : 0}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<TableContainer component={Paper} className='mgt'>
				<Table sx={{ minWidth: 700 }} aria-label='customized table'>
					<TableHead
						id='alignLast'
						sx={{ backgroundColor: '#2c3e50', color: '#fff' }}
					>
						<TableRow>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									width: '30%',
									border: '1px solid #fff',
									fontSize: '1.1rem',
								}}
							>
								Joined{' '}
								{countdown(
									new Date(setUserAdmin.createdAt),
									new Date(),
									countdown.DECADES |
										countdown.YEARS |
										countdown.MONTHS |
										countdown.WEEKS,
								).toString()}{' '}
								ago
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									width: '70%',
									border: '1px solid #fff',
									fontSize: '1.1rem',
								}}
							>
								Location
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody id='alignLast'>
						<TableRow>
							<TableCell>
								<p className='minFont mg0'>
									Joined on{' '}
									{dayjs(setUserAdmin.createdAt).format('dddd, MMMM D, YYYY')}
								</p>
							</TableCell>
							<TableCell>
								<p className='minFont mg0'>{setUserAdmin.location}</p>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Table sx={{ minWidth: 700 }} aria-label='customized table'>
					<TableHead
						id='alignLast'
						sx={{ backgroundColor: '#2c3e50', color: '#fff' }}
					>
						<TableRow>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									width: '30%',
									border: '1px solid #fff',
									fontSize: '1.1rem',
								}}
							>
								Quote
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									width: '70%',
									border: '1px solid #fff',
									fontSize: '1.1rem',
								}}
							>
								Biography
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody id='alignLast'>
						<TableRow>
							<TableCell
								sx={{
									fontSize: '1.1rem',
								}}
								className='alignBaseline'
							>
								{setUserAdmin.quote}
							</TableCell>
							<TableCell
								sx={{
									fontSize: '1.1rem',
								}}
								className='alignBaseline'
							>
								{setUserAdmin.biography}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>

				<p className='center mgt'>Data Made Today</p>
				<Table sx={{ minWidth: 700 }} aria-label='customized table'>
					<TableHead
						id='alignLast'
						sx={{ backgroundColor: '#2c3e50', color: '#fff' }}
					>
						<TableRow>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Posts
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Letters
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Comments
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Replies
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Followed
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody id='alignLast'>
						<TableRow>
							<TableCell>{setUserAdmin.postsMadeToday}</TableCell>
							<TableCell>{setUserAdmin.lettersMadeToday}</TableCell>
							<TableCell>{setUserAdmin.commentsMadeToday}</TableCell>
							<TableCell>{setUserAdmin.repliesMadeToday}</TableCell>
							<TableCell>{setUserAdmin.usersFollowedToday}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Table sx={{ minWidth: 700 }} aria-label='customized table'>
					<TableHead
						id='alignLast'
						sx={{ backgroundColor: '#2c3e50', color: '#fff' }}
					>
						<TableRow>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Post Likes
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Comment Likes
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Reply Likes
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Feature
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody id='alignLast'>
						<TableRow>
							<TableCell>{setUserAdmin.postLikesMadeToday}</TableCell>
							<TableCell>{setUserAdmin.commentLikesMadeToday}</TableCell>
							<TableCell>{setUserAdmin.replyLikesMadeToday}</TableCell>
							<TableCell>{setUserAdmin.featureReportsMadeToday}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Table sx={{ minWidth: 700 }} aria-label='customized table'>
					<TableHead
						id='alignLast'
						sx={{ backgroundColor: '#2c3e50', color: '#fff' }}
					>
						<TableRow>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Support
							</TableCell>

							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								User R.
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Posts R.
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Comment R.
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody id='alignLast'>
						<TableRow>
							<TableCell>{setUserAdmin.supportReportsMadeToday}</TableCell>

							<TableCell>{setUserAdmin.userReportsMadeToday}</TableCell>
							<TableCell>{setUserAdmin.postReportsMadeToday}</TableCell>
							<TableCell>{setUserAdmin.commentReportsMadeToday}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Table sx={{ minWidth: 700 }} aria-label='customized table'>
					<TableHead
						id='alignLast'
						sx={{ backgroundColor: '#2c3e50', color: '#fff' }}
					>
						<TableRow>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Profile Img's
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Reports
							</TableCell>

							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Notif. Prefs
							</TableCell>
							<TableCell
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									border: '1px solid #fff',
									fontSize: '1.1rem',
									width: '25%',
								}}
							>
								Recovery Info
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody id='alignLast'>
						<TableRow>
							<TableCell>{setUserAdmin.imagesUploadedToday}</TableCell>
							<TableCell>{setUserAdmin.issueReportsMadeToday}</TableCell>
							<TableCell>{setUserAdmin.notPreferencesSetToday}</TableCell>
							<TableCell>{setUserAdmin.inactivityInfoSetToday}</TableCell>
						</TableRow>
					</TableBody>
				</Table>

				<div className='flex pd alignCenter'>
					<p className='mg0'>
						{showLetterArray ? 'Hide' : 'Show'}{' '}
						{setUserAdmin.canRecoverLetter.length} recovery letter ids,{' '}
						{setUserAdmin.letters.length} goodbye letter ids, and{' '}
						{setUserAdmin.unreleasedLetters.length} unreleased goodbye letter
						ids?
					</p>
					<CustomButton
						btnText={showLetterArray ? 'Hide' : 'Show'}
						margin='0 0 0 5px'
						padding='0 5px'
						onClick={() => setLetterArray(!showLetterArray)}
					/>
				</div>
				{showLetterArray && (
					<Table sx={{ minWidth: 700 }} aria-label='customized table'>
						<TableHead
							id='alignLast'
							sx={{ backgroundColor: '#2c3e50', color: '#fff' }}
						>
							<TableRow>
								<TableCell
									sx={{
										color: '#fff',
										fontWeight: 'bold',
										width: '50%',
										border: '1px solid #fff',
										fontSize: '1.1rem',
									}}
								>
									Can Recover Letter
								</TableCell>
								<TableCell
									sx={{
										color: '#fff',
										fontWeight: 'bold',
										width: '70%',
										border: '1px solid #fff',
										fontSize: '1.1rem',
									}}
								>
									Goodbye Letters
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell className='alignBaseline'>
									<ol className='pd0 pdlH'>
										{setUserAdmin.canRecoverLetter.map((obj, index) => {
											return (
												<li className='mgb2' key={index}>
													<div className='flex mgb'>
														<p className='mg0'>
															<b>Sender User ID:</b>
														</p>
														<CopyText
															placement='bottom'
															info={true}
															text={obj.userId}
														/>
													</div>
													<div className='flex'>
														<p className='mg0'>
															<b>Letter ID:</b>
														</p>
														<CopyText
															placement='bottom'
															info={true}
															text={obj.letterId}
														/>
													</div>
													<div className='flex'>
														<p className='mg0'>
															<b>
																Release Date:{' '}
																{dayjs(obj.releaseDate).format('LLLL')}
															</b>
														</p>
													</div>
													<hr />
												</li>
											);
										})}
									</ol>
								</TableCell>
								<TableCell className='alignBaseline'>
									{setUserAdmin.letters.map((obj, index) => {
										return (
											<div className='mgb2' key={index}>
												<div className='flex'>
													<p className='mg0'>
														<b>Letter ID:</b>
													</p>
													<CopyText
														placement='bottom'
														info={true}
														text={obj.letterId}
													/>
													<span className='mglH'>
														{dayjs(obj.releaseDate).fromNow()}
													</span>
												</div>
												<hr />
											</div>
										);
									})}
								</TableCell>
								<TableCell className='alignBaseline'>
									{setUserAdmin.unreleasedLetters.map((obj, index) => {
										return (
											<div className='mgb2' key={index}>
												<div className='flex'>
													<p className='mg0'>
														<b>Letter ID:</b>
													</p>
													<CopyText
														placement='bottom'
														info={true}
														text={obj.letterId}
													/>
													<span className='mglH'>
														{dayjs(obj.releaseDate).fromNow()}
													</span>
												</div>
												<hr />
											</div>
										);
									})}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				)}

				<div className='flex pdb alignCenter'>
					<p className='mg0'>
						{showPostArray ? 'Hide' : 'Show'}{' '}
						{setUserAdmin.recentPosts && setUserAdmin.recentPosts.length} recent
						posts and {setUserAdmin.followingUserIds.length} following ids?
					</p>
					<CustomButton
						btnText={showPostArray ? 'Hide' : 'Show'}
						margin='0 0 0 5px'
						padding='0 5px'
						onClick={() => setPostArray(!showPostArray)}
					/>
				</div>

				{showPostArray && (
					<Table sx={{ minWidth: 700 }} aria-label='customized table'>
						<TableHead
							id='alignLast'
							sx={{ backgroundColor: '#2c3e50', color: '#fff' }}
						>
							<TableRow>
								<TableCell
									sx={{
										color: '#fff',
										fontWeight: 'bold',
										width: '50%',
										border: '1px solid #fff',
										fontSize: '1.1rem',
									}}
								>
									Recent Posts
								</TableCell>
								<TableCell
									sx={{
										color: '#fff',
										fontWeight: 'bold',
										width: '70%',
										border: '1px solid #fff',
										fontSize: '1.1rem',
									}}
								>
									Following User Ids
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							<TableRow>
								<TableCell className='alignBaseline'>
									<ol className='pd0 pdlH'>
										{setUserAdmin.recentPosts.map((obj, index) => {
											return (
												<li className='mgb2' key={index}>
													<div className='flex'>
														<p className='mg0'>
															<b>Post Id:</b>
														</p>
														<CopyText
															placement='bottom'
															info={true}
															text={obj.postId}
														/>
													</div>
													<p className='mg0'>
														<b>Created at:</b>{' '}
														{dayjs(obj.createdAt).format('LLLL')}
													</p>
													<hr />
												</li>
											);
										})}
									</ol>
								</TableCell>
								<TableCell className='alignBaseline'>
									{setUserAdmin.followingUserIds.map((userId, index) => {
										return (
											<div className='mgb2' key={index}>
												<div className='flex'>
													<p className='mg0'>
														<b>User ID:</b>
													</p>
													<CopyText
														placement='bottom'
														info={true}
														text={userId}
													/>
												</div>
												<hr />
											</div>
										);
									})}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				)}

				<div className='flex'>
					<CustomButton
						btnText={showImageData ? 'Hide image data' : 'Show image data'}
						onClick={() => setImageData(!showImageData)}
						variant='outlined'
						margin='0 auto 1.5rem'
					/>
				</div>

				{showImageData && (
					<div>
						<Table sx={{ minWidth: 700 }} aria-label='customized table'>
							<TableHead
								id='alignLast'
								sx={{ backgroundColor: '#2c3e50', color: '#fff' }}
							>
								<TableRow>
									<TableCell
										sx={{
											color: '#fff',
											fontWeight: 'bold',
											border: '1px solid #fff',
											fontSize: '1.1rem',
											width: '33%',
										}}
									>
										Image Url
									</TableCell>

									<TableCell
										sx={{
											color: '#fff',
											fontWeight: 'bold',
											border: '1px solid #fff',
											fontSize: '1.1rem',
											width: '33%',
										}}
									>
										File Destination
									</TableCell>
									<TableCell
										sx={{
											color: '#fff',
											fontWeight: 'bold',
											border: '1px solid #fff',
											fontSize: '1.1rem',
											width: '33%',
										}}
									>
										Image Color
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody id='alignLast'>
								<TableRow>
									<TableCell>{setUserAdmin.imageUrl}</TableCell>
									<TableCell>{setUserAdmin.imageUrlFileDestination}</TableCell>
									<TableCell>{setUserAdmin.imageColor}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						<Table sx={{ minWidth: 700 }} aria-label='customized table'>
							<TableHead
								id='alignLast'
								sx={{ backgroundColor: '#2c3e50', color: '#fff' }}
							>
								<TableRow>
									<TableCell
										sx={{
											color: '#fff',
											fontWeight: 'bold',
											border: '1px solid #fff',
											fontSize: '1.1rem',
											width: '33%',
										}}
									>
										Thumbnail Url
									</TableCell>
									<TableCell
										sx={{
											color: '#fff',
											fontWeight: 'bold',
											border: '1px solid #fff',
											fontSize: '1.1rem',
											width: '33%',
										}}
									>
										File Destination
									</TableCell>
									<TableCell
										sx={{
											color: '#fff',
											fontWeight: 'bold',
											border: '1px solid #fff',
											fontSize: '1.1rem',
											width: '33%',
										}}
									>
										Image Color
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody id='alignLast'>
								<TableRow>
									<TableCell>{setUserAdmin.thumbnail}</TableCell>
									<TableCell>{setUserAdmin.thumbnailFileDestination}</TableCell>
									<TableCell>{setUserAdmin.imageColor}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</div>
				)}
			</TableContainer>
		</div>
	) : (
		<div></div>
	);
}
