import { Component, Fragment, PureComponent, memo } from 'react';
import {
	onMessageListener,
	logoutAllTabs,
	refreshAllTabs,
	notIcon,
} from '../../firebase/firebaseInit';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	BottomNavigation,
	BottomNavigationAction,
	Box,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Skeleton,
} from '@mui/material';
import { withRouter } from '../../redux/withRouter';
import MenuIcon from '@mui/icons-material/Menu';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import Diversity1RoundedIcon from '@mui/icons-material/Diversity1Rounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { setNotificationRedirect } from '../../redux/actions/userActions';
import { scrollToTopFunc, setFeedData } from '../../redux/actions/dataActions';
import Searchbar from '../SocialComponents/Searchbar';
import logo from '../../util/Images/logo.svg';
import ImgSrc from '../SocialComponents/ImgSrc';
import Notifications from '../SocialComponents/Notifications';
import CustomTooltip from '../SocialComponents/CustomTooltip';
import ConfirmationDialog from '../SocialComponents/ConfirmationDialog';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowRight from '@mui/icons-material/ArrowRight';
import LightMode from './LightMode';
import Media from 'react-media';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import gbaLogo from '../../util/Images/gbaLogo.png';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LoginIcon from '@mui/icons-material/Login';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LanguageIcon from '@mui/icons-material/Language';
import SetLang from '../SocialComponents/SetLang';
import { t } from 'i18next';
const memoizeClassComponent = (Component) => {
	class MemoizedComponent extends PureComponent {
		render() {
			return <Component {...this.props} />;
		}
	}

	return memo(MemoizedComponent);
};

class Navbar extends Component {
	state = {
		panel: 'home',
		permission: false,
		notificationRedirect: false,
		// notificationTokenValidation: false,
		right: false,
		signoutDialog: false,
		newVersionDialog: false,
		languageDialog: false,
	};

	handleChange = (e, newValue) => {
		if (newValue === 'home') {
			this.travel(
				this.props.user.authenticated && this.props.user.emailVerified
					? '/feed'
					: '/',
			);
		} else if (newValue === 'profile') {
			this.props.history(`/users/${this.props.user.credentials.handle}`, {
				data: this.props.user.credentials.userId,
			});
		} else {
			this.travel(`/${newValue}`);
		}
		this.scrollToTop();
		this.setState({
			panel: newValue,
		});
	};

	componentDidUpdate(prevProps) {
		let userId = this.props.user.credentials.userId;
		let notObj = this.props.user.notificationUrlToRedirect;
		let notifications = this.props.user.notifications;

		if (
			Object.keys(notObj).length > 0 &&
			userId !== undefined &&
			userId === notObj.userId &&
			this.state.notificationRedirect === false &&
			(notifications.length > 0 ||
				notObj.type === 'app' ||
				notObj.type === 'legal')
		) {
			this.setState({ notificationRedirect: true }, () =>
				this.props.history(notObj.path),
			);

			this.props.setNotificationRedirect({});
		} else if (
			Object.keys(notObj).length > 0 &&
			userId !== undefined &&
			userId !== notObj.userId &&
			this.state.notificationRedirect === false
		) {
			// case where two or more users are using the same device and.. if the user === notObj.userId I set it to {}
			this.props.setNotificationRedirect({});
			this.setState({ notificationRedirect: true });
		}
	}

	checkFeedCollection = (feedCollection, showFeedFilterOptions) => {
		if (
			this.props.location.pathname === '/feed' &&
			(feedCollection === 'filtered' || showFeedFilterOptions)
		) {
			this.props.setFeedData({
				feedCollection: 'posts',
				showFeedFilterOptions: false,
			});
		}
		this.scrollToTop();
	};

	handleLogout = () => {
		this.setState({
			signoutDialog: false,
		});
		if (Object.keys(this.props.user.notificationUrlToRedirect).length > 0) {
			this.props.setNotificationRedirect({});
			this.setState({ notificationRedirect: false });
		}
		this.props.logoutAllTabs();
		this.props.refreshAllTabs(false);
	};

	travel = (path) => {
		this.props.history(path);
	};

	handleConfirmationDialog = (boolean, dialog) => {
		if (dialog === 'signout') {
			this.setState({ signoutDialog: boolean });
		} else if (dialog === 'newVersion') {
			this.setState({ newVersionDialog: boolean });
		} else if (dialog === 'language') {
			this.setState({ languageDialog: boolean });
		}
	};
	toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		this.setState({ anchor: open });
	};

	list = (anchor) => (
		<Box
			sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
			role='presentation'
			onClick={this.toggleDrawer(anchor, false)}
			onKeyDown={this.toggleDrawer(anchor, false)}
		>
			<hr className='mg0' />
			{this.props.UI.newVersionAvailable && (
				<List onClick={() => this.handleConfirmationDialog(true, 'newVersion')}>
					<ListItem disablePadding style={{ backgroundColor: '#2fcc71' }}>
						<ListItemButton>
							<ListItemIcon>
								<AppShortcutIcon style={{ color: '#fff' }} />
							</ListItemIcon>
							<ListItemText
								style={{ color: '#fff', fontWeight: 'bold' }}
								primary={<b>{t('new_version_av')}</b>}
							/>
						</ListItemButton>
					</ListItem>
				</List>
			)}

			{this.props.user.credentials.admin && (
				<List onClick={() => this.travel('/gbadmins')}>
					<ListItem disablePadding>
						<ListItemButton>
							<ListItemIcon>
								<AdminPanelSettingsIcon
									className={`${
										this.props.UI.mode === 'light' ? 'navMobileIcon' : ''
									}`}
								/>
							</ListItemIcon>
							<ListItemText primary={<b>{t('admin')}</b>} />
						</ListItemButton>
					</ListItem>
				</List>
			)}
			{this.props.user.authenticated && this.props.user.emailVerified && (
				<List
					onClick={() =>
						this.travel(`/users/${this.props.user.credentials.handle}`)
					}
				>
					<ListItem disablePadding>
						<ListItemButton>
							<ListItemIcon>
								<AccountCircleIcon
									className={`${
										this.props.UI.mode === 'light' ? 'navMobileIcon' : ''
									}`}
								/>
							</ListItemIcon>
							<ListItemText primary={<b>{t('profile')}</b>} />
						</ListItemButton>
					</ListItem>
				</List>
			)}
			<List onClick={() => this.travel('/contact')}>
				<ListItem disablePadding>
					<ListItemButton>
						<ListItemIcon>
							<InfoIcon
								className={`${
									this.props.UI.mode === 'light' ? 'navMobileIcon' : ''
								}`}
							/>
						</ListItemIcon>
						<ListItemText primary={<b>{t('support')}</b>} />
					</ListItemButton>
				</ListItem>
			</List>
			<List onClick={() => this.travel('/report')}>
				<ListItem disablePadding>
					<ListItemButton>
						<ListItemIcon>
							<BugReportOutlinedIcon
								className={`${
									this.props.UI.mode === 'light' ? 'navMobileIcon' : ''
								}`}
							/>
						</ListItemIcon>
						<ListItemText primary={<b>{t('report')}</b>} />
					</ListItemButton>
				</ListItem>
			</List>
			{this.props.user.authenticated && this.props.user.emailVerified && (
				<Media
					query='(max-width: 836px)'
					render={() => (
						<List onClick={() => this.travel('/donate')}>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<Diversity1OutlinedIcon
											className={`${
												this.props.UI.mode === 'light' ? 'navMobileIcon' : ''
											}`}
										/>
									</ListItemIcon>
									<ListItemText primary={<b>{t('donate')}</b>} />
								</ListItemButton>
							</ListItem>
						</List>
					)}
				/>
			)}

			<List onClick={() => this.handleConfirmationDialog(true, 'language')}>
				<ListItem disablePadding>
					<ListItemButton>
						<ListItemIcon>
							<LanguageIcon
								className={`${
									this.props.UI.mode === 'light' ? 'navMobileIcon' : ''
								}`}
							/>
						</ListItemIcon>
						<ListItemText primary={<b>{t('language')}</b>} />
					</ListItemButton>
				</ListItem>
			</List>

			{this.props.user.authenticated && this.props.user.emailVerified && (
				<List onClick={() => this.travel('/settings')}>
					<ListItem disablePadding>
						<ListItemButton>
							<ListItemIcon>
								<SettingsIcon
									className={`${
										this.props.UI.mode === 'light' ? 'navMobileIcon' : ''
									}`}
								/>
							</ListItemIcon>
							<ListItemText primary={<b>{t('settings')}</b>} />
						</ListItemButton>
					</ListItem>
				</List>
			)}

			{this.props.user.authenticated && this.props.user.emailVerified && (
				<div>
					<hr className='mg0' />
					<List onClick={() => this.handleConfirmationDialog(true, 'signout')}>
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									<ExitToAppIcon
										className={`${
											this.props.UI.mode === 'light' ? 'navMobileIcon' : ''
										}`}
									/>
								</ListItemIcon>
								<ListItemText primary={<b>{t('sign_out')}</b>} />
							</ListItemButton>
						</ListItem>
					</List>
				</div>
			)}
		</Box>
	);

	removeHandleFromString(str, name) {
		// Create a regular expression to match the name at the beginning of the string
		const regex = new RegExp(`^${name}\\s+`);
		// Replace the name at the beginning of the string with an empty string
		return str.replace(regex, '');
	}

	onToastClick = ({
		click_action,
		isAuth,
		recipient,
		notificationId,
		type,
	}) => {
		const path = click_action;
		const pathname =
			type === 'app' || type === 'legal'
				? new URL(path).pathname
				: click_action;

		if (isAuth) {
			this.travel(pathname);
		} else {
			if (this.props.user.emailVerified && this.props.user.authenticated) {
			} else {
				this.props.setNotificationRedirect({
					path: pathname,
					userId: recipient,
					notificationId: notificationId,
					type,
				});
				this.travel('/login');
			}
		}
	};

	// testNot = () => {
	// 	let obj = {
	// 		body: 'Click here to login.',
	// 		click_action: '/users/calvin/notifications/post/Ke84jkpIpTVYuaFBp6FL',
	// 		postId: 'Ke84jkpIpTVYuaFBp6FL',
	// 		recipient: 'm7qmJQw7xCUsaLnhYoS3Wq9MyQH2',
	// 		recipientHandle: 'calvin',
	// 		recipientIcon:
	// 			'https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/profileImages%2F5d5a7664-8c26-43e5-81ec-420860e32957_m7qmJQw7xCUsaLnhYoS3Wq9MyQH2_600x600.jpeg?alt=media',
	// 		recipientIconColor: '#4bfb00',
	// 		recipientThumbnail:
	// 			'https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/profileImages%2Fthumbnails%2F5d5a7664-8c26-43e5-81ec-420860e32957_m7qmJQw7xCUsaLnhYoS3Wq9MyQH2_100x100.jpeg?alt=media',
	// 		requireInteraction: 'true',
	// 		sender: 'kaBP6Z5Hi7YqhKGWPhb5sPHzGIm1',
	// 		senderHandle: 'valenmarta',
	// 		senderIcon:
	// 			'https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/profileImages%2F094ee720-dd21-478f-9ce3-9ceaa037e1e8_kaBP6Z5Hi7YqhKGWPhb5sPHzGIm1_600x600.jpeg?alt=media',
	// 		senderIconColor: '#6DCCFA',
	// 		senderThumbnail:
	// 			'https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/profileImages%2Fthumbnails%2F094ee720-dd21-478f-9ce3-9ceaa037e1e8_kaBP6Z5Hi7YqhKGWPhb5sPHzGIm1_100x100.jpeg?alt=media',
	// 		title: 'valenmarta wanted you to see this post.',
	// 		type: 'postRelease',
	// 	};

	// 	let {
	// 		title,
	// 		body,
	// 		click_action,
	// 		notificationId,

	// 		sender,
	// 		senderHandle,
	// 		senderIcon,
	// 		senderThumbnail,
	// 		senderIconColor,
	// 		recipient,
	// 		recipientHandle,
	// 		recipientIcon,
	// 		recipientThumbnail,
	// 		recipientIconColor,
	// 		// postId,
	// 		// letterId,
	// 		type,
	// 		// tag,
	// 		topic,
	// 	} = obj;
	// 	const storageUserId = localStorage.getItem('userId');

	// 	title = this.removeHandleFromString(title, senderHandle);

	// 	toast.info(
	// 		<div
	// 			onClick={() =>
	// 				this.onToastClick({
	// 					click_action,
	// 					isAuth:
	// 						type === 'app' ||
	// 						type === 'legal' ||
	// 						this.props.user.authenticated,
	// 					recipient,
	// 					notificationId,
	// 					type,
	// 				})
	// 			}
	// 		>
	// 			<div className='flex'>
	// 				{!this.props.user.authenticated &&
	// 				recipient === storageUserId &&
	// 				recipientIcon ? (
	// 					<ImgSrc
	// 						img={recipientIcon}
	// 						imgColor={recipientIconColor}
	// 						handle={recipientHandle}
	// 						userId={recipient}
	// 						thumbnail={recipientThumbnail}
	// 						css='toastImg'
	// 						fontSize='16px'
	// 						noMargin
	// 						margin='0 10px 0 0'
	// 					/>
	// 				) : senderIcon ? (
	// 					<ImgSrc
	// 						img={senderIcon}
	// 						imgColor={senderIconColor}
	// 						handle={senderHandle}
	// 						userId={sender}
	// 						thumbnail={senderThumbnail}
	// 						css='toastImg'
	// 						fontSize='16px'
	// 						noMargin
	// 						margin='0 10px 0 0'
	// 					/>
	// 				) : null}

	// 				<div className='flexColumn spaceAround'>
	// 					<div className='flex alignItemsCenter'>
	// 						{senderHandle && (
	// 							<span className='bold mgr4px'>{senderHandle}</span>
	// 						)}
	// 						{notIcon(obj, 'toast')}
	// 						{topic === 'globalTopic' && (
	// 							<span className='bold mgl4px'>{title}</span>
	// 						)}
	// 					</div>
	// 					{topic !== 'globalTopic' && title}
	// 				</div>
	// 			</div>
	// 			{topic === 'globalTopic' && <p className='mg0'>{body}</p>}
	// 			{/* <p className='mg0'>
	// 				{window.location.pathname === '/login' ? 'Log in to view.' : body}
	// 			</p> */}
	// 		</div>,
	// 		{
	// 			icon: false,
	// 			containerId: 'app',
	// 			position: 'top-left',
	// 			autoClose: 60000000,
	// 			toastId: notificationId,
	// 			closeButton: (
	// 				<div>
	// 					<div className='flexColumn'>
	// 						<CloseIcon />

	// 						{obj.thumbnail && (
	// 							<div className='mgtH'>
	// 								<img
	// 									src={obj.thumbnail}
	// 									className='toastThumbnail'
	// 									alt='thumbnail'
	// 								/>
	// 							</div>
	// 						)}
	// 					</div>
	// 				</div>
	// 			),
	// 		},
	// 	);
	// };

	updateNewVersion = () => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.getRegistration()
				.then((reg) => {
					if (reg && reg.waiting) {
						reg.waiting.postMessage('skipWaiting'); // Send message to skip waiting
					}
				})
				.catch((err) => {
					// console.log(err);
				});
		}
		this.handleConfirmationDialog(false, 'newVersion');
		window.location.reload();
	};

	scrollToTop = () => {
		scrollToTopFunc();
	};
	render() {
		const {
			user: {
				authenticated,
				emailVerified,
				credentials: {
					userId,
					handle,
					imageUrl,
					thumbnail,
					imageColor,
					disabled,
				},
				loadingUser,
				loadingLogout,
				errorLogingOut,
			},
			data: { feedCollection, showFeedFilterOptions },
			// UI: { mode },
		} = this.props;

		const { anchor, panel, signoutDialog, newVersionDialog, languageDialog } =
			this.state;
		const pathname = this.props.location.pathname;
		const auth = authenticated && emailVerified;

		onMessageListener()
			.then((payload) => {
				// console.log('NAVBAR ON MESSAGE LISTENER =>', payload);
				const {
					title,
					body,
					click_action,
					notificationId,
					sender,
					senderHandle,
					senderIcon,
					senderThumbnail,
					senderIconColor,
					recipient,
					recipientHandle,
					recipientIcon,
					recipientThumbnail,
					recipientIconColor,
					// postId,
					// letterId,
					type,
					// tag,
					topic,
				} = payload.data;
				const storageUserId = localStorage.getItem('userId');
				if (
					!authenticated ||
					(recipient === storageUserId &&
						(type === 'app' ||
							type === 'legal' ||
							type === 'accountInfringementRemoved' ||
							type === 'accountInfringement')) ||
					topic
				) {
					const notTitle = this.removeHandleFromString(title, senderHandle);

					toast.info(
						<div
							onClick={() =>
								this.onToastClick({
									click_action,
									isAuth:
										type === 'app' ||
										type === 'legal' ||
										this.props.user.authenticated,
									recipient,
									notificationId,
									type,
								})
							}
						>
							<div className='flex'>
								{!authenticated &&
								recipient === storageUserId &&
								recipientIcon ? (
									<ImgSrc
										img={recipientIcon}
										imgColor={recipientIconColor}
										handle={recipientHandle}
										userId={recipient}
										thumbnail={recipientThumbnail}
										css='toastImg'
										fontSize='16px'
										noMargin
										margin='0 10px 0 0'
									/>
								) : senderIcon ? (
									<ImgSrc
										img={senderIcon}
										imgColor={senderIconColor}
										handle={senderHandle}
										userId={sender}
										thumbnail={senderThumbnail}
										css='toastImg'
										fontSize='16px'
										noMargin
										margin='0 10px 0 0'
									/>
								) : null}

								<div className='flexColumn spaceAround'>
									<div className='flex alignItemsCenter'>
										{senderHandle && (
											<span className='bold mgr4px'>{senderHandle}</span>
										)}
										{notIcon(payload.data, 'toast')}
										{topic === 'globalTopic' && (
											<span className='bold mgl4px'>{notTitle}</span>
										)}
									</div>
									{topic !== 'globalTopic' && notTitle}
								</div>
							</div>
							{topic === 'globalTopic' && <p className='mg0'>{body}</p>}
							{/* <p className='mg0'>
					{window.location.pathname === '/login' ? 'Log in to view.' : body}
				</p> */}
						</div>,
						{
							icon: false,
							containerId: 'app',
							position: 'top-left',
							autoClose: 6000,
							toastId: notificationId,
							closeButton: (
								<div>
									<div className='flexColumn'>
										<CloseIcon />

										{payload.data.thumbnail && (
											<div className='mgtH'>
												<img
													src={payload.data.thumbnail}
													className='toastThumbnail'
													alt='thumbnail'
												/>
											</div>
										)}
									</div>
								</div>
							),
						},
					);
				}
			})
			.catch((err) => {
				toast.error(JSON.stringify(err));
			});

		return (
			<Fragment>
				<nav className='navbarContent sticky'>
					<Link
						to={disabled ? '#' : auth ? '/feed' : '/'}
						onClick={() =>
							this.checkFeedCollection(feedCollection, showFeedFilterOptions)
						}
					>
						<div className='flex alignItemsCenter'>
							<div className='backgroundLogo mgX '>
								<img className='logo' src={logo} alt='Logo' />
							</div>
							<img className='navbarGBLogo' src={gbaLogo} alt='Goodbye App' />
						</div>
					</Link>
					{/* <button onClick={this.testNot}>TOAST</button> */}
					{!disabled && (
						<Media
							query='(min-width: 1065px)'
							render={() => (
								<ul className='nav-menu-user'>
									<Link to={auth ? '/feed' : '/'} onClick={this.scrollToTop}>
										<div className='flexColumn nav-links-user mgrHome mgX'>
											{auth ? (
												<CustomTooltip title={t('home')} arrow>
													<IconButton
														aria-owns='simple-menu'
														aria-haspopup='true'
														color='primary'
													>
														{pathname === '/' || pathname === '/feed' ? (
															<div className='focusedTab'>
																<HomeRoundedIcon className='white' />
															</div>
														) : (
															<HomeOutlinedIcon className='white' />
														)}
													</IconButton>
												</CustomTooltip>
											) : pathname === '/' || pathname === '/feed' ? (
												<HomeRoundedIcon className='white' />
											) : (
												<HomeOutlinedIcon className='white' />
											)}

											{!auth && (
												<span
													className={`
									${pathname === '/' || pathname === '/feed' ? 'underline' : ''}
									`}
												>
													{t('home')}
												</span>
											)}
										</div>
									</Link>
									{auth && <Searchbar focus={true} navbar />}

									{auth && (
										<div className='nav-links-user'>
											<Notifications showBadge={true} />
										</div>
									)}

									<Link to='/donate' onClick={this.scrollToTop}>
										<div className='flexColumn nav-links-user mgX'>
											{auth ? (
												<CustomTooltip title={t('donate')} arrow>
													<IconButton
														aria-owns='simple-menu'
														aria-haspopup='true'
														color='primary'
													>
														{pathname === '/donate' ? (
															<div className='focusedTab'>
																<Diversity1RoundedIcon className='white' />
															</div>
														) : (
															<Diversity1OutlinedIcon className='white' />
														)}
													</IconButton>
												</CustomTooltip>
											) : (
												<div className='flexColumn alignItemsCenter'>
													{pathname === '/donate' ? (
														<Diversity1RoundedIcon className='white' />
													) : (
														<Diversity1OutlinedIcon className='white' />
													)}
													<span
														className={
															pathname === '/donate' ? 'underline' : ''
														}
													>
														{t('donate')}
													</span>
												</div>
											)}
										</div>
									</Link>
									{!auth && <SetLang />}
									{!auth && (
										<Link to='/login' onClick={this.scrollToTop}>
											<div className='signInLinks mgX'>
												<span
													className={pathname === '/login' ? 'underline' : ''}
												>
													{t('login')}
												</span>
											</div>
										</Link>
									)}
									{!auth && (
										<Link to='/signup' onClick={this.scrollToTop}>
											<div className='signInLinks'>
												<span
													className={pathname === '/signup' ? 'underline' : ''}
												>
													{t('signup')}
												</span>
											</div>
										</Link>
									)}

									{auth && (
										<div className='mgX'>
											{loadingUser ? (
												<Skeleton
													variant='circular'
													width={'45px'}
													height={'55px'}
												/>
											) : (
												<div className='bottomTranslate2'>
													<Link
														to={`/users/${handle}`}
														state={{
															data: userId,
														}}
														onClick={this.scrollToTop}
													>
														<ImgSrc
															img={imageUrl}
															thumbnail={thumbnail}
															handle={handle}
															imgColor={
																pathname === `/users/${handle}`
																	? imageColor
																	: ''
															}
															light={pathname !== `/users/${handle}`}
															css='navImg'
															fontSize='18px'
															noMargin
														/>
													</Link>
												</div>
											)}
										</div>
									)}

									<IconButton
										size='large'
										disableRipple={true}
										sx={{
											padding: '0 1rem 0 1rem',
											'& svg': {
												color: 'rgba(255,255,255,0.8)',
												transition: '0.2s',
												transform: 'translateX(0) rotate(0)',
											},
											'&:hover': {
												bgcolor: 'unset',
												'& svg:first-of-type': {
													transform: 'translateX(-4px) rotate(-20deg)',
												},
												'& svg:last-of-type': {
													right: 0,
													opacity: 1,
												},
											},
											'&:after': {
												content: '""',
												position: 'absolute',
												height: '80%',
												display: 'block',
												left: 0,
												width: '1px',
											},
										}}
										onClick={this.toggleDrawer('anchor', true)}
									>
										<SettingsIcon id='configSettingsIcon' />
										{/* <SvgIcon>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											fill='none'
											viewBox='0 0 24 24'
											strokeWidth={1.5}
											stroke='white'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												d='M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495'
											/>
										</svg>
									</SvgIcon> */}
										<ArrowRight
											sx={{ position: 'absolute', right: 4, opacity: 0 }}
										/>
									</IconButton>
								</ul>
							)}
						/>
					)}

					{signoutDialog && (
						<ConfirmationDialog
							open={signoutDialog}
							customButtonId='customCancelButton'
							closeFunc={() => this.handleConfirmationDialog(false, 'signout')}
							openFunc={() => this.handleConfirmationDialog(true, 'signout')}
							actionBtnText={t('sign_out')}
							action={this.handleLogout}
							loading={loadingLogout}
							error={errorLogingOut}
							startIcon={<ExitToAppIcon />}
							phrase={t('sign_out')}
							contentTitle={t('sign_out_prompt')}
						/>
					)}
					{newVersionDialog && (
						<ConfirmationDialog
							open={newVersionDialog}
							customButtonId='customInfoButton'
							closeFunc={() =>
								this.handleConfirmationDialog(false, 'newVersion')
							}
							openFunc={() => this.handleConfirmationDialog(true, 'newVersion')}
							actionBtnText={
								<div>
									{t('reload')}{' '}
									<span className='notranslate mgl4px'> Goodbye App</span>
								</div>
							}
							action={this.updateNewVersion}
							startIcon={<AllInclusiveIcon />}
							alertIcon={<AppShortcutIcon />}
							phrase={t('new_version_av')}
							contentTitle={t('click_below_to_reload')}
						/>
					)}
					{languageDialog && (
						<SetLang
							mobile
							closeDialog={() =>
								this.handleConfirmationDialog(false, 'language')
							}
						/>
					)}
					<Media
						query='(max-width: 1064px)'
						render={() => (
							<MenuIcon
								id='navMenuIcon'
								onClick={this.toggleDrawer('anchor', true)}
							/>
						)}
					/>
					<Drawer
						anchor={'right'}
						open={anchor}
						onClose={this.toggleDrawer('right', false)}
					>
						<LightMode />
						{this.list('right')}
					</Drawer>
				</nav>

				{!disabled && (
					<Media
						query='(max-width: 1064px)'
						render={() => (
							<BottomNavigation
								value={panel}
								onChange={this.handleChange}
								showLabels
								id='navMobile'
								sx={{
									height: auth ? '70px' : '80px',
								}}
							>
								<BottomNavigationAction
									id='navMobileAction'
									label={
										auth ? (
											''
										) : (
											<p
												className={`
								navMobileText white bold mg0
									${pathname === '/' || pathname === '/feed' ? 'underline' : ''}`}
											>
												{t('home')}
											</p>
										)
									}
									value='home'
									icon={
										pathname === '/' || pathname === '/feed' ? (
											<HomeRoundedIcon className='white' />
										) : (
											<HomeOutlinedIcon className='white' />
										)
									}
								/>
								{auth && <Searchbar focus={true} navMobile />}

								{auth && <Notifications showBadge={true} navMobile />}
								{auth && (
									<BottomNavigationAction
										value='profile'
										id='navMobileAction'
										icon={
											<ImgSrc
												img={imageUrl}
												thumbnail={thumbnail}
												handle={handle}
												imgColor={
													pathname === `/users/${handle}` ? imageColor : ''
												}
												light={pathname !== `/users/${handle}`}
												noMargin
												css='navBottomImg'
												fontSize='16px'
											/>
										}
									/>
								)}
								{!auth && (
									<BottomNavigationAction
										label={
											<p
												className={`
								navMobileText white bold mg0 mgr3px
									${pathname === '/donate' ? 'underline' : ''}`}
											>
												{t('donate')}
											</p>
										}
										id='navMobileAction'
										value='donate'
										icon={
											pathname === '/donate' ? (
												<Diversity1RoundedIcon className='white' />
											) : (
												<Diversity1OutlinedIcon className='white' />
											)
										}
									/>
								)}
								{!auth && (
									<BottomNavigationAction
										label={
											<p
												className={`
							navMobileText 	white bold mg0
									${pathname === '/login' ? 'underline' : ''}`}
											>
												{t('login')}
											</p>
										}
										id='navMobileAction'
										value='login'
										icon={<LoginIcon className='white' />}
									/>
								)}
								{!auth && (
									<BottomNavigationAction
										label={
											<p
												className={`
								navMobileText white bold mg0
									${pathname === '/signup' ? 'underline' : ''}`}
											>
												{t('signup')}
											</p>
										}
										value='signup'
										id='navMobileAction'
										icon={
											pathname === '/signup' ? (
												<AccountCircleIcon className='white' />
											) : (
												<AccountCircleOutlinedIcon className='white' />
											)
										}
									/>
								)}
							</BottomNavigation>
						)}
					/>
				)}
			</Fragment>
		);
	}
}

Navbar.propTypes = {
	setNotificationRedirect: PropTypes.func.isRequired,
	logoutAllTabs: PropTypes.func.isRequired,
	refreshAllTabs: PropTypes.func.isRequired,
	setFeedData: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	data: state.data,
	user: state.user,
	UI: state.UI,
});

const mapActionsToProps = {
	setNotificationRedirect,
	setFeedData,
	logoutAllTabs,
	refreshAllTabs,
};

export default memoizeClassComponent(
	connect(mapStateToProps, mapActionsToProps)(withRouter(Navbar)),
);
