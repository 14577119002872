import React, { Component } from 'react';
import {
	setAudioFile,
	resetAudioFile,
	clearAudioFile,
} from '../../redux/actions/dataActions';
import { setMicrophoneAccess } from '../../redux/actions/userActions';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js';
import CustomButton from '../SocialComponents/CustomButton';
import PauseIcon from '@mui/icons-material/Pause';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import GetAppIcon from '@mui/icons-material/GetApp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AudioPlayer from '../MediaPlayers/AudioPlayer';
import {
	Dialog,
	DialogContent,
	FormControl,
	Grow,
	InputLabel,
	MenuItem,
	Select,
	Toolbar,
} from '@mui/material';
import Media from 'react-media';
import CustomAlerts from '../SocialComponents/CustomAlerts';
import { toast } from 'react-toastify';
import { withRouter } from '../../redux/withRouter';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { connect } from 'react-redux';
import EnableMicrophone from '../../pages/USEFUL/EnableMicrophone';
import { t } from 'i18next';

const ffmpeg = new FFmpeg();

class AudioRecorder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			wavesurfer: null,
			record: null,
			recordedUrl: null,
			initialized: false,
			progress: '00:00',
			audioActionText: '',
			recordActionText: '',
			micOptions: [],
			micValue: '',
			showMicrophones: true,
			loadingAudio: false,
			loadingWavesurfer: false,
			prompt: false,
			opened: false,
		};
		this.prevTimeRef = React.createRef();
	}

	updateProgress = (time) => {
		const currentTimeInSeconds = Math.floor(time / 1000);
		// Update the state only if a whole second has passed
		if (currentTimeInSeconds !== this.prevTimeRef.current) {
			const formattedTime = [
				Math.floor((time % 3600000) / 60000), // minutes
				Math.floor((time % 60000) / 1000), // seconds
			]
				.map((v) => (v < 10 ? '0' + v : v))
				.join(':');
			this.setState({ progress: formattedTime });
			this.prevTimeRef.current = currentTimeInSeconds;
		}
	};

	recheckPermission = () => {
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then((stream) => {
				this.props.setMicrophoneAccess(true);

				RecordPlugin.getAvailableAudioDevices().then((devices) => {
					let availableMicOptions = devices.map((device) => ({
						value: device.deviceId,
						text: device.label || device.deviceId,
					}));
					this.setState({
						micValue: availableMicOptions[0].value,
						micOptions: availableMicOptions,
						showMicrophones: true,
						prompt: false,
					});
				});

				// Remember to stop the stream after checking
				stream.getTracks().forEach((track) => track.stop());
			})
			.catch((err) => {
				this.setState({
					showMicrophones: false,
					prompt: false,
				});
				// console.log(err);
			});
	};

	isMicrophoneAllowed = () => {
		if (!navigator.permissions) {
			console.log('The Permissions API is not supported in this browser.');
			// Fallback logic to determine if the microphone is allowed, if possible
			// For example, try to access the microphone directly and catch errors
			this.recheckPermission();
			return;
		}
		navigator.permissions
			.query({
				name: 'microphone',
			})
			.then((permissionStatus) => {
				const showMicrophones = permissionStatus.state === 'granted';
				const prompt =
					!showMicrophones &&
					!this.props.user.microphoneAccess &&
					permissionStatus.state === 'prompt';
				if (showMicrophones) {
					this.props.setMicrophoneAccess(true);
				}
				this.setState({
					showMicrophones,
					prompt,
				});

				permissionStatus.onchange = (event) => {
					if (event.target.state === 'granted') {
						this.props.setMicrophoneAccess(true);
						RecordPlugin.getAvailableAudioDevices().then((devices) => {
							let availableMicOptions = [];
							devices.forEach((device) => {
								let micOption = {
									value: device.deviceId,
									text: device.label || device.deviceId,
								};
								availableMicOptions.push(micOption);
							});
							this.setState({
								micValue: availableMicOptions[0].value,
								micOptions: availableMicOptions,
								showMicrophones: true,
							});
						});
					} else if (event.target.state === 'denied') {
						this.setState({
							showMicrophones: false,
						});
						this.props.setMicrophoneAccess(false);
					} else if (permissionStatus.state === 'prompt') {
						this.setState({
							showMicrophones: false,
							prompt: true,
						});
						this.props.setMicrophoneAccess(false);
					}
				};
			})
			.catch((err) => {
				// console.log(err)
			});
	};

	componentDidMount() {
		const ws = WaveSurfer.create({
			container: '#mic',
			waveColor: '#009de0',
			progressColor: '#000',
		});
		this.setState({ wavesurfer: ws });
		if (this.props.editedAudioFile !== null) {
			this.setState({
				recordedUrl: this.props.editedAudioFile,
			});
		}
		if (!this.props.user.microphoneAccess) {
			this.isMicrophoneAllowed();
		}
	}
	componentWillUnmount() {
		this.state.record.stopRecording();
		if (
			this.state.record &&
			(this.state.record.isRecording() || this.state.record.isPaused())
		) {
			this.setState({
				record: null,
				recordedUrl: null,
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.wavesurfer && this.state.wavesurfer) {
			this.setupWaveSurfer();
		}
	}

	setupWaveSurfer = () => {
		const ws = this.state.wavesurfer;

		RecordPlugin.getAvailableAudioDevices().then((devices) => {
			let availableMicOptions = [];
			devices.forEach((device) => {
				let micOption = {
					value: device.deviceId,
					text: device.label || device.deviceId,
				};
				availableMicOptions.push(micOption);
			});
			this.setState({
				micValue: availableMicOptions[0].value,
				micOptions: availableMicOptions,
			});
		});

		const newRecord = ws.registerPlugin(
			RecordPlugin.create({
				renderRecordedAudio: false,
			}),
		);
		this.setState({
			record: newRecord,
			recordActionText: t('record_audio'),
		});

		newRecord.on('record-end', async (blob) => {
			this.setState({
				loadingAudio: true,
			});
			const ffmpegInput = 'input.wav'; // Input file name (used by FFmpeg)
			const ffmpegOutput = 'output.mp3'; // Output file name (used by FFmpeg)

			await ffmpeg.load();
			// Write the audio blob to a file
			await ffmpeg.writeFile(ffmpegInput, await fetchFile(blob));

			// Run FFmpeg command to convert the audio
			await ffmpeg.exec([
				'-i',
				ffmpegInput,
				'-codec:a',
				'libmp3lame',
				'-q:a',
				'2',
				ffmpegOutput,
			]);

			// Read the converted audio file from FFmpeg's file system
			const convertedAudio = await ffmpeg.readFile(ffmpegOutput);

			// Create a new Blob from the converted audio data
			const convertedBlob = new Blob([convertedAudio.buffer], {
				type: 'audio/mp3',
			});

			const recordedUrl = URL.createObjectURL(convertedBlob);
			let file = new File([convertedBlob], 'audio.mp3', { type: 'audio/mp3' });
			let fileSrc = URL.createObjectURL(convertedBlob);
			let fileData = { fileToUpload: file, fileSrc: fileSrc };
			this.props.setAudioFile(fileData);
			this.setState({
				recordedUrl: recordedUrl,
				loadingAudio: false,
			});
		});

		newRecord.on('record-progress', (time) => {
			this.updateProgress(time);
			if (time >= 3600000) {
				//reached an hour STOP!
				this.state.record.stopRecording();
			}
		});
	};

	audioAction = () => {
		if (this.state.record.isPaused()) {
			this.setState({ audioActionText: t('pause') });
			this.state.record.resumeRecording();
		} else {
			this.setState({ audioActionText: t('resume') });
			this.state.record.pauseRecording();
		}
	};

	recordAudio = () => {
		if (this.state.record.isRecording() || this.state.record.isPaused()) {
			this.state.record.stopRecording();
		} else {
			if (this.props.secondClose) {
				this.props.secondAction();
			}
			const deviceId = this.state.micValue;
			this.state.record
				.startRecording({ deviceId })
				.then(() => {
					this.props.clearAudioFile();

					if (this.props.setFileType) {
						this.props.setFileType('audio', true);
					}
					this.setState({
						recordActionText: t('finish'),
						initialized: true,
						audioActionText: t('pause'),
					});
				})
				.catch((err) => {
					// console.log('error recording:', err);
				});
		}
	};

	deleteAudio = () => {
		if (this.state.wavesurfer) {
			this.state.wavesurfer.destroy();
		}

		this.setState({ wavesurfer: null });

		if (this.props.onClose) {
			this.props.onClose();
		}
		if (this.props.deletedAudioFile && !this.props.editedAudioFile) {
			let fileData = {
				fileToUpload: this.props.deletedEditedAudioFileToUpload,
				fileSrc: this.props.deletedAudioFile,
			};

			this.props.setAudioFile(fileData);
			if (this.props.setFileType) {
				this.props.setFileType('audio');
			}
			this.setState({
				recordedUrl: this.props.deletedAudioFile,
			});
		} else {
			this.props.resetAudioFile();
			setTimeout(() => {
				const ws = WaveSurfer.create({
					container: '#mic',
					waveColor: '#009de0',
					progressColor: '#000',
				});
				this.setState({
					recordActionText: t('record_audio'),
					recordedUrl: null,
					progress: '00:00',
					record: null,
					wavesurfer: ws,
					audioActionText: '',
					initialized: false,
				});
				this.setupWaveSurfer();
			}, 100);
		}
		if (this.props.setFileType) {
			this.props.setFileType('');
		}
	};

	handleChange = (e) => {
		e.preventDefault();
		this.setState({ micValue: e.target.value });
	};

	downloadAudio = () => {
		if (this.state.recordedUrl) {
			const link = document.createElement('a');
			link.href = this.state.recordedUrl;
			link.download = 'audio_GoodbyeApp';
			link.click();
		}
	};

	requestMicPermission = () => {
		this.setState({
			loadingWavesurfer: true,
		});
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then((stream) => {
				this.setState({
					showMicrophones: true,
					loadingWavesurfer: false,
				});
				this.setupWaveSurfer();
				stream.getTracks().forEach((track) => track.stop());
			})
			.catch((err) => {
				// console.log(err);
				this.setState({
					loadingWavesurfer: false,
				});
				toast.info(t('mic_perm_denied'), {
					position: 'bottom-left',
					autoClose: false,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					containerId: 'app',
					toastId: 'requestMicPermission',
					onClick: () => this.props.history('/enableMicrophone'),
				});
			});
	};

	openDialog = () => {
		this.setState((prevState) => ({ opened: !prevState.opened }));
	};
	render() {
		const {
			severity,
			icon,
			message,
			tooltip,
			closeIcon,
			deletedAudioFile,
			secondTooltip,
			secondIcon,
			secondClose,
			secondAction,
			openAudioInput,
			clickAudioInput,
			editedAudioFile,
			fileType,
			disabled,
			makePostFunctionality,
		} = this.props;
		const {
			recordedUrl,
			initialized,
			recordActionText,
			audioActionText,
			micOptions,
			progress,
			micValue,
			showMicrophones,
			loadingAudio,
			loadingWavesurfer,
			prompt,
			opened,
		} = this.state;

		return (
			<div>
				<Media
					queries={{
						isMobile: '(max-width: 1064px)',
						verySmall: '(max-width: 400px)',
					}}
				>
					{(matches) => (
						<div>
							{(deletedAudioFile ||
								recordedUrl ||
								recordActionText !== t('record_audio')) && (
								<Grow in={true}>
									<div>
										<CustomAlerts
											info={true}
											severity={severity}
											icon={icon}
											message={message}
											tooltip={tooltip}
											secondTooltip={secondTooltip}
											closeIcon={deletedAudioFile ? closeIcon : false}
											onClose={this.deleteAudio}
											close={deletedAudioFile}
											noMargin
											noCenter
											iconButton={true}
											secondClose={secondClose}
											showBtn={recordedUrl}
											btnClick={this.downloadAudio}
											btnTooltip={t('download')}
											btnIcon={<GetAppIcon />}
											secondIcon={secondIcon}
											secondAction={secondAction}
											alertId='smallMuiAlert'
										/>
									</div>
								</Grow>
							)}

							{!initialized && !editedAudioFile && (
								<div>
									{!showMicrophones && (
										<div>
											<CustomAlerts
												error={!showMicrophones && !prompt}
												severity='warning'
												noMargin
												icon={<GraphicEqIcon />}
												message={
													<div className='text alignItemsCenter'>
														{t('mic_access_denied')}
														<CustomButton
															onClick={this.openDialog}
															className='topTranslate'
															btnText={t('how_to_mic_perm')}
															id='underlineHover'
															margin='0'
															padding='0 0 0 6px'
															disableRipple
														/>
													</div>
												}
												alertId='smallMuiAlert'
											/>
											<CustomAlerts
												info={!showMicrophones && prompt}
												noMargin
												loading={loadingWavesurfer}
												icon={<GraphicEqIcon fontSize='small' />}
												message={
													<div className='text alignItemsCenter'>
														{t('to_access_mic')}
														<CustomButton
															onClick={this.recheckPermission}
															className='topTranslate'
															btnText={t('request_perm')}
															id='underlineHover'
															margin='0'
															padding='0 0 0 6px'
															disableRipple
														/>
													</div>
												}
												alertId='smallMuiAlert'
											/>
										</div>
									)}

									{showMicrophones && micOptions.length > 0 && (
										<div>
											<CustomAlerts
												success={t('voice_recording')}
												icon={<GraphicEqIcon />}
												noMargin
												noCenter={matches.isMobile}
												alertId='smallMuiAlert'
											/>
											<Media
												query='(min-width: 800px)'
												render={() => (
													<FormControl
														id='mgt'
														style={{ minWidth: '200px' }}
														disabled={disabled}
													>
														<InputLabel id='audio_microphone'>
															{t('microphone')}
														</InputLabel>
														<Select
															labelId='Microphone'
															value={micValue}
															defaultValue=''
															label={t('microphone')}
															onChange={this.handleChange}
														>
															{micOptions.map((option, index) => (
																<MenuItem value={option.value} key={index}>
																	{option.text}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												)}
											/>
										</div>
									)}
								</div>
							)}

							<div className='flex alignItemsCenter'>
								{!initialized && openAudioInput && makePostFunctionality && (
									<div style={{ width: '50%' }}>
										<CustomButton
											onClick={clickAudioInput}
											variant='contained'
											btnText={t('audio_file')}
											id='customInfoButton'
											margin='1rem auto'
											startIcon={<GraphicEqIcon id='iconButton' />}
										/>
									</div>
								)}
								{!recordedUrl && (
									<div
										style={{
											width:
												!recordedUrl && recordActionText !== t('record_audio')
													? '45%'
													: fileType === 'text' ||
													  //   deletedAudioFile ||
													  !makePostFunctionality
													? '100%'
													: '50%',
										}}
									>
										<CustomButton
											onClick={this.recordAudio}
											btnText={recordActionText}
											variant='contained'
											loading={loadingAudio}
											id={
												prompt || !showMicrophones
													? ''
													: recordActionText === t('record_audio')
													? 'customCancelButton'
													: 'customInfoButton'
											}
											margin='1rem auto'
											startIcon={
												recordActionText === t('record_audio') ? (
													<RadioButtonCheckedIcon className='iconRed' />
												) : (
													<CheckCircleIcon />
												)
											}
											disabled={disabled || prompt || !showMicrophones}
										/>
									</div>
								)}

								{!recordedUrl && recordActionText !== t('record_audio') && (
									<b className='mgXH' style={{ width: '10%' }}>
										{progress}
									</b>
								)}

								{!recordedUrl && audioActionText && (
									<div style={{ width: '45%' }}>
										<CustomButton
											btnText={matches.verySmall ? '' : audioActionText}
											onClick={this.audioAction}
											variant='contained'
											id='customCancelButton'
											margin='1rem auto'
											startIcon={
												audioActionText === t('pause') ? (
													<PauseIcon />
												) : (
													<PlayArrowIcon />
												)
											}
										/>
									</div>
								)}
							</div>

							<div
								className={
									!recordedUrl && audioActionText ? 'pdtExcept' : 'pdb3px'
								}
							>
								<div
									id='mic'
									style={{
										border: '1px solid #ddd',
										borderRadius: '4px',
										display: initialized && !recordedUrl ? 'block' : 'none',
									}}
								/>
								<div id='recordings' />
							</div>
							{recordedUrl && (
								<AudioPlayer
									fileUrl={recordedUrl}
									postId='preview'
									waveColor='#7e7e7e'
									makePostFunctionality
								/>
							)}

							<Dialog
								open={opened}
								onClose={this.openDialog}
								disableEnforceFocus
								fullWidth
								maxWidth={!matches.isMobile ? 'xl' : false}
								fullScreen={matches.isMobile}
							>
								<Toolbar id='dialogBar'>
									<div className='titleToolbar'>{t('audio_permissions')}</div>
									<CloseIcon
										id='closeDialogButton'
										onClick={this.openDialog}
										aria-label='close'
									/>
								</Toolbar>

								<DialogContent sx={{ padding: 0 }}>
									<EnableMicrophone popup />
								</DialogContent>
							</Dialog>
						</div>
					)}
				</Media>
			</div>
		);
	}
}

const mapActionsToProps = {
	setAudioFile,
	resetAudioFile,
	clearAudioFile,
	setMicrophoneAccess,
};
const mapStateToProps = (state) => ({
	user: state.user,
});
export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(AudioRecorder));
