import { Component } from 'react';
import PropTypes from 'prop-types';
import {
	applyActionCode,
	confirmPasswordReset,
	verifyPasswordResetCode,
	checkActionCode,
	sendPasswordResetEmail,
	fetchSignInMethodsForEmail,
} from 'firebase/auth';
import history from '../../browserHistory';
import { auth } from '../../firebase/config';
import store from '../../redux/store';
import {
	SET_VERIFIED_EMAIL_OOBCODE,
	VERIFYING_EMAIL_OOBCODE,
	ERROR_VERIFYING_EMAIL_OOBCODE,
	VERIFYING_RESET_PASSWORD_OOBCODE,
	SET_VERIFIED_RESET_PASSWORD_OOBCODE,
	ERROR_VERIFYING_RESET_PASSWORD_OOBCODE,
	VERIFYING_RESET_EMAIL_OOBCODE,
	SUCCESS_SENDING_PASSWORD_RESET_EMAIL,
	ERROR_SENDING_PASSWORD_RESET_EMAIL,
	ERROR_RESET_EMAIL_OOBCODE,
	EMAIL_SUCCESFULLY_UPDATED,
	CLEAR_ACCOUNT_ACTION_TYPES,
	SENT_TOO_MANY_EMAILS,
	SENDING_PASSWORD_RESET_EMAIL,
} from '../../redux/types';
import {
	changeUsername,
	checkUsernameAvailability,
	updateUserEmail,
	deleteUser,
	getUserData,
} from '../../redux/actions/userActions';
import {
	logoutAllTabsListener,
	logoutAllTabs,
	loginAllTabs,
	loginAllTabsListener,
	sendSignInLinkToEmail,
} from '../../firebase/firebaseInit';
import { Helmet } from 'react-helmet-async';
import { Avatar, Grid } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PasswordIcon from '@mui/icons-material/Password';
import { connect } from 'react-redux';
import { withRouter } from '../../redux/withRouter';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import CustomInput from '../../components/SocialComponents/CustomInput';
import CustomButton from '../../components/SocialComponents/CustomButton';
import {
	isEmail,
	launchConfetti,
	scrollToTopFunc,
} from '../../redux/actions/dataActions';
import Footer from '../../components/Footer/Footer';
import axios from 'axios';
import { t } from 'i18next';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PasswordStrength from '../../components/SocialComponents/PasswordStrength';

class AccountActions extends Component {
	state = {
		email: '',
		password: '',
		confirmPassword: '',
		showPasswordRequirements: false,
		usernameErrors: {},
		emailErrors: {},
		passwordErrors: {},
		confirmPasswordErrors: {},
		mode: '',
		error: '',
		newEmail: '',
		previousEmail: '',

		checkingEmailValidation: false,
		redirectLogin: false,
		inputHandle: '',
		checkAvailability: true,
		userData: {},
		resetPasswordInfo: false,
		alreadySentTwice: false,
	};

	componentDidMount() {
		scrollToTopFunc();
		this.authAction();
		this.props.loginAllTabsListener();
		this.props.logoutAllTabsListener();
	}

	clearTextfield = (key) => {
		this.setState({ [key]: '' });
	};

	authAction = () => {
		// Get the action to complete.
		const mode = this.getParameterByName('mode');
		// Get the one-time code from the query parameter.
		const actionCode = this.getParameterByName('oobCode');
		// (Optional) Get the continue URL from the query parameter if available.
		// const continueUrl = this.getParameterByName('continueUrl');
		// // (Optional) Get the language code if available.
		// const lang = this.getParameterByName('lang') || 'en';

		if (mode === 'verifyEmail') {
			this.handleVerifyEmail(actionCode);
			this.setState({
				mode: mode,
				redirectLogin: true,
			});
		} else if (mode === 'resetPassword') {
			this.setState({
				mode: mode,
				actionCode: actionCode,
			});
		} else if (mode === 'recoverEmail') {
			this.setState({
				mode: mode,
				actionCode: actionCode,
			});
			this.handleRecoverEmail(actionCode);
		} else if (mode === 'verifyAndChangeEmail') {
			this.setState({
				mode: mode,
				actionCode: actionCode,
			});
			this.verifyAndChangeEmail(actionCode);
		} else if (this.props.user.handleUpdateEmailAddress) {
			this.setState({
				mode: 'setNewEmail',
			});
		} else if (this.props.user.handleUpdateUsername) {
			this.setState({
				mode: 'setNewUsername',
			});
		} else if (this.props.user.handleUpdatePassword) {
			this.setState({
				mode: 'changePassword',
			});
			this.resetPassword(this.props.user.handleUpdatePassword);
		} else if (this.props.user.deleteAccount) {
			this.setState({
				mode: 'deleteUserAccount',
			});
		} else {
			this.setState({
				redirectLogin: true,
			});
		}
		window.history.pushState('', '', '/accountActions');
	};

	static getDerivedStateFromProps(nextProps, state) {
		if (
			nextProps.user.usernameAvailable &&
			state.inputHandle === nextProps.user.usernameAvailable
		) {
			return {
				buttonText: t('update'),
				checkAvailability: false,
			};
		}
		return null;
	}

	handleSubmit = (event) => {
		event.preventDefault();
		let state = this.state;

		switch (state.mode) {
			case 'resetPassword':
				// Display change password handler and UI.
				this.handleResetPassword(state.actionCode);

				break;
			case 'changePassword':
				this.resetPassword(this.props.user.handleUpdatePassword);
				this.setState({
					alreadySentTwice: true,
				});
				break;

			case 'recoverEmail':
				// Display email recovery handler and UI.
				this.handleRecoverEmail(state.actionCode, true);
				break;

			case 'setNewEmail':
				this.setNewEmail();
				break;

			case 'setNewUsername':
				this.setNewUsername();
				break;

			case 'deleteUserAccount':
				this.props.deleteUser();
				this.props.logoutAllTabs();
				break;
			default:
				this.setState({ error: 'Invalid.' });
			// Error: invalid mode.
		}
	};

	testEmail = () => {
		if (this.state.email.trim() === '') {
			this.focus('email');
			this.setState({
				emailErrors: {
					email: t('email_error_enter_address'),
				},
			});
		} else if (!isEmail(this.state.email)) {
			this.focus('email');
			this.setState({
				emailErrors: {
					email: t('email_error_invalid_address'),
				},
			});
		} else {
			this.setState({
				emailErrors: { email: '' },
			});
			return true;
		}
	};

	testConfirmPass = () => {
		let value = this.state.confirmPassword;
		if (value === '') {
			this.focus('confirmPassword');
			this.setState({
				confirmPasswordErrors: {
					confirmPassword: t('re_enter_password'),
				},
			});
		} else if (this.state.password !== value) {
			this.focus('confirmPassword');
			this.setState({
				confirmPasswordErrors: {
					confirmPassword: t('passwords_dont_match'),
				},
			});
		} else {
			this.setState({
				confirmPasswordErrors: { confirmPassword: '' },
			});
			return true;
		}
	};

	handleChange = (event) => {
		// if (this.state.showPasswordRequirements) {
		// 	this.setState({ showPasswordRequirements: false });
		// }

		if (event.target.name === 'inputHandle') {
			this.setState({
				[event.target.name]: event.target.value.toLowerCase().trim(),
			});
			if (this.state.usernameErrors.handle) {
				this.setState({
					usernameErrors: {},
				});
			}
			if (
				event.target.value.toLowerCase().trim() ===
				this.props.user.usernameAvailable
			) {
				this.setState({
					checkAvailability: false,
					buttonText: t('update'),
				});
			} else {
				this.setState({
					checkAvailability: true,
					buttonText: t('check_availability'),
				});
			}
		} else if (event.target.name === 'password') {
			this.setState({
				[event.target.name]: event.target.value,
			});
			if (this.state.passwordErrors.handle) {
				this.setState({
					passwordErrors: {},
				});
			}
			// this.countCharacters(event.target.value);
			// this.setState({ showPasswordRequirements: true });
		} else if (event.target.name === 'confirmPassword') {
			this.setState({
				[event.target.name]: event.target.value,
			});
			if (this.state.confirmPasswordErrors.handle) {
				this.setState({
					confirmPasswordErrors: {},
				});
			}
		} else if (event.target.name === 'email') {
			this.setState({
				[event.target.name]: event.target.value.trim(),
			});
			if (this.state.emailErrors.handle) {
				this.setState({
					emailErrors: {},
				});
			}
		}
	};

	getParameterByName = (name) => {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		var regexS = '[\\?&]' + name + '=([^&#]*)';
		var regex = new RegExp(regexS);
		var results = regex.exec(window.location.href);
		if (results == null) return '';
		else return decodeURIComponent(results[1].replace(/\+/g, ' '));
	};
	verifyAndChangeEmail = (actionCode) => {
		//dispatch changing email;
		let newEmail;
		let previousEmail;
		store.dispatch({ type: VERIFYING_RESET_EMAIL_OOBCODE });
		checkActionCode(auth, actionCode)
			.then((info) => {
				newEmail = info.data.email;
				previousEmail = info.data.previousEmail;
				this.setState({ newEmail: newEmail, previousEmail: previousEmail });

				applyActionCode(auth, actionCode).then(() => {
					scrollToTopFunc();

					// Email address has been verified.
					store.dispatch({
						type: SET_VERIFIED_EMAIL_OOBCODE,
						payload: t('email_successfully_verified'),
					});
					launchConfetti(1);

					if (
						this.props.user.authenticated &&
						this.props.user.credentials.email === previousEmail
					) {
						this.props.updateUserEmail(newEmail, previousEmail);
					} else {
						this.props.logoutAllTabs({
							signInEmail: newEmail,
						});
					}
				});
			})
			.catch((error) => {
				// console.log(error);

				if (error.code === 'auth/invalid-action-code') {
					store.dispatch({
						type: ERROR_RESET_EMAIL_OOBCODE,
						payload: t('link_expired'),
					});
				} else {
					store.dispatch({
						type: ERROR_RESET_EMAIL_OOBCODE,
						payload: error.message,
					});
				}
			});
	};
	handleVerifyEmail = (actionCode) => {
		history.replace('/login');
		store.dispatch({ type: VERIFYING_EMAIL_OOBCODE, payload: true });
		applyActionCode(auth, actionCode)
			.then((resp) => {
				scrollToTopFunc();
				// Email address has been verified.
				store.dispatch({
					type: SET_VERIFIED_EMAIL_OOBCODE,
					payload: t('email_verified_success'),
				});
				if (auth.currentUser !== null) {
					auth.currentUser
						.getIdToken(true)
						.then((idToken) => {
							const FBIdToken = `Bearer ${idToken}`;
							localStorage.setItem('FBIdToken', FBIdToken);
							axios.defaults.headers.common['Authorization'] = FBIdToken;
							store.dispatch(getUserData('loadNewUser'));
						})
						.catch((error) => {
							// console.log('error new idToken', error);
						});
				}
				launchConfetti(1);
			})
			.catch((error) => {
				// Code is invalid or expired. Ask the user to verify their email address
				// again.

				if (error.code === 'auth/invalid-action-code') {
					store.dispatch({
						type: ERROR_VERIFYING_EMAIL_OOBCODE,
						payload: t('login_again_for_new_verification_link'),
					});
				} else {
					store.dispatch({
						type: ERROR_VERIFYING_EMAIL_OOBCODE,
						// payload: error.response.data.error.message,
						payload: error.message,
					});
				}
			});
	};
	handleResetPassword = (actionCode, continueUrl, lang) => {
		if (this.testPassword() && this.testConfirmPass()) {
			store.dispatch({ type: VERIFYING_RESET_PASSWORD_OOBCODE });
			let accountEmail;
			// Verify the change password code is valid.
			verifyPasswordResetCode(auth, actionCode)
				.then((email) => {
					accountEmail = email;

					const newPassword = this.state.password;

					confirmPasswordReset(auth, actionCode, newPassword)
						.then(() => {
							scrollToTopFunc();

							store.dispatch({
								type: SET_VERIFIED_RESET_PASSWORD_OOBCODE,
								payload: t('password_successfully_updated'),
							});
							launchConfetti(1);
							// Password change has been confirmed and new password updated.
							const userData = {
								email: accountEmail,
								password: newPassword,
								action: 'accountActions',
							};
							this.setState({ userData });
							this.props.loginAllTabs(userData);
						})
						.catch((error) => {
							// Error occurred during confirmation. The code might have expired or the
							// password is too weak.
							// console.log(error);
						});
				})
				.catch((error) => {
					// Invalid or expired action code. Ask user to try to change the password
					// again.

					if (
						error.code === 'auth/invalid-action-code' ||
						error.code === 'auth/expired-action-code'
					) {
						store.dispatch({
							type: ERROR_VERIFYING_RESET_PASSWORD_OOBCODE,
							payload: t('change_password_link_expired'),
						});
						if (accountEmail !== undefined) {
							this.resetPassword(accountEmail);
						} else if (this.props.user.credentials.email) {
							this.resetPassword(this.props.user.credentials.email);
						} else {
							this.setState({
								resetPasswordInfo: t('change_password_link_validity'),
							});
						}
					} else {
						store.dispatch({
							type: ERROR_VERIFYING_RESET_PASSWORD_OOBCODE,
							// payload: error.response.data.error.message,
							payload: error.message,
						});
					}
				});
		}
	};

	handleRecoverEmail = (actionCode, recover) => {
		// Localize the UI to the selected language as determined by the lang
		// parameter.

		if (recover) {
			store.dispatch({ type: VERIFYING_RESET_EMAIL_OOBCODE });
		}
		let restoredEmail = null;
		let email = null;
		// Confirm the action code is valid.
		checkActionCode(auth, actionCode)
			.then((info) => {
				// Get the restored email address.
				email = info.data.previousEmail;
				restoredEmail = info['data']['email'];
				this.setState({ newEmail: email, previousEmail: restoredEmail });
				// Revert to the old email.
				if (recover) {
					return applyActionCode(auth, actionCode);
				}
			})
			.then((data) => {
				// Account email reverted to restoredEmail
				if (recover) {
					this.resetPassword(restoredEmail, true);
					let data = {
						email: restoredEmail,
						message: t('email_successfully_updated'),
					};
					scrollToTopFunc();

					store.dispatch({ type: EMAIL_SUCCESFULLY_UPDATED, payload: data });
					if (
						this.props.user.authenticated &&
						this.props.user.credentials.email === email
					) {
						updateUserEmail(restoredEmail, email);
					} else {
						this.props.logoutAllTabs({
							signInEmail: restoredEmail,
						});
					}
				}
			})
			.catch((error) => {
				// Some error occurred, you can inspect the code: error.code
				// console.log(error);
				if (error.code === 'auth/invalid-action-code') {
					store.dispatch({
						type: ERROR_RESET_EMAIL_OOBCODE,
						payload: t('recovery_link_used_or_expired'),
					});
					// this.props.sendSignInLinkToEmail(email, true);
				} else if (error.code === 'Firebase: Error (auth/missing-email).') {
					store.dispatch({
						type: ERROR_RESET_EMAIL_OOBCODE,
						payload: t('email_error_enter_address'),
					});
				} else {
					store.dispatch({
						type: ERROR_RESET_EMAIL_OOBCODE,
						payload: error.code,
					});
				}
			});
	};
	setNewUsername = () => {
		let newUsername = this.state.inputHandle;
		if (this.testUsername(newUsername)) {
			if (this.state.checkAvailability) {
				this.props.checkUsernameAvailability(newUsername);
			} else {
				this.props.changeUsername(newUsername);
			}
		}
	};
	setNewEmail() {
		// Localize the UI to the selected language as determined by the lang
		// parameter.
		let newEmail = this.state.email;
		if (newEmail === this.props.user.credentials.email) {
			this.setState({
				emailErrors: {
					email: t('enter_different_email_address'),
				},
			});
		} else if (this.testEmail(newEmail)) {
			// check that new email doesnt already exist.
			this.setState({
				checkingEmailValidation: true,
			});

			fetchSignInMethodsForEmail(auth, newEmail).then((result) => {
				if (result.length > 0) {
					this.setState({
						emailErrors: {
							email: t('email_address_in_use'),
						},
						checkingEmailValidation: false,
					});
				} else {
					this.props.logoutAllTabs({
						newEmailForSignIn: newEmail,
						currentEmailForSignIn: this.props.user.credentials.email,
					});
				}
			});
		} else if (newEmail === '') {
			this.setState({
				emailErrors: {
					email: t('email_error_invalid_address'),
				},
			});
		} else if (newEmail === this.props.user.credentials.email) {
			this.setState({
				emailErrors: {
					email: t('account_has_same_email_address'),
				},
			});
		}
	}

	resetPassword = (email, compromised) => {
		store.dispatch({
			type: SENDING_PASSWORD_RESET_EMAIL,
		});
		sendPasswordResetEmail(auth, email)
			.then(() => {
				if (compromised) {
					store.dispatch({
						type: SUCCESS_SENDING_PASSWORD_RESET_EMAIL,
						payload: t('new_change_password_link_sent', { email }),
					});
				} else {
					store.dispatch({
						type: SUCCESS_SENDING_PASSWORD_RESET_EMAIL,
						payload: t('change_password_link_sent_successfully'),
					});
				}
			})
			.catch((error) => {
				if (error.code === 'auth/too-many-requests') {
					store.dispatch({
						type: SENT_TOO_MANY_EMAILS,
						payload: t('verification_email_sent_too_many_times'),
					});
				} else {
					store.dispatch({
						type: ERROR_SENDING_PASSWORD_RESET_EMAIL,
						payload: error.message,
					});
				}
			});
	};

	testUsername = () => {
		const handle = this.state.inputHandle;
		const handleRegex = /^[a-z0-9_.]+$/;
		if (handle === '') {
			this.focus('inputHandle');
			this.setState({
				usernameErrors: {
					handle: t('enter_username'),
				},
			});
		} else if (handle === this.props.user.credentials.handle) {
			this.focus('inputHandle');
			this.setState({
				usernameErrors: {
					handle: t('change_current_username', { handle }),
				},
			});
		} else if (handle.length < 3) {
			this.focus('inputHandle');
			this.setState({
				usernameErrors: {
					handle: t('username_short_length'),
				},
			});
		} else if (handle.length > 15) {
			this.focus('inputHandle');
			this.setState({
				usernameErrors: {
					handle: t('username_long_length'),
				},
			});
		} else if (!handleRegex.test(handle)) {
			this.focus('handle');
			this.setState({
				usernameErrors: {
					handle: t('username_special_characters'),
				},
			});
		} else {
			this.setState({
				usernameErrors: { handle: '' },
			});
			return true;
		}
	};
	focus = (id) => {
		document.getElementById(id).focus();
	};
	testPassword = () => {
		let value = this.state.password;
		if (value === '') {
			this.focus('password');
			this.setState({
				passwordErrors: {
					password: t('enter_password'),
				},
			});
		} else if (value.length <= 5) {
			this.focus('password');
			this.setState({
				passwordErrors: {
					password: t('password_short_length'),
				},
			});
		}
		// else if (value.search(/^[a-zA-Z0-9]*$/) === -1) {
		// 	this.focus('password');
		// 	this.setState({
		// 		passwordErrors: {
		// 			password: "Please remove any special characters like '(;@&$!#%/=)'.",
		// 		},
		// 	});
		// }
		else if (value.length >= 30) {
			this.focus('password');
			this.setState({
				passwordErrors: {
					password: t('password_long_length'),
				},
			});
		} else if (value.search(/\d/) === -1) {
			this.focus('password');
			this.setState({
				passwordErrors: {
					password: t('add_password_number'),
				},
			});
		}
		// else if (value.search(/[A-Z]/) === -1) {
		// 	this.focus('password');
		// 	this.setState({
		// 		passwordErrors: {
		// 			password: 'The password must have one capitalized letter or more.',
		// 		},
		// 	});
		// }
		else {
			this.setState({
				passwordErrors: { password: '' },
			});
			return true;
		}
	};

	getActionModeText = (
		mode,
		handleUpdateEmailAddress,
		handleUpdateUsername,
		handleUpdatePassword,
		deleteAccount,
	) => {
		if (mode === 'verifyEmail') return t('verifying_email');
		if (mode === 'resetPassword' || handleUpdatePassword)
			return t('change_password');
		if (mode === 'recoverEmail') return t('cancel_email_change');
		if (mode === 'verifyAndChangeEmail' || handleUpdateEmailAddress)
			return t('change_email_address');
		if (handleUpdateUsername) return t('change_username');
		if (deleteAccount)
			return t('delete_option', {
				option: t('account'),
			});
		return '';
	};

	getActionMessageText = (
		mode,
		handleUpdateEmailAddress,
		handleUpdateUsername,
		handleUpdatePassword,
		deleteAccount,
	) => {
		if (mode === 'verifyEmail') return t('please_await');
		if (mode === 'resetPassword') return t('type_new_password');
		if (mode === 'recoverEmail') return t('set_account_to_prev_email');
		if (mode === 'verifyAndChangeEmail')
			return t('verify_and_change_email_address');
		if (handleUpdateEmailAddress)
			return t('please_type_below_your_new_email_address');
		if (handleUpdateUsername)
			return t('usernames_can_be_changed_every_30_days');
		if (handleUpdatePassword) return t('please_check_your_inbox');
		if (deleteAccount)
			return t('we_apologize_that_you_want_to_delete_your_account');
		if (deleteAccount) return t('permanently_delete_account');
		return '';
	};
	getButtonText = (
		mode,
		handleUpdateEmailAddress,
		handleUpdateUsername,
		handleUpdatePassword,
		deleteAccount,
	) => {
		if (mode === 'verifyEmail') return t('verifying_email');
		if (mode === 'resetPassword' || mode === 'recoverEmail') return t('update');
		if (handleUpdateEmailAddress) return t('next');
		if (handleUpdateUsername) return t('check_availability');
		if (handleUpdatePassword) return t('resend');
		if (deleteAccount) return t('permanently_delete_account');
		return t('loading');
	};

	componentWillUnmount() {
		store.dispatch({ type: CLEAR_ACCOUNT_ACTION_TYPES });
	}

	render() {
		let {
			mode,
			inputHandle,
			password,
			confirmPassword,
			showPasswordRequirements,
			passwordErrors,
			error,
			emailErrors,
			confirmPasswordErrors,
			newEmail,
			previousEmail,
			checkingEmailValidation,
			redirectLogin,
			usernameErrors,
			resetPasswordInfo,
			alreadySentTwice,
		} = this.state;

		const {
			user: {
				verifyingPassword,
				verifiedPasswordSuccess,
				authErrors,
				errorVerifyingPassword,
				verifyingResetEmail,
				verifiedResetEmailSuccess,
				errorVerifyingResetEmail,
				sendingResetPasswordSuccess,
				sendingResetPassword,
				errorVerifyingResetPassword,
				authenticated,
				emailVerified,
				sendingSignInEmail,
				signedEmailSentSuccess,
				errorSendingSignInEmail,
				credentials: { handle, email },
				emailUpdatedSuccess,
				handleUpdateEmailAddress,
				handleUpdateUsername,
				handleUpdatePassword,
				errorSignUp,
				updatingNewUsername,
				newUsernameUpdatedSuccess,
				errorUpdatingNewUsername,
				usernameAvailable,
				checkingUsernameAvailability,
				errorCheckingUsernameAvailability,
				deleteAccount,
				deletingUserData,
				deletedUserSuccess,
				errorDeletingUserData,
				newEmailForSignIn,
			},
			UI: { onlineStatus },
		} = this.props;

		const paramMode = this.getParameterByName('mode');
		const paramActionCode = this.getParameterByName('oobCode');

		const actionMode = this.getActionModeText(
			mode,
			handleUpdateEmailAddress,
			handleUpdateUsername,
			handleUpdatePassword,
			deleteAccount,
		);
		const actionMessage = this.getActionMessageText(
			mode,
			handleUpdateEmailAddress,
			handleUpdateUsername,
			handleUpdatePassword,
			deleteAccount,
		);

		const buttonText = this.getButtonText(
			mode,
			handleUpdateEmailAddress,
			handleUpdateUsername,
			handleUpdatePassword,
			deleteAccount,
		);

		return (
			<div>
				{(redirectLogin || authErrors) && (
					<Navigate to='/login' replace={true} />
				)}
				{authenticated &&
				emailVerified &&
				!paramMode &&
				!paramActionCode &&
				!mode &&
				!actionMode &&
				!newEmailForSignIn &&
				!handleUpdateEmailAddress &&
				!errorVerifyingResetEmail &&
				!handleUpdatePassword &&
				!handleUpdateUsername &&
				!deleteAccount ? (
					<Navigate to='/feed' />
				) : !paramMode &&
				  !paramActionCode &&
				  !mode &&
				  !actionMode &&
				  !newEmailForSignIn &&
				  !handleUpdateEmailAddress &&
				  !handleUpdatePassword &&
				  !handleUpdateUsername &&
				  !deleteAccount ? (
					<Navigate to='/login' />
				) : (
					<div>
						<Helmet>
							<title>Goodbye App</title>
						</Helmet>
						<div className='skyBackground pd2Media'>
							<div
								className={`card pd2b1Media ${
									this.props.UI.mode === 'dark' && 'dbc'
								}`}
							>
								<Avatar id='cardMainIcon' className='flexAuto'>
									<ManageAccountsIcon id='cardMainIconSize' />
								</Avatar>
								<h1 className='title center mg0'>{actionMode}</h1>
								<h3 className='subtitle center mg0'>
									{verifiedPasswordSuccess && !authErrors
										? t('authenticating')
										: actionMessage}
								</h3>
								{/* {verifiedPasswordSuccess && !authErrors && (
									<div className='mg flex center'>
										<CircularProgress
											variant='indeterminate'
											size={30}
											thickness={3}
										/>
									</div>
								)} */}
								<form noValidate onSubmit={this.handleSubmit}>
									<CustomAlerts
										margin='1rem 0 0'
										success={verifiedPasswordSuccess}
										error={errorVerifyingPassword}
										moreErrors={error}
										info={
											verifyingPassword && t('verifying_change_password_link')
										}
										verifying1={checkingUsernameAvailability}
									/>
									{mode === 'recoverEmail' ||
									mode === 'verifyAndChangeEmail' ||
									mode === 'changePassword' ||
									mode === 'resetPassword' ? (
										<CustomAlerts
											margin='1rem 0 0'
											success={emailUpdatedSuccess}
											success1={sendingResetPasswordSuccess}
											success2={verifiedResetEmailSuccess}
											error={errorVerifyingResetEmail}
											error1={errorVerifyingResetPassword}
											error2={emailErrors.email}
											moreErrors={error}
											info={verifyingResetEmail && t('updating')}
											linkLocation='/settings'
											linkState={{ expanded: 'emailAddressPanel' }}
										/>
									) : null}
									<CustomAlerts
										margin='1rem 0 0'
										success={resetPasswordInfo}
										message={resetPasswordInfo}
										severity='info'
									/>
									{resetPasswordInfo && (
										<div>
											<p className='textTitle mgtOnly'>
												{t('to_change_account_password')}
											</p>

											{!alreadySentTwice && !sendingResetPasswordSuccess && (
												<CustomButton
													onClick={() =>
														authenticated && email
															? this.resetPassword(email)
															: history.replace('/forgotPassword')
													}
													variant='contained'
													btnText={t('resend')}
													fullWidth
													id='customInfoButton'
													margin='1.3rem auto 1rem auto'
													startIcon={<MailOutlineIcon />}
												/>
											)}
										</div>
									)}
									{mode === 'setNewUsername' && (
										<CustomAlerts
											margin='1rem 0 0'
											success={newUsernameUpdatedSuccess}
											success1={
												usernameAvailable && (
													<span>
														{t('is_available_to_use', {
															handle: usernameAvailable,
														})}
													</span>
												)
											}
											success2={verifiedResetEmailSuccess}
											error={errorUpdatingNewUsername}
											error1={errorCheckingUsernameAvailability}
											moreErrors={error}
											info={updatingNewUsername && t('updating')}
											verifying1={
												checkingUsernameAvailability &&
												t('checking_availability')
											}
										/>
									)}
									<CustomAlerts
										margin='1rem 0 0'
										success={signedEmailSentSuccess}
										error={errorSendingSignInEmail}
										moreErrors={error}
										info={sendingSignInEmail && t('sending_new_email_link')}
									/>
									<CustomAlerts
										margin='1rem 0 0'
										info={sendingResetPassword && !sendingResetPasswordSuccess}
										message={t('sending_email_link')}
									/>
									<CustomAlerts
										margin='1rem 0 0'
										info={signedEmailSentSuccess || sendingResetPasswordSuccess}
										message={t('new_email_might_be_in_junk_or_spam')}
									/>
									<CustomAlerts
										margin='1rem 0 0'
										info={alreadySentTwice}
										severity='warning'
										message={t('already_sent_twice')}
									/>
									<CustomAlerts
										margin='1rem 0 0'
										info={
											sendingResetPassword &&
											handleUpdatePassword &&
											sendingResetPasswordSuccess &&
											!errorVerifyingResetPassword
										}
										message={t('sending_new_email_link')}
										severity='info'
									/>
									<CustomAlerts
										margin='1rem 0 0'
										success={emailUpdatedSuccess && sendingResetPasswordSuccess}
										message={sendingResetPasswordSuccess}
										severity='info'
									/>
									<CustomAlerts
										margin='1rem 0 0'
										info={checkingEmailValidation}
										message={t('verifying_email')}
										severity='info'
									/>
									<CustomAlerts
										margin='1rem 0 0'
										error={errorSignUp || usernameErrors.handle}
										message={errorSignUp ? errorSignUp : usernameErrors.handle}
										severity='info'
									/>
									{mode === 'setNewEmail' &&
									!errorVerifyingResetEmail &&
									!emailUpdatedSuccess ? (
										<CustomInput
											variant='outlined'
											autoFocus={true}
											type='email'
											titleLabel={t('new_email')}
											placeholder={t('type_here')}
											name='email'
											inputId='email'
											onChange={this.handleChange}
											helperText={emailErrors.email}
											inputError={emailErrors.email ? true : false}
											inputValue={this.state.email}
											inputAdornmentPosition='end'
											autoComplete='email'
											required
											fullWidth
											deletable={() => this.clearTextfield('email')}
											isEmail={isEmail(email)}
										/>
									) : mode === 'resetPassword' ? (
										<div>
											{!verifiedPasswordSuccess &&
												!error &&
												errorVerifyingPassword === null && (
													<div>
														<CustomInput
															variant='outlined'
															autoFocus={true}
															type='password'
															titleLabel={`${t('new_password')} ${
																showPasswordRequirements && password.length <= 6
																	? t('use_six_or_more')
																	: ''
															}`}
															placeholder={t('type_here')}
															name='password'
															inputId='password'
															onChange={this.handleChange}
															helperText={passwordErrors.password}
															inputError={
																passwordErrors.password ? true : false
															}
															inputValue={password}
															inputAdornmentPosition='end'
															onFocus={() =>
																this.setState({
																	showPasswordRequirements: true,
																})
															}
															onBlur={() =>
																this.setState({
																	showPasswordRequirements: false,
																})
															}
															maxLength={50}
															autoComplete='new-password'
															required
															fullWidth
														/>
														{showPasswordRequirements && (
															<PasswordStrength password={password} />
														)}

														<CustomInput
															variant='outlined'
															titleLabel={t('confirm_password')}
															placeholder={t('re_enter_new_password')}
															type='password'
															name='confirmPassword'
															inputId='confirmPassword'
															onChange={this.handleChange}
															helperText={confirmPasswordErrors.confirmPassword}
															inputError={
																confirmPasswordErrors.confirmPassword
																	? true
																	: false
															}
															inputValue={confirmPassword}
															autoComplete='new-password'
															inputAdornmentPosition='end'
															// onBlur={() => this.blur('confirmPassword')}
															maxLength={50}
															required
															fullWidth
														/>
													</div>
												)}
										</div>
									) : (mode === 'verifyAndChangeEmail' ||
											mode === 'recoverEmail' ||
											mode === 'changePassword') &&
									  !errorVerifyingResetEmail ? (
										<section className='validator_div pdb'>
											{authenticated && email === newEmail && (
												<p className='textTitle mgYH'>
													{t('username')}:{' '}
													<b>
														{authenticated && handle ? handle : t('loading')}
													</b>
												</p>
											)}
											{emailUpdatedSuccess || handleUpdatePassword ? (
												<p className='textTitle mgYH breakWord'>
													{t('email_address')}:{' '}
													<b>{email ? email : t('loading')}</b>
												</p>
											) : (
												<div>
													<p className='textTitle mgYH breakWord'>
														{t('current_email_address')}:{' '}
														<b>{newEmail ? newEmail : t('loading')}</b>
													</p>
													<p className='textTitle mgYH breakWord'>
														{t('previous_email_address')}:{' '}
														<b>
															{previousEmail ? previousEmail : t('loading')}
														</b>
													</p>
												</div>
											)}
										</section>
									) : mode === 'setNewUsername' ? (
										<div className='flexColumn'>
											<div className='validator_div'>
												<p className='textTitle mgtOnly'>
													{t('current_username')}:{' '}
													<b>
														{authenticated && handle ? handle : t('loading')}
													</b>
												</p>
											</div>
											{!errorUpdatingNewUsername && (
												<div>
													<CustomInput
														variant='outlined'
														titleLabel={t('new_username')}
														placeholder={t('type_here')}
														inputId='inputHandle'
														name='inputHandle'
														autoComplete='inputHandle'
														onChange={this.handleChange}
														helperText={
															errorSignUp ||
															usernameErrors.handle ||
															errorCheckingUsernameAvailability
																? t('check_info')
																: ''
														}
														inputError={
															errorSignUp ||
															usernameErrors.handle ||
															errorCheckingUsernameAvailability
																? true
																: false
														}
														inputValue={inputHandle}
														inputAdornmentPosition='end'
														// onBlur={() => this.blur('handle')}
														maxLength={15}
														required
														fullWidth
														deletable={() => this.clearTextfield('inputHandle')}
													/>
												</div>
											)}
										</div>
									) : mode === 'deleteUserAccount' ? (
										<div>
											<CustomAlerts
												margin='1rem 0 0'
												success={
													handle &&
													!deletedUserSuccess &&
													!errorDeletingUserData &&
													!deletedUserSuccess
												}
												message={t('delete_account_warning', { handle })}
												severity='warning'
											/>
											<CustomAlerts
												margin='1rem 0 0'
												success={deletedUserSuccess}
												message={t('account_successfully_deleted')}
											/>
											<CustomAlerts
												margin='1rem 0 0'
												info={deletingUserData}
												message={t('deleting_account')}
											/>
											<CustomAlerts
												margin='1rem 0 0'
												error={errorDeletingUserData}
											/>
											{deletingUserData && (
												<div>
													<p className='textTitle mgtOnly'>
														{t('username')}:{' '}
														<b>
															{authenticated && handle ? handle : t('loading')}
														</b>
													</p>
													<p className='textTitle mgYH breakWord'>
														{t('email_address')}:{' '}
														<b>{email ? email : t('loading')}</b>
													</p>
												</div>
											)}
										</div>
									) : null}
									{mode !== 'verifyAndChangeEmail' &&
										!errorVerifyingResetEmail &&
										!emailUpdatedSuccess &&
										!errorVerifyingPassword &&
										!verifiedPasswordSuccess &&
										!verifiedResetEmailSuccess &&
										!alreadySentTwice &&
										// !sendingResetPasswordSuccess &&
										!signedEmailSentSuccess &&
										// !handleUpdatePassword &&
										!errorUpdatingNewUsername &&
										!deletedUserSuccess &&
										!errorDeletingUserData && (
											<CustomButton
												type='submit'
												variant='contained'
												disabled={
													!onlineStatus ||
													verifyingResetEmail ||
													verifyingPassword ||
													checkingUsernameAvailability ||
													(mode === 'deleteUserAccount' && !handle) ||
													deletingUserData ||
													alreadySentTwice
												}
												loading={
													checkingUsernameAvailability ||
													verifyingPassword ||
													verifyingResetEmail ||
													(mode === 'deleteUserAccount' && !handle) ||
													deletingUserData ||
													sendingResetPassword
												}
												btnText={buttonText}
												fullWidth
												id={
													mode === 'deleteUserAccount'
														? 'customDangerButton'
														: mode === 'setNewUsername' &&
														  usernameAvailable === inputHandle
														? 'customInfoButton'
														: 'customSuccessButton'
												}
												margin='1.3rem auto 1rem auto'
												startIcon={<AccountCircleIcon />}
											/>
										)}
								</form>
								{!deletingUserData && (
									<div>
										<hr />
										<Grid container className='center mgt'>
											<Grid item xs={6} sm={6} md={6}>
												<div className='fpass'>
													{newEmailForSignIn || handle ? (
														<Link to='/feed' id='link'>
															<span className='text bold capitalize'>
																{t('home')}
															</span>
														</Link>
													) : (
														<Link to='/login' id='link'>
															<span className='text bold capitalize'>
																{t('login')}
															</span>
														</Link>
													)}
												</div>
											</Grid>
											<Grid item xs={6} sm={6} md={6} id='baseline'>
												<div className='signup'>
													{newEmailForSignIn || handle ? (
														<Link to={`/users/${handle}`} id='link'>
															<span className='text bold capitalize'>
																{t('profile')}
															</span>
														</Link>
													) : (
														<Link to='/signup' id='link'>
															<span className='text bold capitalize'>
																{t('signup')}
															</span>
														</Link>
													)}
												</div>
											</Grid>
										</Grid>
									</div>
								)}
							</div>
						</div>
						<Footer />
					</div>
				)}
			</div>
		);
	}
}

AccountActions.propTypes = {
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	loginAllTabs: PropTypes.func.isRequired,
	sendSignInLinkToEmail: PropTypes.func.isRequired,
	loginAllTabsListener: PropTypes.func.isRequired,
	logoutAllTabsListener: PropTypes.func.isRequired,
	logoutAllTabs: PropTypes.func.isRequired,
	changeUsername: PropTypes.func.isRequired,
	checkUsernameAvailability: PropTypes.func.isRequired,
	updateUserEmail: PropTypes.func.isRequired,
	deleteUser: PropTypes.func.isRequired,
	getUserData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI,
});
const mapActionsToProps = {
	loginAllTabs,
	sendSignInLinkToEmail,
	loginAllTabsListener,
	logoutAllTabsListener,
	logoutAllTabs,
	changeUsername,
	checkUsernameAvailability,
	updateUserEmail,
	deleteUser,
	getUserData,
};

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(AccountActions));
