import {
	SET_POSTS,
	SET_USER_AND_DATA,
	SET_USER_POSTS,
	LIKE_POST,
	UNLIKE_POST,
	LIKE_COMMENT,
	UNLIKE_COMMENT,
	LOADING_POSTS,
	ERROR_LOADING_POSTS,
	LOADING_FILTERED_POSTS,
	ERROR_LOADING_FILTERED_POSTS,
	LOADING_DELETE_POST,
	DELETE_POST,
	ERROR_DELETE_POST,
	DELETE_INFRINGEMENT_COMMENT,
	DELETE_COMMENT,
	ERROR_DELETE_COMMENT,
	REMOVE_DELETED_POST_ACTIONS,
	REMOVE_DELETED_COMMENT_ACTIONS,
	SET_POST,
	SET_POST_RECIPIENTS,
	DELETE_RECIPIENT,
	DELETE_ALL_RECIPIENTS,
	SUBMIT_COMMENT,
	LOADING_SUBMIT_COMMENT,
	ERROR_SUBMIT_COMMENT,
	SET_LIKES,
	SET_MORE_LIKES,
	SET_QUERIED_USERS,
	ERROR_SET_QUERIED_USERS,
	ERROR_SET_MORE_QUERIED_USERS,
	SET_MORE_QUERIED_USERS,
	CLEAR_QUERIED_USERS,
	SET_MORE_COMMENTS,
	SET_FOLLOWERS,
	SET_TAG_FOLLOWERS,
	ERROR_SET_FOLLOWERS,
	SET_MORE_FOLLOWERS,
	CLEAR_ERROR_SET_FOLLOWERS,
	CLEAR_FOLLOWERS,
	UPDATE_PROFILE_DATA,
	LOADING_SEARCHBAR,
	LOADING_FOLLOWERS,
	LOADING_PROFILE,
	LOADING_PROFILE_POSTS,
	LOADING_UNRELEASED_PROFILE_POSTS,
	LOADING_POST,
	ERROR_LOADING_POST,
	LOADING_LIKES,
	ERROR_LOADING_LIKES,
	LOADING_MORE_LIKES,
	EDITING_POST_PROGRESS,
	ERROR_UPLOADING_POST,
	ERROR_EDITING_POST,
	LOADING_MORE_COMMENTS,
	ERROR_LOADING_MORE_LIKES,
	LOADING_DELETE_COMMENT,
	ERROR_SET_USER_AND_DATA,
	ERROR_SET_USER_POSTS,
	ERROR_SET_UNRELEASED_POSTS,
	CLEAR_ERROR_SET_QUERIED_USERS,
	SET_UNAUTHENTICATED,
	SET_MORE_USER_POSTS,
	UPLOADED_POST,
	ADD_POST_TO_FEED,
	REMOVE_POST_FROM_FEED,
	UPLOADING_POST,
	SET_UNRELEASED_POSTS,
	UPLOADED_UNRELEASED_PROFILE_POST,
	LOADING_UPDATE_POST,
	SET_POST_TO_EDIT,
	SET_EDITED_LIVE_POST,
	SET_EDITED_UNRELEASED_POST,
	UNRELEASED_POST_IS_LIVE,
	RELEASED_POST_IS_UNLIVE,
	ERROR_SET_MORE_COMMENTS,
	CLEAR_UNRELEASED_POSTS,
	EDITED_IMG_FILE,
	CLEAR_EDITED_IMG_FILE,
	CORRECT_LIKE_ACTION,
	CORRECT_LIKE_COMMENT_ACTION,
	UPDATE_POST_LIKES,
	UPDATE_POST_COMMENTS,
	UPDATE_VISITED_PROFILE,
	SET_UPLOADING_VIDEO_PAUSED,
	RESUME_UPLOADING_VIDEO,
	CANCEL_UPLOADING_VIDEO,
	UPLOADING_VIDEO_PROGRESS,
	UPLOADING_VIDEO_RUNNING,
	ERROR_UPLOADING_VIDEO,
	UPLOADING_VIDEO_SUCCESS,
	UPLOADING_IMAGE_PROGRESS,
	ERROR_UPLOADING_IMAGE,
	UPLOADING_IMAGE_SUCCESS,
	SET_REPORT_DATA,
	CLEAR_SOCIAL_REPORT,
	REPORTING_SOCIAL_ISSUE,
	REPORTING_SOCIAL_ISSUE_SUCCESS,
	ERROR_REPORTING_SOCIAL_ISSUE,
	HIDE_REPORTED_POST,
	HIDE_REPORTED_COMMENT,
	SHOW_REPORTED_POST,
	SHOW_REPORTED_COMMENT,
	CLEAR_ERROR_UPLOADING_POST,
	LOADING_REPORTS,
	SET_ISSUE_REPORTS,
	SET_REPORTS_ERRORS,
	SET_USER_REPORTS,
	SET_POST_REPORTS,
	SET_COMMENT_REPORTS,
	SET_QUERIED_REPORTS,
	CLEAR_QUERIED_REPORTS,
	CLEAR_REPORTS_ERRORS,
	SET_MORE_ISSUE_REPORTS,
	LOADING_MORE_REPORTS,
	SET_MORE_USER_REPORTS,
	SET_MORE_POST_REPORTS,
	SET_MORE_COMMENT_REPORTS,
	SET_MORE_QUERIED_REPORTS,
	SET_UPDATED_ISSUE_REPORT,
	SET_UPDATED_USER_REPORT,
	SET_UPDATED_POST_REPORT,
	SET_UPDATED_COMMENT_REPORT,
	SET_UPDATED_QUERIED_REPORT,
	SET_UPDATED_REPORT_ERROR,
	LOADING_REPORT_UPDATE,
	LOADING_ACCOUNT_REPORTS,
	SET_ACCOUNT_REPORTS,
	ERROR_SET_ACCOUNT_REPORTS,
	SET_ACCOUNT_ACTION,
	RELEASE_POSTS,
	LOADING_POST_INFRINGEMENT,
	SET_POST_INFRINGEMENT,
	ERROR_POST_INFRINGEMENT,
	SET_ADMIN_REPORT,
	ADD_POST_INFRINGEMENT,
	REMOVE_POST_INFRINGEMENT,
	LOADING_COMMENT_INFRINGEMENT,
	SET_COMMENT_REPORT_INFRINGEMENT,
	ERROR_COMMENT_INFRINGEMENT,
	ADD_REPORTER_COMMENT_INFRINGEMENT,
	REMOVE_REPORTED_COMMENT_INFRINGEMENT,
	REMOVE_REPORTER_COMMENT_INFRINGEMENT,
	ERROR_FETCHING_ADMIN_COMMENTS,
	SET_ADMIN_COMMENTS,
	FETCHING_ADMIN_COMMENTS,
	SET_COMMENT_INFRINGEMENT,
	SET_POST_REPORT_INFRINGEMENT,
	SET_MORE_ADMIN_COMMENTS,
	SET_MORE_USER_AND_DATA,
	SET_POST_DIALOG,
	SET_VISITED_USER_AND_DATA,
	SET_MORE_VISITED_USER_POSTS,
	LOADING_LIKED_POST_DATA,
	SET_LIKED_POST_DATA,
	ERROR_SET_LIKED_POST_DATA,
	SET_MORE_LIKED_POST_DATA,
	RESET_LIKED_POST_DATA,
	RESET_PROFILE_POSTS,
	RESET_VISITED_PROFILE_POSTS,
	RESET_UNRELEASED_POSTS,
	LOADING_POST_COMMENTS,
	ERROR_LOADING_POST_COMMENTS,
	SET_POST_COMMENTS,
	LOADING_NEW_POSTS,
	SET_NEW_POSTS,
	STOP_LOADING_NEW_POSTS,
	LOADING_NEW_FOLLOWING_POSTS,
	SET_NEW_FOLLOWING_POSTS,
	STOP_LOADING_NEW_FOLLOWING_POSTS,
	CLEAR_LOADING_MORE_COMMENTS_ERRORS,
	SET_MORE_UNRELEASED_POSTS,
	SET_POST_RECIPIENTS_DATA,
	ERROR_LOADING_POST_RECIPIENTS,
	LOADING_POST_RECIPIENTS,
	SET_POST_APPEALS,
	SET_UPDATED_POST_APPEAL,
	SET_MORE_POST_APPEALS,
	ADD_REPORTED_POST_INFRINGEMENT,
	REMOVE_REPORTED_POST_INFRINGEMENT,
	SUBMITTING_APPEAL,
	ERROR_SUBMITTING_APPEAL,
	POST_APPEAL_SUCCESFULLY_COMMITTED,
	CLEAR_APPEAL_ACTIONS,
	SET_COMMENT_APPEALS,
	SET_MORE_COMMENT_APPEALS,
	SET_UPDATED_COMMENT_APPEAL,
	ADD_REPORTED_COMMENT_INFRINGEMENT,
	DELETE_ERROR_COMMENT,
	SET_COMMENT_REPORT_COLLECTION_INFRINGEMENT,
	COMMENT_APPEAL_SUCCESFULLY_COMMITTED,
	UPDATING_APPEALABLE_CONTENT,
	SUCCESS_UPDATING_APPEALABLE_CONTENT,
	ERROR_UPDATING_APPEALABLE_CONTENT,
	CLEAR_LOADING_POST_COMMENTS_ERRORS,
	LOADING_NEW_ORDER,
	LOADING_COMMENT_REPLIES,
	ERROR_LOADING_COMMENT_REPLIES,
	SET_COMMENT_REPLIES,
	LOADING_SUBMIT_COMMENT_REPLY,
	SET_MORE_COMMENT_REPLIES,
	CLEAR_ERROR_LOADING_COMMENT_REPLIES,
	LIKE_COMMENT_REPLY,
	UNLIKE_COMMENT_REPLY,
	SET_COMMENT_REPLY_REPORT_INFRINGEMENT,
	DELETE_COMMENT_REPLY,
	DELETE_INFRINGEMENT_REPLY,
	SET_USER_APPEALS,
	SET_MORE_USER_APPEALS,
	SET_UPDATED_USER_APPEAL,
	USER_APPEAL_SUCCESFULLY_COMMITTED,
	COMMENT_REPLY_APPEAL_SUCCESFULLY_COMMITTED,
	UPDATE_POST_REPLIES,
	CORRECT_LIKE_COMMENT_REPLY_ACTION,
	SET_FILTERED_POSTS,
	LOADING_HASHTAGS,
	ERROR_LOADING_HASHTAGS,
	SET_HASHTAGS,
	SET_HASHTAGS_FROM_COMP,
	SET_QUERY_POST_HASHTAGS,
	SET_FEED_DATA,
	LOADING_NEW_FILTERED_POSTS,
	STOP_LOADING_NEW_FILTERED_POSTS,
	SET_NEW_FILTERED_POSTS,
	CLEAR_FILTERED_POSTS,
	SET_PREVIEW_HASHTAGS,
	STOP_LOADING_HASHTAGS,
	LOADING_FOLLOWING_POSTS,
	SET_FOLLOWING_POSTS,
	RESET_FOLLOWING_POSTS,
	CLEAR_FOLLOWING_POSTS,
	ERROR_LOADING_FOLLOWING_POSTS,
	SET_FEED_IDS,
	LOADING_FEED_IDS,
	ERROR_LOADING_FEED_IDS,
	SET_TAG_SEARCHBAR_USERS,
	SET_USER_ADMIN,
	ERROR_LOADING_ONLY_USER_COMMENTS,
	SET_ONLY_USER_COMMENTS,
	LOADING_ONLY_USER_COMMENTS,
	SET_MORE_ONLY_USER_COMMENTS,
	LOADING_LIKED_COMMENT_DATA,
	SET_LIKED_COMMENT_DATA,
	RESET_LIKED_COMMENT_DATA,
	SET_MORE_LIKED_COMMENT_DATA,
	ERROR_SET_LIKED_COMMENT_DATA,
	LOADING_LIKED_REPLY_DATA,
	SET_LIKED_REPLY_DATA,
	RESET_LIKED_REPLY_DATA,
	SET_MORE_LIKED_REPLY_DATA,
	ERROR_SET_LIKED_REPLY_DATA,
	LOADING_ADMIN_POST_COMMENTS,
	SET_ADMIN_POST_COMMENTS,
	ERROR_LOADING_ADMIN_POST_COMMENTS,
	CLEAR_ADMIN_POST_COMMENTS,
	SET_FEATURE_REPORTS,
	SET_MORE_FEATURE_REPORTS,
	SET_UPDATED_FEATURE_REPORT,
	SET_SUPPORT_REPORTS,
	SET_MORE_SUPPORT_REPORTS,
	SET_UPDATED_SUPPORT_REPORT,
	CONVERT_SUPPORT_TO_ISSUE_REPORT,
	DELETE_ERROR_REPLY,
	SET_LOGOUT_SUCCESS_MESSAGE,
	SET_LOGOUT_WARNING_MESSAGE,
	SET_LOGOUT_INFO_MESSAGE,
	SET_LOGOUT_ERROR_MESSAGE,
	SET_FEEDBACK,
	SET_MORE_FEEDBACK,
	SET_UPDATED_FEEDBACK,
	SET_ACTIVE_VIDEO,
	MUTE_ACTIVE_VIDEO,
	SET_DATE_VALUE,
	SET_EMAIL_RECIPIENTS,
	SET_VISITED_USER_POSTS,
	UPDATING_GOODBYE_LETTER,
	SUCCESS_UPDATING_GOODBYE_LETTER,
	CLEAR_ERROR_UPDATING_GOODBYE_LETTER,
	ERROR_UPDATING_GOODBYE_LETTER,
	LOADING_GOODBYE_LETTER,
	SET_GOODBYE_LETTER,
	ERROR_LOADING_GOODBYE_LETTER,
	CLEAR_ALL_LETTER_ERRORS,
	DELETING_GOODBYE_LETTER,
	SUCCESS_DELETING_GOODBYE_LETTER,
	ERROR_DELETING_GOODBYE_LETTER,
	SET_ALL_EMAIL_RECIPIENTS,
	RESET_DATE_VALUE,
	LOADING_LETTER_SENDERS,
	ERROR_LOADING_LETTER_SENDERS,
	SET_LETTER_SENDERS_DATA,
	SET_MORE_LETTER_SENDERS_DATA,
	LOADING_RECEIVED_GOODBYE_LETTER,
	ERROR_LOADING_RECEIVED_GOODBYE_LETTER,
	SET_RECEIVED_GOODBYE_LETTER,
	ADD_VIEWED_POST,
	RESTORE_VIEWED_POSTS,
	EDITED_AUDIO_FILE,
	RESET_EDITED_AUDIO_FILE,
	SET_OPENED_DIALOG,
	CLEAR_ACCOUNT_REPORTS,
	SET_MORE_FILTERED_POSTS,
	UPLOADING_AUDIO_PROGRESS,
	ERROR_UPLOADING_AUDIO,
	UPLOADING_AUDIO_SUCCESS,
	SET_BOUNDARY_REPORTS,
	SET_MORE_BOUNDARY_REPORTS,
	SET_UPDATED_BOUNDARY_REPORTS,
	UPLOADING_LETTER_AUDIO_PROGRESS,
	ERROR_UPLOADING_LETTER_AUDIO,
	UPLOADING_LETTER_AUDIO_SUCCESS,
	IS_MESSAGING_SUPPORTED,
	CLEAR_ERROR_SET_USER_AND_DATA,
	CLEAR_AUDIO_FILE,
	SENDING_COMMENT_ADMIN,
	AUDIO_NOT_FOUND,
	ERROR_LIKE_DATA,
	SET_LETTER_RECIPIENTS,
	LOADING_LETTER_RECIPIENTS,
	SET_LETTER_RECIPIENTS_DATA,
	ERROR_LOADING_LETTER_RECIPIENTS,
	SET_QUERY_ORDER,
	SET_FOLLOWING,
	ERROR_SET_FOLLOWING,
	CLEAR_ERROR_SET_FOLLOWING,
	SET_MORE_FOLLOWING,
	CLEAR_FOLLOWING,
	LOADING_FOLLOWING,
	CLEAR_POST,
} from '../types';

const initialState = {
	posts: [],
	filteredPosts: [],
	followingPosts: [],
	post: {
		comments: [],
		likes: [],
	},
	userPosts: [],
	visitedUserPosts: [],
	postToEdit: {},
	unreleasedPosts: [],
	visitedUserCredentials: {},
	queriedUsers: [],
	queriedFollowingUsers: [],
	hashtags: [],
	previewHashtags: [],
	filterPostHashtags: [],
	removedPostIds: [],
	removedCommentIds: [],
	postRecipients: [],
	letterRecipients: [],
	emailRecipients: [],
	letterSendersData: [],
	loadingLetterSendersData: null,
	errorLoadingLetterSendersData: null,
	loadingLetterRecipientsData: null,
	errorLoadingLetterRecipientsData: null,
	errorQueryingHashtags: null,
	errorQueryingUsers: null,
	errorQueryingMoreUsers: null,
	errorDeletingPost: [],
	errorSettingUserAndPosts: null,
	errorSettingUserPosts: null,
	errorSettingUnreleasedUserPosts: null,
	errorFollowers: null,
	errorFollowing: null,
	errorLoadingPosts: null,
	errorLoadingFilteredPosts: null,
	errorLoadingFollowingPosts: null,
	errorLoadingPost: null,
	errorLoadingLikes: null,
	errorUploadingPost: null,
	errorUploadingVideo: null,
	errorUploadingAudio: null,
	errorUploadingImage: null,
	errorUploadingLetterAudio: null,
	uploadingImageProgress: null,
	errorEditingPost: [],
	errorLoadingMoreLikes: null,
	followers: [],
	following: [],
	tagFollowers: [],
	errorLikeData: [],
	loadingPosts: null,
	loadingFilteredPosts: null,
	loadingFollowingPosts: null,
	loadingNewPosts: null,
	loadingNewFollowingPosts: null,
	loadingNewFilteredPosts: null,
	loadingHashtags: null,
	loadingSearchbar: null,
	loadingFollowers: null,
	loadingFollowing: false,
	loadingProfile: null,
	loadingProfilePosts: null,
	loadingUnreleasedPosts: null,
	loadingPost: null,
	loadingLikes: null,
	loadingMoreLikes: null,
	uploadingPost: null,
	uploadingVideoProgress: null,
	uploadingAudioProgress: null,
	uploadingLetterAudioProgress: null,
	editingPostProgress: null,
	loadingMoreComments: null,
	loadingLikeCommentAction: null,
	submittingComments: [],
	errorSubmitComments: [],
	errorDeletingComment: null,
	editedImageFile: null,
	editedImageFileToUpload: null,
	editedAudioFile: null,
	deletedAudioFile: null,
	editedAudioFileToUpload: null,
	deletedEditedAudioFileToUpload: null,
	errorLoadingMoreComments: '',
	loadingDeletePost: [],
	editingPost: [],
	lastQueriedUserId: '',
	errorSubmittingLike: [],
	errorSubmittingCommentLike: [],
	deletingComments: [],
	errorDeletingComments: [],
	listenPostUpdates: false,
	uploadingVideoPaused: null,
	uploadingVideoRunning: null,
	hasMorePosts: false,
	reportData: {},
	reportingSocialIssue: null,
	reportingSocialIssueSuccess: '',
	errorReportingSocialIssue: null,
	reportedComments: [],
	reportedPosts: [],
	loadingReports: false,
	loadingMoreReports: false,
	reports: [],
	userReports: [],
	postReports: [],
	commentReports: [],
	featureReports: [],
	supportReports: [],
	feedback: [],
	boundaryReports: [],
	queriedReports: [],
	userAppeals: [],
	postAppeals: [],
	commentAppeals: [],
	loadingReportUpdate: false,
	reportsErrors: null,
	updateReportError: null,
	setAccountReports: {},
	loadingAccountReports: false,
	errorSetAccountReports: null,
	loadingPostInfringement: false,
	errorPostInfringement: null,
	loadingCommentInfringement: false,
	errorCommentInfringement: null,
	fetchingAdminComments: false,
	loadingNewCommentOrder: false,
	loadingFollowingFeedIds: false,
	errorLoadingFollowingFeedIds: null,
	setUserAdmin: {},
	followingFeedIds: [],
	adminUserComments: [],
	adminPostComments: [],
	errorFetchingAdminComments: null,
	adminReportId: '',
	adminReportedUserId: '',
	adminReportCollection: '',
	adminReportPostId: '',
	adminReportCommentId: '',
	adminReportRepliedId: '',
	adminReportInfringement: '',
	reportingCommentId: '',
	reportingPostId: '',
	loadingLikedPostData: false,
	likedPostData: [],
	errorLoadingLikedPostData: null,
	loadingLikedCommentData: false,
	likedCommentData: [],
	errorLoadingLikedCommentData: null,
	loadingLikedReplyData: false,
	likedReplyData: [],
	errorLoadingLikedReplyData: null,
	hashtagOption: '',
	loadingPostComments: false,
	errorLoadingPostComments: null,
	loadingAdminPostComments: false,
	loadingPostRecipientsData: false,
	errorLoadingPostRecipientsData: null,
	submittingAppeal: false,
	submittingAppealSuccess: '',
	errorSubmittingAppeal: null,
	updatingAppealableContent: false,
	successUpdatingAppealableContent: '',
	errorUpdatingAppealableContent: null,
	reportedContent: [],
	loadingCommentReplies: [],
	errorLoadingCommentReplies: [],
	feedCollection: 'posts',
	showFeedFilterOptions: false,
	lastHashtag: '',
	lastHashtagCount: 0,
	hashtagOrder: '',
	loadingOnlyComments: false,
	onlyUserComments: [],
	errorLoadingOnlyComments: null,
	logoutSuccessMessage: '',
	logoutInfoMessage: '',
	logoutWarningMessage: '',
	logoutErrorMessage: '',
	activeVideoPostId: '',
	openedDialog: false,
	dateTimer: {
		dateValue: null,
		meridiem: '',
		hourValue: '',
	},
	updatingGoodbyeLetter: false,
	successUpdatingGoodbyeLetter: '',
	errorUpdatingGoodbyeLetter: null,
	deletingGoodbyeLetter: false,
	successDeletingGoodbyeLetter: '',
	errorDeletingGoodbyeLetter: null,
	loadingGoodbyeLetter: false,
	goodbyeLetter: {},
	errorLoadingGoodbyeLetter: null,
	loadingReceivedGoodbyeLetter: false,
	receivedGoodbyeLetter: {},
	errorLoadingReceivedGoodbyeLetter: null,
	viewedPostIds: [],
	isMessagingSupported: false,
	sendingCommentAdmin: '',
	audioUrlsNotFound: [],
	queryOrder: {
		queryLikesOrder: 'desc',
		queryPostsOrder: 'desc',
		queryVisitedPostsOrder: 'desc',
		queryUnreleasedPostsOrder: 'desc',
	},
};

export default function Reducer(state = initialState, action) {
	switch (action.type) {
		case SET_UNAUTHENTICATED:
			return initialState;
		case LOADING_NEW_POSTS:
			return {
				...state,
				loadingNewPosts: true,
			};
		case STOP_LOADING_NEW_POSTS:
			return {
				...state,
				loadingNewPosts: false,
			};
		case SET_NEW_POSTS:
			return {
				...state,
				posts: action.payload.concat(state.posts),
				loadingNewPosts: false,
			};

		case LOADING_NEW_FOLLOWING_POSTS:
			return {
				...state,
				loadingNewFollowingPosts: true,
			};
		case STOP_LOADING_NEW_FOLLOWING_POSTS:
			return {
				...state,
				loadingNewFollowingPosts: false,
			};
		case SET_NEW_FOLLOWING_POSTS:
			return {
				...state,
				followingPosts: action.payload.concat(state.followingPosts),
				loadingNewFollowingPosts: false,
			};

		case LOADING_NEW_FILTERED_POSTS:
			return {
				...state,
				loadingNewFilteredPosts: true,
			};
		case STOP_LOADING_NEW_FILTERED_POSTS:
			return {
				...state,
				loadingNewFilteredPosts: false,
			};
		case SET_NEW_FILTERED_POSTS:
			return {
				...state,
				filteredPosts: action.payload.concat(state.filteredPosts),
				loadingNewFilteredPosts: false,
			};

		case LOADING_FILTERED_POSTS:
			return {
				...state,
				loadingFilteredPosts: true,
				feedCollection: 'filtered',
				errorLoadingFilteredPosts: null,
			};
		case CLEAR_FILTERED_POSTS:
			return {
				...state,
				filteredPosts: [],
				errorLoadingFilteredPosts: null,
			};
		case SET_FILTERED_POSTS:
			return {
				...state,
				filteredPosts: action.payload.filteredPosts,
				loadingFilteredPosts: false,
				hashtagOrder: action.payload.order,
			};
		case SET_MORE_FILTERED_POSTS:
			return {
				...state,
				filteredPosts: state.filteredPosts.concat(action.payload.filteredPosts),
				loadingFilteredPosts: false,

				hashtagOrder: action.payload.order,
			};
		case ERROR_LOADING_FILTERED_POSTS:
			return {
				...state,
				loadingFilteredPosts: false,
				showFeedFilterOptions: true,
				errorLoadingFilteredPosts: action.payload,
			};

		case LOADING_FOLLOWING_POSTS:
			return {
				...state,
				loadingFollowingPosts: true,
				feedCollection: 'following',
			};
		case SET_FOLLOWING_POSTS:
			return {
				...state,
				followingPosts: state.followingPosts.concat(action.payload),
				loadingFollowingPosts: false,
			};
		case RESET_FOLLOWING_POSTS:
			return {
				...state,
				followingPosts: action.payload,
				loadingFollowingPosts: false,
			};
		case CLEAR_FOLLOWING_POSTS:
			return {
				...state,
				followingPosts: [],
				followingFeedIds: [],
			};
		case ERROR_LOADING_FOLLOWING_POSTS:
			return {
				...state,
				loadingFollowingPosts: false,
				errorLoadingFollowingPosts: action.payload,
			};

		case LOADING_POSTS:
			return {
				...state,
				loadingPosts: true,
			};
		case ERROR_LOADING_POSTS:
			return {
				...state,
				loadingPosts: false,
				loadingNewPosts: false,
				errorLoadingPosts: action.payload,
			};
		case SET_POSTS:
			return {
				...state,
				posts: state.posts.concat(action.payload),
				loadingPosts: false,
			};

		case SET_VISITED_USER_AND_DATA:
			return {
				...state,
				visitedUserPosts: action.payload.posts ? action.payload.posts : [],
				visitedUserCredentials: action.payload.user,
				adminUserComments: action.payload.comments
					? action.payload.comments
					: [],
				loadingProfile: false,
			};
		case SET_USER_AND_DATA:
			return {
				...state,
				userPosts: action.payload.posts ? action.payload.posts : [],
				adminUserComments: action.payload.comments
					? action.payload.comments
					: [],
				loadingProfile: false,
			};
		case ERROR_SET_USER_AND_DATA:
			return {
				...state,
				loadingProfile: false,
				errorSettingUserAndPosts: action.payload,
			};
		case ERROR_SET_USER_POSTS:
			return {
				...state,
				loadingProfilePosts: false,
				errorSettingUserPosts: action.payload,
			};
		case ERROR_SET_UNRELEASED_POSTS:
			return {
				...state,
				loadingUnreleasedPosts: false,
				errorSettingUnreleasedUserPosts: action.payload,
			};
		case UPDATE_PROFILE_DATA:
			return {
				...state,
				userPosts: action.payload.posts,
				loadingProfile: false,
			};
		case RESET_PROFILE_POSTS:
			return {
				...state,
				userPosts: [],
			};
		case RESET_VISITED_PROFILE_POSTS:
			return {
				...state,
				visitedUserPosts: [],
			};

		case SET_USER_POSTS:
			return {
				...state,
				userPosts: action.payload,
				loadingProfilePosts: false,
			};
		case SET_VISITED_USER_POSTS:
			let completeVisitedUserPosts = action.payload.map((doc) => {
				return {
					...doc,
					userHandle: state.visitedUserCredentials.handle,
					userImage: state.visitedUserCredentials.imageUrl,
					userImageColor: state.visitedUserCredentials.imageColor,
				};
			});
			return {
				...state,
				visitedUserPosts: completeVisitedUserPosts,
				loadingProfilePosts: false,
			};

		case SET_UNRELEASED_POSTS:
			return {
				...state,
				unreleasedPosts: action.payload,
				loadingUnreleasedPosts: false,
			};
		case SET_MORE_UNRELEASED_POSTS:
			return {
				...state,
				unreleasedPosts: state.unreleasedPosts.concat(action.payload),
				loadingUnreleasedPosts: false,
			};
		case RESET_UNRELEASED_POSTS:
			return {
				...state,
				unreleasedPosts: [],
			};

		case SET_MORE_USER_POSTS:
			return {
				...state,
				userPosts: state.userPosts.concat(action.payload),
				loadingProfilePosts: false,
			};

		case SET_MORE_VISITED_USER_POSTS:
			return {
				...state,
				visitedUserPosts: state.visitedUserPosts.concat(action.payload),
				loadingProfilePosts: false,
			};
		case SET_MORE_USER_AND_DATA:
			return {
				...state,
				visitedUserPosts: state.visitedUserPosts.concat(action.payload),
				loadingProfilePosts: false,
			};

		case SET_POST_TO_EDIT:
			return {
				...state,
				postToEdit: action.payload,
			};
		case CLEAR_POST: {
			return {
				...state,
				post: {
					comments: [],
					likes: [],
				},
			};
		}
		case SET_POST:
			return {
				...state,

				post: { ...action.payload, likes: state.post.likes },
				posts: state.posts.map((post) =>
					post.postId === action.payload.postId
						? {
								...action.payload,
						  }
						: post,
				),
				userPosts: state.userPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...action.payload,
						  }
						: post,
				),
				filteredPosts: state.filteredPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...action.payload,
						  }
						: post,
				),
				visitedUserPosts: state.visitedUserPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...action.payload,
						  }
						: post,
				),
				likedPostData: state.likedPostData.map((post) =>
					post.postId === action.payload.postId
						? {
								...action.payload,
						  }
						: post,
				),
				loadingPost: false,
			};
		case SET_POST_DIALOG:
			return {
				...state,
				post: {
					comments: [],
					...action.payload,
				},
			};
		case LOADING_POST_RECIPIENTS:
			return {
				...state,
				loadingPostRecipientsData: true,
				errorLoadingPostRecipientsData: null,
			};
		case SET_POST_RECIPIENTS_DATA:
			return {
				...state,
				postRecipients: action.payload,
				loadingPostRecipientsData: false,
			};
		case ERROR_LOADING_POST_RECIPIENTS:
			return {
				...state,
				errorLoadingPostRecipientsData: action.payload,
				loadingPostRecipientsData: false,
			};

		case LOADING_LETTER_RECIPIENTS:
			return {
				...state,
				loadingLetterRecipientsData: true,
				errorLoadingLetterRecipientsData: null,
			};
		case SET_LETTER_RECIPIENTS_DATA:
			return {
				...state,
				letterRecipients: action.payload,
				loadingLetterRecipientsData: false,
			};
		case ERROR_LOADING_LETTER_RECIPIENTS:
			return {
				...state,
				errorLoadingLetterRecipientsData: action.payload,
				loadingLetterRecipientsData: false,
			};
		case LOADING_LETTER_SENDERS:
			return {
				...state,
				loadingLetterSendersData: true,
				errorLoadingLetterSendersData: null,
			};
		case SET_LETTER_SENDERS_DATA:
			return {
				...state,
				letterSendersData: action.payload,
				loadingLetterSendersData: false,
			};
		case SET_MORE_LETTER_SENDERS_DATA:
			const newSenderExists =
				state.letterSendersData.findIndex(
					(obj) => obj.userId === action.payload[0].userId,
				) !== -1;
			return {
				...state,
				letterSendersData: newSenderExists
					? state.letterSendersData.map((obj) =>
							obj.userId === action.payload[0].userId ? action.payload[0] : obj,
					  )
					: [...state.letterSendersData, ...action.payload],
				loadingLetterSendersData: false,
			};
		case ERROR_LOADING_LETTER_SENDERS:
			return {
				...state,
				errorLoadingLetterSendersData: action.payload,
				loadingLetterSendersData: false,
			};

		case SET_ALL_EMAIL_RECIPIENTS:
			return {
				...state,
				emailRecipients: action.payload,
			};

		case SET_EMAIL_RECIPIENTS:
			let emailRecipientIndex = state.emailRecipients.findIndex(
				(email) => email === action.payload.email,
			);
			let emailRecipientArr = [...state.emailRecipients];
			if (emailRecipientIndex === -1) {
				emailRecipientArr = [...state.emailRecipients, action.payload.email];
			}
			return {
				...state,
				emailRecipients: emailRecipientArr,
				queriedUsers: [],
				lastQueriedUserId: '',
				errorQueryingUsers: null,
			};

		case SET_LETTER_RECIPIENTS:
			let letterRecipientIndex = state.letterRecipients.findIndex(
				(recipient) => recipient.handle === action.payload.handle,
			);
			let letterRecipientsArr = [...state.letterRecipients];
			if (letterRecipientIndex === -1) {
				letterRecipientsArr = [...state.letterRecipients, action.payload];
			}
			return {
				...state,
				letterRecipients: letterRecipientsArr,
				queriedUsers: [],
				lastQueriedUserId: '',
				errorQueryingUsers: null,
			};
		case SET_POST_RECIPIENTS:
			let recipientIndex = state.postRecipients.findIndex(
				(recipient) => recipient.handle === action.payload.handle,
			);
			let arr = [...state.postRecipients];
			if (recipientIndex === -1) {
				arr = [...state.postRecipients, action.payload];
			}
			return {
				...state,
				postRecipients: arr,
				queriedUsers: [],
				lastQueriedUserId: '',
				errorQueryingUsers: null,
			};

		case DELETE_RECIPIENT:
			return {
				...state,
				postRecipients: state.postRecipients.filter(
					(recipient) => recipient.handle !== action.payload.handle,
				),

				letterRecipients: state.letterRecipients.filter(
					(recipient) => recipient.handle !== action.payload.handle,
				),

				emailRecipients: state.emailRecipients.filter(
					(email) => email !== action.payload.email,
				),
			};
		case DELETE_ALL_RECIPIENTS:
			return {
				...state,
				letterRecipients: [],
				postRecipients: [],
				emailRecipients: [],
			};

		case SET_LIKES:
			return {
				...state,
				post: { ...state.post, likes: action.payload },
				loadingLikes: false,
			};
		case SET_MORE_LIKES:
			return {
				...state,
				post: { ...state.post, likes: state.post.likes.concat(action.payload) },
				loadingMoreLikes: false,
			};

		case LIKE_COMMENT:
		case UNLIKE_COMMENT:
			return {
				...state,
				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.commentId
							? {
									...comment,
									...action.payload,
							  }
							: comment,
					),
				},

				likedCommentData: state.likedCommentData.map((comment) =>
					comment.commentId === action.payload.commentId
						? {
								...comment,
								...action.payload,
						  }
						: comment,
				),
				likedReplyData: state.likedReplyData.map((comment) =>
					comment.commentId === action.payload.commentId
						? {
								...comment,
								...action.payload,
						  }
						: comment,
				),
			};
		case LIKE_COMMENT_REPLY:
		case UNLIKE_COMMENT_REPLY:
			return {
				...state,

				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.repliedId
							? {
									...comment,
									replies: comment.replies.map((reply) =>
										reply.commentId === action.payload.commentId
											? {
													...reply,
													...action.payload,
											  }
											: reply,
									),
							  }
							: comment,
					),
				},

				likedCommentData: state.likedCommentData.map((comment) =>
					comment.commentId === action.payload.commentId
						? {
								...comment,
								...action.payload,
						  }
						: comment,
				),
				likedReplyData: state.likedReplyData.map((comment) =>
					comment.commentId === action.payload.commentId
						? {
								...comment,
								...action.payload,
						  }
						: comment,
				),
			};
		case LIKE_POST:
		case UNLIKE_POST:
			return {
				...state,
				posts: state.posts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								...action.payload,
						  }
						: post,
				),
				post:
					state.post.postId === action.payload.postId
						? {
								...state.post,
								...action.payload,
						  }
						: state.post,
				followingPosts: state.followingPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								...action.payload,
						  }
						: post,
				),
				userPosts: state.userPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								...action.payload,
						  }
						: post,
				),
				filteredPosts: state.filteredPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								...action.payload,
						  }
						: post,
				),

				unreleasedPosts: state.unreleasedPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								...action.payload,
						  }
						: post,
				),
				visitedUserPosts: state.visitedUserPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								...action.payload,
						  }
						: post,
				),
				likedPostData: state.likedPostData.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								...action.payload,
						  }
						: post,
				),
			};

		case CORRECT_LIKE_ACTION:
			let correctedLiked = action.payload.correctedLiked;

			return {
				...state,
				posts: state.posts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,

								liked: correctedLiked,
						  }
						: post,
				),
				post: {
					...state.post,
					liked: correctedLiked,
				},
				userPosts: state.userPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								liked: correctedLiked,
						  }
						: post,
				),
				visitedUserPosts: state.visitedUserPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								liked: correctedLiked,
						  }
						: post,
				),
				likedPostData: state.likedPostData.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								liked: correctedLiked,
						  }
						: post,
				),
			};
		case CORRECT_LIKE_COMMENT_ACTION:
			let correctedLikedComment = action.payload.correctedLiked;
			return {
				...state,
				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.commentId
							? {
									...comment,
									liked: correctedLikedComment,
							  }
							: comment,
					),
				},
			};
		case CORRECT_LIKE_COMMENT_REPLY_ACTION:
			let correctedLikedCommentReply = action.payload.correctedLiked;
			return {
				...state,
				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.repliedId
							? {
									...comment,
									replies: comment.replies.map((reply) => {
										return reply.commentId === action.payload.commentId
											? {
													...reply,
													liked: correctedLikedCommentReply,
											  }
											: reply;
									}),
							  }
							: comment,
					),
				},
			};

		case UPDATE_POST_COMMENTS:
			return {
				...state,
				posts: state.posts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								commentCount: post.commentCount + 1,
						  }
						: post,
				),
				post:
					state.post.postId === action.payload.postId
						? {
								...state.post,
								commentCount: state.post.commentCount + 1,
						  }
						: { ...state.post },
				userPosts: state.userPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								commentCount: post.commentCount + 1,
						  }
						: post,
				),
				visitedUserPosts: state.visitedUserPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								commentCount: post.commentCount + 1,
						  }
						: post,
				),
				likedPostData: state.likedPostData.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								commentCount: post.commentCount + 1,
						  }
						: post,
				),
			};
		case UPDATE_POST_REPLIES:
			return {
				...state,
				posts: state.posts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								replyCount: post.replyCount + 1,
								comments: post.comments
									? post.comments.map((reply) =>
											reply.commentId === action.payload.repliedId
												? {
														...reply,
														replyCount: reply.replyCount
															? reply.replyCount + 1
															: 1,
												  }
												: reply,
									  )
									: [],
						  }
						: post,
				),
				post:
					state.post.postId === action.payload.postId
						? {
								...state.post,
								replyCount: state.post.replyCount + 1,
								comments: state.post.comments
									? state.post.comments.map((reply) =>
											reply.commentId === action.payload.repliedId
												? {
														...reply,
														replyCount: reply.replyCount
															? reply.replyCount + 1
															: 1,
												  }
												: reply,
									  )
									: [],
						  }
						: { ...state.post },
				userPosts: state.userPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								replyCount: post.replyCount + 1,
								comments: post.comments
									? post.comments.map((reply) =>
											reply.commentId === action.payload.repliedId
												? {
														...reply,
														replyCount: reply.replyCount
															? reply.replyCount + 1
															: 1,
												  }
												: reply,
									  )
									: [],
						  }
						: post,
				),
				visitedUserPosts: state.visitedUserPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								replyCount: post.replyCount + 1,
								comments: post.comments
									? post.comments.map((reply) =>
											reply.commentId === action.payload.repliedId
												? {
														...reply,
														replyCount: reply.replyCount
															? reply.replyCount + 1
															: 1,
												  }
												: reply,
									  )
									: [],
						  }
						: post,
				),
				likedPostData: state.likedPostData.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								replyCount: post.replyCount + 1,
								comments: post.comments
									? post.comments.map((reply) =>
											reply.commentId === action.payload.repliedId
												? {
														...reply,
														replyCount: reply.replyCount
															? reply.replyCount + 1
															: 1,
												  }
												: reply,
									  )
									: [],
						  }
						: post,
				),
			};

		case UPDATE_POST_LIKES:
			return {
				...state,
				posts: state.posts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								likeCount: post.likeCount + action.payload.count,
						  }
						: post,
				),
				post:
					state.post.postId === action.payload.postId
						? {
								...state.post,
								likeCount: state.post.likeCount + action.payload.count,
						  }
						: { ...state.post },
				userPosts: state.userPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								likeCount: post.likeCount + action.payload.count,
						  }
						: post,
				),
				visitedUserPosts: state.visitedUserPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								likeCount: post.likeCount + action.payload.count,
						  }
						: post,
				),
				likedPostData: state.likedPostData.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								likeCount: post.likeCount + action.payload.count,
						  }
						: post,
				),
			};
		case LOADING_DELETE_POST:
			return {
				...state,
				loadingDeletePost: state.loadingDeletePost.concat(action.payload),
			};
		case LOADING_UPDATE_POST:
			const editingPostArr = state.editingPost;
			editingPostArr.push(action.payload);
			return {
				...state,
				editingPost: editingPostArr,
				errorEditingPost: state.errorEditingPost.filter(
					(postId) => postId !== action.payload,
				),
			};
		case DELETE_POST:
			return {
				...state,
				posts: state.posts.filter((post) => post.postId !== action.payload),
				userPosts: state.userPosts.filter(
					(post) => post.postId !== action.payload,
				),
				unreleasedPosts: state.unreleasedPosts.filter(
					(post) => post.postId !== action.payload,
				),
				filteredPosts: state.filteredPosts.filter(
					(post) => post.postId !== action.payload,
				),

				loadingDeletePost: state.loadingDeletePost.filter(
					(postId) => postId !== action.payload,
				),
			};
		case ERROR_DELETE_POST:
			return {
				...state,
				loadingDeletePost: state.loadingDeletePost.filter(
					(postId) => postId !== action.payload,
				),
				errorDeletingPost: state.errorDeletingPost.concat(action.payload),
			};

		case RELEASE_POSTS:
			let postToRelease = state.unreleasedPosts.filter((post) =>
				action.payload.includes(post.postId),
			);
			// Map over the filtered posts to update the livePost property
			postToRelease = postToRelease.map((post) => ({
				...post,
				livePost: true,
			}));

			const newFeedPosts = postToRelease.filter((post) => post.publicPost);
			return {
				...state,
				posts: newFeedPosts,
				userPosts: postToRelease.concat(state.userPosts),
				unreleasedPosts: state.unreleasedPosts.filter(
					(post) => !action.payload.includes(post.postId),
				),
			};

		case ADD_POST_TO_FEED:
			return {
				...state,
				posts: state.posts.length > 0 ? [action.payload, ...state.posts] : [],
			};
		case REMOVE_POST_FROM_FEED:
			return {
				...state,
				posts: state.posts.filter((post) => post.postId !== action.payload),
			};

		case CLEAR_UNRELEASED_POSTS:
			return {
				...state,
				unreleasedPosts: [],
			};

		case UPLOADED_POST:
			return {
				...state,
				userPosts: [action.payload, ...state.userPosts],
				uploadingPost: null,
				postRecipients: [],
				uploadPostLocation: '',
			};
		case UPLOADING_POST:
			return {
				...state,
				uploadingPost: true,
			};

		case SET_EDITED_LIVE_POST:
			return {
				...state,
				posts: action.payload.publicPost
					? state.posts.map((post) =>
							post.postId === action.payload.postId
								? { ...post, ...action.payload }
								: post,
					  )
					: state.posts,
				userPosts: state.userPosts.map((post) =>
					post.postId === action.payload.postId
						? { ...post, ...action.payload }
						: post,
				),
				filteredPosts: state.filteredPosts.map((post) =>
					post.postId === action.payload.postId
						? { ...post, ...action.payload }
						: post,
				),
				post:
					state.post.postId === action.payload.postId
						? { ...state.post, ...action.payload }
						: state.post,

				postToEdit: {},
				editingPostProgress: null,
				editingPost: state.editingPost.filter(
					(postId) => postId !== action.payload.postId,
				),
			};
		case UNRELEASED_POST_IS_LIVE:
			let releasePost = { ...state.postToEdit, ...action.payload };

			return {
				...state,
				unreleasedPosts: state.unreleasedPosts.filter(
					(post) => post.postId !== action.payload.postId,
				),
				userPosts: [releasePost, ...state.userPosts],
				editingPostProgress: null,
				postToEdit: {},
				editingPost: state.editingPost.filter(
					(postId) => postId !== action.payload.postId,
				),
			};

		case RELEASED_POST_IS_UNLIVE:
			let releasedPost = { ...state.postToEdit, ...action.payload };
			return {
				...state,
				posts: state.posts.filter(
					(post) => post.postId !== action.payload.postId,
				),
				userPosts: state.userPosts.filter(
					(post) => post.postId !== action.payload.postId,
				),
				unreleasedPosts: [releasedPost, ...state.unreleasedPosts],
				postToEdit: {},
				editingPostProgress: null,
				editingPost: state.editingPost.filter(
					(postId) => postId !== action.payload.postId,
				),
			};
		case SET_EDITED_UNRELEASED_POST:
			return {
				...state,
				unreleasedPosts: state.unreleasedPosts.map((post) =>
					post.postId === action.payload.postId
						? { ...post, ...action.payload }
						: post,
				),
				post:
					state.post.postId === action.payload.postId
						? { ...state.post, ...action.payload }
						: state.post,

				postToEdit: {},
				editingPostProgress: null,
				editingPost: state.editingPost.filter(
					(postId) => postId !== action.payload.postId,
				),
			};

		case UPLOADED_UNRELEASED_PROFILE_POST:
			return {
				...state,
				unreleasedPosts: [action.payload, ...state.unreleasedPosts],
				uploadingPost: null,

				postRecipients: [],
				uploadPostLocation: '',
			};
		case REMOVE_DELETED_POST_ACTIONS:
			const filteredPostIds = state.removedPostIds.filter(
				(id) => id !== action.payload,
			);
			filteredPostIds.push(action.payload);
			return {
				...state,
				removedPostIds: filteredPostIds,

				loadingDeletePost: state.loadingDeletePost.filter(
					(postId) => postId !== action.payload,
				),
			};

		case ERROR_UPLOADING_POST:
			return {
				...state,
				errorUploadingPost: action.payload,
				uploadingPost: null,
			};
		case CLEAR_ERROR_UPLOADING_POST:
			return {
				...state,
				errorUploadingPost: null,
				errorUploadingImage: null,
				errorUploadingAudio: null,
				errorUploadingVideo: null,
			};
		case ERROR_EDITING_POST:
			const errorEditingPostArr = state.errorEditingPost;
			errorEditingPostArr.push(action.payload);
			return {
				...state,
				errorEditingPost: errorEditingPostArr,
				editingPostProgress: null,
				editingPost: state.editingPost.filter(
					(postId) => postId !== action.payload,
				),
				postToEdit: {},
			};
		case EDITING_POST_PROGRESS:
			return {
				...state,
				editingPostProgress: action.payload,
			};

		case LOADING_DELETE_COMMENT:
			return {
				...state,

				deletingComments: state.deletingComments.concat(action.payload),
				errorDeletingComment: null,
			};
		case ERROR_DELETE_COMMENT:
			return {
				...state,
				errorDeletingComment: action.payload.message,
				errorDeletingComments: state.errorDeletingComments.concat(
					action.payload.commentId,
				),
			};

		case DELETE_INFRINGEMENT_COMMENT:
			return {
				...state,
				post: {
					...state.post,
					comments: state.post.comments.filter(
						(comment) => comment.commentId !== action.payload.deletedCommentId,
					),
				},
				deletingComments: state.deletingComments.filter(
					(commentId) => commentId !== action.payload.deletedCommentId,
				),
			};
		case DELETE_INFRINGEMENT_REPLY:
			return {
				...state,
				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.repliedId
							? {
									...comment,
									replyCount: comment.replyCount - 1,
									replies: comment.replies.filter(
										(reply) =>
											reply.commentId !== action.payload.deletedCommentId,
									),
							  }
							: comment,
					),
				},
				deletingComments: state.deletingComments.filter(
					(commentId) => commentId !== action.payload.deletedCommentId,
				),
			};
		case DELETE_COMMENT:
			return {
				...state,
				posts: state.posts.map((post) =>
					post.postId === action.payload.data.postId
						? {
								...post,
								...action.payload.data,
						  }
						: post,
				),
				userPosts: state.userPosts.map((post) =>
					post.postId === action.payload.data.postId
						? {
								...post,
								...action.payload.data,
						  }
						: post,
				),
				unreleasedPosts: state.unreleasedPosts.map((post) =>
					post.postId === action.payload.data.postId
						? {
								...post,
								...action.payload.data,
						  }
						: post,
				),
				filteredPosts: state.filteredPosts.map((post) =>
					post.postId === action.payload.data.postId
						? {
								...post,
								...action.payload.data,
						  }
						: post,
				),
				visitedUserPosts: state.visitedUserPosts.map((post) =>
					post.postId === action.payload.data.postId
						? {
								...post,
								...action.payload.data,
						  }
						: post,
				),
				likedPostData: state.likedPostData.map((post) =>
					post.postId === action.payload.data.postId
						? {
								...post,
								...action.payload.data,
						  }
						: post,
				),
				post: {
					...state.post,
					...action.payload.data,
					comments: state.post.comments.filter(
						(comment) => comment.commentId !== action.payload.deletedCommentId,
					),
				},
				deletingComments: state.deletingComments.filter(
					(commentId) => commentId !== action.payload.deletedCommentId,
				),
				onlyUserComments: state.onlyUserComments
					.filter(
						(comment) => comment.commentId !== action.payload.deletedCommentId,
					)
					.filter(
						(reply) => reply.repliedId !== action.payload.deletedCommentId,
					),
			};

		case DELETE_COMMENT_REPLY:
			return {
				...state,
				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.repliedId
							? {
									...comment,
									replyCount: comment.replyCount - 1,
									replies: comment.replies.filter(
										(reply) =>
											reply.commentId !== action.payload.deletedCommentId,
									),
							  }
							: comment,
					),
				},
				onlyUserComments: state.onlyUserComments
					.map((comment) =>
						comment.commentId === action.payload.repliedId
							? {
									...comment,
									replyCount: comment.replyCount - 1,
							  }
							: comment,
					)
					.filter(
						(reply) => reply.commentId !== action.payload.deletedCommentId,
					),
			};

		case REMOVE_DELETED_COMMENT_ACTIONS:
			const filteredCommentIds = state.removedCommentIds.filter(
				(id) => id !== action.payload,
			);
			filteredCommentIds.push(action.payload);

			return {
				...state,
				removedCommentIds: filteredCommentIds,
				deletingComments: state.deletingComments.filter(
					(id) => id !== action.payload,
				),
			};
		case LOADING_SUBMIT_COMMENT:
			return {
				...state,
				submittingComments: state.submittingComments.concat(
					action.payload.commentId,
				),
				post: {
					...state.post,
					comments: [action.payload, ...state.post.comments],
				},
			};
		case LOADING_SUBMIT_COMMENT_REPLY:
			return {
				...state,
				submittingComments: state.submittingComments.concat(
					action.payload.commentId,
				),
				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.repliedId
							? {
									...comment,
									replies:
										typeof comment.replies === 'object'
											? comment.replies.concat(action.payload)
											: [action.payload],
							  }
							: comment,
					),
				},
			};
		case SUBMIT_COMMENT:
			return {
				...state,
				onlyUserComments: [],
				submittingComments: state.submittingComments.filter(
					(commentId) => commentId !== action.payload.commentId,
				),
				post: {
					...state.post,
					comments: state.post.comments.map((comment) => {
						return comment.commentId === action.payload.commentId
							? {
									...comment,
									...action.payload.newComment,
							  }
							: comment.commentId === action.payload.newComment.repliedId
							? {
									...comment,
									replyCount: comment.replyCount ? comment.replyCount + 1 : 1,
									replies: comment.replies.map((reply) => {
										return reply.commentId === action.payload.commentId
											? {
													...reply,
													...action.payload.newComment,
											  }
											: reply;
									}),
							  }
							: comment;
					}),

					commentCount: action.payload.postData.commentCount,
					replyCount: action.payload.postData.replyCount,
				},
				posts: state.posts.map((post) => {
					return post.postId === action.payload.newComment.postId ||
						post.postId === action.payload.newComment.repliedPostId
						? {
								...post,
								...action.payload.postData,
						  }
						: post;
				}),
				userPosts: state.userPosts.map((post) => {
					return post.postId === action.payload.newComment.postId ||
						post.postId === action.payload.newComment.repliedPostId
						? {
								...post,
								...action.payload.postData,
						  }
						: post;
				}),
				filteredPosts: state.filteredPosts.map((post) => {
					return post.postId === action.payload.newComment.postId ||
						post.postId === action.payload.newComment.repliedPostId
						? {
								...post,
								...action.payload.postData,
						  }
						: post;
				}),
				unreleasedPosts: state.unreleasedPosts.map((post) => {
					return post.postId === action.payload.newComment.postId ||
						post.postId === action.payload.newComment.repliedPostId
						? {
								...post,
								...action.payload.postData,
						  }
						: post;
				}),
				followingPosts: state.followingPosts.map((post) => {
					return post.postId === action.payload.newComment.postId ||
						post.postId === action.payload.newComment.repliedPostId
						? {
								...post,
								...action.payload.postData,
						  }
						: post;
				}),
				visitedUserPosts: state.visitedUserPosts.map((post) => {
					return post.postId === action.payload.newComment.postId ||
						post.postId === action.payload.newComment.repliedPostId
						? {
								...post,
								...action.payload.postData,
						  }
						: post;
				}),
				likedPostData: state.likedPostData.map((post) => {
					return post.postId === action.payload.newComment.postId ||
						post.postId === action.payload.newComment.repliedPostId
						? {
								...post,
								...action.payload.postData,
						  }
						: post;
				}),
			};

		case ERROR_SUBMIT_COMMENT:
			return {
				...state,
				submittingComments: state.submittingComments.filter(
					(commentId) => commentId !== action.payload,
				),
				errorSubmitComments: state.errorSubmitComments.concat(action.payload),
			};
		case DELETE_ERROR_COMMENT:
			return {
				...state,
				errorSubmitComments: state.errorSubmitComments.filter(
					(commentId) => commentId !== action.payload,
				),
				post: {
					...state.post,
					comments: state.post.comments.filter(
						(comment) => comment.commentId !== action.payload,
					),
				},
			};
		case DELETE_ERROR_REPLY:
			return {
				...state,
				errorSubmitComments: state.errorSubmitComments.filter(
					(commentId) => commentId !== action.payload,
				),
				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.repliedId
							? {
									...comment,
									replyCount: comment.replyCount ? comment.replyCount - 1 : 0,
									replies: comment.replies.filter(
										(reply) => reply.commentId !== action.payload.commentId,
									),
							  }
							: comment,
					),
				},
			};

		case SET_HASHTAGS_FROM_COMP:
			return {
				...state,
				hashtags: action.payload,
				errorQueryingHashtags: null,
			};
		case SET_HASHTAGS:
			return {
				...state,
				hashtags: action.payload,
				loadingHashtags: false,
				errorQueryingHashtags: null,
			};
		case SET_QUERY_POST_HASHTAGS:
			return {
				...state,
				filterPostHashtags: action.payload,
				loadingHashtags: false,
				errorQueryingHashtags: null,
			};
		case SET_QUERIED_USERS:
			return {
				...state,
				queriedUsers: action.payload.users,
				lastQueriedUserId: action.payload.lastUserId,
				loadingSearchbar: false,
				errorQueryingUsers: null,
			};
		case SET_TAG_SEARCHBAR_USERS:
			return {
				...state,
				queriedFollowingUsers: action.payload.users,
				lastQueriedUserId: action.payload.lastUserId,
			};
		case CLEAR_ERROR_SET_QUERIED_USERS:
			return {
				...state,
				errorQueryingUsers: null,
				errorQueryingMoreUsers: null,
			};
		case ERROR_SET_QUERIED_USERS:
			return {
				...state,
				errorQueryingUsers: action.payload,
				loadingSearchbar: false,
			};
		case ERROR_SET_MORE_QUERIED_USERS:
			return {
				...state,
				errorQueryingMoreUsers: action.payload,
				loadingSearchbar: false,
			};
		case ERROR_LOADING_HASHTAGS:
			return {
				...state,
				errorQueryingHashtags: action.payload,
				loadingHashtags: false,
			};
		case SET_MORE_QUERIED_USERS:
			return {
				...state,
				queriedUsers: state.queriedUsers.concat(action.payload.users),
				lastQueriedUserId: action.payload.lastUserId,
				loadingSearchbar: false,
				errorQueryingMoreUsers: null,
			};
		case SET_MORE_COMMENTS:
			return {
				...state,
				post: {
					...state.post,
					comments: state.post.comments.concat(action.payload),
				},
				loadingMoreComments: false,
				errorLoadingMoreComments: '',
			};
		case ERROR_SET_MORE_COMMENTS:
			return {
				...state,
				errorLoadingMoreComments: action.payload,
				loadingMoreComments: false,
			};
		case CLEAR_LOADING_MORE_COMMENTS_ERRORS:
			return {
				...state,
				errorLoadingMoreComments: '',
				loadingMoreComments: false,
			};
		case CLEAR_LOADING_POST_COMMENTS_ERRORS:
			return {
				...state,
				errorLoadingPostComments: null,
				errorLoadingOnlyComments: null,
				errorLoadingLikedReplyData: null,
				loadingPostComments: false,
			};
		case CLEAR_QUERIED_USERS:
			return {
				...state,
				queriedUsers: [],
				lastQueriedUserId: '',
				errorQueryingUsers: null,
			};

		case SET_TAG_FOLLOWERS:
			return {
				...state,
				tagFollowers: action.payload,
				loadingFollowers: false,
				errorFollowers: null,
			};
		case SET_FOLLOWERS:
			return {
				...state,
				followers: action.payload,
				loadingFollowers: false,
				errorFollowers: null,
			};
		case LOADING_FOLLOWERS:
			return {
				...state,
				loadingFollowers: true,
				errorFollowers: null,
			};
		case ERROR_SET_FOLLOWERS:
			return {
				...state,
				loadingFollowers: false,
				errorFollowers: action.payload,
			};
		case CLEAR_ERROR_SET_FOLLOWERS:
			return {
				...state,
				errorFollowers: null,
			};
		case SET_MORE_FOLLOWERS:
			return {
				...state,
				followers: state.followers.concat(action.payload),
				loadingFollowers: false,
				errorFollowers: null,
			};
		case CLEAR_FOLLOWERS:
			return {
				...state,
				followers: [],
				errorFollowers: null,
			};

		case SET_FOLLOWING:
			return {
				...state,
				following: action.payload,
				loadingFollowing: false,
				errorFollowing: null,
			};
		case LOADING_FOLLOWING:
			return {
				...state,
				loadingFollowing: true,
				errorFollowing: null,
			};
		case ERROR_SET_FOLLOWING:
			return {
				...state,
				loadingFollowing: false,
				errorFollowing: action.payload,
			};
		case CLEAR_ERROR_SET_FOLLOWING:
			return {
				...state,
				errorFollowing: null,
			};
		case SET_MORE_FOLLOWING:
			return {
				...state,
				following: state.following.concat(action.payload),
				loadingFollowing: false,
				errorFollowing: null,
			};
		case CLEAR_FOLLOWING:
			return {
				...state,
				following: [],
				errorFollowing: null,
			};

		case LOADING_HASHTAGS:
			return {
				...state,
				loadingHashtags: true,
				errorQueryingHashtags: null,
			};
		case STOP_LOADING_HASHTAGS:
			return {
				...state,
				loadingHashtags: false,
				errorQueryingHashtags: null,
			};
		case LOADING_SEARCHBAR:
			return {
				...state,
				loadingSearchbar: true,
				errorQueryingUsers: null,
			};

		case LOADING_PROFILE:
			return {
				...state,
				loadingProfile: true,
				errorSettingUserAndPosts: null,
				errorSettingUserPosts: null,
				errorSettingUnreleasedUserPosts: null,
			};
		case LOADING_PROFILE_POSTS:
			return {
				...state,
				loadingProfilePosts: true,
				errorSettingUserAndPosts: null,
				errorSettingUserPosts: null,
			};
		case LOADING_UNRELEASED_PROFILE_POSTS:
			return {
				...state,
				loadingUnreleasedPosts: true,
				errorSettingUserAndPosts: null,
				errorSettingUnreleasedUserPosts: null,
			};

		case CLEAR_ERROR_SET_USER_AND_DATA:
			return {
				...state,
				errorSettingUserAndPosts: null,
			};

		case LOADING_POST:
			return {
				...state,
				errorLoadingPost: null,
				loadingPost: true,
			};
		case ERROR_LOADING_POST:
			return {
				...state,
				loadingPost: false,
				errorLoadingPost: action.payload,
			};

		case LOADING_LIKES:
			return {
				...state,
				loadingLikes: true,
				errorLoadingLikes: null,
				errorLoadingMoreLikes: null,
			};
		case ERROR_LOADING_LIKES:
			return {
				...state,
				loadingLikes: false,
				errorLoadingLikes: action.payload,
			};
		case LOADING_MORE_LIKES:
			return {
				...state,
				loadingMoreLikes: true,
			};
		case ERROR_LOADING_MORE_LIKES:
			return {
				...state,
				loadingMoreLikes: false,
				errorLoadingMoreLikes: action.payload,
			};
		case LOADING_MORE_COMMENTS:
			return {
				...state,
				loadingMoreComments: true,
				errorLoadingMoreComments: '',
			};

		case EDITED_IMG_FILE:
			return {
				...state,
				editedImageFile: action.payload.fileSrc,
				editedImageFileToUpload: action.payload.fileToUpload,
			};
		case CLEAR_EDITED_IMG_FILE:
			return {
				...state,
				editedImageFile: null,
				editedImageFileToUpload: null,
			};
		case EDITED_AUDIO_FILE:
			return {
				...state,
				editedAudioFile: action.payload.fileSrc,
				editedAudioFileToUpload: action.payload.fileToUpload,
				deletedAudioFile: action.payload.fileSrc,
				deletedEditedAudioFileToUpload: action.payload.fileToUpload,
			};
		case RESET_EDITED_AUDIO_FILE:
			return {
				...state,
				editedAudioFile: null,
				editedAudioFileToUpload: null,
			};
		case CLEAR_AUDIO_FILE:
			return {
				...state,
				editedAudioFile: null,
				editedAudioFileToUpload: null,
				deletedAudioFile: null,
				deletedEditedAudioFileToUpload: null,
			};
		case UPDATE_VISITED_PROFILE:
			return {
				...state,
				visitedUserCredentials: {
					...state.visitedUserCredentials,
					...action.payload.user,
				},
			};

		case UPLOADING_IMAGE_PROGRESS:
			return {
				...state,
				uploadingImageProgress: action.payload,
			};
		case ERROR_UPLOADING_IMAGE:
			return {
				...state,
				errorUploadingImage: action.payload,
				uploadingImageProgress: null,
			};

		case UPLOADING_IMAGE_SUCCESS:
			return {
				...state,
				uploadingImageProgress: null,
				errorUploadingImage: null,
			};

		case UPLOADING_LETTER_AUDIO_PROGRESS:
			return {
				...state,
				uploadingLetterAudioProgress: action.payload,
			};
		case ERROR_UPLOADING_LETTER_AUDIO:
			return {
				...state,
				errorUploadingLetterAudio: action.payload,
				uploadingLetterAudioProgress: null,
			};

		case UPLOADING_LETTER_AUDIO_SUCCESS:
			return {
				...state,
				uploadingLetterAudioProgress: null,
				errorUploadingLetterAudio: null,
			};

		case UPLOADING_AUDIO_PROGRESS:
			return {
				...state,
				uploadingAudioProgress: action.payload,
			};
		case ERROR_UPLOADING_AUDIO:
			return {
				...state,
				errorUploadingAudio: action.payload,
				uploadingAudioProgress: null,
			};

		case UPLOADING_AUDIO_SUCCESS:
			return {
				...state,
				uploadingAudioProgress: null,
				errorUploadingAudio: null,
			};

		case UPLOADING_VIDEO_PROGRESS:
			return {
				...state,
				uploadingVideoProgress: action.payload,
			};
		case ERROR_UPLOADING_VIDEO:
			return {
				...state,
				errorUploadingVideo: action.payload,
				uploadingVideoProgress: null,
				uploadingVideoRunning: false,
			};
		case SET_UPLOADING_VIDEO_PAUSED:
			return {
				...state,
				uploadingVideoPaused: true,
				uploadingVideoRunning: false,
			};
		case RESUME_UPLOADING_VIDEO:
			return {
				...state,
				uploadingVideoPaused: false,
			};
		case UPLOADING_VIDEO_SUCCESS:
			return {
				...state,
				uploadingVideoProgress: null,
				errorUploadingVideo: null,
				uploadingVideoPaused: null,
				uploadingVideoRunning: null,
				uploadingPost: null,
			};
		case CANCEL_UPLOADING_VIDEO:
			return {
				...state,
				uploadingVideoProgress: null,
				errorUploadingVideo: null,
				uploadingVideoPaused: null,
				uploadingVideoRunning: null,
			};
		case UPLOADING_VIDEO_RUNNING:
			return {
				...state,
				uploadingVideoRunning: true,
			};
		case SET_REPORT_DATA:
			return {
				...state,
				reportData: action.payload,
			};
		case CLEAR_SOCIAL_REPORT:
			return {
				...state,
				reportData: {},
				reportingSocialIssue: null,
				reportingSocialIssueSuccess: '',
				errorReportingSocialIssue: null,
			};
		case REPORTING_SOCIAL_ISSUE:
			return {
				...state,
				reportingSocialIssue: true,
				reportingSocialIssueSuccess: '',
				errorReportingSocialIssue: null,
			};
		case REPORTING_SOCIAL_ISSUE_SUCCESS:
			return {
				...state,
				reportingSocialIssue: false,
				reportingSocialIssueSuccess: action.payload.message,
				reportedContent: state.reportedContent.concat(
					action.payload.reportedDataId,
				),
			};
		case ERROR_REPORTING_SOCIAL_ISSUE:
			return {
				...state,
				reportingSocialIssue: false,
				errorReportingSocialIssue: action.payload,
			};

		case HIDE_REPORTED_POST:
			return {
				...state,
				reportedPosts: state.reportedPosts.concat(action.payload),
				reportData: {},
				reportingSocialIssue: null,
				reportingSocialIssueSuccess: '',
				errorReportingSocialIssue: null,
			};
		case SHOW_REPORTED_POST:
			return {
				...state,
				reportedPosts: state.reportedPosts.filter(
					(postId) => postId !== action.payload,
				),
			};
		case HIDE_REPORTED_COMMENT:
			return {
				...state,
				reportedComments: state.reportedComments.concat(action.payload),
				reportData: {},
				reportingSocialIssue: null,
				reportingSocialIssueSuccess: '',
				errorReportingSocialIssue: null,
			};
		case SHOW_REPORTED_COMMENT:
			return {
				...state,
				reportedComments: state.reportedComments.filter(
					(commentId) => commentId !== action.payload,
				),
			};
		case LOADING_REPORTS:
			return {
				...state,
				loadingReports: true,
				reportsErrors: null,
			};
		case LOADING_MORE_REPORTS:
			return {
				...state,
				loadingMoreReports: true,
				reportsErrors: null,
			};
		case SET_ISSUE_REPORTS:
			return {
				...state,
				loadingReports: false,
				reportsErrors: null,
				reports: action.payload,
			};
		case SET_MORE_ISSUE_REPORTS:
			return {
				...state,
				loadingMoreReports: false,
				reportsErrors: null,
				reports: state.reports.concat(action.payload),
			};
		case SET_UPDATED_ISSUE_REPORT:
			return {
				...state,
				reports: state.reports.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				),
				loadingReportUpdate: false,
			};

		case SET_USER_REPORTS:
			return {
				...state,
				loadingReports: false,
				reportsErrors: null,
				userReports: action.payload,
			};
		case SET_MORE_USER_REPORTS:
			return {
				...state,
				loadingMoreReports: false,
				reportsErrors: null,
				userReports: state.userReports.concat(action.payload),
			};
		case SET_UPDATED_USER_REPORT:
			return {
				...state,
				userReports: state.userReports.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				),
				loadingReportUpdate: false,
			};

		case SET_POST_REPORTS:
			return {
				...state,
				loadingReports: false,
				reportsErrors: null,
				postReports: action.payload,
			};
		case SET_MORE_POST_REPORTS:
			return {
				...state,
				loadingMoreReports: false,
				reportsErrors: null,
				postReports: state.postReports.concat(action.payload),
			};
		case SET_UPDATED_POST_REPORT:
			return {
				...state,
				postReports: state.postReports.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				),
				loadingReportUpdate: false,
			};

		case SET_COMMENT_REPORTS:
			return {
				...state,
				loadingReports: false,
				reportsErrors: null,
				commentReports: action.payload,
			};
		case SET_MORE_COMMENT_REPORTS:
			return {
				...state,
				loadingMoreReports: false,
				reportsErrors: null,
				commentReports: state.commentReports.concat(action.payload),
			};
		case SET_UPDATED_COMMENT_REPORT:
			return {
				...state,
				commentReports: state.commentReports.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				),
				loadingReportUpdate: false,
			};
		case SET_FEATURE_REPORTS:
			return {
				...state,
				loadingReports: false,
				reportsErrors: null,
				featureReports: action.payload,
			};
		case SET_MORE_FEATURE_REPORTS:
			return {
				...state,
				loadingMoreReports: false,
				reportsErrors: null,
				featureReports: state.featureReports.concat(action.payload),
			};
		case SET_UPDATED_FEATURE_REPORT:
			return {
				...state,
				featureReports: state.featureReports.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				),
				loadingReportUpdate: false,
			};
		case SET_SUPPORT_REPORTS:
			return {
				...state,
				loadingReports: false,
				reportsErrors: null,
				supportReports: action.payload,
			};
		case SET_MORE_SUPPORT_REPORTS:
			return {
				...state,
				loadingMoreReports: false,
				reportsErrors: null,
				supportReports: state.supportReports.concat(action.payload),
			};
		case CONVERT_SUPPORT_TO_ISSUE_REPORT:
			let updatedReport = state.supportReports
				.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				)
				.filter((report) => report.reportId === action.payload.reportId);
			return {
				...state,
				reports: [updatedReport, ...state.reports],
				supportReports: state.supportReports.filter(
					(report) => report.reportId === action.payload.reportId,
				),
				loadingReportUpdate: false,
			};

		case SET_UPDATED_SUPPORT_REPORT:
			return {
				...state,
				supportReports: state.supportReports.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				),
				loadingReportUpdate: false,
			};
		case SET_FEEDBACK:
			return {
				...state,
				loadingReports: false,
				reportsErrors: null,
				feedback: action.payload,
			};
		case SET_MORE_FEEDBACK:
			return {
				...state,
				loadingMoreReports: false,
				reportsErrors: null,
				feedback: state.feedback.concat(action.payload),
			};
		case SET_UPDATED_FEEDBACK:
			return {
				...state,
				feedback: state.feedback.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				),
				loadingReportUpdate: false,
			};
		case SET_BOUNDARY_REPORTS:
			return {
				...state,
				loadingReports: false,
				reportsErrors: null,
				boundaryReports: action.payload,
			};
		case SET_MORE_BOUNDARY_REPORTS:
			return {
				...state,
				loadingMoreReports: false,
				reportsErrors: null,
				boundaryReports: state.boundaryReports.concat(action.payload),
			};
		case SET_UPDATED_BOUNDARY_REPORTS:
			return {
				...state,
				boundaryReports: state.boundaryReports.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				),
				loadingReportUpdate: false,
			};

		case SET_USER_APPEALS:
			return {
				...state,
				loadingReports: false,
				reportsErrors: null,
				userAppeals: action.payload,
			};
		case SET_MORE_USER_APPEALS:
			return {
				...state,
				loadingMoreReports: false,
				reportsErrors: null,
				userAppeals: state.userAppeals.concat(action.payload),
			};
		case SET_UPDATED_USER_APPEAL:
			return {
				...state,
				userAppeals: state.userAppeals.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				),
				loadingReportUpdate: false,
			};
		case SET_POST_APPEALS:
			return {
				...state,
				loadingReports: false,
				reportsErrors: null,
				postAppeals: action.payload,
			};
		case SET_MORE_POST_APPEALS:
			return {
				...state,
				loadingMoreReports: false,
				reportsErrors: null,
				postAppeals: state.postAppeals.concat(action.payload),
			};
		case SET_UPDATED_POST_APPEAL:
			return {
				...state,
				postAppeals: state.postAppeals.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				),
				loadingReportUpdate: false,
			};
		case SET_COMMENT_APPEALS:
			return {
				...state,
				loadingReports: false,
				reportsErrors: null,
				commentAppeals: action.payload,
			};
		case SET_MORE_COMMENT_APPEALS:
			return {
				...state,
				loadingMoreReports: false,
				reportsErrors: null,
				commentAppeals: state.commentAppeals.concat(action.payload),
			};
		case SET_UPDATED_COMMENT_APPEAL:
			return {
				...state,
				commentAppeals: state.commentAppeals.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				),
				loadingReportUpdate: false,
			};

		case SET_REPORTS_ERRORS:
			return {
				...state,
				loadingReports: false,
				loadingMoreReports: false,
				reportsErrors: action.payload,
			};
		case CLEAR_QUERIED_REPORTS:
			return {
				...state,
				queriedReports: [],
			};
		case SET_QUERIED_REPORTS:
			return {
				...state,
				loadingReports: false,
				queriedReports: action.payload,
			};
		case SET_MORE_QUERIED_REPORTS:
			return {
				...state,
				loadingMoreReports: false,
				queriedReports: state.queriedReports.concat(action.payload),
			};
		case LOADING_REPORT_UPDATE:
			return {
				...state,
				loadingReportUpdate: true,
			};
		case SET_UPDATED_QUERIED_REPORT:
			return {
				...state,
				queriedReports: state.queriedReports.map((report) =>
					report.reportId === action.payload.reportId
						? {
								...report,
								...action.payload,
						  }
						: report,
				),
				loadingReportUpdate: false,
			};
		case SET_UPDATED_REPORT_ERROR:
			return {
				...state,
				updateReportError: action.payload,
				loadingReportUpdate: false,
			};
		case CLEAR_REPORTS_ERRORS:
			return {
				...state,
				reportsErrors: null,
				updateReportError: null,
			};
		case CLEAR_ACCOUNT_REPORTS:
			return {
				...state,
				loadingAccountReports: false,
				setAccountReports: {},
			};
		case LOADING_ACCOUNT_REPORTS:
			return {
				...state,
				loadingAccountReports: true,
				setAccountReports: {},
				errorSetAccountReports: null,
			};
		case SET_ACCOUNT_REPORTS:
			return {
				...state,
				loadingAccountReports: false,
				setAccountReports: action.payload,
			};
		case ERROR_SET_ACCOUNT_REPORTS:
			return {
				...state,
				loadingAccountReports: false,
				errorSetAccountReports: action.payload,
			};
		case SET_ACCOUNT_ACTION:
			return {
				...state,
				setAccountReports: {
					...state.setAccountReports,
					reportedId: {
						...state.setAccountReports.reportedId,
						disabled: action.payload.accountRestriction,
					},
				},
				visitedUserCredentials: {
					...state.visitedUserCredentials,
					disabled: action.payload.accountRestriction,
				},
			};
		case LOADING_POST_INFRINGEMENT:
			return {
				...state,
				loadingPostInfringement: true,
				errorPostInfringement: null,
				reportingPostId: action.payload,
			};
		case SET_POST_INFRINGEMENT:
			return {
				...state,
				loadingPostInfringement: false,
				visitedUserPosts: state.visitedUserPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								infringements: action.payload.infringements,
						  }
						: post,
				),
				errorPostInfringement: null,
				postReports: state.postReports.map((report) =>
					report.reportId === state.adminReportId
						? {
								...report,
								infringements: action.payload.infringements,
						  }
						: report,
				),
				post: {
					...state.post,
					infringements: action.payload.infringements,
				},
			};

		case SET_POST_REPORT_INFRINGEMENT:
			return {
				...state,
				loadingPostInfringement: false,
				posts: state.posts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								infringements: action.payload.infringements,
						  }
						: post,
				),
				filteredPosts: state.filteredPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								infringements: action.payload.infringements,
						  }
						: post,
				),
				visitedUserPosts: state.visitedUserPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								infringements: action.payload.infringements,
						  }
						: post,
				),
				errorPostInfringement: null,
				userReports: state.userReports.map((report) =>
					report.reportId === state.adminReportId
						? {
								...report,
								infringements: action.payload.infringements,
						  }
						: report,
				),
				postReports: state.postReports.map((report) =>
					report.reportId === state.adminReportId
						? {
								...report,
								infringements: action.payload.infringements,
						  }
						: report,
				),
				userAppeals: state.userAppeals.map((report) =>
					report.reportId === state.adminReportId
						? {
								...report,
								infringements: action.payload.infringements,
						  }
						: report,
				),
				postAppeals: state.postAppeals.map((report) =>
					report.reportId === state.adminReportId
						? {
								...report,
								infringements: action.payload.infringements,
						  }
						: report,
				),
				commentAppeals: state.commentAppeals.map((report) =>
					report.reportId === state.adminReportId
						? {
								...report,
								infringements: action.payload.infringements,
						  }
						: report,
				),
				post: {
					...state.post,
					infringements: action.payload.infringements,
				},
			};
		case ERROR_POST_INFRINGEMENT:
			return {
				...state,
				loadingPostInfringement: false,
				errorPostInfringement: action.payload,
			};
		case ADD_REPORTED_POST_INFRINGEMENT:
			return {
				...state,
				setAccountReports: {
					...state.setAccountReports,
					reportedId: {
						...state.setAccountReports.reportedId,
						infringements:
							state.setAccountReports.reportedId.infringements > 0
								? state.setAccountReports.reportedId.infringements + 1
								: 1,
						removedPosts:
							state.setAccountReports.reportedId.removedPosts > 0
								? state.setAccountReports.reportedId.removedPosts + 1
								: 1,
					},
				},
			};
		case REMOVE_REPORTED_POST_INFRINGEMENT:
			return {
				...state,
				setAccountReports: {
					...state.setAccountReports,
					reportedId: {
						...state.setAccountReports.reportedId,
						infringements:
							state.setAccountReports.reportedId.infringements > 0
								? state.setAccountReports.reportedId.infringements - 1
								: 0,
						removedPosts:
							state.setAccountReports.reportedId.removedPosts > 0
								? state.setAccountReports.reportedId.removedPosts - 1
								: 0,
					},
				},
			};
		case ADD_POST_INFRINGEMENT:
			return {
				...state,
				setAccountReports: {
					...state.setAccountReports,
					reporterId: {
						...state.setAccountReports.reporterId,
						infringements:
							state.setAccountReports.reporterId.infringements > 0
								? state.setAccountReports.reporterId.infringements + 1
								: 1,
						removedPosts:
							state.setAccountReports.reporterId.removedPosts > 0
								? state.setAccountReports.reporterId.removedPosts + 1
								: 1,
					},
				},
			};
		case REMOVE_POST_INFRINGEMENT:
			return {
				...state,
				setAccountReports: {
					...state.setAccountReports,
					reporterId: {
						...state.setAccountReports.reporterId,
						infringements:
							state.setAccountReports.reporterId.infringements > 0
								? state.setAccountReports.reporterId.infringements - 1
								: 0,
						removedPosts:
							state.setAccountReports.reporterId.removedPosts > 0
								? state.setAccountReports.reporterId.removedPosts - 1
								: 0,
					},
				},
			};
		case LOADING_COMMENT_INFRINGEMENT:
			return {
				...state,
				loadingCommentInfringement: true,
				errorCommentInfringement: null,
				reportingCommentId: action.payload,
			};
		case SET_COMMENT_INFRINGEMENT:
			return {
				...state,
				loadingCommentInfringement: false,
				errorCommentInfringement: null,
				adminUserComments: state.adminUserComments.map((comment) =>
					comment.commentId === action.payload.commentId
						? {
								...comment,
								infringements: action.payload.infringements,
						  }
						: comment,
				),
				adminPostComments: state.adminPostComments.map((comment) =>
					comment.commentId === action.payload.commentId
						? {
								...comment,
								infringements: action.payload.infringements,
						  }
						: comment,
				),
				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.commentId
							? {
									...comment,
									infringements: action.payload.infringements,
							  }
							: comment,
					),
					commentCount: action.payload.infringements
						? state.post.commentCount - 1
						: state.post.commentCount + 1,
				},

				posts: state.posts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								commentCount: action.payload.infringements
									? post.commentCount - 1
									: post.commentCount + 1,
						  }
						: post,
				),
				filteredPosts: state.filteredPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								commentCount: action.payload.infringements
									? post.commentCount - 1
									: post.commentCount + 1,
						  }
						: post,
				),
				visitedUserPosts: state.visitedUserPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								commentCount: action.payload.infringements
									? post.commentCount - 1
									: post.commentCount + 1,
						  }
						: post,
				),
			};
		case SET_COMMENT_REPLY_REPORT_INFRINGEMENT:
			return {
				...state,
				loadingCommentInfringement: false,
				posts: state.posts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								replyCount: action.payload.infringements
									? post.replyCount - 1
									: post.replyCount + 1,
						  }
						: post,
				),
				filteredPosts: state.filteredPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								replyCount: action.payload.infringements
									? post.replyCount - 1
									: post.replyCount + 1,
						  }
						: post,
				),
				visitedUserPosts: state.visitedUserPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								replyCount: action.payload.infringements
									? post.replyCount - 1
									: post.replyCount + 1,
						  }
						: post,
				),

				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.repliedId
							? {
									...comment,
									replies: comment.replies.map((reply) =>
										reply.commentId === action.payload.commentId
											? {
													...reply,
													infringements: action.payload.infringements,
											  }
											: reply,
									),
							  }
							: comment,
					),

					replyCount: action.payload.infringements
						? state.post.replyCount - 1
						: state.post.replyCount + 1,
				},
				errorCommentInfringement: null,
				adminUserComments: state.adminUserComments.map((comment) =>
					comment.commentId === action.payload.repliedId
						? {
								...comment,
								replies: comment.replies.map((reply) =>
									reply.commentId === action.payload.commentId
										? {
												...reply,
												infringements: action.payload.infringements,
										  }
										: reply,
								),
						  }
						: comment,
				),
				adminPostComments: state.adminPostComments.map((comment) =>
					comment.commentId === action.payload.repliedId
						? {
								...comment,
								replies: comment.replies.map((reply) =>
									reply.commentId === action.payload.commentId
										? {
												...reply,
												infringements: action.payload.infringements,
										  }
										: reply,
								),
						  }
						: comment,
				),
			};
		case SET_COMMENT_REPORT_INFRINGEMENT:
			return {
				...state,
				loadingCommentInfringement: false,
				visitedUserPosts: state.visitedUserPosts.map((post) =>
					post.postId === action.payload.postId
						? {
								...post,
								commentCount: action.payload.infringements
									? post.commentCount - 1
									: post.commentCount + 1,
						  }
						: post,
				),
				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.commentId
							? {
									...comment,
									infringements: action.payload.infringements,
							  }
							: comment,
					),
					commentCount: action.payload.infringements
						? state.post.commentCount - 1
						: state.post.commentCount + 1,
				},
				errorCommentInfringement: null,
				adminUserComments: state.adminUserComments.map((comment) =>
					comment.commentId === action.payload.commentId
						? {
								...comment,
								infringements: action.payload.infringements,
						  }
						: comment,
				),
				adminPostComments: state.adminPostComments.map((comment) =>
					comment.commentId === action.payload.commentId
						? {
								...comment,
								infringements: action.payload.infringements,
						  }
						: comment,
				),
			};

		case SET_COMMENT_REPORT_COLLECTION_INFRINGEMENT:
			return {
				...state,
				commentReports: state.commentReports.map((report) =>
					report.reportId === state.adminReportId
						? {
								...report,
								infringements: action.payload.infringements,
						  }
						: report,
				),
				commentAppeals: state.commentAppeals.map((report) =>
					report.reportId === state.adminReportId
						? {
								...report,
								infringements: action.payload.infringements,
						  }
						: report,
				),
			};
		case ERROR_COMMENT_INFRINGEMENT:
			return {
				...state,
				loadingCommentInfringement: false,
				errorCommentInfringement: action.payload,
			};
		case ADD_REPORTER_COMMENT_INFRINGEMENT:
			return {
				...state,
				setAccountReports: {
					...state.setAccountReports,
					reporterId: {
						...state.setAccountReports.reporterId,
						infringements:
							state.setAccountReports.reporterId.infringements > 0
								? state.setAccountReports.reporterId.infringements + 1
								: 1,
						removedComments:
							state.setAccountReports.reporterId.removedComments > 0
								? state.setAccountReports.reporterId.removedComments + 1
								: 1,
					},
				},
			};
		case REMOVE_REPORTER_COMMENT_INFRINGEMENT:
			return {
				...state,
				setAccountReports: {
					...state.setAccountReports,
					reporterId: {
						...state.setAccountReports.reporterId,
						infringements:
							state.setAccountReports.reporterId.infringements > 0
								? state.setAccountReports.reporterId.infringements - 1
								: 0,
						removedComments:
							state.setAccountReports.reporterId.removedComments > 0
								? state.setAccountReports.reporterId.removedComments - 1
								: 0,
					},
				},
			};

		case ADD_REPORTED_COMMENT_INFRINGEMENT:
			return {
				...state,
				setAccountReports: {
					...state.setAccountReports,
					reportedId: {
						...state.setAccountReports.reportedId,
						infringements:
							state.setAccountReports.reportedId.infringements > 0
								? state.setAccountReports.reportedId.infringements + 1
								: 1,
						removedComments:
							state.setAccountReports.reportedId.removedComments > 0
								? state.setAccountReports.reportedId.removedComments + 1
								: 1,
					},
				},
			};
		case REMOVE_REPORTED_COMMENT_INFRINGEMENT:
			return {
				...state,
				setAccountReports: {
					...state.setAccountReports,
					reportedId: {
						...state.setAccountReports.reportedId,
						infringements:
							state.setAccountReports.reportedId.infringements > 0
								? state.setAccountReports.reportedId.infringements - 1
								: 0,
						removedComments:
							state.setAccountReports.reportedId.removedComments > 0
								? state.setAccountReports.reportedId.removedComments - 1
								: 0,
					},
				},
			};

		case SET_ADMIN_REPORT:
			return {
				...state,
				adminReportId: action.payload.reportId,
				adminReportedUserId: action.payload.userId,
				adminReportCollection: action.payload.collection,
				adminReportPostId: action.payload.postId,
				adminReportCommentId: action.payload.commentId,
				adminReportRepliedId: action.payload.repliedId,
				adminReportInfringement: action.payload.infringement,
				post: { ...state.post, infringements: false },
			};

		case FETCHING_ADMIN_COMMENTS:
			return {
				...state,
				fetchingAdminComments: true,
				errorFetchingAdminComments: null,
			};
		case SET_ADMIN_COMMENTS:
			return {
				...state,
				errorLoadingMoreComments: '',
				fetchingAdminComments: false,
				adminUserComments: action.payload,
			};
		case SET_MORE_ADMIN_COMMENTS:
			let moreAdminComments = action.payload.map((comment) => ({
				...comment,
				userImage: state.adminUserComments[0].userImage,
				userImageColor: state.adminUserComments[0].userImageColor,
				userHandle: state.adminUserComments[0].userHandle,
			}));
			return {
				...state,
				adminUserComments: state.adminUserComments.concat(moreAdminComments),
				loadingMoreComments: false,
				errorLoadingMoreComments: '',
			};
		case ERROR_FETCHING_ADMIN_COMMENTS:
			return {
				...state,
				fetchingAdminComments: false,
				errorFetchingAdminComments: action.payload,
			};

		case LOADING_LIKED_POST_DATA:
			return {
				...state,
				loadingLikedPostData: true,
				errorLoadingLikedPostData: null,
			};
		case SET_LIKED_POST_DATA:
			return {
				...state,
				loadingLikedPostData: false,
				likedPostData: action.payload,
			};
		case RESET_LIKED_POST_DATA:
			return {
				...state,
				likedPostData: [],
			};
		case SET_MORE_LIKED_POST_DATA:
			return {
				...state,
				loadingLikedPostData: false,
				likedPostData: state.likedPostData.concat(action.payload),
			};
		case ERROR_SET_LIKED_POST_DATA:
			return {
				...state,
				loadingLikedPostData: false,
				errorLoadingLikedPostData: action.payload,
			};

		case LOADING_LIKED_COMMENT_DATA:
			return {
				...state,
				loadingLikedCommentData: true,
				errorLoadingLikedCommentData: null,
			};
		case SET_LIKED_COMMENT_DATA:
			return {
				...state,
				loadingLikedCommentData: false,
				likedCommentData: action.payload,
			};
		case RESET_LIKED_COMMENT_DATA:
			return {
				...state,
				likedCommentData: [],
			};
		case SET_MORE_LIKED_COMMENT_DATA:
			return {
				...state,
				loadingLikedCommentData: false,
				likedCommentData: state.likedCommentData.concat(action.payload),
			};
		case ERROR_SET_LIKED_COMMENT_DATA:
			return {
				...state,
				loadingLikedCommentData: false,
				errorLoadingLikedCommentData: action.payload,
			};

		case LOADING_LIKED_REPLY_DATA:
			return {
				...state,
				loadingLikedReplyData: true,
				errorLoadingLikedReplyData: null,
			};
		case SET_LIKED_REPLY_DATA:
			return {
				...state,
				loadingLikedReplyData: false,
				likedReplyData: action.payload,
			};
		case RESET_LIKED_REPLY_DATA:
			return {
				...state,
				likedReplyData: [],
			};
		case SET_MORE_LIKED_REPLY_DATA:
			return {
				...state,
				loadingLikedReplyData: false,
				likedReplyData: state.likedReplyData.concat(action.payload),
			};
		case ERROR_SET_LIKED_REPLY_DATA:
			return {
				...state,
				loadingLikedReplyData: false,
				errorLoadingLikedReplyData: action.payload,
			};

		case LOADING_POST_COMMENTS: {
			return {
				...state,
				loadingPostComments: true,
				errorLoadingPostComments: null,
			};
		}
		case SET_POST_COMMENTS: {
			return {
				...state,
				loadingPostComments: false,
				post: {
					...state.post,
					comments: action.payload,
				},
			};
		}
		case ERROR_LOADING_POST_COMMENTS: {
			return {
				...state,
				loadingPostComments: false,
				loadingNewCommentOrder: false,
				errorLoadingPostComments: action.payload,
			};
		}
		case LOADING_ADMIN_POST_COMMENTS: {
			return {
				...state,
				loadingAdminPostComments: true,
			};
		}
		case SET_ADMIN_POST_COMMENTS: {
			return {
				...state,
				loadingAdminPostComments: false,
				adminPostComments: action.payload,
			};
		}
		case ERROR_LOADING_ADMIN_POST_COMMENTS: {
			return {
				...state,
				loadingAdminPostComments: false,
				loadingNewCommentOrder: false,
			};
		}
		case CLEAR_ADMIN_POST_COMMENTS: {
			return {
				...state,
				loadingAdminPostComments: false,
				adminPostComments: [],
			};
		}
		case LOADING_ONLY_USER_COMMENTS: {
			return {
				...state,
				loadingOnlyComments: true,
				errorLoadingOnlyUserComments: null,
			};
		}
		case SET_ONLY_USER_COMMENTS: {
			return {
				...state,
				loadingOnlyComments: false,
				loadingNewCommentOrder: false,
				onlyUserComments: action.payload,
			};
		}
		case SET_MORE_ONLY_USER_COMMENTS: {
			return {
				...state,
				loadingOnlyComments: false,
				onlyUserComments: state.onlyUserComments.concat(action.payload),
			};
		}
		case ERROR_LOADING_ONLY_USER_COMMENTS: {
			return {
				...state,
				loadingOnlyComments: false,
				loadingNewCommentOrder: false,
				errorLoadingOnlyUserComments: action.payload,
			};
		}

		case SUBMITTING_APPEAL: {
			return {
				...state,
				submittingAppeal: true,
				submittingAppealSuccess: '',
				errorSubmittingAppeal: null,
			};
		}

		case USER_APPEAL_SUCCESFULLY_COMMITTED: {
			return {
				...state,
				submittingAppeal: false,
				submittingAppealSuccess: action.payload,
			};
		}
		case POST_APPEAL_SUCCESFULLY_COMMITTED: {
			return {
				...state,
				submittingAppeal: false,
				submittingAppealSuccess: action.payload,
				post: {
					...state.post,
					appeal: false,
				},
			};
		}
		case COMMENT_APPEAL_SUCCESFULLY_COMMITTED: {
			return {
				...state,
				submittingAppeal: false,
				submittingAppealSuccess: action.payload.message,
				post: {
					...state.post,
					comments: state.post.comments.map((comment) => {
						return comment.commentId === action.payload.commentId
							? {
									...comment,
									appeal: false,
							  }
							: comment;
					}),
				},
			};
		}
		case COMMENT_REPLY_APPEAL_SUCCESFULLY_COMMITTED: {
			return {
				...state,
				submittingAppeal: false,
				submittingAppealSuccess: action.payload.message,
				post: {
					...state.post,
					comments: state.post.comments.map((comment) => {
						return comment.commentId === action.payload.repliedId
							? {
									...comment,
									replyCount: comment.replyCount ? comment.replyCount + 1 : 1,
									replies: comment.replies.map((reply) => {
										return reply.commentId === action.payload.commentId
											? {
													...reply,
													appeal: false,
											  }
											: reply;
									}),
							  }
							: comment;
					}),
				},
			};
		}
		case ERROR_SUBMITTING_APPEAL: {
			return {
				...state,
				submittingAppeal: false,
				errorSubmittingAppeal: action.payload,
			};
		}
		case CLEAR_APPEAL_ACTIONS: {
			return {
				...state,
				submittingAppeal: false,
				submittingAppealSuccess: '',
				errorSubmittingAppeal: null,
			};
		}

		case UPDATING_APPEALABLE_CONTENT: {
			return {
				...state,
				updatingAppealableContent: true,
				errorUpdatingAppealableContent: null,
			};
		}
		case SUCCESS_UPDATING_APPEALABLE_CONTENT: {
			return {
				...state,
				updatingAppealableContent: false,
				successUpdatingAppealableContent: action.payload.message,
				visitedUserCredentials: {
					...state.visitedUserCredentials,
					appealable: action.payload.appealable,
				},
			};
		}
		case ERROR_UPDATING_APPEALABLE_CONTENT: {
			return {
				...state,
				updatingAppealableContent: false,
				errorUpdatingAppealableContent: action.payload,
			};
		}
		case LOADING_NEW_ORDER: {
			return {
				...state,
				loadingNewCommentOrder: true,
			};
		}

		case LOADING_COMMENT_REPLIES: {
			return {
				...state,
				loadingCommentReplies: state.loadingCommentReplies.concat(
					action.payload,
				),
				errorLoadingCommentReplies: state.errorLoadingCommentReplies.filter(
					(commentId) => commentId !== action.payload,
				),
			};
		}

		case SET_COMMENT_REPLIES: {
			return {
				...state,
				loadingCommentReplies: state.loadingCommentReplies.filter(
					(commentId) => commentId !== action.payload.commentId,
				),

				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.commentId
							? {
									...comment,
									replies: action.payload.replies,
							  }
							: comment,
					),
				},
				loadingNewCommentOrder: false,
			};
		}
		case SET_MORE_COMMENT_REPLIES: {
			return {
				...state,
				loadingCommentReplies: state.loadingCommentReplies.filter(
					(commentId) => commentId !== action.payload.commentId,
				),

				post: {
					...state.post,
					comments: state.post.comments.map((comment) =>
						comment.commentId === action.payload.commentId
							? {
									...comment,
									replies: comment.replies.concat(action.payload.replies),
							  }
							: comment,
					),
				},
				loadingNewCommentOrder: false,
			};
		}

		case ERROR_LOADING_COMMENT_REPLIES: {
			return {
				...state,
				loadingCommentReplies: state.loadingCommentReplies.filter(
					(commentId) => commentId !== action.payload,
				),
				errorLoadingCommentReplies: state.errorLoadingCommentReplies.concat(
					action.payload,
				),
				loadingNewCommentOrder: false,
			};
		}
		case CLEAR_ERROR_LOADING_COMMENT_REPLIES: {
			return {
				...state,
				loadingNewCommentOrder: false,
				loadingCommentReplies: [],
				errorLoadingCommentReplies: [],
			};
		}
		case SET_FEED_DATA: {
			return {
				...state,
				showFeedFilterOptions:
					action.payload.showFeedFilterOptions !== undefined
						? action.payload.showFeedFilterOptions
						: state.showFeedFilterOptions,
				lastHashtag:
					action.payload.lastHashtag !== undefined
						? action.payload.lastHashtag
						: state.lastHashtag,
				lastHashtagCount:
					action.payload.lastHashtagCount !== undefined
						? action.payload.lastHashtagCount
						: state.lastHashtagCount,
				feedCollection:
					action.payload.feedCollection !== undefined
						? action.payload.feedCollection
						: state.feedCollection,
				hashtagOption:
					action.payload.hashtagOption !== undefined
						? action.payload.hashtagOption
						: state.hashtagOption,
			};
		}

		case SET_PREVIEW_HASHTAGS: {
			return {
				...state,
				previewHashtags: action.payload,
			};
		}

		case LOADING_FEED_IDS: {
			return {
				...state,
				loadingFollowingFeedIds: true,
				errorLoadingFollowingFeedIds: null,
			};
		}
		case SET_FEED_IDS: {
			return {
				...state,
				followingFeedIds: action.payload,
				loadingFollowingFeedIds: false,
			};
		}
		case ERROR_LOADING_FEED_IDS: {
			return {
				...state,
				loadingFollowingFeedIds: false,
				errorLoadingFollowingFeedIds: action.payload,
			};
		}

		case SET_USER_ADMIN: {
			return {
				...state,
				setUserAdmin: action.payload,
			};
		}

		case SET_LOGOUT_SUCCESS_MESSAGE: {
			return {
				...state,
				logoutSuccessMessage: action.payload,
			};
		}
		case SET_LOGOUT_INFO_MESSAGE: {
			return {
				...state,
				logoutInfoMessage: action.payload,
			};
		}
		case SET_LOGOUT_WARNING_MESSAGE: {
			return {
				...state,
				logoutWarningMessage: action.payload,
			};
		}
		case SET_LOGOUT_ERROR_MESSAGE: {
			return {
				...state,
				logoutErrorMessage: action.payload,
			};
		}
		case SET_OPENED_DIALOG: {
			return {
				...state,
				openedDialog: action.payload,
			};
		}

		case SET_ACTIVE_VIDEO: {
			return { ...state, activeVideoPostId: action.payload };
		}
		case MUTE_ACTIVE_VIDEO: {
			return {
				...state,
				activeVideoPostId: '',
			};
		}
		case SET_DATE_VALUE: {
			return {
				...state,
				dateTimer: {
					...state.dateTimer,
					...action.payload,
				},
			};
		}
		case RESET_DATE_VALUE: {
			return {
				...state,
				dateTimer: {
					dateValue: null,
					meridiem: '',
					hourValue: '',
				},
			};
		}

		case UPDATING_GOODBYE_LETTER: {
			return {
				...state,
				updatingGoodbyeLetter: true,
				errorUpdatingGoodbyeLetter: null,
				successUpdatingGoodbyeLetter: '',
				errorLoadingGoodbyeLetter: null,
			};
		}
		case SUCCESS_UPDATING_GOODBYE_LETTER: {
			return {
				...state,
				updatingGoodbyeLetter: false,
				successUpdatingGoodbyeLetter: action.payload.message,
				goodbyeLetter: {
					...state.goodbyeLetter,
					...action.payload.letter,
				},
			};
		}
		case ERROR_UPDATING_GOODBYE_LETTER: {
			return {
				...state,
				updatingGoodbyeLetter: false,
				errorUpdatingGoodbyeLetter: action.payload,
			};
		}
		case CLEAR_ERROR_UPDATING_GOODBYE_LETTER: {
			return {
				...state,
				updatingGoodbyeLetter: false,
				errorUpdatingGoodbyeLetter: null,
			};
		}

		case DELETING_GOODBYE_LETTER: {
			return {
				...state,
				deletingGoodbyeLetter: true,
				errorDeletingGoodbyeLetter: null,
				successDeletingGoodbyeLetter: '',
				errorLoadingGoodbyeLetter: null,
			};
		}
		case SUCCESS_DELETING_GOODBYE_LETTER: {
			return {
				...state,
				deletingGoodbyeLetter: false,
				successDeletingGoodbyeLetter: action.payload,
				goodbyeLetter: {},
				letterRecipients: [],
				emailRecipients: [],
				dateTimer: {
					dateValue: null,
					meridiem: '',
					hourValue: '',
				},
			};
		}
		case ERROR_DELETING_GOODBYE_LETTER: {
			return {
				...state,
				deletingGoodbyeLetter: false,
				errorDeletingGoodbyeLetter: action.payload,
			};
		}

		case LOADING_RECEIVED_GOODBYE_LETTER: {
			return {
				...state,
				loadingReceivedGoodbyeLetter: true,
				receivedGoodbyeLetter: {},
				errorLoadingReceivedGoodbyeLetter: null,
			};
		}
		case SET_RECEIVED_GOODBYE_LETTER: {
			return {
				...state,
				loadingReceivedGoodbyeLetter: false,
				receivedGoodbyeLetter: action.payload,
			};
		}
		case ERROR_LOADING_RECEIVED_GOODBYE_LETTER: {
			return {
				...state,
				loadingReceivedGoodbyeLetter: false,
				errorLoadingReceivedGoodbyeLetter: action.payload,
			};
		}

		case LOADING_GOODBYE_LETTER: {
			return {
				...state,
				loadingGoodbyeLetter: true,
				errorLoadingGoodbyeLetter: null,
			};
		}
		case SET_GOODBYE_LETTER: {
			return {
				...state,
				loadingGoodbyeLetter: false,
				updatingGoodbyeLetter: false,
				goodbyeLetter: action.payload,
			};
		}
		case ERROR_LOADING_GOODBYE_LETTER: {
			return {
				...state,
				loadingGoodbyeLetter: false,

				errorLoadingGoodbyeLetter: action.payload,
			};
		}
		case CLEAR_ALL_LETTER_ERRORS: {
			return {
				...state,
				loadingGoodbyeLetter: false,
				errorLoadingGoodbyeLetter: null,
				errorDeletingGoodbyeLetter: null,
			};
		}
		case ADD_VIEWED_POST:
			return {
				...state,
				viewedPostIds: state.viewedPostIds.concat(action.payload),
			};
		case RESTORE_VIEWED_POSTS:
			return {
				...state,
				viewedPostIds: action.payload,
			};

		case IS_MESSAGING_SUPPORTED: {
			return {
				...state,
				isMessagingSupported: action.payload,
			};
		}
		case SENDING_COMMENT_ADMIN: {
			return {
				...state,
				sendingCommentAdmin: action.payload,
			};
		}
		case AUDIO_NOT_FOUND: {
			return {
				...state,
				audioUrlsNotFound: state.audioUrlsNotFound.concat(action.payload),
			};
		}
		case ERROR_LIKE_DATA: {
			return {
				...state,
				errorLikeData: state.errorLikeData.concat(action.payload),
			};
		}
		case SET_QUERY_ORDER: {
			return {
				...state,
				queryOrder: {
					...state.queryOrder,
					...action.payload,
				},
			};
		}

		default:
			return state;
	}
}
