import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import { Link, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from '../../redux/withRouter';
import dayjs from 'dayjs';
import {
	Dialog,
	DialogContent,
	CircularProgress,
	Toolbar,
	Grow,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import SecurityIcon from '@mui/icons-material/Security';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LikeButton from './LikeButton';
import PostComments from './PostComments';
import CommentForm from './CommentForm';
import DataNotFound from './DataNotFound';
import CustomAlerts from './CustomAlerts';
import DottedMenu from './DottedMenu';
import CopyText from './CopyText';
import ImgSrc from './ImgSrc';
import Appeal from './Appeal';
import TimerCountdown from '../PostTimer/TimerCountdown';
import {
	deletePost,
	getPost,
	getPostAdmin,
	setPostToEdit,
	clearAppealActions,
	numberFormatter,
	setOpenedDialog,
	clearPost,
} from '../../redux/actions/dataActions';
import TodayIcon from '@mui/icons-material/Today';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';
import AudioPlayer from '../MediaPlayers/AudioPlayer';
import VideoPlayer from '../MediaPlayers/VideoPlayer';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import TimerIcon from '@mui/icons-material/Timer';
import LinkFormatter from './LinkFormatter';
import ForumIcon from '@mui/icons-material/Forum';
import CustomTooltip from './CustomTooltip';
import Progress from './Progress';
import countdown from '../Countdownjs/countdown';
import CustomButton from './CustomButton';
import Media from 'react-media';
import CustomReactReadMoreReadLess from '../ReadMore/CustomReadMoreReadLess';
import { t } from 'i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Grow direction='up' ref={ref} {...props} />;
});
class PostDialog extends Component {
	state = {
		open: false,
		imageNotFound: false,
		loaded: false,
		oldPath: '',
		paramsCommentId: '',
		showReleaseCalc: false,
		showAll: false,
		useOriginalImgUrl: false,
		isVertical: false,
	};

	componentDidMount() {
		let activePanel = this.props.user.adminPanel.adminPanelActive;

		if (!activePanel) {
			this.props.setOpenedDialog(true);
		}

		this.setState({
			open: true,
		});
		this.openPostDialog();
	}

	componentWillUnmount() {
		this.closePostDialog();
		if (this.props.data.openedDialog) {
			this.props.setOpenedDialog(false);
		}
		if (this.props.location.pathname.split('/').includes('removedComment')) {
			this.props.clearPost();
		}
	}

	deletePost = () => {
		this.props.deletePost(
			this.props.data.post.postId,
			this.props.data.post.livePost,
			this.props.data.post.infringements,
		);
		this.goBack();
	};
	showReleaseCalculation = (boolean) => {
		this.setState({ showReleaseCalc: boolean });
	};

	image404 = () => {
		if (!this.state.useOriginalImgUrl && this.props.post.originalImgUrl) {
			this.setState({
				useOriginalImgUrl: true,
			});
		} else if (this.state.useOriginalImgUrl && this.props.post.originalImgUrl) {
			this.setState({
				useOriginalImgUrl: false,
			});
		} else if (!this.state.imageNotFound) {
			this.setState({
				imageNotFound: true,
			});
		}
	};

	calculateAspectRatio = (width, height) => {
		this.setState({
			loaded: true,
		});

		const isVertical = width / height;

		if (isVertical < 1) {
			this.setState({
				isVertical: true,
			});
		}
	};

	openPostDialog = () => {
		let props = this.props;
		let params = props.params;
		let post = props.data.post;
		let postId = params.postId;
		let paramsCommentId = params.paramsCommentId; //commentId
		let repliedId = params.repliedId; //commentId
		let likeCount = post.likeCount;
		let commentCount = post.commentCount;
		let replyCount = post.replyCount;
		let postCollection =
			props.location.pathname.split('/').includes('removedPost') ||
			props.data.adminReportCollection === 'postAppeals'
				? 'removedPosts'
				: post.livePost === false ||
				  props.location.pathname.split('/').includes('unreleasedPost')
				? 'unreleasedPosts'
				: 'posts';

		let commentCollection =
			(props.location.pathname.split('/').includes('removedComment') &&
				!repliedId) ||
			props.data.adminReportCollection === 'commentAppeals'
				? 'removedComments'
				: 'comments';

		let limit =
			props.location.pathname.split('/').includes('removedPost') ||
			props.data.adminReportCollection === 'postAppeals' ||
			props.location.pathname.split('/').includes('removedComment') ||
			props.data.adminReportCollection === 'commentAppeals'
				? 1
				: commentCount === undefined
				? 10
				: commentCount === 0
				? 0
				: commentCount > 10
				? 10
				: commentCount;

		if (postId && this.props.user.adminPanel.adminPanelActive) {
			props.getPostAdmin({
				postCollection,
				postId,
				limit,
				commentId: paramsCommentId,
				commentCollection,
				likeCount,
				commentCount,
				replyCount,
			});
		} else if (postId) {
			props.getPost({
				postCollection,
				postId,
				limit,
				commentId: paramsCommentId,
				commentCollection,
				likeCount,
				commentCount,
				replyCount,
			});
		}

		if (paramsCommentId) {
			this.setState({
				paramsCommentId: paramsCommentId,
			});
		}
	};
	closePostDialog = () => {
		this.setState({
			open: false,
		});
		if (this.props.closeNotifications !== undefined) {
			this.props.closeNotifications();
		}
		if (
			this.props.data.submittingAppealSuccess ||
			this.props.data.errorSubmittingAppeal
		) {
			this.props.clearAppealActions();
		}
	};

	goBack = () => {
		let path = this.props.location.pathname;
		if (
			path.split('/')[1] === 'feed' &&
			path.split('/')[2] === 'notifications'
		) {
			this.props.history(path.split('/').slice(0, 3).join('/'));
		} else if (this.props.location.state?.onlyComments) {
			this.props.history('/settings/comments', {
				state: {
					expanded: 'commentsPanel',
				},
			});
		} else if (this.props.location.state?.onlyLiked) {
			this.props.history('/settings/liked', {
				state: {
					repliedId: this.props.params.repliedId,
					expanded: 'commentsPanel',
				},
			});
		} else if (path.split('/')[1] === 'feed') {
			this.props.history(path.split('/').slice(0, 2).join('/'));
		} else if (
			path.split('/')[3] === 'notifications' &&
			path.split('/')[1] === 'users'
		) {
			this.props.history(path.split('/').slice(0, 4).join('/'));
		} else if (this.props.user.adminPanel.adminPanelActive) {
			this.props.history(path.split('/').slice(0, 2).join('/'));
		} else {
			this.props.history(path.split('/').slice(0, 3).join('/'));
		}
		this.setState({ open: false });
	};

	findIndex = (mention) => {
		let index = this.props.data.post.mentions.findIndex(
			(user) => `@${user.handle}` === mention,
		);
		return index;
	};

	handleChange = (e) => {
		e.preventDefault();
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	openLikes = (handle) => {
		let props = this.props;
		let path = props.location.pathname + '/likes';
		let state = {
			postId: props.data.post.postId,
			author: handle && handle.slice(0, 1).toUpperCase() + handle.slice(1),
			limit: props.data.post.likeCount > 10 ? 10 : props.data.post.likeCount,
		};
		props.history(path, { state });
	};
	focus = (id) => {
		document.getElementById(id).focus();
	};

	setPostForEdit = () => {
		this.props.setPostToEdit(this.props.data.post);
	};

	handleReadAll = () => {
		this.setState((prevState) => ({
			showAll: !prevState.showAll,
		}));
	};
	render() {
		const {
			data: {
				post: {
					userId,
					postId,
					body,
					admin,
					createdAt,
					likeCount,
					commentCount,
					userImage,
					userThumbnail,
					userImageColor,
					userHandle,
					comments,
					fileType,
					fileUrl,
					liked,
					recipients,
					emailRecipients,
					infringements,
					livePost,
					commentInfringement,
					releaseDate,
					prevReleaseDate,
					replyCount,
					mentions,
					hashtags,
					viewed,
					notFound,
					publicPost,
					originalImgUrl,
					fileType2,
					fileUrl2,
					deleted,
				},
				loadingPost,
				errorLoadingPost,
				removedPostIds,
				errorDeletingPost,
				loadingPostInfringement,
				errorPostInfringement,
				adminReportCollection,
				editingPost,
				editingPostProgress,
				reportedPosts,
				errorEditingPost,
				loadingDeletePost,
			},
			user: {
				adminPanel: { adminPanelActive },
				credentials: {
					handle,
					imageUrl,
					thumbnail,
					imageColor,
					commentsMadeToday,
				},
			},
			UI: { mode },
			openAdminDialog,
		} = this.props;
		const {
			open,
			paramsCommentId,
			imageNotFound,
			loaded,
			showReleaseCalc,
			showAll,
			useOriginalImgUrl,
			isVertical,
		} = this.state;
		const darkMode = mode === 'dark';
		const ownPost = userId === this.props.user.credentials.userId;
		const imgUrl = ownPost ? imageUrl : userImage;
		const thumbUrl = ownPost ? thumbnail : userThumbnail;
		const dataHandle = ownPost ? handle : userHandle;
		const isAdmin = ownPost ? this.props.user.credentials.admin : admin;

		const imgColor = ownPost ? imageColor : userImageColor;
		const reportedPost = reportedPosts.includes(postId);

		const commentsPlusReplies = commentCount + replyCount;
		const paramsPostId = this.props.params.postId;
		const recipientsLength = recipients ? recipients.length : 0;
		const emailRecipientsLength = emailRecipients ? emailRecipients.length : 0;
		const allRecipientsLength = recipientsLength + emailRecipientsLength;
		const canDownload =
			fileType !== 'text' &&
			((recipients &&
				recipients.includes(this.props.user.credentials.userId)) ||
				(emailRecipients &&
					emailRecipients.includes(this.props.user.credentials.email)) ||
				handle === dataHandle)
				? true
				: false;

		const dialogMarkup = errorLoadingPost ? (
			<DataNotFound error={errorLoadingPost} PostDialog={true} />
		) : open || (adminPanelActive && postId) ? (
			<Media
				queries={{
					isMobile: '(max-width: 1064px)',
				}}
			>
				{(matches) => (
					<div>
						{notFound ? (
							<CustomAlerts
								noMargin
								info={t('content_deleted', {
									content: t('post'),
								})}
								icon={<BlurOnRoundedIcon fontSize='small' />}
								alertId='smallMuiAlert'
							/>
						) : (
							<div>
								{livePost &&
									!publicPost &&
									ownPost &&
									allRecipientsLength > 0 &&
									!loadingDeletePost.includes(postId) && (
										<CustomAlerts
											info={true}
											message={
												<div>
													<b>
														{allRecipientsLength === 1
															? t('1_can_view')
															: t('more_can_view', {
																	number: allRecipientsLength,
															  })}
													</b>{' '}
													<span
														onClick={this.setPostForEdit}
														className='underlineHover pointer'
													>
														{t('edit')}
													</span>
												</div>
											}
											noMargin
											icon={<VisibilityIcon fontSize='small' />}
											alertId='smallMuiAlert'
										/>
									)}
								{!publicPost &&
									userId !== this.props.user.credentials.userId &&
									((recipients &&
										recipients.includes(this.props.user.credentials.userId)) ||
										(emailRecipients &&
											emailRecipients.includes(
												this.props.user.credentials.email,
											))) && (
										<CustomAlerts
											info={true}
											message={
												allRecipientsLength === 1
													? t('only_you_can_view')
													: allRecipientsLength === 2
													? t('you_and_1_can_view')
													: t('you_and_more_can_view', {
															number: allRecipientsLength,
													  })
											}
											noMargin
											icon={<VisibilityIcon fontSize='small' />}
											alertId='smallMuiAlert'
										/>
									)}
								{livePost && createdAt && showReleaseCalc && (
									<div>
										<CustomAlerts
											success={true}
											message={t('released_after_creation', {
												date:
													new Date(releaseDate).getTime() -
														new Date(createdAt).getTime() <
													3600000
														? countdown(
																new Date(createdAt),
																new Date(releaseDate),
																countdown.MINUTES,
														  ).toString()
														: countdown(
																new Date(createdAt),
																new Date(releaseDate),
																countdown.DECADES |
																	countdown.YEARS |
																	countdown.MONTHS |
																	countdown.WEEKS |
																	countdown.DAYS |
																	countdown.HOURS,
														  ).toString(),
											})}
											icon={<BrowseGalleryIcon fontSize='small' />}
											noMargin
											alertId='smallMuiAlert'
										/>
										<CustomAlerts
											info={prevReleaseDate}
											message={t('release_date_inactivity', {
												date: dayjs(prevReleaseDate).format('LLLL'),
											})}
											icon={<TimerIcon fontSize='small' />}
											noMargin
											severity='warning'
											alertId='smallMuiAlert'
										/>
										<CustomAlerts
											info={true}
											message={t('created_on', {
												date: dayjs(createdAt).format('LLLL'),
											})}
											icon={<TodayIcon fontSize='small' />}
											noMargin
											alertId='smallMuiAlert'
										/>
									</div>
								)}
								<div style={{ width: '100%' }}>
									{(adminPanelActive || infringements) && (
										<div>
											<CustomAlerts
												info={
													loadingPostInfringement && infringements
														? `${t('removing')} ${t('infringement')}.`
														: loadingPostInfringement
														? `${t('adding')} ${t('infringement')}.`
														: ''
												}
												noMargin
												alertId='smallMuiAlert'
											/>
											<CustomAlerts
												error={errorPostInfringement}
												noMargin
												alertId='smallMuiAlert'
											/>
											{!adminPanelActive && infringements === true && (
												<Appeal
													socialComponent='post'
													deleteFunction={this.deletePost}
												/>
											)}
										</div>
									)}
									{!livePost && releaseDate >= new Date().toISOString() && (
										<CustomAlerts
											icon={<TodayIcon fontSize='small' />}
											info={true}
											component={
												<div>
													{infringements ? (
														<div>
															{t('wont_released_on')}{' '}
															<b>{dayjs(releaseDate).format('LLLL')}</b>
														</div>
													) : (
														<div>
															{t('scheduled_for')}{' '}
															<b>{dayjs(releaseDate).format('LLLL')}</b>
														</div>
													)}
												</div>
											}
											message={
												infringements ? (
													''
												) : (
													<TimerCountdown
														releaseDate={releaseDate}
														post={true}
														selectorId={`${postId}_dialog`}
													/>
												)
											}
											alertId='smallMuiAlert'
											noMargin
											noCenter
										/>
									)}
									{editingPost.includes(postId) && (
										<Progress
											progress={editingPostProgress}
											title={t('updating')}
											icon={<BlurOnRoundedIcon />}
										/>
									)}
									<CustomAlerts
										error={errorEditingPost.includes(postId)}
										message={t('error_update', {
											content: t('post'),
										})}
										noMargin
										alertId='smallMuiAlert'
									/>
								</div>

								<div
									className={`pdPostHeader 
									${darkMode ? 'postHeaderDark' : ''}
									`}
								>
									<div className='postHeader '>
										<div className='flex alignSelfStart'>
											<Link
												to={adminPanelActive ? '#' : `/users/${dataHandle}`}
												state={{ data: userId }}
												className='flex'
											>
												<ImgSrc
													img={imgUrl}
													imgColor={imgColor}
													thumbnail={thumbUrl}
													handle={dataHandle}
													css='mediaImgSkeleton'
												/>
											</Link>
											<div className='flexColumn'>
												<Link
													to={adminPanelActive ? '#' : `/users/${dataHandle}`}
													state={{ data: userId }}
												>
													<p
														className={`notranslate bold textTitle mg0 ${
															darkMode ? 'white' : 'dark'
														}`}
													>
														{dataHandle}
														{isAdmin && (
															<VerifiedUserIcon
																id='verifiedBadgePost'
																fontSize='small'
															/>
														)}
													</p>
												</Link>
												<div className='flexColumn'>
													{!livePost && createdAt && (
														<small>{dayjs(createdAt).format('LLLL')}</small>
													)}
													{livePost && releaseDate && (
														<small>{dayjs(releaseDate).format('LLLL')}</small>
													)}
												</div>
											</div>
										</div>

										<div className='flexColumn'>
											<DottedMenu
												dataHandle={dataHandle}
												deleteFunction={this.deletePost}
												postComponent={true}
												postToEdit={this.props.data.post}
												comment={false}
												deletedPost={
													removedPostIds.includes(postId) || deleted
														? true
														: false
												}
												postId={postId}
												livePost={livePost}
												canDownload={canDownload && !matches.isMobile}
												dataUserId={userId}
												infringements={infringements}
												removedPost={infringements}
											/>
											{livePost && createdAt && (
												<div className='flex alignSelfCenter bottomTranslate4'>
													<CustomButton
														iconButton
														startIcon={
															<TodayIcon
																id={showReleaseCalc ? 'iconBlue' : ''}
																fontSize='small'
															/>
														}
														color='inherit'
														padding={matches.isMobile ? '0' : '6px'}
														onClick={() =>
															this.showReleaseCalculation(!showReleaseCalc)
														}
														tooltip={
															!showReleaseCalc ? t('scheduled_posts') : ''
														}
														placement='right'
													/>
												</div>
											)}
										</div>
									</div>

									{body ? (
										<div className='pdYH'>
											{(mentions && mentions.length > 0) ||
											(hashtags && hashtags.length > 0) ? (
												<div>
													<p
														className={`mg0 prewrap ${
															body.length > 400 ? 'postBodyContent' : ''
														}  ${showAll ? 'showAllContent' : ''}`}
													>
														<LinkFormatter
															str={body}
															adminPanelActive={adminPanelActive}
															mentions={mentions}
															hashtags={hashtags}
														/>
													</p>
													{body.length > 400 && (
														<div className='flex'>
															<CustomButton
																onClick={this.handleReadAll}
																variant='text'
																btnText={
																	showAll ? t('show_less') : t('read_more')
																}
																margin='0 auto -12px auto'
																fontSize='16px'
																height='36px'
																textTransform='none'
																disableRipple
																id='underlineHover'
															/>
														</div>
													)}
												</div>
											) : (
												<p className='mg0 prewrap'>
													<CustomReactReadMoreReadLess
														charLimit={400}
														readMoreText={t('read_more')}
														readLessText={t('show_less')}
														onClickReadLess={this.handleReadAll}
													>
														{body.replace(/\n{3,}/g, '\n\n')}
													</CustomReactReadMoreReadLess>
												</p>
											)}
										</div>
									) : (
										<div className='pdt'></div>
									)}
								</div>

								{fileType === 'image' ? (
									imageNotFound ? (
										<CustomAlerts
											info={t('file_404')}
											noMargin
											alertId='smallMuiAlert'
										/>
									) : (
										<div
											style={{
												lineHeight: 0,
											}}
										>
											<img
												src={
													useOriginalImgUrl && originalImgUrl
														? originalImgUrl
														: fileUrl
												}
												style={{
													// background: `url(${this.props.data.post.thumbnail}) no-repeat`,
													backgroundSize: 'cover',
													filter: !loaded && 'blur(3px)',
													maxHeight: !loaded
														? '550px'
														: isVertical && !matches.isMobile
														? '70vh'
														: '80vh',
													width: !loaded && '100%',
												}}
												onLoad={(e) =>
													this.calculateAspectRatio(
														e.target.naturalWidth,
														e.target.naturalHeight,
													)
												}
												alt={body}
												id='imageContain'
												loading='lazy'
												onError={(event) => {
													event.onerror = null;
													this.image404();
												}}
											/>
										</div>
									)
								) : fileType === 'audio' ? (
									<AudioPlayer
										fileUrl={fileUrl}
										postId={`${postId}_dialog`}
										isMobile={matches.isMobile}
										makePostFunctionality={!livePost}
										countViews={livePost}
									/>
								) : fileType === 'video' ? (
									<VideoPlayer fileUrl={fileUrl} postId={postId} dialogPost />
								) : null}

								{fileType2 === 'audio' && (
									<AudioPlayer
										fileUrl={fileUrl2}
										postId={`dialog_${postId}`}
										isMobile={matches.isMobile}
										makePostFunctionality={!livePost}
										countViews={livePost}
									/>
								)}

								{removedPostIds.includes(postId) ||
								errorDeletingPost.includes(postId) ? (
									<CustomAlerts
										error={
											removedPostIds.includes(postId)
												? t('content_deleted', {
														content: t('post'),
												  })
												: errorDeletingPost.includes(postId)
												? t('error_deleting_content', {
														content: t('post'),
												  })
												: t('error')
										}
										alertId='smallMuiAlert'
										noMargin
									/>
								) : (
									<div className='postActions'>
										{!adminPanelActive && (
											<div className='bottomTranslate2'>
												<LikeButton
													postId={postId}
													liked={liked}
													unreleasedPost={livePost === false}
													likeCount={likeCount}
													disabled={infringements}
												/>
												<button
													onClick={() =>
														!infringements &&
														this.focus(
															this.props.UI.mode === 'dark'
																? 'darkCommentInput'
																: 'commentInput',
														)
													}
													className={`commentBtn mgl4px ${
														infringements ? '' : 'pointer'
													}`}
													disabled={infringements}
												>
													<ForumIcon
														id={infringements ? 'cIconDisabled' : 'cIcon'}
													/>
												</button>
											</div>
										)}

										<div className='flex white'>
											{likeCount > 0 && (
												<p
													className={`pdrH notranslate bold ${
														infringements ? '' : 'pointer'
													}`}
													onClick={() =>
														!infringements && this.openLikes(dataHandle)
													}
												>
													{likeCount > 30000
														? numberFormatter(likeCount, 1)
														: likeCount}{' '}
													{likeCount === 1 ? t('like') : t('likes')}
												</p>
											)}

											{commentCount > 0 && (
												<p className='pdrH bold'>
													{commentsPlusReplies > 30000
														? numberFormatter(commentsPlusReplies, 1)
														: commentsPlusReplies}{' '}
													{commentsPlusReplies === 1
														? t('comment')
														: t('comments')}
												</p>
											)}
											{viewed > 0 &&
												(fileType === 'video' ||
													fileType === 'audio' ||
													fileType2 === 'audio') && (
													<CustomTooltip
														title={viewed === 1 ? t('view') : t('views')}
														placement={matches.isMobile ? 'top' : 'right'}
														arrow
													>
														<p className='mg0 flex alignItemsCenter pointer bold'>
															<PlayArrowOutlinedIcon />
															{viewed > 1000
																? numberFormatter(viewed, 1)
																: viewed}{' '}
														</p>
													</CustomTooltip>
												)}
										</div>
									</div>
								)}

								{!adminPanelActive &&
									!infringements &&
									!commentInfringement &&
									!removedPostIds.includes(postId) &&
									postId !== undefined && (
										<div className='mgb'>
											<CommentForm postId={postId} livePost={livePost} />
										</div>
									)}

								{commentsMadeToday >= 200 && (
									<div>
										<CustomAlerts
											severity='warning'
											info={t('you_have_exceeded_daily_limit_action')}
											noMargin
											alertId='smallMuiAlert'
										/>
										<CustomAlerts
											info={t('security_anti_spam_measures')}
											icon={<SecurityIcon fontSize='small' />}
											noMargin
											alertId='smallMuiAlert'
										/>
									</div>
								)}
							</div>
						)}
						{(!infringements || adminPanelActive) && (
							<PostComments
								notFound={notFound}
								queryFromCommentId={
									paramsCommentId &&
									comments.length > 0 &&
									paramsCommentId === comments[0].commentId
								}
								commentCount={commentCount}
								comments={comments}
								// lastCommentKey={lastCommentKey}
								postId={postId}
								livePost={livePost}
								// closeDialogs={this.closeDialogs}
								paramsCommentId={paramsCommentId}
								subCollection={this.props.subCollection}
							/>
						)}
					</div>
				)}
			</Media>
		) : null;
		return (
			<div>
				{this.props.children}
				{reportedPost ? (
					<div id='postCard' className='center'>
						<CustomAlerts
							success={t('report_success', {
								content: t('post'),
							})}
						/>
						<CustomButton
							onClick={() => this.showReportedPost(postId)}
							btnText={t('show_content', {
								content: t('post'),
							})}
						/>
					</div>
				) : adminPanelActive &&
				  adminReportCollection !== 'userReports' &&
				  adminReportCollection !== 'userAppeals' &&
				  openAdminDialog === false ? (
					<div>{dialogMarkup}</div>
				) : (
					<Media
						queries={{
							isMobile: '(max-width: 1064px)',
						}}
					>
						{(matches) => (
							<Dialog
								TransitionComponent={Transition}
								open={open}
								fullWidth
								maxWidth={!matches.isMobile ? 'lg' : false}
								fullScreen={matches.isMobile}
								onClose={this.goBack}
								disableEnforceFocus
								style={{ zIndex: '5' }}
							>
								<Toolbar id='postDialogBar'>
									<div className='titleToolbar flex alignBaseline capitalize'>
										{t('post')}{' '}
										{adminPanelActive && (
											<CopyText frase='ID:' text={postId} small />
										)}
									</div>
									<CloseIcon
										id='closeDialogButton'
										onClick={this.goBack}
										aria-label='close'
									/>
								</Toolbar>

								{(adminPanelActive && loadingPost) ||
								(loadingPost && postId !== paramsPostId) ||
								(loadingPost &&
									postId === undefined &&
									!adminPanelActive &&
									!notFound) ||
								(loadingPost && notFound) ? (
									<div className='PD_spinnerDiv'>
										<div className='PD_loadingDiv'>
											<h4 className='animation'>{t('loading')}</h4>
											<CircularProgress size={70} thickness={1} />
										</div>
									</div>
								) : (
									<DialogContent
										id='scrollableDivComments'
										className='pd0Dialog'
									>
										{dialogMarkup}
									</DialogContent>
								)}
							</Dialog>
						)}
					</Media>
				)}
				<Outlet />
			</div>
		);
	}
}

PostDialog.propTypes = {
	deletePost: PropTypes.func,
	getPost: PropTypes.func.isRequired,
	getPostAdmin: PropTypes.func.isRequired,
	setPostToEdit: PropTypes.func.isRequired,
	clearAppealActions: PropTypes.func.isRequired,
	closeNotifications: PropTypes.func,
	paramsCommentId: PropTypes.string,
	data: PropTypes.object,
	setOpenedDialog: PropTypes.func.isRequired,
	clearPost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	data: state.data,
	user: state.user,
	UI: state.UI,
});

const mapActionsToProps = {
	deletePost,
	getPost,
	getPostAdmin,
	setPostToEdit,
	clearAppealActions,
	setOpenedDialog,
	clearPost,
};

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(memo(PostDialog)));
