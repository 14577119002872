export const TOGGLE_MODE = 'TOGGLE_MODE';
export const SET_USER_ID_LISTENER = 'SET_USER_ID_LISTENER';
export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS';
export const LOADING_LOGOUT = 'LOADING_LOGOUT';
export const ERROR_LOADING_LOGOUT = 'ERROR_LOADING_LOGOUT';
export const SET_PROFILE_IMAGE = 'SET_PROFILE_IMAGE';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const ERRORS_LOGIN = 'ERRORS_LOGIN';
export const CLEAR_AUTH_MESSAGES = 'CLEAR_AUTH_MESSAGES';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SENDING_FORGOT_PASSWORD_EMAIL = 'SENDING_FORGOT_PASSWORD_EMAIL';
export const FORGOT_PASSWORD_EMAIL_SUCCESS = 'FORGOT_PASSWORD_EMAIL_SUCCESS';
export const ERROR_SENDING_FORGOT_PASSWORD_EMAIL =
	'ERROR_SENDING_FORGOT_PASSWORD_EMAIL';
export const SET_USER = 'SET_USER';
export const SET_VERIFIED_USER = 'SET_VERIFIED_USER';
export const LOADING_VERIFICATION_EMAIL = 'LOADING_VERIFICATION_EMAIL';
export const VERIFICATION_EMAIL_SUCCESS_MESSAGE =
	'VERIFICATION_EMAIL_SUCCESS_MESSAGE';
export const CLEAR_VERIFICATION_EMAIL_SUCCESS_MESSAGE =
	'CLEAR_VERIFICATION_EMAIL_SUCCESS_MESSAGE';
export const SENT_TOO_MANY_EMAILS = 'SENT_TOO_MANY_EMAILS';
export const LOADING_USER = 'LOADING_USER';
export const LOADING_IMAGE = 'LOADING_IMAGE';
export const STOP_LOADING_IMAGE = 'STOP_LOADING_IMAGE';
export const UPDATING_IMG = 'UPDATING_IMG';
export const STOP_UPDATING_IMG = 'STOP_UPDATING_IMG';
export const CLEAR_ACCOUNT_REPORTS = 'CLEAR_ACCOUNT_REPORTS';
export const MARK_ONE_NOTIFICATION_READ = 'MARK_ONE_NOTIFICATION_READ';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const ALLOW_NOTIFICATIONS = 'ALLOW_NOTIFICATIONS';
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const DELETE_INFRINGEMENT_NOTIFICATION =
	'DELETE_INFRINGEMENT_NOTIFICATION';
export const NOTIFICATION_REDIRECT_URL = 'NOTIFICATION_REDIRECT_URL';
export const SET_RESETED_IMAGE = 'SET_RESETED_IMAGE';
export const CORRECT_LIKE_ACTION = 'CORRECT_LIKE_ACTION';
export const CORRECT_LIKE_COMMENT_ACTION = 'CORRECT_LIKE_COMMENT_ACTION';
export const CORRECT_LIKE_COMMENT_REPLY_ACTION =
	'CORRECT_LIKE_COMMENT_REPLY_ACTION';
export const UPLOADING_USER_DETAILS = 'UPLOADING_USER_DETAILS';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const ERROR_SET_USER_DETAILS = 'ERROR_SET_USER_DETAILS';
export const ERROR_SET_RESETED_IMAGE = 'ERROR_SET_RESETED_IMAGE';
export const ERROR_IMG_UPLOAD = 'ERROR_IMG_UPLOAD';
export const CLEAR_ERROR_IMG_UPLOAD = 'CLEAR_ERROR_IMG_UPLOAD';
export const IMG_UPLOAD_PROGRESS = 'IMG_UPLOAD_PROGRESS';
export const SET_NOTIFICATION_ERRORS = 'SET_NOTIFICATION_ERRORS';
export const LOADING_LOGIN = 'LOADING_LOGIN';
export const STOP_LOADING_LOGIN = 'STOP_LOADING_LOGIN';
export const LOADING_SIGNUP = 'LOADING_SIGNUP';
export const SUCCESS_SIGNUP = 'SUCCESS_SIGNUP';
export const ERRORS_SIGNUP = 'ERRORS_SIGNUP';
export const LOADING_FOLLOWER_ACTION_ID = 'LOADING_FOLLOWER_ACTION_ID';
export const ERROR_FOLLOWER_ACTION = 'ERROR_FOLLOWER_ACTION';
export const LOADING_NOTIFICATIONS = 'LOADING_NOTIFICATIONS';
export const UPDATE_UNREAD_NOTIFICATIONS_COUNT =
	'UPDATE_UNREAD_NOTIFICATIONS_COUNT';
export const LOADING_FILTERED_NOTIFICATIONS = 'LOADING_FILTERED_NOTIFICATIONS';
export const LOADING_MORE_FILTERED_NOTIFICATIONS =
	'LOADING_MORE_FILTERED_NOTIFICATIONS';
export const LOADING_SEARCHBAR = 'LOADING_SEARCHBAR';
export const LOADING_DATA = 'LOADING_DATA';
export const LOADING_POSTS = 'LOADING_POSTS';
export const ERROR_LOADING_POSTS = 'ERROR_LOADING_POSTS';
export const LOADING_FILTERED_POSTS = 'LOADING_FILTERED_POSTS';
export const ERROR_LOADING_FILTERED_POSTS = 'ERROR_LOADING_FILTERED_POSTS';
export const SET_TAG_FOLLOWERS = 'SET_TAG_FOLLOWERS';
export const SET_TAG_SEARCHBAR_USERS = 'SET_TAG_SEARCHBAR_USERS';
export const LOADING_FOLLOWERS = 'LOADING_FOLLOWERS';
export const SET_FOLLOWERS = 'SET_FOLLOWERS';
export const SET_MORE_FOLLOWERS = 'SET_MORE_FOLLOWERS';
export const CLEAR_ERROR_SET_FOLLOWERS = 'CLEAR_ERROR_SET_FOLLOWERS';
export const ERROR_SET_FOLLOWERS = 'ERROR_SET_FOLLOWERS';
export const CLEAR_FOLLOWERS = 'CLEAR_FOLLOWERS';
export const SET_FOLLOWING = 'SET_FOLLOWING';
export const LOADING_FOLLOWING = 'LOADING_FOLLOWING';
export const SET_MORE_FOLLOWING = 'SET_MORE_FOLLOWING';
export const CLEAR_ERROR_SET_FOLLOWING = 'CLEAR_ERROR_SET_FOLLOWING';
export const ERROR_SET_FOLLOWING = 'ERROR_SET_FOLLOWING';
export const CLEAR_FOLLOWING = 'CLEAR_FOLLOWING';
export const LOADING_PROFILE = 'LOADING_PROFILE';
export const LOADING_PROFILE_POSTS = 'LOADING_PROFILE_POSTS';
export const LOADING_UNRELEASED_PROFILE_POSTS =
	'LOADING_UNRELEASED_PROFILE_POSTS';
export const LOADING_POST = 'LOADING_POST';
export const ERROR_LOADING_POST = 'ERROR_LOADING_POST';
export const LOADING_LIKES = 'LOADING_LIKES';
export const ERROR_LOADING_LIKES = 'ERROR_LOADING_LIKES';
export const LOADING_MORE_LIKES = 'LOADING_MORE_LIKES';
export const ERROR_LOADING_MORE_LIKES = 'ERROR_LOADING_MORE_LIKES';
export const LOADING_MORE_COMMENTS = 'LOADING_MORE_COMMENTS';
export const SET_MORE_COMMENTS = 'SET_MORE_COMMENTS';
export const ERROR_SET_MORE_COMMENTS = 'ERROR_SET_MORE_COMMENTS';
export const EDITING_POST_PROGRESS = 'EDITING_POST_PROGRESS';
export const SET_POST_TO_EDIT = 'SET_POST_TO_EDIT';
export const ERROR_EDITING_POST = 'ERROR_EDITING_POST';
export const ERROR_UPLOADING_POST = 'ERROR_UPLOADING_POST';
export const CLEAR_ERROR_UPLOADING_POST = 'CLEAR_ERROR_UPLOADING_POST';
export const SET_POSTS = 'SET_POSTS';
export const SET_FILTERED_POSTS = 'SET_FILTERED_POSTS';
export const SET_MORE_FILTERED_POSTS = 'SET_MORE_FILTERED_POSTS';
export const CLEAR_FILTERED_POSTS = 'CLEAR_FILTERED_POSTS';
export const SET_VISITED_USER_AND_DATA = 'SET_VISITED_USER_AND_DATA';
export const SET_USER_AND_DATA = 'SET_USER_AND_DATA';
export const SET_MORE_USER_AND_DATA = 'SET_MORE_USER_AND_DATA';
export const ERROR_SET_USER_AND_DATA = 'ERROR_SET_USER_AND_DATA';
export const ERROR_SET_USER_POSTS = 'ERROR_SET_USER_POSTS';
export const DELETING_POST_PROGRESS = 'DELETING_POST_PROGRESS';
export const ERROR_SET_UNRELEASED_POSTS = 'ERROR_SET_UNRELEASED_POSTS';
export const UPLOADED_UNRELEASED_PROFILE_POST =
	'UPLOADED_UNRELEASED_PROFILE_POST';
export const RESET_VISITED_PROFILE_POSTS = 'RESET_VISITED_PROFILE_POSTS';
export const SET_VISITED_USER_POSTS = 'SET_VISITED_USER_POSTS';
export const RESET_PROFILE_POSTS = 'RESET_PROFILE_POSTS';
export const SET_USER_POSTS = 'SET_USER_POSTS';
export const RESET_UNRELEASED_POSTS = 'RESET_UNRELEASED_POSTS';
export const SET_UNRELEASED_POSTS = 'SET_UNRELEASED_POSTS';
export const SET_MORE_USER_POSTS = 'SET_MORE_USER_POSTS';
export const SET_MORE_UNRELEASED_POSTS = 'SET_MORE_UNRELEASED_POSTS';
export const SET_MORE_VISITED_USER_POSTS = 'SET_MORE_VISITED_USER_POSTS';
export const SET_POST = 'SET_POST';
export const CLEAR_POST = 'CLEAR_POST';
export const SET_POST_DIALOG = 'SET_POST_DIALOG';
export const DECREMENT_POST_COUNT = 'DECREMENT_POST_COUNT';
export const DECREMENT_UNRELEASED_POST_COUNT =
	'DECREMENT_UNRELEASED_POST_COUNT';
export const LIKE_POST = 'LIKE_POST';
export const UNLIKE_POST = 'UNLIKE_POST';
export const LIKE_COMMENT = 'LIKE_COMMENT';
export const UNLIKE_COMMENT = 'UNLIKE_COMMENT';
export const LIKE_COMMENT_REPLY = 'LIKE_COMMENT_REPLY';
export const UNLIKE_COMMENT_REPLY = 'UNLIKE_COMMENT_REPLY';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const DELETE_INFRINGEMENT_COMMENT = 'DELETE_INFRINGEMENT_COMMENT';
export const DELETE_INFRINGEMENT_REPLY = 'DELETE_INFRINGEMENT_REPLY';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_COMMENT_REPLY = 'DELETE_COMMENT_REPLY';
export const REMOVE_DELETED_COMMENT_ACTIONS = 'REMOVE_DELETED_COMMENT_ACTIONS';
export const ERROR_DELETE_COMMENT = 'ERROR_DELETE_COMMENT';
export const LOADING_DELETE_COMMENT = 'LOADING_DELETE_COMMENT';
export const LOADING_SUBMIT_COMMENT = 'LOADING_SUBMIT_COMMENT';
export const LOADING_SUBMIT_COMMENT_REPLY = 'LOADING_SUBMIT_COMMENT_REPLY';
export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';
export const ERROR_SUBMIT_COMMENT = 'ERROR_SUBMIT_COMMENT';
export const DELETE_COMMENT_NOTIFICATIONS = 'DELETE_COMMENT_NOTIFICATIONS';
export const RELEASE_POSTS = 'RELEASE_POSTS';
export const UPLOADED_POST = 'UPLOADED_POST';
export const ADD_POST_TO_FEED = 'ADD_POST_TO_FEED';
export const REMOVE_POST_FROM_FEED = 'REMOVE_POST_FROM_FEED';
export const UPLOADING_POST = 'UPLOADING_POST';
export const SET_EDITED_LIVE_POST = 'SET_EDITED_LIVE_POST';
export const UNRELEASED_POST_IS_LIVE = 'UNRELEASED_POST_IS_LIVE';
export const RELEASED_POST_IS_UNLIVE = 'RELEASED_POST_IS_UNLIVE';
export const SET_EDITED_UNRELEASED_POST = 'SET_EDITED_UNRELEASED_POST';
export const LOADING_DELETE_POST = 'LOADING_DELETE_POST';
export const LOADING_UPDATE_POST = 'LOADING_UPDATE_POST';
export const DELETE_POST = 'DELETE_POST';
export const ERROR_DELETE_POST = 'ERROR_DELETE_POST';
export const DELETE_POST_NOTIFICATIONS = 'DELETE_POST_NOTIFICATIONS';
export const REMOVE_DELETED_POST_ACTIONS = 'REMOVE_DELETED_POST_ACTIONS';
export const SET_POST_RECIPIENTS = 'SET_POST_RECIPIENTS';
export const DELETE_RECIPIENT = 'DELETE_RECIPIENT';
export const DELETE_ALL_RECIPIENTS = 'DELETE_ALL_RECIPIENTS';
export const EDITED_IMG_FILE = 'EDITED_IMG_FILE';
export const CLEAR_EDITED_IMG_FILE = 'CLEAR_EDITED_IMG_FILE';
export const EDITED_AUDIO_FILE = 'EDITED_AUDIO_FILE';
export const RESET_EDITED_AUDIO_FILE = 'RESET_EDITED_AUDIO_FILE';
export const CLEAR_AUDIO_FILE = 'CLEAR_AUDIO_FILE';
export const SET_LIKES = 'SET_LIKES';
export const SET_MORE_LIKES = 'SET_MORE_LIKES';
export const SET_QUERIED_USERS = 'SET_QUERIED_USERS';
export const SET_MORE_QUERIED_USERS = 'SET_MORE_QUERIED_USERS';
export const ERROR_SET_QUERIED_USERS = 'ERROR_SET_QUERIED_USERS';
export const ERROR_SET_MORE_QUERIED_USERS = 'ERROR_SET_MORE_QUERIED_USERS';
export const CLEAR_ERROR_SET_QUERIED_USERS = 'CLEAR_ERROR_SET_QUERIED_USERS';
export const CLEAR_QUERIED_USERS = 'CLEAR_QUERIED_USERS';
export const SET_MORE_NOTIFICATIONS = 'SET_MORE_NOTIFICATIONS';
export const SET_FILTERED_NOTIFICATIONS = 'SET_FILTERED_NOTIFICATIONS';
export const SET_MORE_FILTERED_NOTIFICATIONS =
	'SET_MORE_FILTERED_NOTIFICATIONS';
export const VERIFYING_EMAIL_OOBCODE = 'VERIFYING_EMAIL_OOBCODE';
export const SET_VERIFIED_EMAIL_OOBCODE = 'SET_VERIFIED_EMAIL_OOBCODE';
export const ERROR_VERIFYING_EMAIL_OOBCODE = 'ERROR_VERIFYING_EMAIL_OOBCODE';
export const VERIFYING_RESET_PASSWORD_OOBCODE =
	'VERIFYING_RESET_PASSWORD_OOBCODE';
export const SET_VERIFIED_RESET_PASSWORD_OOBCODE =
	'SET_VERIFIED_RESET_PASSWORD_OOBCODE';
export const ERROR_VERIFYING_RESET_PASSWORD_OOBCODE =
	'ERROR_VERIFYING_RESET_PASSWORD_OOBCODE';
export const VERIFYING_RESET_EMAIL_OOBCODE = 'VERIFYING_RESET_EMAIL_OOBCODE';
export const SET_VERIFIED_RESET_EMAIL_OOBCODE =
	'SET_VERIFIED_RESET_EMAIL_OOBCODE';
export const ERROR_RESET_EMAIL_OOBCODE = 'ERROR_RESET_EMAIL_OOBCODE';
export const ERROR_SENDING_PASSWORD_RESET_EMAIL =
	'ERROR_SENDING_PASSWORD_RESET_EMAIL';
export const SUCCESS_SENDING_PASSWORD_RESET_EMAIL =
	'SUCCESS_SENDING_PASSWORD_RESET_EMAIL';
export const SENDING_PASSWORD_RESET_EMAIL = 'SENDING_PASSWORD_RESET_EMAIL';
export const SENDING_LOG_IN_EMAIL_SENT = 'SENDING_LOG_IN_EMAIL_SENT';
export const SIGN_IN_EMAIL_SENT = 'SIGN_IN_EMAIL_SENT';
export const ERROR_SENDING_LOG_IN_EMAIL_SENT =
	'ERROR_SENDING_LOG_IN_EMAIL_SENT';
export const NEW_EMAIL_UPDATE_LINK_SENT = 'NEW_EMAIL_UPDATE_LINK_SENT';
export const EMAIL_SUCCESFULLY_UPDATED = 'EMAIL_SUCCESFULLY_UPDATED';
export const UPDATE_EMAIL_ACCOUNT_ACTION = 'UPDATE_EMAIL_ACCOUNT_ACTION';
export const UPDATE_PASSWORD_ACCOUNT_ACTION = 'UPDATE_PASSWORD_ACCOUNT_ACTION';
export const UPDATE_USERNAME_ACCOUNT_ACTION = 'UPDATE_USERNAME_ACCOUNT_ACTION';
export const UPDATING_USERNAME_ACCOUNT_ACTION =
	'UPDATING_USERNAME_ACCOUNT_ACTION';
export const USERNAME_UPDATED_SUCCESFULLY = 'USERNAME_UPDATED_SUCCESFULLY';
export const ERROR_UPDATING_USERNAME_ACCOUNT_ACTION =
	'ERROR_UPDATING_USERNAME_ACCOUNT_ACTION';
export const CHECKING_USERNAME_AVAILABILTY = 'CHECKING_USERNAME_AVAILABILTY';
export const USERNAME_IS_AVAILABLE = 'USERNAME_IS_AVAILABLE';
export const ERROR_CHECKING_USERNAME_AVAILABILTY =
	'ERROR_CHECKING_USERNAME_AVAILABILTY';
export const CLEAR_CONFIG_SUCCESS = 'CLEAR_CONFIG_SUCCESS';
export const ALLOW_USERNAME_CHANGE = 'ALLOW_USERNAME_CHANGE';
export const CLEAR_ACCOUNT_ACTION_TYPES = 'CLEAR_ACCOUNT_ACTION_TYPES';
export const SET_SIGN_IN_EMAIL = 'SET_SIGN_IN_EMAIL';
export const DELETE_ACCOUNT_ACTION = 'DELETE_ACCOUNT_ACTION';
export const DELETING_USER_DATA = 'DELETING_USER_DATA';
export const DELETED_USER_DATA_SUCCESFULLY = 'DELETED_USER_DATA_SUCCESFULLY';
export const ERROR_DELETING_USER_DATA = 'ERROR_DELETING_USER_DATA';
export const UPDATE_POST_LIKES = 'UPDATE_POST_LIKES';
export const UPDATE_POST_COMMENTS = 'UPDATE_POST_COMMENTS';
export const UPDATE_POST_REPLIES = 'UPDATE_POST_REPLIES';
// export const UPDATE_POST = 'UPDATE_POST';
export const UPDATE_VISITED_PROFILE = 'UPDATE_VISITED_PROFILE';
export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';
export const SET_UPLOADING_VIDEO_PAUSED = 'SET_UPLOADING_VIDEO_PAUSED';
export const RESUME_UPLOADING_VIDEO = 'RESUME_UPLOADING_VIDEO';
export const CANCEL_UPLOADING_VIDEO = 'CANCEL_UPLOADING_VIDEO';
export const UPLOADING_VIDEO_PROGRESS = 'UPLOADING_VIDEO_PROGRESS';
export const UPLOADING_VIDEO_RUNNING = 'UPLOADING_VIDEO_RUNNING';
export const ERROR_UPLOADING_VIDEO = 'ERROR_UPLOADING_VIDEO';
export const UPLOADING_VIDEO_SUCCESS = 'UPLOADING_VIDEO_SUCCESS';
export const UPLOADING_IMAGE_PROGRESS = 'UPLOADING_IMAGE_PROGRESS';
export const UPLOADING_IMAGE_RUNNING = 'UPLOADING_IMAGE_RUNNING';
export const ERROR_UPLOADING_IMAGE = 'ERROR_UPLOADING_IMAGE';
export const UPLOADING_IMAGE_SUCCESS = 'UPLOADING_IMAGE_SUCCESS';
export const UPLOADING_AUDIO_PROGRESS = 'UPLOADING_AUDIO_PROGRESS';
export const UPLOADING_AUDIO_RUNNING = 'UPLOADING_AUDIO_RUNNING';
export const ERROR_UPLOADING_AUDIO = 'ERROR_UPLOADING_AUDIO';
export const UPLOADING_AUDIO_SUCCESS = 'UPLOADING_AUDIO_SUCCESS';
export const UPLOADING_LETTER_AUDIO_PROGRESS =
	'UPLOADING_LETTER_AUDIO_PROGRESS';
export const UPLOADING_LETTER_AUDIO_RUNNING = 'UPLOADING_LETTER_AUDIO_RUNNING';
export const ERROR_UPLOADING_LETTER_AUDIO = 'ERROR_UPLOADING_LETTER_AUDIO';
export const UPLOADING_LETTER_AUDIO_SUCCESS = 'UPLOADING_LETTER_AUDIO_SUCCESS';
export const CLEAR_REPORT = 'CLEAR_REPORT';
export const REPORTING_ISSUE = 'REPORTING_ISSUE';
export const REPORTING_ISSUE_SUCCESS = 'REPORTING_ISSUE_SUCCESS';
export const ERROR_REPORTING_ISSUE = 'ERROR_REPORTING_ISSUE';
export const REPORTING_FEATURE = 'REPORTING_FEATURE';
export const REPORTING_FEATURE_SUCCESS = 'REPORTING_FEATURE_SUCCESS';
export const ERROR_REPORTING_FEATURE = 'ERROR_REPORTING_FEATURE';
export const SET_REPORT_DATA = 'SET_REPORT_DATA';
export const CLEAR_SOCIAL_REPORT = 'CLEAR_SOCIAL_REPORT';
export const REPORTING_SOCIAL_ISSUE = 'REPORTING_SOCIAL_ISSUE';
export const REPORTING_SOCIAL_ISSUE_SUCCESS = 'REPORTING_SOCIAL_ISSUE_SUCCESS';
export const ERROR_REPORTING_SOCIAL_ISSUE = 'ERROR_REPORTING_SOCIAL_ISSUE';
export const HIDE_REPORTED_POST = 'HIDE_REPORTED_POST';
export const SHOW_REPORTED_POST = 'SHOW_REPORTED_POST';
export const HIDE_REPORTED_COMMENT = 'HIDE_REPORTED_COMMENT';
export const SHOW_REPORTED_COMMENT = 'SHOW_REPORTED_COMMENT';
export const LOADING_ADMIN_DATA = 'LOADING_ADMIN_DATA';
export const SET_ADMIN_DATA = 'SET_ADMIN_DATA';
export const LOADING_REPORTS = 'LOADING_REPORTS';
export const LOADING_MORE_REPORTS = 'LOADING_MORE_REPORTS';
export const SET_REPORTS_ERRORS = 'SET_REPORTS_ERRORS';
export const SET_ISSUE_REPORTS = 'SET_ISSUE_REPORTS';
export const SET_USER_REPORTS = 'SET_USER_REPORTS';
export const SET_POST_REPORTS = 'SET_POST_REPORTS';
export const SET_COMMENT_REPORTS = 'SET_COMMENT_REPORTS';
export const SET_FEATURE_REPORTS = 'SET_FEATURE_REPORTS';
export const SET_QUERIED_REPORTS = 'SET_QUERIED_REPORTS';
export const CLEAR_QUERIED_REPORTS = 'CLEAR_QUERIED_REPORTS';
export const SET_USER_APPEALS = 'SET_USER_APPEALS';
export const SET_POST_APPEALS = 'SET_POST_APPEALS';
export const SET_COMMENT_APPEALS = 'SET_COMMENT_APPEALS';
export const SET_MORE_REPORTS_ERRORS = 'SET_MORE_REPORTS_ERRORS';
export const SET_MORE_ISSUE_REPORTS = 'SET_MORE_ISSUE_REPORTS';
export const SET_MORE_USER_REPORTS = 'SET_MORE_USER_REPORTS';
export const SET_MORE_POST_REPORTS = 'SET_MORE_POST_REPORTS';
export const SET_MORE_COMMENT_REPORTS = 'SET_MORE_COMMENT_REPORTS';
export const SET_MORE_FEATURE_REPORTS = 'SET_MORE_FEATURE_REPORTS';
export const SET_MORE_QUERIED_REPORTS = 'SET_MORE_QUERIED_REPORTS';
export const SET_MORE_USER_APPEALS = 'SET_MORE_USER_APPEALS';
export const SET_MORE_POST_APPEALS = 'SET_MORE_POST_APPEALS';
export const SET_MORE_COMMENT_APPEALS = 'SET_MORE_COMMENT_APPEALS';
export const SET_UPDATED_ISSUE_REPORT = 'SET_UPDATED_ISSUE_REPORT';
export const SET_UPDATED_USER_REPORT = 'SET_UPDATED_USER_REPORT';
export const SET_UPDATED_POST_REPORT = 'SET_UPDATED_POST_REPORT';
export const SET_UPDATED_COMMENT_REPORT = 'SET_UPDATED_COMMENT_REPORT';
export const SET_UPDATED_FEATURE_REPORT = 'SET_UPDATED_FEATURE_REPORT';
export const SET_UPDATED_QUERIED_REPORT = 'SET_UPDATED_QUERIED_REPORT';
export const SET_UPDATED_USER_APPEAL = 'SET_UPDATED_USER_APPEAL';
export const SET_UPDATED_POST_APPEAL = 'SET_UPDATED_POST_APPEAL';
export const SET_UPDATED_COMMENT_APPEAL = 'SET_UPDATED_COMMENT_APPEAL';
export const SET_UPDATED_REPORT_ERROR = 'SET_UPDATED_REPORT_ERROR';
export const CLEAR_REPORTS_ERRORS = 'CLEAR_REPORTS_ERRORS';
export const LOADING_REPORT_UPDATE = 'LOADING_REPORT_UPDATE';
export const SET_DISABLED_USER = 'SET_DISABLED_USER';
export const LOADING_ACCOUNT_ACTION = 'LOADING_ACCOUNT_ACTION';
export const SET_ACCOUNT_ACTION = 'SET_ACCOUNT_ACTION';
export const ERROR_SET_ACCOUNT_ACTION = 'ERROR_SET_ACCOUNT_ACTION';
export const LOADING_ACCOUNT_REPORTS = 'LOADING_ACCOUNT_REPORTS';
export const SET_ACCOUNT_REPORTS = 'SET_ACCOUNT_REPORTS';
export const ERROR_SET_ACCOUNT_REPORTS = 'ERROR_SET_ACCOUNT_REPORTS';
export const LOADING_ADMIN_REPORT_DATA = 'LOADING_ADMIN_REPORT_DATA';
export const SET_ADMIN_REPORT_DATA = 'SET_ADMIN_REPORT_DATA';
export const ERROR_SET_ADMIN_REPORT_DATA = 'ERROR_SET_ADMIN_REPORT_DATA';
export const CLEAR_ACCOUNT_RESTRICTION = 'CLEAR_ACCOUNT_RESTRICTION';
export const SET_ADMIN_REPORT = 'SET_ADMIN_REPORT';
export const LOADING_POST_INFRINGEMENT = 'LOADING_POST_INFRINGEMENT';
export const SET_POST_INFRINGEMENT = 'SET_POST_INFRINGEMENT';
export const SET_POST_REPORT_INFRINGEMENT = 'SET_POST_REPORT_INFRINGEMENT';
export const ERROR_POST_INFRINGEMENT = 'ERROR_POST_INFRINGEMENT';
export const ADD_POST_INFRINGEMENT = 'ADD_POST_INFRINGEMENT';
export const REMOVE_POST_INFRINGEMENT = 'REMOVE_POST_INFRINGEMENT';
export const ADD_REPORTED_POST_INFRINGEMENT = 'ADD_REPORTED_POST_INFRINGEMENT';
export const REMOVE_REPORTED_POST_INFRINGEMENT =
	'REMOVE_REPORTED_POST_INFRINGEMENT';
export const ADD_REPORTED_COMMENT_INFRINGEMENT =
	'ADD_REPORTED_COMMENT_INFRINGEMENT';
export const REMOVE_REPORTED_COMMENT_INFRINGEMENT =
	'REMOVE_REPORTED_COMMENT_INFRINGEMENT';
export const LOADING_COMMENT_INFRINGEMENT = 'LOADING_COMMENT_INFRINGEMENT';
export const SET_COMMENT_REPORT_INFRINGEMENT =
	'SET_COMMENT_REPORT_INFRINGEMENT';
export const SET_COMMENT_REPORT_COLLECTION_INFRINGEMENT =
	'SET_COMMENT_REPORT_COLLECTION_INFRINGEMENT';
export const SET_COMMENT_INFRINGEMENT = 'SET_COMMENT_INFRINGEMENT';
export const SET_COMMENT_REPLY_REPORT_INFRINGEMENT =
	'SET_COMMENT_REPLY_REPORT_INFRINGEMENT';
export const ERROR_COMMENT_INFRINGEMENT = 'ERROR_COMMENT_INFRINGEMENT';
export const ADD_REPORTER_COMMENT_INFRINGEMENT =
	'ADD_REPORTER_COMMENT_INFRINGEMENT';
export const ADD_COMMENT_REPLY_INFRINGEMENT = 'ADD_COMMENT_REPLY_INFRINGEMENT';
export const REMOVE_REPORTER_COMMENT_INFRINGEMENT =
	'REMOVE_REPORTER_COMMENT_INFRINGEMENT';
export const REMOVE_COMMENT_REPLY_INFRINGEMENT =
	'REMOVE_COMMENT_REPLY_INFRINGEMENT';
export const REFRESH_TAB = 'REFRESH_TAB';
export const FETCHING_ADMIN_COMMENTS = 'FETCHING_ADMIN_COMMENTS';
export const SET_ADMIN_COMMENTS = 'SET_ADMIN_COMMENTS';
export const ERROR_FETCHING_ADMIN_COMMENTS = 'ERROR_FETCHING_ADMIN_COMMENTS';
export const SET_ADMIN_PANEL_DATA = 'SET_ADMIN_PANEL_DATA';
export const SET_MORE_ADMIN_COMMENTS = 'SET_MORE_ADMIN_COMMENTS';
export const LOADING_LIKED_POST_DATA = 'LOADING_LIKED_POST_DATA';
export const SET_LIKED_POST_DATA = 'SET_LIKED_POST_DATA';
export const RESET_LIKED_POST_DATA = 'RESET_LIKED_POST_DATA';
export const SET_MORE_LIKED_POST_DATA = 'SET_MORE_LIKED_POST_DATA';
export const ERROR_SET_LIKED_POST_DATA = 'ERROR_SET_LIKED_POST_DATA';
export const LOADING_LIKED_COMMENT_DATA = 'LOADING_LIKED_COMMENT_DATA';
export const SET_LIKED_COMMENT_DATA = 'SET_LIKED_COMMENT_DATA';
export const RESET_LIKED_COMMENT_DATA = 'RESET_LIKED_COMMENT_DATA';
export const SET_MORE_LIKED_COMMENT_DATA = 'SET_MORE_LIKED_COMMENT_DATA';
export const ERROR_SET_LIKED_COMMENT_DATA = 'ERROR_SET_LIKED_COMMENT_DATA';
export const LOADING_LIKED_REPLY_DATA = 'LOADING_LIKED_REPLY_DATA';
export const SET_LIKED_REPLY_DATA = 'SET_LIKED_REPLY_DATA';
export const RESET_LIKED_REPLY_DATA = 'RESET_LIKED_REPLY_DATA';
export const SET_MORE_LIKED_REPLY_DATA = 'SET_MORE_LIKED_REPLY_DATA';
export const ERROR_SET_LIKED_REPLY_DATA = 'ERROR_SET_LIKED_REPLY_DATA';
export const LOADING_POST_COMMENTS = 'LOADING_POST_COMMENTS';
export const SET_POST_COMMENTS = 'SET_POST_COMMENTS';
export const ERROR_LOADING_POST_COMMENTS = 'ERROR_LOADING_POST_COMMENTS';
export const LOADING_ADMIN_POST_COMMENTS = 'LOADING_ADMIN_POST_COMMENTS';
export const SET_ADMIN_POST_COMMENTS = 'SET_ADMIN_POST_COMMENTS';
export const ERROR_LOADING_ADMIN_POST_COMMENTS =
	'ERROR_LOADING_ADMIN_POST_COMMENTS';
export const CLEAR_ADMIN_POST_COMMENTS = 'CLEAR_ADMIN_POST_COMMENTS';
export const LOADING_NEW_POSTS = 'LOADING_NEW_POSTS';
export const SET_NEW_POSTS = 'SET_NEW_POSTS';
export const STOP_LOADING_NEW_POSTS = 'STOP_LOADING_NEW_POSTS';
export const LOADING_NEW_FOLLOWING_POSTS = 'LOADING_NEW_FOLLOWING_POSTS';
export const SET_NEW_FOLLOWING_POSTS = 'SET_NEW_FOLLOWING_POSTS';
export const STOP_LOADING_NEW_FOLLOWING_POSTS =
	'STOP_LOADING_NEW_FOLLOWING_POSTS';
export const LOADING_NEW_FILTERED_POSTS = 'LOADING_NEW_FILTERED_POSTS';
export const SET_NEW_FILTERED_POSTS = 'SET_NEW_FILTERED_POSTS';
export const STOP_LOADING_NEW_FILTERED_POSTS =
	'STOP_LOADING_NEW_FILTERED_POSTS';
export const CLEAR_LOADING_MORE_COMMENTS_ERRORS =
	'CLEAR_LOADING_MORE_COMMENTS_ERRORS';
export const CLEAR_LOADING_POST_COMMENTS_ERRORS =
	'CLEAR_LOADING_POST_COMMENTS_ERRORS';
export const SET_NEW_EMAIL_ACTIONS = 'SET_NEW_EMAIL_ACTIONS';
export const CLEAR_NEW_EMAIL_ACTIONS = 'CLEAR_NEW_EMAIL_ACTIONS';
export const LOADING_POST_RECIPIENTS = 'LOADING_POST_RECIPIENTS';
export const SET_POST_RECIPIENTS_DATA = 'SET_POST_RECIPIENTS_DATA';
export const ERROR_LOADING_POST_RECIPIENTS = 'ERROR_LOADING_POST_RECIPIENTS';
export const ON_MESSAGE_LISTENER = 'ON_MESSAGE_LISTENER';
export const SENDING_MESSAGE = 'SENDING_MESSAGE';
export const SENDING_MESSAGE_SUCCESS = 'SENDING_MESSAGE_SUCCESS';
export const ERROR_SENDING_MESSAGE = 'ERROR_SENDING_MESSAGE';
export const CLEAR_MESSAGE_ACTIONS = 'CLEAR_MESSAGE_ACTIONS';
export const LOADING_UNSENT_EMAILS = 'LOADING_UNSENT_EMAILS';
export const SET_UNSENT_EMAILS_SUCCESS = 'SET_UNSENT_EMAILS_SUCCESS';
export const ERROR_LOADING_UNSENT_EMAILS = 'ERROR_LOADING_UNSENT_EMAILS';
export const SUBMITTING_APPEAL = 'SUBMITTING_APPEAL';
export const USER_APPEAL_SUCCESFULLY_COMMITTED =
	'USER_APPEAL_SUCCESFULLY_COMMITTED';
export const POST_APPEAL_SUCCESFULLY_COMMITTED =
	'POST_APPEAL_SUCCESFULLY_COMMITTED';
export const COMMENT_APPEAL_SUCCESFULLY_COMMITTED =
	'COMMENT_APPEAL_SUCCESFULLY_COMMITTED';
export const COMMENT_REPLY_APPEAL_SUCCESFULLY_COMMITTED =
	'COMMENT_REPLY_APPEAL_SUCCESFULLY_COMMITTED';
export const ERROR_SUBMITTING_APPEAL = 'ERROR_SUBMITTING_APPEAL';
export const CLEAR_APPEAL_ACTIONS = 'CLEAR_APPEAL_ACTIONS';
export const DELETE_ERROR_COMMENT = 'DELETE_ERROR_COMMENT';
export const DELETE_ERROR_REPLY = 'DELETE_ERROR_REPLY';
export const UPDATING_APPEALABLE_CONTENT = 'UPDATING_APPEALABLE_CONTENT';
export const SUCCESS_UPDATING_APPEALABLE_CONTENT =
	'SUCCESS_UPDATING_APPEALABLE_CONTENT';
export const ERROR_UPDATING_APPEALABLE_CONTENT =
	'ERROR_UPDATING_APPEALABLE_CONTENT';
export const LOADING_NEW_ORDER = 'LOADING_NEW_ORDER';
export const LOADING_COMMENT_REPLIES = 'LOADING_COMMENT_REPLIES';
export const SET_COMMENT_REPLIES = 'SET_COMMENT_REPLIES';
export const SET_MORE_COMMENT_REPLIES = 'SET_MORE_COMMENT_REPLIES';
export const ERROR_LOADING_COMMENT_REPLIES = 'ERROR_LOADING_COMMENT_REPLIES';
export const CLEAR_ERROR_LOADING_COMMENT_REPLIES =
	'CLEAR_ERROR_LOADING_COMMENT_REPLIES';
export const LOADING_HASHTAGS = 'LOADING_HASHTAGS';
export const STOP_LOADING_HASHTAGS = 'STOP_LOADING_HASHTAGS';
export const SET_HASHTAGS = 'SET_HASHTAGS';
export const SET_QUERY_POST_HASHTAGS = 'SET_QUERY_POST_HASHTAGS';
export const SET_HASHTAGS_FROM_COMP = 'SET_HASHTAGS_FROM_COMP';
export const ERROR_LOADING_HASHTAGS = 'ERROR_LOADING_HASHTAGS';
export const SET_FEED_DATA = 'SET_FEED_DATA';
export const SET_PREVIEW_HASHTAGS = 'SET_PREVIEW_HASHTAGS';
export const SET_FEED_IDS = 'SET_FEED_IDS';
export const LOADING_FEED_IDS = 'LOADING_FEED_IDS';
export const ERROR_LOADING_FEED_IDS = 'ERROR_LOADING_FEED_IDS';
export const LOADING_FOLLOWING_POSTS = 'LOADING_FOLLOWING_POSTS';
export const SET_FOLLOWING_POSTS = 'SET_FOLLOWING_POSTS';
export const RESET_FOLLOWING_POSTS = 'RESET_FOLLOWING_POSTS';
export const CLEAR_FOLLOWING_POSTS = 'CLEAR_FOLLOWING_POSTS';
export const ERROR_LOADING_FOLLOWING_POSTS = 'ERROR_LOADING_FOLLOWING_POSTS';
export const SET_USER_ADMIN = 'SET_USER_ADMIN';
export const LOADING_ONLY_USER_COMMENTS = 'LOADING_ONLY_USER_COMMENTS';
export const SET_ONLY_USER_COMMENTS = 'SET_ONLY_USER_COMMENTS';
export const ERROR_LOADING_ONLY_USER_COMMENTS =
	'ERROR_LOADING_ONLY_USER_COMMENTS';
export const SET_MORE_ONLY_USER_COMMENTS = 'SET_MORE_ONLY_USER_COMMENTS';
export const FCM_DATA = 'FCM_DATA';
export const LOADING_NEW_NOTIFICATION_PREFERENCE =
	'LOADING_NEW_NOTIFICATION_PREFERENCE';

export const SET_LETTER_RECIPIENTS = 'SET_LETTER_RECIPIENTS';
export const SETTING_INACTIVITY_INFO = 'SETTING_INACTIVITY_INFO';

export const LOGGING_OUT_ALL_USER_SESSIONS = 'LOGGING_OUT_ALL_USER_SESSIONS';
export const SET_LOGOUT_SUCCESS_MESSAGE = 'SET_LOGOUT_SUCCESS_MESSAGE';
export const SET_LOGOUT_INFO_MESSAGE = 'SET_LOGOUT_INFO_MESSAGE';
export const SET_LOGOUT_WARNING_MESSAGE = 'SET_LOGOUT_WARNING_MESSAGE';
export const SET_LOGOUT_ERROR_MESSAGE = 'SET_LOGOUT_ERROR_MESSAGE';
export const SET_FEEDBACK = 'SET_FEEDBACK';
export const SET_MORE_FEEDBACK = 'SET_MORE_FEEDBACK';
export const SET_UPDATED_FEEDBACK = 'SET_UPDATED_FEEDBACK';

export const SET_BOUNDARY_REPORTS = 'SET_BOUNDARY_REPORTS';
export const SET_MORE_BOUNDARY_REPORTS = 'SET_MORE_BOUNDARY_REPORTS';
export const SET_UPDATED_BOUNDARY_REPORTS = 'SET_UPDATED_BOUNDARY_REPORTS';

export const SET_ACTIVE_VIDEO = 'SET_ACTIVE_VIDEO';
export const MUTE_ACTIVE_VIDEO = 'MUTE_ACTIVE_VIDEO';
export const SET_DATE_VALUE = 'SET_DATE_VALUE';
export const RESET_DATE_VALUE = 'RESET_DATE_VALUE';
export const SET_EMAIL_RECIPIENTS = 'SET_EMAIL_RECIPIENTS';
export const SET_ALL_EMAIL_RECIPIENTS = 'SET_ALL_EMAIL_RECIPIENTS';
export const UPDATING_GOODBYE_LETTER = 'UPDATING_GOODBYE_LETTER';
export const SUCCESS_UPDATING_GOODBYE_LETTER =
	'SUCCESS_UPDATING_GOODBYE_LETTER';
export const CLEAR_ERROR_UPDATING_GOODBYE_LETTER =
	'CLEAR_ERROR_UPDATING_GOODBYE_LETTER';
export const ERROR_UPDATING_GOODBYE_LETTER = 'ERROR_UPDATING_GOODBYE_LETTER';
export const DELETING_GOODBYE_LETTER = 'DELETING_GOODBYE_LETTER';
export const SUCCESS_DELETING_GOODBYE_LETTER =
	'SUCCESS_DELETING_GOODBYE_LETTER';
export const ERROR_DELETING_GOODBYE_LETTER = 'ERROR_DELETING_GOODBYE_LETTER';
export const LOADING_GOODBYE_LETTER = 'LOADING_GOODBYE_LETTER';
export const SET_GOODBYE_LETTER = 'SET_GOODBYE_LETTER';
export const ERROR_LOADING_GOODBYE_LETTER = 'ERROR_LOADING_GOODBYE_LETTER';
export const CLEAR_ALL_LETTER_ERRORS = 'CLEAR_ALL_LETTER_ERRORS';
export const LOADING_RECEIVED_GOODBYE_LETTER =
	'LOADING_RECEIVED_GOODBYE_LETTER';
export const SET_RECEIVED_GOODBYE_LETTER = 'SET_RECEIVED_GOODBYE_LETTER';
export const ERROR_LOADING_RECEIVED_GOODBYE_LETTER =
	'ERROR_LOADING_RECEIVED_GOODBYE_LETTER';
export const LOADING_LETTER_RECIPIENTS = 'LOADING_LETTER_RECIPIENTS';
export const SET_LETTER_RECIPIENTS_DATA = 'SET_LETTER_RECIPIENTS_DATA';
export const ERROR_LOADING_LETTER_RECIPIENTS =
	'ERROR_LOADING_LETTER_RECIPIENTS';
export const LOADING_LETTER_SENDERS = 'LOADING_LETTER_SENDERS';
export const SET_LETTER_SENDERS_DATA = 'SET_LETTER_SENDERS_DATA';
export const ERROR_LOADING_LETTER_SENDERS = 'ERROR_LOADING_LETTER_SENDERS';
export const SET_MORE_LETTER_SENDERS_DATA = 'SET_MORE_LETTER_SENDERS_DATA';
export const ADD_VIEWED_POST = 'ADD_VIEWED_POST';
export const RESTORE_VIEWED_POSTS = 'RESTORE_VIEWED_POSTS';
export const SET_OPENED_DIALOG = 'SET_OPENED_DIALOG';
export const LOADING_AUDIO_SENDER_DETAILS = 'LOADING_AUDIO_SENDER_DETAILS';
export const SET_AUDIO_SENDER_DETAILS = 'SET_AUDIO_SENDER_DETAILS';
export const ERROR_LOADING_AUDIO_SENDER_DETAILS =
	'ERROR_LOADING_AUDIO_SENDER_DETAILS';
export const DENY_MESSAGING = 'DENY_MESSAGING';
export const IS_MESSAGING_SUPPORTED = 'IS_MESSAGING_SUPPORTED';
export const CLEAR_ERROR_SET_USER_AND_DATA = 'CLEAR_ERROR_SET_USER_AND_DATA';
export const SENDING_COMMENT_ADMIN = 'SENDING_COMMENT_ADMIN';
export const REPORTING_SUPPORT = 'REPORTING_SUPPORT';
export const REPORTING_SUPPORT_SUCCESS = 'REPORTING_SUPPORT_SUCCESS';
export const ERROR_REPORTING_SUPPORT = 'ERROR_REPORTING_SUPPORT';
export const SET_SUPPORT_REPORTS = 'SET_SUPPORT_REPORTS';
export const SET_MORE_SUPPORT_REPORTS = 'SET_MORE_SUPPORT_REPORTS';
export const SET_UPDATED_SUPPORT_REPORT = 'SET_UPDATED_SUPPORT_REPORT';
export const UPDATE_ADMIN_DOC_COUNT = 'UPDATE_ADMIN_DOC_COUNT';
export const CONVERT_SUPPORT_TO_ISSUE_REPORT =
	'CONVERT_SUPPORT_TO_ISSUE_REPORT';
export const AUDIO_NOT_FOUND = 'AUDIO_NOT_FOUND';
export const ERROR_LIKE_DATA = 'ERROR_LIKE_DATA';
export const VERIFY_EMAIL_FIRST = 'VERIFY_EMAIL_FIRST';
export const NEW_VERSION_AVAILABLE = 'NEW_VERSION_AVAILABLE';
export const SET_QUERY_ORDER = 'SET_QUERY_ORDER';
export const SET_MICROPHONE_ACCESS = 'SET_MICROPHONE_ACCESS';
export const SET_CAMERA_ACCESS = 'SET_CAMERA_ACCESS';
export const CLEAR_UNRELEASED_POSTS = 'CLEAR_UNRELEASED_POSTS';
