import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import YoutubeVideos from './YoutubeVideos';

export default function YTCarousel({ mode }) {
	const [play, setPlay] = useState(true);
	return (
		<div className='pdY2Media'>
			<Carousel
				fullHeightHover={false}
				autoPlay={play}
				navButtonsAlwaysVisible={true}
				interval={4000}
				duration={1000}
				animation='slide'
			>
				<div className={`card pdtH ${mode === 'dark' && 'dbc'}`}>
					<YoutubeVideos
						mode={mode}
						esp='https://www.youtube.com/watch?v=q3N6s8c6OKQ'
						eng='https://www.youtube.com/watch?v=Fr_cswok1g0'
						setPlay={() => setPlay(false)}
					/>
				</div>
				<div className={`card pdtH ${mode === 'dark' && 'dbc'}`}>
					<YoutubeVideos
						mode={mode}
						esp='https://www.youtube.com/watch?v=4Itwbn6nrzs'
						eng='https://www.youtube.com/watch?v=d9afg1YBb78&t=53s'
						setPlay={() => setPlay(false)}
					/>
				</div>
				<div className={`card pdtH ${mode === 'dark' && 'dbc'}`}>
					<YoutubeVideos
						mode={mode}
						esp='https://www.youtube.com/watch?v=QtjZCuPYR8I'
						eng='https://www.youtube.com/watch?v=3fTUwY2Sxxw'
						setPlay={() => setPlay(false)}
					/>
				</div>
			</Carousel>
		</div>
	);
}
