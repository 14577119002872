import { Component } from 'react';
import PropTypes from 'prop-types';
// MUI
import {
	Dialog,
	CircularProgress,
	Toolbar,
	DialogContent,
} from '@mui/material';
// MUI ICONS
import CloseIcon from '@mui/icons-material/Close';
// Redux
import { connect } from 'react-redux';
import {
	getFollowers,
	clearErrorFollowers,
	addRecipients,
	deleteRecipients,
	clearQueriedUsers,
	clearQueriedUsersErrors,
	setActiveVideo,
} from '../../redux/actions/dataActions';
import ImgSrc from './ImgSrc';
import FollowButton from './FollowButton';
import QueryUsersSkeleton from '../Skeletons/QueryUsersSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { withRouter } from '../../redux/withRouter';
import Searchbar from './Searchbar';
import CustomAlerts from './CustomAlerts';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { toast } from 'react-toastify';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { t } from 'i18next';

class FollowerCount extends Component {
	state = {
		openDialog: false,
		oldPath: '',
		newPath: '',
		followerHandleKey: '',
	};
	componentDidMount() {
		if (this.props.data.queriedUsers.length > 0) {
			this.props.clearQueriedUsers();
		}

		if (this.props.data.activeVideoPostId !== '') {
			this.props.setActiveVideo('');
		}

		this.getFollowersFunc();
	}

	getMoreFollowers = (lastFollowerKey, limit) => {
		if (this.state.followerHandleKey !== lastFollowerKey) {
			this.setState({ followerHandleKey: lastFollowerKey });
			let props = this.props;
			props.getFollowers({
				user: props.makePostFunctionality
					? props.user.credentials.handle
					: props.params.handle,
				key: lastFollowerKey,
				limit: limit ? limit : 10,
			});
		}
	};
	getFollowersFunc = async (refresh) => {
		let props = this.props;
		let paramsHandle = props.params.handle;
		let credentials = props.user.credentials;
		let visitedCredentials = props.data.visitedUserCredentials;

		this.setState({
			openDialog: true,
		});
		let userHandle;
		if (props.makePostFunctionality) {
			userHandle = props.user.credentials.handle;
		} else {
			userHandle = props.params.handle;
		}

		let followerCount =
			paramsHandle === credentials.handle
				? credentials.followerCount
				: visitedCredentials.followerCount;

		if (
			followerCount > 0 ||
			followerCount === undefined ||
			paramsHandle !== credentials.handle
		) {
			let limit =
				followerCount === undefined || refresh
					? 10
					: followerCount > 10
					? 10
					: followerCount;
			await props.getFollowers({ user: userHandle, limit: limit });
		}
	};

	onClose = () => {
		this.setState({ openDialog: false, followerHandleKey: '' });
		// this.props.history();
		let split = this.props.location.pathname.split('/');
		let splicedStr = split.slice(0, split.length - 1).join('/');
		this.props.history(splicedStr);

		if (this.props.data.errorFollowers) {
			this.props.clearErrorFollowers();
		}
		if (this.props.data.errorQueryingUsers !== null) {
			this.props.clearQueriedUsersErrors();
		}
		if (this.props.data.queriedUsers.length > 0) {
			this.props.clearQueriedUsers();
		}
	};

	close = (path) => {
		this.setState({ openDialog: false, followerHandleKey: '' });
	};

	addAndRemoveRecipient = (userId, handle, name, imgUrl, imgColor) => {
		let recipient = {
			userId: userId,
			handle: handle,
			name: name,
			imageUrl: imgUrl,
			imageColor: imgColor,
		};
		const from = this.props.goodbyeLetter ? 'letter' : 'post';
		let recipients = this.props.goodbyeLetter
			? this.props.data.letterRecipients
			: this.props.data.postRecipients;

		if (this.props.user.credentials.handle !== recipient.handle) {
			if (
				recipients.findIndex((recip) => recip.handle === recipient.handle) ===
				-1
			) {
				if (recipients.length < 15) {
					this.props.addRecipients(recipient, from);
				} else {
					toast.info(t('only_15_recipients'), {
						type: 'info',
						isLoading: false,
						autoClose: 6000,
						hideProgressBar: false,
						closeOnClick: true,
						draggable: true,
						progress: undefined,
						containerId: 'app',
						toastId: 'recipientsExceeded',
					});
				}
				this.setState({
					searchInput: '',
					viewResults: false,
					lastSearch: '',
				});
			} else {
				this.props.deleteRecipients(recipient);
			}
		}
	};

	travelUser = (dataHandle, disabled, dataUserId) => {
		this.props.history(`/users/${dataHandle}`, {
			state: { disabled: disabled, data: dataUserId },
		});
		this.close();
	};

	render() {
		const { openDialog } = this.state;
		const {
			data: {
				followers,
				loadingFollowers,
				errorFollowers,
				queriedUsers,
				letterRecipients,
				postRecipients,
				visitedUserCredentials,
			},
			user: {
				credentials: {
					admin,
					userId,
					handle,
					name,
					imageUrl,
					thumbnail,
					imageColor,
					followerCount,
					followingUserIds,
				},
			},
			UI: { mode },

			makePostFunctionality,
			errorMP,
			goodbyeLetter,
		} = this.props;
		const notificationBackgroundColor =
			mode === 'light' ? 'notificationReadLight' : 'notificationReadDark';
		const recipientArray = goodbyeLetter ? letterRecipients : postRecipients;
		const paramsHandle = this.props.params.handle;

		const ownProfile = handle === paramsHandle;
		const countFrom =
			ownProfile ||
			makePostFunctionality ||
			this.props.location.pathname === '/settings'
				? followerCount
				: visitedUserCredentials.followerCount;

		const queryCount = countFrom - (followers ? followers.length : 0);

		const limit = queryCount >= 10 ? 10 : queryCount;

		const followersList =
			followers === null && loadingFollowers ? (
				<QueryUsersSkeleton fulline number={limit} showHr />
			) : countFrom === 0 ? (
				<CustomAlerts
					severity='info'
					info={true}
					message={
						paramsHandle === handle || makePostFunctionality
							? t('no_followers')
							: t('has_no_followers', {
									handle: paramsHandle,
							  })
					}
					noMargin
				/>
			) : errorFollowers ? (
				<CustomAlerts
					error={errorFollowers}
					severity={errorFollowers === 'No results found.' ? 'info' : 'error'}
					noMargin
				/>
			) : loadingFollowers || (followers && followers.length > 0) ? (
				<InfiniteScroll
					dataLength={followers.length} //This is important field to render the next data
					scrollThreshold={0.7} // next will be called when user comes below 70% of the total height.
					next={() =>
						this.getMoreFollowers(
							followers[followers.length - 1]?.userHandle,
							limit,
						)
					}
					hasMore={
						followers.length % 10 === 0 &&
						!errorFollowers &&
						((paramsHandle !== handle && followers.length !== 1000) ||
							paramsHandle === handle)
					}
					scrollableTarget={
						makePostFunctionality
							? 'scrollableMakePostDiv'
							: 'scrollableFollowersDiv'
					}
					loader={
						loadingFollowers && (
							<div>
								{followers.length > 0 && <hr className='mg0' />}
								<QueryUsersSkeleton fulline number={limit} showHr />
							</div>
						)
					}
					endMessage={
						<CustomAlerts
							noMargin
							severity='info'
							info={
								paramsHandle !== handle &&
								followers &&
								followers.length === 1000
							}
							message={t('only_handle_followers', {
								handle: paramsHandle,
							})}
						/>
					}
				>
					{followers.map((follower, index) => {
						const bool = follower.userHandleId === userId;
						const dataUserId = bool ? userId : follower.userHandleId;
						const dataName = bool ? name : follower.name;
						const isAdmin = bool ? admin : follower.admin;
						const imgUrl = bool ? imageUrl : follower.userImage;
						const thumbUrl = bool ? thumbnail : follower.userThumbnail;
						const imgColor = bool ? imageColor : follower.userImageColor;
						const dataHandle = bool ? handle : follower.userHandle;

						return (
							<div key={dataHandle}>
								<li className={`flex center pd ${notificationBackgroundColor}`}>
									{makePostFunctionality ? (
										<div
											className='followList'
											onClick={() =>
												this.addAndRemoveRecipient(
													dataUserId,
													dataHandle,
													dataName,
													imgUrl,
													imgColor,
												)
											}
										>
											<ImgSrc
												img={imgUrl}
												thumbnail={thumbUrl}
												imgColor={imgColor}
												handle={dataHandle}
												css='mediaImg'
												fontSize='20px'
											/>
											<div className='listItemText notranslate'>
												<div className='flex alignItemsCenter'>
													<p className='listItemSpan mg0'>{dataHandle}</p>
													{isAdmin && <VerifiedUserIcon fontSize='small' />}
												</div>
												{dataName && (
													<p className='mg0 capitalize'>{dataName}</p>
												)}
											</div>
											{recipientArray.findIndex(
												(recipient) => recipient.handle === dataHandle,
											) === -1 ? (
												<span className='new s_resetInput bold underline'>
													{handle === dataHandle
														? t('you_will_be_notified')
														: t('add')}
												</span>
											) : (
												<span className='new s_resetInput bold underline red'>
													{t('remove')}
												</span>
											)}
										</div>
									) : (
										<div className='followList spaceBetween'>
											<div
												className={`flex ${
													followingUserIds &&
													followingUserIds.includes(follower.userHandleId)
														? 'fullWidth'
														: 'width70'
												}`}
												onClick={() =>
													this.travelUser(
														dataHandle,
														follower.disabled,
														follower.userHandleId,
													)
												}
											>
												<ImgSrc
													img={imgUrl}
													thumbnail={thumbUrl}
													imgColor={imgColor}
													handle={dataHandle}
													css='mediaImg'
													fontSize='20px'
												/>

												<div className='listItemText notranslate'>
													<div className='flex alignItemsCenter'>
														<p className='listItemSpan mg0'>{dataHandle}</p>
														{isAdmin && <VerifiedUserIcon fontSize='small' />}
													</div>

													{dataName && (
														<p className='mg0 capitalize'>{dataName}</p>
													)}
												</div>
											</div>

											<FollowButton
												isFollowingBackBool={follower.isFollowingBack}
												userHandle={dataHandle}
												userId={follower.userHandleId}
											/>
										</div>
									)}
								</li>
								{index !== followers.length - 1 && <hr className='mg0' />}
							</div>
						);
					})}
				</InfiniteScroll>
			) : null;

		return (
			<div>
				{makePostFunctionality ? (
					<div>
						<Searchbar
							makePostFunctionality
							searchGroup='followers'
							paramsHandle={handle}
							// focus={true}
							errorMP={errorMP}
							disabledSearch={followerCount <= 0}
							// queryCount={queryCount === 0 ? 1 : limit}
							queryCount={1}
							hasMoreResults={followers.length > 0}
						/>

						{queriedUsers.length <= 0 && (
							<ul className='ul_list'>{followersList}</ul>
						)}
					</div>
				) : (
					<Dialog
						disableEnforceFocus
						open={openDialog}
						onClose={this.onClose}
						fullScreen
					>
						<Toolbar id='dialogBar'>
							<div className='titleToolbar'>
								{countFrom > 0 ? countFrom : ''}{' '}
								{countFrom === 1 ? t('follower') : t('followers')}
							</div>

							<CloseIcon
								id='closeDialogButton'
								onClick={this.onClose}
								aria-label='close'
							/>
						</Toolbar>

						<DialogContent sx={{ padding: 0 }} id='scrollableFollowersDiv'>
							<div>
								<PullToRefresh
									onRefresh={this.getFollowersFunc}
									isPullable={!loadingFollowers}
									pullDownThreshold={50}
									resistance={1}
									refreshingContent={
										<div id='circularProgressQuery'>
											<CircularProgress
												variant='indeterminate'
												id='queryUserAuthProgress'
												size={30}
												thickness={3}
											/>
										</div>
									}
									pullingContent={
										<h3 className='center' id='mgt'>
											{t('pull_to_refresh')}
										</h3>
									}
								>
									<Searchbar
										searchGroup='followers'
										paramsHandle={this.props.params.handle}
										disabledSearch={followers && followers.length <= 0}
										// queryCount={queryCount === 0 ? 1 : limit}
										queryCount={1}
										hasMoreResults={followers.length > 0}
									/>

									{queriedUsers.length <= 0 && (
										<ul className='ul_list'>{followersList}</ul>
									)}
								</PullToRefresh>
							</div>
						</DialogContent>
					</Dialog>
				)}
			</div>
		);
	}
}

FollowerCount.propTypes = {
	getFollowers: PropTypes.func.isRequired,
	clearErrorFollowers: PropTypes.func.isRequired,
	clearQueriedUsersErrors: PropTypes.func.isRequired,
	clearQueriedUsers: PropTypes.func.isRequired,
	addRecipients: PropTypes.func.isRequired,
	deleteRecipients: PropTypes.func.isRequired,
	setActiveVideo: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	data: state.data,
	user: state.user,
	UI: state.UI,
});

const mapActionsToProps = {
	getFollowers,
	clearErrorFollowers,
	clearQueriedUsersErrors,
	clearQueriedUsers,
	setActiveVideo,
	addRecipients,
	deleteRecipients,
};

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(FollowerCount));
