import {
	SET_POSTS,
	SET_USER_AND_DATA,
	LOADING_MORE_COMMENTS,
	LIKE_POST,
	UNLIKE_POST,
	DELETE_POST,
	SET_POST,
	CLEAR_UNRELEASED_POSTS,
	CLEAR_POST,
	SET_USER_POSTS,
	SET_LIKES,
	SET_MORE_LIKES,
	SET_QUERIED_USERS,
	SET_MORE_QUERIED_USERS,
	CLEAR_QUERIED_USERS,
	SET_MORE_NOTIFICATIONS,
	SET_MORE_COMMENTS,
	SET_FOLLOWERS,
	SET_MORE_FOLLOWERS,
	CLEAR_FOLLOWERS,
	UPDATE_PROFILE_DATA,
	SET_NOTIFICATION_ERRORS,
	LOADING_NOTIFICATIONS,
	LOADING_SEARCHBAR,
	LOADING_POSTS,
	ERROR_LOADING_POSTS,
	LOADING_FOLLOWERS,
	LOADING_PROFILE,
	LOADING_PROFILE_POSTS,
	LOADING_POST,
	ERROR_LOADING_POST,
	LOADING_LIKES,
	ERROR_LOADING_LIKES,
	LOADING_MORE_LIKES,
	ERROR_LOADING_MORE_LIKES,
	ERROR_UPLOADING_POST,
	LOADING_SUBMIT_COMMENT,
	SUBMIT_COMMENT,
	ERROR_SUBMIT_COMMENT,
	DELETE_COMMENT,
	REMOVE_DELETED_POST_ACTIONS,
	LOADING_DELETE_COMMENT,
	ERROR_DELETE_COMMENT,
	REMOVE_DELETED_COMMENT_ACTIONS,
	CORRECT_LIKE_ACTION,
	ERROR_SET_USER_AND_DATA,
	ERROR_SET_USER_POSTS,
	LOADING_DELETE_POST,
	ERROR_DELETE_POST,
	ERROR_SET_MORE_COMMENTS,
	ERROR_SET_QUERIED_USERS,
	CLEAR_ERROR_SET_QUERIED_USERS,
	ERROR_SET_FOLLOWERS,
	CLEAR_ERROR_SET_FOLLOWERS,
	SET_POST_RECIPIENTS,
	DELETE_RECIPIENT,
	DELETE_ALL_RECIPIENTS,
	SET_MORE_USER_POSTS,
	UPLOADED_POST,
	ADD_POST_TO_FEED,
	REMOVE_POST_FROM_FEED,
	SET_UNRELEASED_POSTS,
	LOADING_UNRELEASED_PROFILE_POSTS,
	ERROR_SET_UNRELEASED_POSTS,
	UPLOADED_UNRELEASED_PROFILE_POST,
	DECREMENT_POST_COUNT,
	DECREMENT_UNRELEASED_POST_COUNT,
	ERROR_EDITING_POST,
	EDITING_POST_PROGRESS,
	LOADING_UPDATE_POST,
	SET_POST_TO_EDIT,
	SET_EDITED_LIVE_POST,
	UNRELEASED_POST_IS_LIVE,
	RELEASED_POST_IS_UNLIVE,
	SET_EDITED_UNRELEASED_POST,
	CLEAR_EDITED_IMG_FILE,
	ERROR_SET_MORE_QUERIED_USERS,
	SET_UPLOADING_VIDEO_PAUSED,
	RESUME_UPLOADING_VIDEO,
	CANCEL_UPLOADING_VIDEO,
	UPLOADING_VIDEO_PROGRESS,
	UPLOADING_VIDEO_RUNNING,
	ERROR_UPLOADING_VIDEO,
	UPLOADING_VIDEO_SUCCESS,
	SET_REPORT_DATA,
	CLEAR_SOCIAL_REPORT,
	REPORTING_SOCIAL_ISSUE,
	REPORTING_SOCIAL_ISSUE_SUCCESS,
	ERROR_REPORTING_SOCIAL_ISSUE,
	HIDE_REPORTED_COMMENT,
	HIDE_REPORTED_POST,
	SHOW_REPORTED_POST,
	SHOW_REPORTED_COMMENT,
	CLEAR_ERROR_UPLOADING_POST,
	LOADING_REPORTS,
	SET_REPORTS_ERRORS,
	SET_ISSUE_REPORTS,
	SET_USER_REPORTS,
	SET_POST_REPORTS,
	SET_COMMENT_REPORTS,
	SET_QUERIED_REPORTS,
	CLEAR_QUERIED_REPORTS,
	CLEAR_REPORTS_ERRORS,
	LOADING_MORE_REPORTS,
	SET_MORE_ISSUE_REPORTS,
	SET_MORE_USER_REPORTS,
	SET_MORE_POST_REPORTS,
	SET_MORE_COMMENT_REPORTS,
	SET_MORE_QUERIED_REPORTS,
	SET_UPDATED_ISSUE_REPORT,
	SET_UPDATED_USER_REPORT,
	SET_UPDATED_POST_REPORT,
	SET_UPDATED_COMMENT_REPORT,
	SET_UPDATED_REPORT_ERROR,
	SET_UPDATED_QUERIED_REPORT,
	LOADING_REPORT_UPDATE,
	LOADING_ACCOUNT_REPORTS,
	SET_ACCOUNT_REPORTS,
	ERROR_SET_ACCOUNT_REPORTS,
	LOADING_POST_INFRINGEMENT,
	SET_POST_REPORT_INFRINGEMENT,
	ERROR_POST_INFRINGEMENT,
	SET_ADMIN_REPORT,
	REMOVE_POST_INFRINGEMENT,
	ADD_POST_INFRINGEMENT,
	LOADING_COMMENT_INFRINGEMENT,
	ADD_REPORTER_COMMENT_INFRINGEMENT,
	REMOVE_REPORTER_COMMENT_INFRINGEMENT,
	SET_COMMENT_REPORT_INFRINGEMENT,
	ERROR_COMMENT_INFRINGEMENT,
	FETCHING_ADMIN_COMMENTS,
	SET_ADMIN_COMMENTS,
	ERROR_FETCHING_ADMIN_COMMENTS,
	SET_COMMENT_INFRINGEMENT,
	SET_POST_INFRINGEMENT,
	SET_MORE_ADMIN_COMMENTS,
	SET_MORE_USER_AND_DATA,
	SET_POST_DIALOG,
	SET_VISITED_USER_AND_DATA,
	SET_MORE_VISITED_USER_POSTS,
	LOADING_LIKED_POST_DATA,
	SET_LIKED_POST_DATA,
	ERROR_SET_LIKED_POST_DATA,
	SET_MORE_LIKED_POST_DATA,
	RESET_LIKED_POST_DATA,
	RESET_PROFILE_POSTS,
	RESET_UNRELEASED_POSTS,
	LOADING_POST_COMMENTS,
	SET_POST_COMMENTS,
	ERROR_LOADING_POST_COMMENTS,
	SET_ONLINE_STATUS,
	LOADING_NEW_POSTS,
	SET_NEW_POSTS,
	STOP_LOADING_NEW_POSTS,
	CLEAR_LOADING_MORE_COMMENTS_ERRORS,
	SET_MORE_UNRELEASED_POSTS,
	LOADING_POST_RECIPIENTS,
	SET_POST_RECIPIENTS_DATA,
	ERROR_LOADING_POST_RECIPIENTS,
	SET_POST_APPEALS,
	SET_MORE_POST_APPEALS,
	SET_UPDATED_POST_APPEAL,
	ADD_REPORTED_POST_INFRINGEMENT,
	REMOVE_REPORTED_POST_INFRINGEMENT,
	SUBMITTING_APPEAL,
	POST_APPEAL_SUCCESFULLY_COMMITTED,
	ERROR_SUBMITTING_APPEAL,
	CLEAR_APPEAL_ACTIONS,
	SET_COMMENT_APPEALS,
	SET_MORE_COMMENT_APPEALS,
	SET_UPDATED_COMMENT_APPEAL,
	ADD_REPORTED_COMMENT_INFRINGEMENT,
	REMOVE_REPORTED_COMMENT_INFRINGEMENT,
	DELETE_ERROR_COMMENT,
	// UPDATE_POST,
	SET_COMMENT_REPORT_COLLECTION_INFRINGEMENT,
	COMMENT_APPEAL_SUCCESFULLY_COMMITTED,
	UPDATING_APPEALABLE_CONTENT,
	SUCCESS_UPDATING_APPEALABLE_CONTENT,
	ERROR_UPDATING_APPEALABLE_CONTENT,
	DELETE_INFRINGEMENT_COMMENT,
	CLEAR_LOADING_POST_COMMENTS_ERRORS,
	LIKE_COMMENT,
	UNLIKE_COMMENT,
	CORRECT_LIKE_COMMENT_ACTION,
	LOADING_NEW_ORDER,
	LOADING_COMMENT_REPLIES,
	SET_COMMENT_REPLIES,
	ERROR_LOADING_COMMENT_REPLIES,
	LOADING_SUBMIT_COMMENT_REPLY,
	SET_MORE_COMMENT_REPLIES,
	CLEAR_ERROR_LOADING_COMMENT_REPLIES,
	LIKE_COMMENT_REPLY,
	UNLIKE_COMMENT_REPLY,
	SET_COMMENT_REPLY_REPORT_INFRINGEMENT,
	DELETE_COMMENT_REPLY,
	DELETE_INFRINGEMENT_REPLY,
	SET_USER_APPEALS,
	SET_MORE_USER_APPEALS,
	SET_UPDATED_USER_APPEAL,
	COMMENT_REPLY_APPEAL_SUCCESFULLY_COMMITTED,
	CORRECT_LIKE_COMMENT_REPLY_ACTION,
	LOADING_FILTERED_NOTIFICATIONS,
	SET_MORE_FILTERED_NOTIFICATIONS,
	SET_FILTERED_NOTIFICATIONS,
	LOADING_MORE_FILTERED_NOTIFICATIONS,
	SET_TAG_FOLLOWERS,
	LOADING_FILTERED_POSTS,
	SET_FILTERED_POSTS,
	ERROR_LOADING_FILTERED_POSTS,
	LOADING_HASHTAGS,
	SET_HASHTAGS,
	ERROR_LOADING_HASHTAGS,
	SET_HASHTAGS_FROM_COMP,
	SET_QUERY_POST_HASHTAGS,
	SET_FEED_DATA,
	LOADING_NEW_FILTERED_POSTS,
	SET_NEW_FILTERED_POSTS,
	STOP_LOADING_NEW_FILTERED_POSTS,
	CLEAR_FILTERED_POSTS,
	SET_PREVIEW_HASHTAGS,
	STOP_LOADING_HASHTAGS,
	SET_FEED_IDS,
	LOADING_FEED_IDS,
	ERROR_LOADING_FEED_IDS,
	LOADING_FOLLOWING_POSTS,
	SET_FOLLOWING_POSTS,
	RESET_FOLLOWING_POSTS,
	ERROR_LOADING_FOLLOWING_POSTS,
	LOADING_NEW_FOLLOWING_POSTS,
	SET_NEW_FOLLOWING_POSTS,
	STOP_LOADING_NEW_FOLLOWING_POSTS,
	SET_TAG_SEARCHBAR_USERS,
	SET_USER_ADMIN,
	LOADING_ONLY_USER_COMMENTS,
	SET_ONLY_USER_COMMENTS,
	ERROR_LOADING_ONLY_USER_COMMENTS,
	SET_MORE_ONLY_USER_COMMENTS,
	LOADING_LIKED_COMMENT_DATA,
	SET_LIKED_COMMENT_DATA,
	ERROR_SET_LIKED_COMMENT_DATA,
	SET_MORE_LIKED_COMMENT_DATA,
	RESET_LIKED_COMMENT_DATA,
	LOADING_LIKED_REPLY_DATA,
	SET_MORE_LIKED_REPLY_DATA,
	ERROR_SET_LIKED_REPLY_DATA,
	RESET_LIKED_REPLY_DATA,
	SET_LIKED_REPLY_DATA,
	LOADING_ADMIN_POST_COMMENTS,
	SET_ADMIN_POST_COMMENTS,
	ERROR_LOADING_ADMIN_POST_COMMENTS,
	CLEAR_ADMIN_POST_COMMENTS,
	SET_FEATURE_REPORTS,
	SET_MORE_FEATURE_REPORTS,
	SET_UPDATED_FEATURE_REPORT,
	DELETE_ERROR_REPLY,
	SET_FEEDBACK,
	SET_MORE_FEEDBACK,
	SET_UPDATED_FEEDBACK,
	SET_ACTIVE_VIDEO,
	MUTE_ACTIVE_VIDEO,
	SET_DATE_VALUE,
	SET_LETTER_RECIPIENTS,
	SET_EMAIL_RECIPIENTS,
	RESET_VISITED_PROFILE_POSTS,
	SET_VISITED_USER_POSTS,
	UPDATING_GOODBYE_LETTER,
	SUCCESS_UPDATING_GOODBYE_LETTER,
	CLEAR_ERROR_UPDATING_GOODBYE_LETTER,
	ERROR_UPDATING_GOODBYE_LETTER,
	LOADING_GOODBYE_LETTER,
	SET_GOODBYE_LETTER,
	ERROR_LOADING_GOODBYE_LETTER,
	CLEAR_ALL_LETTER_ERRORS,
	DELETING_GOODBYE_LETTER,
	SUCCESS_DELETING_GOODBYE_LETTER,
	ERROR_DELETING_GOODBYE_LETTER,
	SET_ALL_EMAIL_RECIPIENTS,
	RESET_DATE_VALUE,
	LOADING_LETTER_SENDERS,
	SET_LETTER_SENDERS_DATA,
	ERROR_LOADING_LETTER_SENDERS,
	LOADING_RECEIVED_GOODBYE_LETTER,
	SET_RECEIVED_GOODBYE_LETTER,
	ERROR_LOADING_RECEIVED_GOODBYE_LETTER,
	ADD_VIEWED_POST,
	RESTORE_VIEWED_POSTS,
	RESET_EDITED_AUDIO_FILE,
	SET_OPENED_DIALOG,
	TOGGLE_MODE,
	CLEAR_ACCOUNT_REPORTS,
	SET_MORE_FILTERED_POSTS,
	CLEAR_VERIFICATION_EMAIL_SUCCESS_MESSAGE,
	UPLOADING_AUDIO_PROGRESS,
	ERROR_UPLOADING_AUDIO,
	UPLOADING_AUDIO_SUCCESS,
	UPLOADING_POST,
	UPLOADING_IMAGE_PROGRESS,
	UPLOADING_LETTER_AUDIO_PROGRESS,
	ERROR_UPLOADING_IMAGE,
	ERROR_UPLOADING_LETTER_AUDIO,
	UPLOADING_IMAGE_SUCCESS,
	UPLOADING_LETTER_AUDIO_SUCCESS,
	SET_MORE_BOUNDARY_REPORTS,
	SET_BOUNDARY_REPORTS,
	SET_UPDATED_BOUNDARY_REPORTS,
	CLEAR_ERROR_SET_USER_AND_DATA,
	EDITED_AUDIO_FILE,
	CLEAR_AUDIO_FILE,
	SET_MORE_LETTER_SENDERS_DATA,
	SENDING_COMMENT_ADMIN,
	SET_UPDATED_SUPPORT_REPORT,
	CONVERT_SUPPORT_TO_ISSUE_REPORT,
	SET_SUPPORT_REPORTS,
	SET_MORE_SUPPORT_REPORTS,
	ERROR_LIKE_DATA,
	UPDATE_ADMIN_DOC_COUNT,
	LOADING_LETTER_RECIPIENTS,
	SET_LETTER_RECIPIENTS_DATA,
	ERROR_LOADING_LETTER_RECIPIENTS,
	CLEAR_FOLLOWING_POSTS,
	DELETE_POST_NOTIFICATIONS,
	DELETE_COMMENT_NOTIFICATIONS,
	SET_QUERY_ORDER,
	LOADING_FOLLOWING,
	SET_MORE_FOLLOWING,
	SET_FOLLOWING,
	ERROR_SET_FOLLOWING,
	CLEAR_ERROR_SET_FOLLOWING,
	CLEAR_FOLLOWING,
} from '../types';
import isEqual from 'lodash.isequal';

import axios from 'axios';
import { logoutAllTabs, unauthorizedToken } from '../../firebase/firebaseInit';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {
	getDownloadURL,
	getStorage,
	ref,
	uploadBytesResumable,
} from 'firebase/storage';
import confetti from 'canvas-confetti';
import history from '../../browserHistory';
import { t } from 'i18next';

let uploadTask;

export const noImgUrl = () => {
	return 'https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/no-img.jpg?alt=media';
};
export const defaultUserUrl = () => {
	return 'https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/emailBlankProfile.png?alt=media&token=604541ba-7ffd-4511-98a6-72fe61ee6f03';
};
export const dim = (flag) => {
	return {
		opacity: flag ? 0.45 : 1,
		pointerEvents: flag ? 'none' : 'initial',
	};
};

export const titleCase = (string, noSpace) => {
	let result;
	if (noSpace) {
		result = string.replace(/([A-Z])/g, '$1');
	} else {
		result = string.replace(/([A-Z])/g, ' $1');
	}
	const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
	return finalResult;
};
export const transformKey = (key) => {
	return key.replace(/([A-Z])/g, '_$1').toLowerCase();
};

export const scrollToTopFunc = (behavior) => {
	const val = behavior ? behavior : 'smooth';
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: val,
	});
};

export const isEmail = (email) => {
	const regEx =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
	// eslint disable comment above to remove no-useless-escape console warning...
	if (email && email.match(regEx)) return true;
	else return false;
};

export const isSameDay = (date, date2Millis) => {
	let date1String;
	const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z?$/;
	if (isoDateRegex.test(date)) {
		date1String = date.split('T')[0];
	} else {
		date1String = new Date(date).toISOString().split('T')[0];
	}
	const date2String = new Date(date2Millis).toISOString().split('T')[0];
	return date1String === date2String;
};
export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};
export const setFeedData = (data) => (dispatch) => {
	dispatch({ type: SET_FEED_DATA, payload: data });
};
export const clearFollowingPosts = () => (dispatch) => {
	dispatch({ type: CLEAR_FOLLOWING_POSTS });
};

export const setOnlineStatus = (bool) => (dispatch) => {
	dispatch({ type: SET_ONLINE_STATUS, payload: bool });
};
export const getPreviewHashtags = () => (dispatch) => {
	axios
		.post('/getPreviewHashtags')
		.then((res) => {
			dispatch({ type: SET_PREVIEW_HASHTAGS, payload: res.data });
		})
		.catch((error) => {
			// console.log(error);
		});
};
export const clearFilteredPosts = () => (dispatch) => {
	dispatch({ type: CLEAR_FILTERED_POSTS });
};
export const queryPostsByHashtag = (queryData, clear, retry) => (dispatch) => {
	if (clear) {
		dispatch({ type: CLEAR_FILTERED_POSTS });
	}
	dispatch({ type: LOADING_FILTERED_POSTS });
	axios
		.post('/queryPostsByHashtag', queryData)
		.then((res) => {
			if (queryData.key) {
				dispatch({
					type: SET_MORE_FILTERED_POSTS,
					payload: {
						filteredPosts: res.data,
						order: queryData.order,
					},
				});
			} else {
				dispatch({
					type: SET_FILTERED_POSTS,
					payload: {
						filteredPosts: res.data,
						order: queryData.order,
					},
				});
			}
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(queryPostsByHashtag(queryData, clear, true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				dispatch({
					type: ERROR_LOADING_FILTERED_POSTS,
					payload: errorMessage,
				});
			}
			// console.log(err);
		});
};
export const fetchPosts =
	({ followingFeedIds, resetFollowingPosts, key, retry }) =>
	(dispatch) => {
		let formData = {};
		if (followingFeedIds) {
			formData.followingPosts = followingFeedIds;
			dispatch({ type: LOADING_FOLLOWING_POSTS });
		} else {
			dispatch({ type: LOADING_POSTS });
		}

		if (key) {
			formData.key = key;
		}
		axios
			.post('/fetchPosts', formData)
			.then((res) => {
				if (followingFeedIds && resetFollowingPosts) {
					dispatch({
						type: RESET_FOLLOWING_POSTS,
						payload: res.data,
					});
				} else if (followingFeedIds) {
					dispatch({
						type: SET_FOLLOWING_POSTS,
						payload: res.data,
					});
				} else {
					dispatch({
						type: SET_POSTS,
						payload: res.data,
					});
				}
			})
			.catch(async (err) => {
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							fetchPosts({
								followingFeedIds,
								resetFollowingPosts,
								key,
								retry: true,
							}),
						);
					}
				} else {
					const errorMessage = err.response.data.error
						? err.response.data.error
						: err.message;
					if (followingFeedIds) {
						dispatch({
							type: ERROR_LOADING_FOLLOWING_POSTS,
							payload: errorMessage,
						});
					} else {
						dispatch({ type: ERROR_LOADING_POSTS, payload: errorMessage });
					}
				}
			});
	};

export const fetchNewPosts = (key, postId, retry) => async (dispatch) => {
	try {
		let formData = {};
		if (postId) {
			formData.postId = postId;
			dispatch({ type: LOADING_NEW_FOLLOWING_POSTS });
		}
		if (key) {
			formData.key = key;
			dispatch({ type: LOADING_NEW_POSTS });
		}
		const res = await axios.post('/fetchNewPosts', formData);
		if (postId) {
			dispatch({ type: SET_NEW_FOLLOWING_POSTS, payload: res.data });
		} else {
			dispatch({
				type: SET_NEW_POSTS,
				payload: res.data,
			});
		}
	} catch (err) {
		// Handle errors and throw them to be caught by the caller
		if (
			err.response.status === 405 ||
			(err.response.status === 401 &&
				err.response.data.error === 'Unauthorized token.') ||
			(err.response.status === 403 &&
				err.response.data.error === 'Invalid Authentication Token.')
		) {
			const tokenRefreshed = await dispatch(unauthorizedToken());
			if (tokenRefreshed && !retry) {
				return dispatch(fetchNewPosts(key, postId, true));
			}
		} else {
			if (postId) {
				dispatch({ type: STOP_LOADING_NEW_FOLLOWING_POSTS });
			} else {
				dispatch({ type: STOP_LOADING_NEW_POSTS });
			}
			const errorMessage = err.response.data.error
				? err.response.data.error
				: err.message;
			toast.info(errorMessage, {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				containerId: 'B',
				toastId: 'fetchNewPosts',
			});
		}
	}
};

export const fetchNewHashtagPosts = (formData, retry) => async (dispatch) => {
	try {
		dispatch({ type: LOADING_NEW_FILTERED_POSTS });
		const res = await axios.post('/fetchNewHashtagPosts', formData);
		dispatch({
			type: SET_NEW_FILTERED_POSTS,
			payload: res.data,
		});
	} catch (err) {
		if (
			err.response.status === 405 ||
			(err.response.status === 401 &&
				err.response.data.error === 'Unauthorized token.') ||
			(err.response.status === 403 &&
				err.response.data.error === 'Invalid Authentication Token.')
		) {
			const tokenRefreshed = await dispatch(unauthorizedToken());
			if (tokenRefreshed && !retry) {
				return dispatch(fetchNewHashtagPosts(formData, true));
			}
		} else {
			dispatch({ type: STOP_LOADING_NEW_FILTERED_POSTS });
			const errorMessage = err.response.data.error
				? err.response.data.error
				: err.message;

			toast.info(errorMessage, {
				containerId: 'app',
				toastId: 'fetchNewHashtagPosts',
			});
		}
	}
};
export const clearPost = () => (dispatch) => {
	dispatch({ type: CLEAR_POST });
};
export const getPost =
	({
		postCollection,
		postId,
		limit,
		commentId,
		commentCollection,
		likeCount,
		commentCount,
		replyCount,
		retry,
	}) =>
	async (dispatch) => {
		let queryData = {
			postId,
			limit,
			postCollection,
		};
		dispatch({ type: LOADING_POST });

		let path = '/post';

		if (commentId) {
			path = '/post/commentId';
			queryData.commentId = commentId;
			queryData.commentCollection = commentCollection;
		}

		try {
			const postDoc = await axios.post(path, queryData);
			const post = postDoc.data;

			if (post.commentNotFound) {
				toast.error(
					t('removed_or_deleted', {
						content: t('comment'),
					}),
					{
						containerId: 'app',
						autoClose: 5000,
					},
				);
			}

			dispatch({
				type: SET_POST,
				payload: post,
			});

			// if (commentId === undefined) {
			// 	if (
			// 		(likeCount !== undefined && post.likeCount !== likeCount) ||
			// 		(commentCount !== undefined && post.commentCount !== commentCount) ||
			// 		(replyCount !== undefined && post.replyCount !== replyCount)
			// 	) {
			// 		dispatch({ type: UPDATE_POST, payload: post });
			// 	}
			// }
		} catch (err) {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(
						getPost({
							postCollection,
							postId,
							limit,
							commentId,
							commentCollection,
							likeCount,
							commentCount,
							replyCount,
							retry: true,
						}),
					);
				}
			} else {
				if (err.response) {
					if (err.message !== undefined && err.response.status === 404) {
						dispatch({
							type: ERROR_LOADING_POST,
							payload: err.message,
						});
					}
					if (err.response.status === 404 && err.response.data) {
						dispatch({
							type: REMOVE_DELETED_POST_ACTIONS,
							payload: postId,
						});
					}
				} else {
					dispatch({ type: ERROR_LOADING_POST, payload: err.message });
				}
			}
		}
	};

export const setPostDialogData = (post) => (dispatch) => {
	dispatch({ type: SET_POST_DIALOG, payload: post });
};
export const setPostToEdit = (postToEdit) => (dispatch) => {
	dispatch({ type: SET_POST_TO_EDIT, payload: postToEdit });
};

export const getLikes = (queryData, retry) => async (dispatch) => {
	try {
		if (queryData.key) {
			dispatch({ type: LOADING_MORE_LIKES });
		} else {
			dispatch({ type: LOADING_LIKES });
		}

		let res = await axios.post('/getLikes', queryData);

		if (queryData.key) {
			dispatch({
				type: SET_MORE_LIKES,
				payload: res.data,
			});
		} else {
			dispatch({
				type: SET_LIKES,
				payload: res.data,
			});
		}
	} catch (err) {
		if (
			err.response.status === 405 ||
			(err.response.status === 401 &&
				err.response.data.error === 'Unauthorized token.') ||
			(err.response.status === 403 &&
				err.response.data.error === 'Invalid Authentication Token.')
		) {
			const tokenRefreshed = await dispatch(unauthorizedToken());
			if (tokenRefreshed && !retry) {
				return dispatch(getLikes(queryData, true));
			}
		} else {
			const errorMessage = err.response.data.error
				? err.response.data.error
				: err.message;
			if (queryData.key) {
				dispatch({
					type: ERROR_LOADING_MORE_LIKES,
					payload: errorMessage,
				});
			} else {
				dispatch({
					type: ERROR_LOADING_LIKES,
					payload: errorMessage,
				});
			}
		}
	}
};

export const editPost =
	({
		editedPost,
		// history,
		handle,
		postId,
		editedLivePost,
		location,
		wasPublic,
		retry,
	}) =>
	(dispatch) => {
		if (editedLivePost === false && location === undefined) {
			history(`/users/${handle}`);
		}
		if (editedLivePost === undefined) {
			if (editedPost.livePost) {
				dispatch({
					type: SET_EDITED_LIVE_POST,
					payload: editedPost,
				});
			} else {
				dispatch({
					type: SET_EDITED_UNRELEASED_POST,
					payload: editedPost,
				});
			}
		}
		dispatch({ type: LOADING_UPDATE_POST, payload: postId });
		dispatch({ type: EDITING_POST_PROGRESS, payload: 0 });

		let formData = { editedPost };
		axios
			.post('/editPost', formData, {
				onUploadProgress: (data) => {
					let percentage = Math.round((data.loaded / data.total) * 100);
					dispatch({ type: EDITING_POST_PROGRESS, payload: percentage });
				},
			})
			.then((res) => {
				if (editedLivePost === undefined) {
					if (editedPost.livePost) {
						dispatch({
							type: SET_EDITED_LIVE_POST,
							payload: res.data,
						});

						if (!wasPublic && res.data.publicPost) {
							dispatch({
								type: ADD_POST_TO_FEED,
								payload: res.data,
							});
						}
						if (wasPublic && !res.data.publicPost) {
							let windowLocation = window.location.pathname.split('/');
							if (windowLocation[1] !== 'users') {
								history.replace(`/users/${handle}`, {
									historyPostId: postId,
									livePost: res.data.livePost,
								});
							}
							dispatch({
								type: REMOVE_POST_FROM_FEED,
								payload: postId,
							});
						}
					} else {
						dispatch({
							type: SET_EDITED_UNRELEASED_POST,
							payload: res.data,
						});
					}
				} else if (editedLivePost) {
					dispatch({
						type: UNRELEASED_POST_IS_LIVE,
						payload: res.data,
					});
					dispatch({ type: DECREMENT_UNRELEASED_POST_COUNT });
				} else {
					dispatch({ type: DELETE_POST_NOTIFICATIONS, payload: postId });

					dispatch({
						type: RELEASED_POST_IS_UNLIVE,
						payload: res.data,
					});
				}
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response &&
					(err.response.status === 405 ||
						(err.response.status === 401 &&
							err.response.data.error === 'Unauthorized token.') ||
						(err.response.status === 403 &&
							err.response.data.error === 'Invalid Authentication Token.'))
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							editPost({
								editedPost,
								history,
								handle,
								postId,
								editedLivePost,
								location,
								wasPublic,
								retry: true,
							}),
						);
					}
				} else {
					if (err.response) {
						// get response with a status code not in range 2xx
						// console.log(err.response.data);
						// console.log(err.response.status);
						// console.log(err.response.headers);
					} else if (err.request) {
						// no response
						// console.log(err.request);
						// instance of XMLHttpRequest in the browser
						// instance ofhttp.ClientRequest in node.js
					} else {
						// Something wrong in setting up the request
						// console.log('Error', err.message);
					}
					// console.log(err.config);
					dispatch({
						type: ERROR_EDITING_POST,
						payload: postId,
					});

					const errorMessage = err.response.data.error
						? err.response.data.error
						: err.message;
					toast.error(errorMessage, {
						containerId: 'B',
					});
				}
			});
	};

export const pauseVideoUpload = () => (dispatch) => {
	// Pause the upload
	uploadTask.pause();
	dispatch({
		type: SET_UPLOADING_VIDEO_PAUSED,
	});
};

export const resumeVideoUpload = () => (dispatch) => {
	// Resume the upload
	uploadTask.resume();
	dispatch({
		type: RESUME_UPLOADING_VIDEO,
	});
};

export const cancelVideoUpload = () => (dispatch) => {
	// Cancel the upload
	uploadTask.cancel();
	dispatch({
		type: CANCEL_UPLOADING_VIDEO,
	});
};

export const uploadFile =
	({ userId, data, file, file2, letter, letterId, handle }) =>
	(dispatch) => {
		let folder, imageFileName, thumbnailFileName;

		const fileType =
			data.fileType && !data.fileType2
				? data.fileType
				: data.fileType && !data.fileUrl
				? data.fileType
				: data.fileType2;

		if (fileType === 'image') {
			folder = 'postImages';
			dispatch({ type: UPLOADING_IMAGE_PROGRESS, payload: 0 });
		} else if (fileType === 'audio') {
			folder = 'postAudios';
			dispatch({ type: UPLOADING_AUDIO_PROGRESS, payload: 0 });
		} else if (fileType === 'video') {
			folder = 'postVideos';
			dispatch({ type: UPLOADING_VIDEO_PROGRESS, payload: 0 });
		} else if (letter) {
			dispatch({ type: UPLOADING_LETTER_AUDIO_PROGRESS, payload: 0 });
			folder = 'letterAudios';
		}

		// Generate a unique token for storage destination
		const generatedToken = uuidv4();
		// Extract file extension
		const fileExtension = file.name.split('.').pop();

		// Initialize Firebase Storage
		const storage = getStorage();
		const storageFileDestination = `${folder}/${generatedToken}_${userId}.${fileExtension}`;
		if (fileType === 'image') {
			imageFileName = `${generatedToken}_${userId}_1080x1080.jpeg`;
			thumbnailFileName = `${generatedToken}_${userId}_100x100.jpeg`;
		}
		const storageRef = ref(storage, storageFileDestination);

		// Create an upload task
		uploadTask = uploadBytesResumable(storageRef, file);

		// Observe state change events such as progress, pause, and resume
		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100,
				);

				if (fileType === 'image') {
					dispatch({ type: UPLOADING_IMAGE_PROGRESS, payload: progress });
				} else if (fileType === 'audio') {
					dispatch({ type: UPLOADING_AUDIO_PROGRESS, payload: progress });
				} else if (fileType === 'video') {
					dispatch({ type: UPLOADING_VIDEO_PROGRESS, payload: progress });
				} else if (letter) {
					dispatch({
						type: UPLOADING_LETTER_AUDIO_PROGRESS,
						payload: progress,
					});
				}

				if (fileType === 'video') {
					switch (snapshot.state) {
						case 'paused':
							dispatch({
								type: SET_UPLOADING_VIDEO_PAUSED,
								payload: 'Upload is paused.',
							});
							break;
						case 'running':
							dispatch({
								type: UPLOADING_VIDEO_RUNNING,
								payload: 'Upload is running',
							});
							break;
						default:
							return;
					}
				}
			},
			(error) => {
				// console.log('ERROR:', error);

				if (fileType === 'image') {
					dispatch({ type: ERROR_UPLOADING_IMAGE, payload: error.message });
				} else if (fileType === 'audio') {
					dispatch({ type: ERROR_UPLOADING_AUDIO, payload: error.message });
				} else if (fileType === 'video') {
					if (error.code !== 'storage/canceled') {
						dispatch({ type: ERROR_UPLOADING_VIDEO, payload: error.code });
					}
				} else if (letter) {
					dispatch({
						type: ERROR_UPLOADING_LETTER_AUDIO,
						payload: error.message,
					});
				}
				// if (error.code !== 'storage/canceled') {
				// } else {
				// }
			},
			() => {
				// Handle successful uploads on complete
				getDownloadURL(uploadTask.snapshot.ref)
					.then((downloadURL) => {
						// Dispatch success action

						if (fileType === 'image') {
							dispatch({ type: UPLOADING_IMAGE_SUCCESS });
						} else if (fileType === 'audio') {
							dispatch({ type: UPLOADING_AUDIO_SUCCESS });
						} else if (fileType === 'video') {
							dispatch({ type: UPLOADING_VIDEO_SUCCESS });
						} else if (letter) {
							dispatch({ type: UPLOADING_LETTER_AUDIO_SUCCESS });
						}

						let formData = {
							...data,
						};

						if (letter) {
							formData.audioUrl = downloadURL;
							formData.storageAudioUrl = storageFileDestination;
							dispatch(uploadGoodbyeLetter(formData, letterId));
						} else {
							if (data.storageFileDestination && data.fileType2 === 'audio') {
								formData.fileUrl2 = downloadURL;
								formData.storageFileDestination2 = storageFileDestination;
							} else {
								formData.fileUrl = downloadURL;
								formData.storageFileDestination = storageFileDestination;
							}
							if (fileType === 'image') {
								formData.originalImgUrl = downloadURL;
								formData.originalImgStorageFileDestination =
									storageFileDestination;
								formData.fileUrl = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_STORAGE_BUCKET}/o/${folder}%2F${imageFileName}?alt=media`;
								formData.storageFileDestination = `${folder}/${imageFileName}`;
								formData.thumbnail = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_STORAGE_BUCKET}/o/${folder}%2Fthumbnails%2F${thumbnailFileName}?alt=media`;
								formData.thumbnailFileDestination = `${folder}/thumbnails/${thumbnailFileName}`;
							}

							if (
								data.fileType2 === 'audio' &&
								file2 &&
								!formData.storageFileDestination2
							) {
								dispatch(
									uploadFile({ userId, data: formData, file: file2, handle }),
								);
							} else {
								dispatch(uploadPost(formData, handle));
							}
						}
					})
					.catch(async (err) => {
						// console.log(err);
						if (fileType === 'image') {
							dispatch({ type: ERROR_UPLOADING_IMAGE, payload: err.message });
						} else if (fileType === 'audio') {
							dispatch({ type: ERROR_UPLOADING_AUDIO, payload: err.message });
						} else if (fileType === 'video') {
							dispatch({ type: ERROR_UPLOADING_VIDEO, payload: err.message });
						} else if (letter) {
							dispatch({
								type: ERROR_UPLOADING_LETTER_AUDIO,
								payload: err.message,
							});
						}
					});
			},
		);
	};

export const uploadPost = (postData, handle, retry) => (dispatch) => {
	if (postData.fileType === 'image') {
		dispatch({ type: CLEAR_EDITED_IMG_FILE });
	}
	if (postData.fileType === 'audio' || postData.fileType2 === 'audio') {
		dispatch({ type: CLEAR_AUDIO_FILE });
	}
	dispatch({ type: UPLOADING_POST });
	axios
		.post('/uploadPost', postData)
		.then((res) => {
			let post = res.data;
			if (postData.originalImgUrl) {
				post.originalImgUrl = postData.originalImgUrl;
			}

			if (post.publicPost) {
				dispatch({
					type: ADD_POST_TO_FEED,
					payload: post,
				});
			}

			if (post.livePost) {
				dispatch({
					type: UPLOADED_POST,
					payload: post,
				});
				dispatch(launchConfetti(1));
			} else {
				dispatch({
					type: UPLOADED_UNRELEASED_PROFILE_POST,
					payload: post,
				});
			}

			if (!post.livePost || !post.publicPost) {
				let windowLocation = window.location.pathname.split('/');
				if (windowLocation[1] !== 'users') {
					history.replace(`/users/${handle}`, {
						historyPostId: post.postId,
						livePost: post.livePost,
					});
				}
				// 	toast.success(
				// 		`${
				// 			!post.livePost ? 'Scheduled post' : 'Post'
				// 		} uploaded succesfully.`,
				// 		{
				// 			position: 'bottom-left',
				// 			autoClose: 6000,
				// 			hideProgressBar: false,
				// 			closeOnClick: true,
				// 			pauseOnHover: true,
				// 			draggable: true,
				// 			progress: undefined,
				// 			containerId: 'app',
				// 			// onClick: () =>
				// 			// 	history.replace(`/users/${handle}`, {
				// 			// 		historyPostId: post.postId,
				// 			// 		livePost: post.livePost,
				// 			// 	}),
				// 		},
				// 	);
			}
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response &&
				(err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					err.response.status === 403)
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(uploadPost(postData, handle, true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				dispatch({
					type: ERROR_UPLOADING_POST,
					payload: errorMessage,
				});
			}
		});
};

export const clearPostUploadError = () => (dispatch) => {
	dispatch({ type: CLEAR_ERROR_UPLOADING_POST, payload: '' });
};

export const deleteRecipients = (recipientObj, deleteAll) => (dispatch) => {
	if (deleteAll) {
		dispatch({ type: DELETE_ALL_RECIPIENTS });
	} else {
		dispatch({ type: DELETE_RECIPIENT, payload: recipientObj });
	}
};

export const like =
	({ likeCount, postId, commentId, repliedId, unreleasedPost, retry }) =>
	(dispatch) => {
		let dataType = repliedId ? 'Reply' : commentId ? 'Comment' : 'Post';

		let formData = {
			postId,
		};
		if (commentId) {
			formData.commentId = commentId;
		}
		if (repliedId) {
			formData.repliedId = repliedId;
		}
		if (unreleasedPost) {
			formData.unreleasedPost = unreleasedPost;
		}

		axios
			.post('/like', formData)
			.then((res) => {
				let data = res.data;

				if (commentId && repliedId) {
					dispatch({
						type: LIKE_COMMENT_REPLY,
						payload: {
							liked: true,
							commentId: commentId,
							repliedId: repliedId,
						},
					});
				} else if (commentId) {
					dispatch({
						type: LIKE_COMMENT,
						payload: {
							liked: true,
							commentId: commentId,
						},
					});
				} else {
					dispatch({
						type: LIKE_POST,
						payload: data,
					});
				}
			})
			.catch(async (err) => {
				// console.log(err);

				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							like({
								likeCount,
								postId,
								commentId,
								repliedId,
								unreleasedPost,
								retry: true,
							}),
						);
					}
				} else {
					if (err.response && err.response.status === 404) {
						if (commentId) {
							dispatch({
								type: REMOVE_DELETED_COMMENT_ACTIONS,
								payload: commentId,
							});
						} else {
							dispatch({
								type: REMOVE_DELETED_POST_ACTIONS,
								payload: postId,
							});
						}
					} else if (err.response.data.error === `${dataType} already liked.`) {
						let obj;
						toast.error(err.response.data.error, {
							containerId: 'B',
						});
						if (postId) {
							obj = { correctedLiked: true, postId: postId };
							dispatch({
								type: CORRECT_LIKE_ACTION,
								payload: obj,
							});
						}
						if (!repliedId && commentId) {
							obj = { correctedLiked: true, commentId: commentId };
							dispatch({
								type: CORRECT_LIKE_COMMENT_ACTION,
								payload: obj,
							});
						}
						if (repliedId) {
							obj = {
								correctedLiked: true,
								commentId: commentId,
								repliedId: repliedId,
							};
							dispatch({
								type: CORRECT_LIKE_COMMENT_REPLY_ACTION,
								payload: obj,
							});
						}
					} else {
						const errorMessage = err.response.data.error
							? err.response.data.error
							: err.message;
						toast.error(errorMessage, {
							containerId: 'B',
						});

						const dataId = repliedId
							? repliedId
							: commentId
							? commentId
							: postId;
						dispatch({
							type: ERROR_LIKE_DATA,
							payload: dataId,
						});
						dispatch(
							instantLikeAction({
								liked: true,
								likeCount,
								postId,
								commentId,
								repliedId,
							}),
						);
					}
				}
			});
	};

export const instantLikeAction =
	({ liked, likeCount, postId, commentId, repliedId }) =>
	(dispatch) => {
		let data = {};

		if (liked) {
			data.likeCount = likeCount > 0 ? likeCount - 1 : 0;
			data.liked = false;

			if (commentId && repliedId) {
				data.commentId = commentId;
				data.repliedId = repliedId;
				dispatch({
					type: UNLIKE_COMMENT_REPLY,
					payload: data,
				});
			} else if (commentId) {
				data.commentId = commentId;

				dispatch({
					type: UNLIKE_COMMENT,
					payload: data,
				});
			} else {
				data.postId = postId;
				dispatch({
					type: UNLIKE_POST,
					payload: data,
				});
			}
		} else {
			data.likeCount = likeCount ? likeCount + 1 : 1;
			data.liked = true;

			if (commentId && repliedId) {
				data.commentId = commentId;
				data.repliedId = repliedId;
				dispatch({
					type: LIKE_COMMENT_REPLY,
					payload: data,
				});
			} else if (commentId) {
				data.commentId = commentId;

				dispatch({
					type: LIKE_COMMENT,
					payload: data,
				});
			} else {
				data.postId = postId;
				dispatch({
					type: LIKE_POST,
					payload: data,
				});
			}
		}
	};

export const unlike =
	({ likeCount, postId, commentId, repliedId, unreleasedPost, retry }) =>
	(dispatch) => {
		let dataType = repliedId ? 'Reply' : postId ? 'Post' : 'Comment';

		let formData = {
			postId,
		};
		if (commentId) {
			formData.commentId = commentId;
		}
		if (repliedId) {
			formData.repliedId = repliedId;
		}
		if (unreleasedPost) {
			formData.unreleasedPost = unreleasedPost;
		}
		axios
			.post('/unlike', formData)
			.then((res) => {
				let data = res.data;

				if (commentId && repliedId) {
					dispatch({
						type: UNLIKE_COMMENT_REPLY,
						payload: {
							liked: false,
							commentId: commentId,
							repliedId: repliedId,
						},
					});
				} else if (commentId) {
					dispatch({
						type: UNLIKE_COMMENT,
						payload: {
							liked: false,
							commentId: commentId,
						},
					});
				} else {
					dispatch({
						type: UNLIKE_POST,
						payload: data,
					});
				}
			})
			.catch(async (err) => {
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							unlike({
								likeCount,
								postId,
								commentId,
								repliedId,
								unreleasedPost,
								retry: true,
							}),
						);
					}
				} else {
					if (
						// err.response.status !== undefined &&
						err.response &&
						err.response.status === 404
					) {
						if (commentId) {
							dispatch({
								type: REMOVE_DELETED_COMMENT_ACTIONS,
								payload: commentId,
							});
						} else {
							dispatch({
								type: REMOVE_DELETED_POST_ACTIONS,
								payload: postId,
							});
						}
					} else if (
						err.response.data.error ===
						`Only a liked ${dataType.toLowerCase()} can be unliked.`
					) {
						toast.error(err.response.data.error, {
							containerId: 'B',
						});
						let obj;
						if (postId) {
							obj = { correctedLiked: false, postId: postId };
							dispatch({
								type: CORRECT_LIKE_ACTION,
								payload: obj,
							});
						}

						if (!repliedId && commentId) {
							obj = { correctedLiked: false, commentId: commentId };
							dispatch({
								type: CORRECT_LIKE_COMMENT_ACTION,
								payload: obj,
							});
						}
						if (repliedId) {
							obj = {
								correctedLiked: false,
								commentId: commentId,
								repliedId: repliedId,
							};
							dispatch({
								type: CORRECT_LIKE_COMMENT_REPLY_ACTION,
								payload: obj,
							});
						}
					} else {
						const errorMessage = err.response.data.error
							? err.response.data.error
							: err.message;
						toast.error(errorMessage, {
							containerId: 'B',
						});

						const dataId = repliedId
							? repliedId
							: commentId
							? commentId
							: postId;
						dispatch({
							type: ERROR_LIKE_DATA,
							payload: dataId,
						});
						dispatch(
							instantLikeAction({
								liked: false,
								likeCount,
								postId,
								commentId,
								repliedId,
							}),
						);
					}
				}
			});
	};
export const submitComment = (formData, commentData, retry) => (dispatch) => {
	let repliedId = formData.commentId;
	if (repliedId) {
		dispatch({
			type: LOADING_SUBMIT_COMMENT_REPLY,
			payload: { repliedId: repliedId, ...formData, ...commentData },
		});
	} else {
		dispatch({
			type: LOADING_SUBMIT_COMMENT,
			payload: { ...formData, ...commentData },
		});
	}
	axios
		.post(`/comment`, formData)
		.then((res) => {
			let data = res.data;
			data.commentId = commentData.commentId;

			dispatch({
				type: SUBMIT_COMMENT,
				payload: data,
			});
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(submitComment(formData, commentData, true));
				}
			} else {
				if (
					err.response.status !== undefined &&
					err.response &&
					err.response.status === 404
				) {
					dispatch({
						type: ERROR_SUBMIT_COMMENT,
						payload: commentData.commentId,
					});
					// toast.error('Unable to publish comment.', {
					// 	containerId: 'app',
					// 	autoClose: 5000,
					// });

					if (repliedId) {
						dispatch({
							type: REMOVE_DELETED_COMMENT_ACTIONS,
							payload: repliedId,
						});
					} else {
						dispatch({
							type: REMOVE_DELETED_POST_ACTIONS,
							payload: formData.postId,
						});
					}
				} else {
					dispatch({
						type: ERROR_SUBMIT_COMMENT,
						payload: commentData.commentId,
					});

					const errorMessage = err.response.data.error
						? err.response.data.error
						: err.message;

					toast.error(errorMessage, {
						containerId: 'app',
					});
				}
			}
		});
};
export const deleteComment =
	({ postId, commentId, livePost, commentInfringements, repliedId, retry }) =>
	(dispatch) => {
		let postType = livePost ? 'p' : 'u';
		let commentType = commentInfringements ? 'r' : 'c';

		dispatch({ type: LOADING_DELETE_COMMENT, payload: commentId });

		axios
			.delete(
				`/post/${postId}/delete-comment/${commentId}/${postType}/${commentType}`,
			)
			.then((res) => {
				let payload = {
					data: res.data,
					deletedCommentId: commentId,
				};

				if (repliedId) {
					payload.repliedId = repliedId;
					if (commentInfringements) {
						dispatch({ type: DELETE_INFRINGEMENT_REPLY, payload: payload });
					} else {
						dispatch({ type: DELETE_COMMENT_REPLY, payload: payload });
					}
				} else if (commentInfringements) {
					dispatch({ type: DELETE_INFRINGEMENT_COMMENT, payload: payload });
				}

				dispatch({ type: DELETE_COMMENT, payload: payload });
				dispatch({ type: DELETE_COMMENT_NOTIFICATIONS, payload: payload });

				return;
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							deleteComment({
								postId,
								commentId,
								livePost,
								commentInfringements,
								repliedId,
								retry,
							}),
						);
					}
				} else {
					if (err.response && err.response.status === 404) {
						dispatch({
							type: REMOVE_DELETED_COMMENT_ACTIONS,
							payload: commentId,
						});
					} else {
						dispatch({
							type: ERROR_DELETE_COMMENT,
							payload: { message: err.message, commentId: commentId },
						});
					}
				}
			});
	};

export const deletePost =
	(postId, livePost, infringements, retry) => (dispatch) => {
		let postType;
		if (infringements) {
			postType = 'r';
		} else if (livePost) {
			postType = 'p';
		} else {
			postType = 'u';
		}
		dispatch({ type: LOADING_DELETE_POST, payload: postId });
		axios
			.delete(`/post/${postId}/${postType}`)
			.then(() => {
				if (infringements) {
				} else if (livePost) {
					dispatch({ type: DECREMENT_POST_COUNT });
				} else {
					dispatch({ type: DECREMENT_UNRELEASED_POST_COUNT });
				}
				dispatch({ type: DELETE_POST, payload: postId });
				dispatch({ type: DELETE_POST_NOTIFICATIONS, payload: postId });
				// deleteUnreleasedPostListener(postId);
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(deletePost(postId, livePost, infringements, true));
					}
				} else {
					if (err.response && err.response.status === 404) {
						dispatch({
							type: REMOVE_DELETED_POST_ACTIONS,
							payload: postId,
						});
						toast.error(t('post_already_deleted'), {
							containerId: 'B',
						});
					} else {
						const errorMessage = err.response.data.error
							? err.response.data.error
							: err.message;
						toast.error(errorMessage, {
							containerId: 'B',
						});

						dispatch({ type: ERROR_DELETE_POST, payload: postId });
					}
				}
			});
	};

// getting user data from static profile component
export const getUserDataAndPosts =
	({ credentialsHandle, userId, paramsHandle, userHandle, load, retry }) =>
	async (dispatch) => {
		try {
			let path;
			let formData = {};
			if (load === undefined) {
				dispatch({ type: LOADING_PROFILE });
			}
			if (userId === undefined) {
				path = '/user/handle';
				formData.handle = paramsHandle;
			} else {
				path = '/user/id';
				formData.userId = userId;
			}
			let res = await axios.post(path, formData);
			let response = { posts: res.data.posts, user: res.data.user };
			if (
				paramsHandle !== userHandle ||
				res.data.user.userId === localStorage.getItem('userId')
			) {
				if (
					credentialsHandle === paramsHandle ||
					res.data.user.userId === localStorage.getItem('userId')
				) {
					dispatch({
						type: SET_USER_AND_DATA,
						payload: response,
					});
				} else {
					dispatch({
						type: SET_VISITED_USER_AND_DATA,
						payload: response,
					});
				}
			} else {
				dispatch({
					type: UPDATE_PROFILE_DATA,
					payload: response,
				});
			}
		} catch (err) {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(
						getUserDataAndPosts({
							credentialsHandle,
							userId,
							paramsHandle,
							userHandle,
							load,
							retry: true,
						}),
					);
				}
			} else {
				dispatch({
					type: ERROR_SET_USER_AND_DATA,
					payload: err.message,
				});
			}
		}
	};
export const clearErrorSetUserAndData = () => (dispatch) => {
	dispatch({ type: CLEAR_ERROR_SET_USER_AND_DATA });
};
// getting user posts from user.js component - static profile
export const getProfilePosts =
	({
		userId,
		postType,
		key,
		action,
		order,
		limit,
		visitedProfile,
		postData,
		retry,
	}) =>
	async (dispatch) => {
		try {
			if (postType === 'livePost') {
				dispatch({ type: LOADING_PROFILE_POSTS });
				if (action === 'resetState') {
					if (visitedProfile) {
						dispatch({ type: RESET_VISITED_PROFILE_POSTS });
					} else {
						dispatch({ type: RESET_PROFILE_POSTS });
					}
				}
			} else if (postType === 'unreleasedPost') {
				dispatch({ type: LOADING_UNRELEASED_PROFILE_POSTS });
				if (action === 'resetState') {
					dispatch({ type: RESET_UNRELEASED_POSTS });
				}
			}

			let formData = {
				postType: postType,
				order: order,
				limit: limit,
			};
			if (key) {
				formData.key = key;
			}
			if (userId) {
				formData.userId = userId;
			}
			// formData.limit = 5;
			let res = await axios.post('/user/getProfilePosts', formData);
			let posts = res.data;
			if (postData && Object.keys(postData).length > 0) {
				posts = res.data.map((post) => ({
					...post,
					...postData,
				}));
			}

			if (postType === 'livePost') {
				if (visitedProfile) {
					if (formData.key) {
						dispatch({
							type: SET_MORE_VISITED_USER_POSTS,
							payload: posts,
						});
					} else {
						dispatch({
							type: SET_VISITED_USER_POSTS,
							payload: posts,
						});
					}
				} else {
					if (formData.key) {
						dispatch({
							type: SET_MORE_USER_POSTS,
							payload: posts,
						});
					} else {
						dispatch({
							type: SET_USER_POSTS,
							payload: posts,
						});
					}
				}
				// }
			}

			if (postType === 'unreleasedPost') {
				if (formData.key) {
					dispatch({
						type: SET_MORE_UNRELEASED_POSTS,
						payload: posts,
					});
				} else {
					dispatch({
						type: SET_UNRELEASED_POSTS,
						payload: posts,
					});
				}
			}
		} catch (err) {
			// console.log(err);
			if (
				err.response &&
				(err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					err.response.status === 403)
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(
						getProfilePosts({
							userId,
							postType,
							key,
							action,
							order,
							limit,
							visitedProfile,
							postData,
							retry: true,
						}),
					);
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				if (postType === 'livePost') {
					dispatch({
						type: ERROR_SET_USER_POSTS,
						payload: errorMessage,
					});
				} else if (postType === 'unreleasedPost') {
					dispatch({
						type: ERROR_SET_UNRELEASED_POSTS,
						payload: errorMessage,
					});
				} else {
					toast.error(errorMessage, {
						containerId: 'app',
					});
				}
			}
		}
	};

// fetch more notifications from db
export const getMoreNotifications = (queryData, retry) => (dispatch) => {
	dispatch({ type: LOADING_NOTIFICATIONS });

	return axios
		.post('/moreNotifications', queryData)
		.then((res) => {
			let notArray = res.data;
			dispatch({ type: SET_MORE_NOTIFICATIONS, payload: notArray });
			if (res.data.length !== queryData.limit) {
				dispatch({
					type: SET_NOTIFICATION_ERRORS,
					payload: 'No more results found.',
				});
			}
			return res.data;
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(getMoreNotifications(queryData, true));
				}
			} else {
				dispatch({
					type: SET_NOTIFICATION_ERRORS,
					payload: err.response.data.error,
				});
			}
		});
};
export const filterNotifications = (queryData, retry) => (dispatch) => {
	if (queryData.key) {
		dispatch({ type: LOADING_MORE_FILTERED_NOTIFICATIONS });
	} else {
		dispatch({ type: LOADING_FILTERED_NOTIFICATIONS });
	}

	return axios
		.post('/filterNotifications', queryData)
		.then((res) => {
			let notArray = res.data;
			if (queryData.key) {
				dispatch({
					type: SET_MORE_FILTERED_NOTIFICATIONS,
					payload: notArray,
				});
			} else {
				dispatch({ type: SET_FILTERED_NOTIFICATIONS, payload: notArray });
			}
			// notArray.forEach((not) => {
			// 	dispatch(setNotificationListener(not.notificationId));
			// });

			return res.data;
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(filterNotifications(queryData, true));
				}
			} else {
				dispatch({
					type: SET_NOTIFICATION_ERRORS,
					payload: err.response.data.error,
				});
			}
		});
};

// fetch more post comments from db
export const getMoreCommentsAdmin = (formData, retry) => (dispatch) => {
	dispatch({ type: LOADING_MORE_COMMENTS });
	axios
		.post('/getMoreCommentsAdmin', formData)
		.then((res) => {
			dispatch({ type: SET_MORE_COMMENTS, payload: res.data });
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(getMoreCommentsAdmin(formData, true));
				}
			} else {
				dispatch({
					type: ERROR_SET_MORE_COMMENTS,
					payload: err.response.data.error,
				});
			}
		});
};
// fetch more post comments from db
export const getMoreComments = (queryData, retry) => (dispatch) => {
	dispatch({ type: LOADING_MORE_COMMENTS });
	axios
		.post('/post/fetchMoreComments', queryData)
		.then((res) => {
			dispatch({ type: SET_MORE_COMMENTS, payload: res.data });
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(getMoreComments(queryData, true));
				}
			} else {
				dispatch({
					type: ERROR_SET_MORE_COMMENTS,
					payload: err.response.data.error,
				});
			}
		});
};

export const setHashtags = (hashtags) => (dispatch) => {
	dispatch({ type: SET_HASHTAGS_FROM_COMP, payload: hashtags });
};
let hashtagQueryCancelToken;
export const queryHashtags =
	(queryData, filterPostHashtags, retry) => (dispatch) => {
		hashtagQueryCancelToken = axios.CancelToken.source();
		//Check if there are any previous pending requests
		if (hashtagQueryCancelToken != typeof undefined) {
			hashtagQueryCancelToken.cancel(
				'Operation canceled due to new request or empty request.',
			);
		}

		if (queryData.search !== '') {
			dispatch({ type: LOADING_HASHTAGS });
			//Save the cancel token for the current request
			axios
				.post('/queryHashtags', queryData, {
					hashtagQueryCancelToken: hashtagQueryCancelToken.token, //Pass the cancel token to the current request
				})
				.then((res) => {
					if (filterPostHashtags) {
						dispatch({
							type: SET_QUERY_POST_HASHTAGS,
							payload: res.data,
						});
					} else {
						dispatch({
							type: SET_HASHTAGS,
							payload: res.data,
						});
					}
				})
				.catch(async (err) => {
					if (
						err.response.status === 405 ||
						(err.response.status === 401 &&
							err.response.data.error === 'Unauthorized token.') ||
						(err.response.status === 403 &&
							err.response.data.error === 'Invalid Authentication Token.')
					) {
						const tokenRefreshed = await dispatch(unauthorizedToken());
						if (tokenRefreshed && !retry) {
							return dispatch(
								queryHashtags(queryData, filterPostHashtags, true),
							);
						}
					} else {
						if (
							err.message === 'Operation canceled due to new request.' ||
							err.message ===
								'Operation canceled due to new request or empty request.'
						) {
							return;
						} else if (err.response !== undefined) {
							dispatch({
								type: ERROR_LOADING_HASHTAGS,
								payload: err.response.data.error,
							});
						} else {
							dispatch({ type: ERROR_LOADING_HASHTAGS, payload: err.message });
						}
					}
				});
		}
	};
export const loadHashtags = (bool) => (dispatch) => {
	if (bool) {
		dispatch({ type: LOADING_HASHTAGS });
	} else {
		dispatch({ type: STOP_LOADING_HASHTAGS });
	}
};
export const loadSearchbar = () => (dispatch) => {
	dispatch({ type: LOADING_SEARCHBAR });
};
export const errSearchbar = (err) => (dispatch) => {
	dispatch({ type: ERROR_SET_QUERIED_USERS, payload: err });
};

let userQueryCancelToken;
export const queryUsers = (queryData, retry) => (dispatch) => {
	userQueryCancelToken = axios.CancelToken.source();
	//Check if there are any previous pending requests
	if (typeof userQueryCancelToken != typeof undefined) {
		userQueryCancelToken.cancel(
			'Operation canceled due to new request or empty request.',
		);
	}

	if (queryData.search !== '') {
		dispatch({ type: LOADING_SEARCHBAR });
		//Save the cancel token for the current request
		axios
			.post('/users', queryData, {
				userQueryCancelToken: userQueryCancelToken.token, //Pass the cancel token to the current request
			})
			.then((res) => {
				if (queryData.lastQueriedUser) {
					dispatch({
						type: SET_MORE_QUERIED_USERS,
						payload: res.data,
					});
				} else {
					dispatch({
						type: SET_QUERIED_USERS,
						payload: res.data,
					});
				}
			})
			.catch(async (err) => {
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(queryUsers(queryData, true));
					}
				} else {
					if (
						err.message === 'Operation canceled due to new request.' ||
						err.message ===
							'Operation canceled due to new request or empty request.'
					) {
						return;
					} else if (err.response !== undefined) {
						if (queryData.lastQueriedUser) {
							dispatch({
								type: ERROR_SET_MORE_QUERIED_USERS,
								payload: err.response.data.error,
							});
						} else {
							dispatch({
								type: ERROR_SET_QUERIED_USERS,
								payload: err.response.data.error,
							});
						}
					} else {
						if (queryData.lastQueriedUser) {
							dispatch({
								type: ERROR_SET_MORE_QUERIED_USERS,
								payload: err.message,
							});
						} else {
							dispatch({ type: ERROR_SET_QUERIED_USERS, payload: err.message });
						}
					}
				}
			});
	}
};

export const clearQueriedUsersErrors = () => (dispatch) => {
	dispatch({ type: CLEAR_ERROR_SET_QUERIED_USERS });
};

export const clearQueriedUsers = () => (dispatch) => {
	dispatch({ type: CLEAR_QUERIED_USERS });
};

export const getFollowers =
	(queryData, tagList, getFollowing, retry) => async (dispatch) => {
		try {
			let backEndString = '';
			if (getFollowing) {
				backEndString = '/fetchFollowing';
			} else {
				backEndString = '/fetchFollowers';
			}
			if (tagList) {
				queryData.lastFollowing = true;
			}
			if (getFollowing) {
				dispatch({ type: LOADING_FOLLOWING });
			} else {
				dispatch({ type: LOADING_FOLLOWERS });
			}
			let res = await axios.post(backEndString, queryData);

			if (tagList) {
				dispatch({ type: SET_TAG_FOLLOWERS, payload: res.data });

				let obj = {
					users: res.data,
					lastUserId: res.data[res.data.length - 1].handle,
				};
				dispatch({
					type: SET_TAG_SEARCHBAR_USERS,
					payload: obj,
				});
			} else {
				if (queryData.key && getFollowing) {
					dispatch({ type: SET_MORE_FOLLOWING, payload: res.data });
				} else if (getFollowing) {
					dispatch({ type: SET_FOLLOWING, payload: res.data });
				} else if (queryData.key) {
					dispatch({ type: SET_MORE_FOLLOWERS, payload: res.data });
				} else {
					dispatch({ type: SET_FOLLOWERS, payload: res.data });
				}
			}
		} catch (err) {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(getFollowers(queryData, tagList, getFollowing, true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				if (getFollowing) {
					dispatch({ type: ERROR_SET_FOLLOWING, payload: errorMessage });
				} else {
					dispatch({ type: ERROR_SET_FOLLOWERS, payload: errorMessage });
				}
			}
		}
	};
export const clearErrorFollowers = () => (dispatch) => {
	dispatch({ type: CLEAR_ERROR_SET_FOLLOWERS });
};

export const clearFollowers = () => (dispatch) => {
	dispatch({ type: CLEAR_FOLLOWERS });
};
export const clearErrorFollowing = () => (dispatch) => {
	dispatch({ type: CLEAR_ERROR_SET_FOLLOWING });
};
export const clearFollowing = () => (dispatch) => {
	dispatch({ type: CLEAR_FOLLOWING });
};

export const setReportData = (reportData) => (dispatch) => {
	dispatch({ type: SET_REPORT_DATA, payload: reportData });
};
export const clearReportData = () => (dispatch) => {
	dispatch({ type: CLEAR_SOCIAL_REPORT });
};

export const reportSocialIssue = (formData, type, retry) => (dispatch) => {
	dispatch({ type: REPORTING_SOCIAL_ISSUE });
	let path;
	let reportedDataId = '';
	if (type === 'user') {
		reportedDataId = formData.reported;
		path = '/reportUser';
	} else if (type === 'post') {
		reportedDataId = formData.postId;
		path = '/reportPost';
	} else if (type === 'comment') {
		reportedDataId = formData.commentId;
		path = '/reportComment';
	}
	axios
		.post(path, formData)
		.then((res) => {
			let obj = {
				message: res.data.message,
				reportedDataId: reportedDataId,
			};
			dispatch({
				type: REPORTING_SOCIAL_ISSUE_SUCCESS,
				payload: obj,
			});

			if (type === 'comment') {
				dispatch({
					type: HIDE_REPORTED_COMMENT,
					payload: formData.commentId,
				});
			} else if (type === 'post') {
				dispatch({
					type: HIDE_REPORTED_POST,
					payload: formData.postId,
				});
			}
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(reportSocialIssue(formData, type, true));
				}
			} else {
				// console.log(err);
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				dispatch({
					type: ERROR_REPORTING_SOCIAL_ISSUE,
					payload: errorMessage,
				});
			}
		});
};

export const showReportedPost = (postId) => (dispatch) => {
	dispatch({ type: SHOW_REPORTED_POST, payload: postId });
};

export const showReportedComment = (commentId) => (dispatch) => {
	dispatch({ type: SHOW_REPORTED_COMMENT, payload: commentId });
};

export const getCollectionReports = (formData, query, retry) => (dispatch) => {
	if (formData.startAfter) {
		dispatch({ type: LOADING_MORE_REPORTS });
	} else {
		dispatch({ type: LOADING_REPORTS });
	}
	axios
		.post('/fetchReports', formData)
		.then((res) => {
			if (formData.startAfter) {
				if (query) {
					dispatch({ type: SET_MORE_QUERIED_REPORTS, payload: res.data });
				} else {
					if (formData.collection === 'issueReports') {
						dispatch({ type: SET_MORE_ISSUE_REPORTS, payload: res.data });
					}
					if (formData.collection === 'supportReports') {
						dispatch({ type: SET_MORE_SUPPORT_REPORTS, payload: res.data });
					}
					if (formData.collection === 'userReports') {
						dispatch({ type: SET_MORE_USER_REPORTS, payload: res.data });
					}
					if (formData.collection === 'postReports') {
						dispatch({ type: SET_MORE_POST_REPORTS, payload: res.data });
					}
					if (formData.collection === 'commentReports') {
						dispatch({ type: SET_MORE_COMMENT_REPORTS, payload: res.data });
					}
					if (formData.collection === 'featureReports') {
						dispatch({ type: SET_MORE_FEATURE_REPORTS, payload: res.data });
					}
					if (formData.collection === 'feedback') {
						dispatch({ type: SET_MORE_FEEDBACK, payload: res.data });
					}
					if (formData.collection === 'boundaryReports') {
						dispatch({ type: SET_MORE_BOUNDARY_REPORTS, payload: res.data });
					}
					if (formData.collection === 'userAppeals') {
						dispatch({ type: SET_MORE_USER_APPEALS, payload: res.data });
					}
					if (formData.collection === 'postAppeals') {
						dispatch({ type: SET_MORE_POST_APPEALS, payload: res.data });
					}
					if (formData.collection === 'commentAppeals') {
						dispatch({ type: SET_MORE_COMMENT_APPEALS, payload: res.data });
					}
				}
			} else {
				if (query) {
					dispatch({ type: SET_QUERIED_REPORTS, payload: res.data });
				} else {
					if (formData.collection === 'issueReports') {
						dispatch({ type: SET_ISSUE_REPORTS, payload: res.data });
					}
					if (formData.collection === 'supportReports') {
						dispatch({ type: SET_SUPPORT_REPORTS, payload: res.data });
					}
					if (formData.collection === 'userReports') {
						dispatch({ type: SET_USER_REPORTS, payload: res.data });
					}
					if (formData.collection === 'postReports') {
						dispatch({ type: SET_POST_REPORTS, payload: res.data });
					}
					if (formData.collection === 'commentReports') {
						dispatch({ type: SET_COMMENT_REPORTS, payload: res.data });
					}
					if (formData.collection === 'featureReports') {
						dispatch({ type: SET_FEATURE_REPORTS, payload: res.data });
					}
					if (formData.collection === 'feedback') {
						dispatch({ type: SET_FEEDBACK, payload: res.data });
					}
					if (formData.collection === 'boundaryReports') {
						dispatch({ type: SET_BOUNDARY_REPORTS, payload: res.data });
					}

					if (formData.collection === 'userAppeals') {
						dispatch({ type: SET_USER_APPEALS, payload: res.data });
					}
					if (formData.collection === 'postAppeals') {
						dispatch({ type: SET_POST_APPEALS, payload: res.data });
					}
					if (formData.collection === 'commentAppeals') {
						dispatch({ type: SET_COMMENT_APPEALS, payload: res.data });
					}
				}
			}
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(getCollectionReports(formData, query, true));
				}
			} else {
				// console.log(err.response.status);
				if (err.response && err.response.status === 404) {
					dispatch({
						type: SET_REPORTS_ERRORS,
						payload: err.response.data.error,
					});
				} else {
					dispatch({ type: SET_REPORTS_ERRORS, payload: err.message });
				}
			}
		});
};

export const getReport = (formData, retry) => (dispatch) => {
	dispatch({ type: LOADING_REPORTS });
	axios
		.post('/fetchReport', formData)
		.then((res) => {
			dispatch({ type: SET_QUERIED_REPORTS, payload: res.data });
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(getReport(formData, true));
				}
			} else {
				// console.log(err.response.status);
				if (err.response && err.response.status === 404) {
					dispatch({
						type: SET_REPORTS_ERRORS,
						payload: 'No reports found.',
					});
				} else {
					dispatch({ type: SET_REPORTS_ERRORS, payload: err.message });
				}
			}
		});
};

export const clearQueriedReports = () => (dispatch) => {
	dispatch({ type: CLEAR_QUERIED_REPORTS });
};
export const clearReportErrors = () => (dispatch) => {
	dispatch({ type: CLEAR_REPORTS_ERRORS });
};

export const submitCommentAdmin =
	(formData, actions, editing) => (dispatch) => {
		let collection = formData.collection;

		let adminActions = [];

		if (editing && formData.delComment) {
			adminActions = actions.map((obj) => {
				return obj.commentId === formData.commentId
					? { ...obj, deleted: true }
					: obj;
			});
			dispatch({ type: SENDING_COMMENT_ADMIN, payload: formData.commentId });
		} else if (editing) {
			adminActions = actions.map((obj) => {
				return obj.commentId === formData.commentId
					? { ...obj, ...formData }
					: obj;
			});
			dispatch({ type: SENDING_COMMENT_ADMIN, payload: formData.commentId });
		} else {
			adminActions = actions.concat([{ ...formData, id: 'uploading' }]);
			dispatch({ type: SENDING_COMMENT_ADMIN, payload: 'uploading' });
		}
		let newAdminActions = {
			reportId: formData.reportId,
			adminActions,
		};

		if (collection === 'issueReports') {
			dispatch({ type: SET_UPDATED_ISSUE_REPORT, payload: newAdminActions });
		} else if (collection === 'supportReports') {
			dispatch({
				type: SET_UPDATED_SUPPORT_REPORT,
				payload: newAdminActions,
			});
		} else if (collection === 'userReports') {
			dispatch({ type: SET_UPDATED_USER_REPORT, payload: newAdminActions });
		} else if (collection === 'postReports') {
			dispatch({ type: SET_UPDATED_POST_REPORT, payload: newAdminActions });
		} else if (collection === 'commentReports') {
			dispatch({
				type: SET_UPDATED_COMMENT_REPORT,
				payload: newAdminActions,
			});
		} else if (collection === 'featureReports') {
			dispatch({
				type: SET_UPDATED_FEATURE_REPORT,
				payload: newAdminActions,
			});
		} else if (collection === 'feedback') {
			dispatch({ type: SET_UPDATED_FEEDBACK, payload: newAdminActions });
		} else if (collection === 'boundaryReports') {
			dispatch({
				type: SET_UPDATED_BOUNDARY_REPORTS,
				payload: newAdminActions,
			});
		} else if (collection === 'userAppeals') {
			dispatch({ type: SET_UPDATED_USER_APPEAL, payload: newAdminActions });
		} else if (collection === 'postAppeals') {
			dispatch({ type: SET_UPDATED_POST_APPEAL, payload: newAdminActions });
		} else if (collection === 'commentAppeals') {
			dispatch({
				type: SET_UPDATED_COMMENT_APPEAL,
				payload: newAdminActions,
			});
		}

		let path = '/submitCommentAdmin';
		if (editing) {
			path = '/editCommentAdmin';
		}
		axios
			.post(path, formData)
			.then((res) => {
				let collection = formData.collection;
				let adminActions = [];

				if (editing && formData.delComment) {
					adminActions = actions.filter(
						(obj) => obj.commentId !== formData.commentId,
					);
				} else if (editing) {
					adminActions = actions.map((obj) => {
						return obj.commentId === formData.commentId
							? { ...obj, ...res.data }
							: obj;
					});
				} else {
					adminActions = actions.concat([res.data]);
				}
				let newAdminActions = {
					reportId: formData.reportId,
					adminActions,
				};

				if (collection === 'issueReports') {
					dispatch({
						type: SET_UPDATED_ISSUE_REPORT,
						payload: newAdminActions,
					});
				} else if (collection === 'supportReports') {
					dispatch({
						type: SET_UPDATED_SUPPORT_REPORT,
						payload: newAdminActions,
					});
				} else if (collection === 'userReports') {
					dispatch({ type: SET_UPDATED_USER_REPORT, payload: newAdminActions });
				} else if (collection === 'postReports') {
					dispatch({ type: SET_UPDATED_POST_REPORT, payload: newAdminActions });
				} else if (collection === 'commentReports') {
					dispatch({
						type: SET_UPDATED_COMMENT_REPORT,
						payload: newAdminActions,
					});
				} else if (collection === 'featureReports') {
					dispatch({
						type: SET_UPDATED_FEATURE_REPORT,
						payload: newAdminActions,
					});
				} else if (collection === 'feedback') {
					dispatch({ type: SET_UPDATED_FEEDBACK, payload: newAdminActions });
				} else if (collection === 'boundaryReports') {
					dispatch({
						type: SET_UPDATED_BOUNDARY_REPORTS,
						payload: newAdminActions,
					});
				} else if (collection === 'userAppeals') {
					dispatch({ type: SET_UPDATED_USER_APPEAL, payload: newAdminActions });
				} else if (collection === 'postAppeals') {
					dispatch({ type: SET_UPDATED_POST_APPEAL, payload: newAdminActions });
				} else if (collection === 'commentAppeals') {
					dispatch({
						type: SET_UPDATED_COMMENT_APPEAL,
						payload: newAdminActions,
					});
				}
				dispatch({ type: SENDING_COMMENT_ADMIN, payload: false });
			})
			.catch(async (err) => {
				// console.log(err);
				dispatch({ type: SENDING_COMMENT_ADMIN, payload: false });

				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;

				toast.error(errorMessage, {
					containerId: 'app',
				});
			});
	};

export const updateReport =
	({
		report,
		openDialog,
		collection,
		queriedReports,
		viewed,
		updatedPriority,
		updatedTopic,
		openedAction,
		viewedReport,
		stateInfringements,
		postData,
		adminDoc,
		convertSupportToIssue,
		createDevice,
		retry,
	}) =>
	(dispatch) => {
		dispatch({ type: LOADING_REPORT_UPDATE });
		let formData = {
			reportId: report.reportId,
			collection,
		};

		if (viewed && openDialog) {
			formData.viewed = viewed;
		} else {
			formData.viewed = viewedReport;

			if (report.priority) {
				formData.priority = report.priority;
			}
			if (updatedPriority) {
				formData.updatedPriority = updatedPriority;
				formData.active = report.active;
			}
			if (report.topic) {
				formData.topic = report.topic;
			}
			if (updatedTopic) {
				formData.updatedTopic = updatedTopic;
				formData.active = report.active;
			}
			if (openedAction) {
				formData.reOpen = true;
			}
			if (openedAction === false) {
				formData.active = false;
			}
			if (convertSupportToIssue) {
				formData.convertSupportToIssue = convertSupportToIssue;
				formData.device = createDevice;
				delete formData.priority;
				delete formData.active;
				delete formData.viewed;
			}
		}

		if (stateInfringements !== undefined) {
			formData.infringements = stateInfringements;
		}

		if (collection === 'userAppeals') {
			formData.infringements = report.infringements;
		}
		if (collection === 'postAppeals' || collection === 'commentAppeals') {
			formData.infringements = report.infringements;
			formData.appealerUserId = report.userId;
			formData.postId = report.postId;
			if (collection === 'commentAppeals') {
				formData.postAuthorHandle = postData?.userHandle;
				formData.commentId = report.commentId;
				formData.commentCreatedAt = postData?.comments[0].createdAt;
			}
		}

		axios
			.post('/updateReport', formData)
			.then(() => {
				let adminDocUpdate = { ...adminDoc };
				if (openedAction) {
					formData.active = true;
				}
				if (collection === 'issueReports') {
					if (updatedPriority) {
						formData.priority = updatedPriority;
					}
					dispatch({ type: SET_UPDATED_ISSUE_REPORT, payload: formData });
				} else if (collection === 'supportReports') {
					if (updatedTopic) {
						formData.topic = updatedTopic;
					}
					if (convertSupportToIssue) {
						adminDocUpdate[collection] = adminDocUpdate[collection] + 1;
						dispatch({
							type: CONVERT_SUPPORT_TO_ISSUE_REPORT,
							payload: formData,
						});
					} else {
						dispatch({ type: SET_UPDATED_SUPPORT_REPORT, payload: formData });
					}
				} else if (collection === 'userReports') {
					dispatch({ type: SET_UPDATED_USER_REPORT, payload: formData });
				} else if (collection === 'postReports') {
					dispatch({ type: SET_UPDATED_POST_REPORT, payload: formData });
				} else if (collection === 'commentReports') {
					dispatch({ type: SET_UPDATED_COMMENT_REPORT, payload: formData });
				} else if (collection === 'featureReports') {
					dispatch({ type: SET_UPDATED_FEATURE_REPORT, payload: formData });
				} else if (collection === 'feedback') {
					dispatch({ type: SET_UPDATED_FEEDBACK, payload: formData });
				} else if (collection === 'boundaryReports') {
					dispatch({ type: SET_UPDATED_BOUNDARY_REPORTS, payload: formData });
				} else if (collection === 'userAppeals') {
					dispatch({ type: SET_UPDATED_USER_APPEAL, payload: formData });
				} else if (collection === 'postAppeals') {
					dispatch({ type: SET_UPDATED_POST_APPEAL, payload: formData });
				} else if (collection === 'commentAppeals') {
					dispatch({ type: SET_UPDATED_COMMENT_APPEAL, payload: formData });
				}

				if (!openDialog && openedAction !== undefined) {
					if (openedAction) {
						adminDocUpdate[collection] = adminDocUpdate[collection] + 1;
					} else {
						adminDocUpdate[collection] = adminDocUpdate[collection] - 1;
					}
				}

				if (queriedReports) {
					dispatch({ type: SET_UPDATED_QUERIED_REPORT, payload: formData });
				}

				if (updatedPriority || openedAction === false) {
					if (report.priority === 'minor') {
						adminDocUpdate.minorPriority = adminDocUpdate.minorPriority - 1;
					} else if (report.priority === 'medium') {
						adminDocUpdate.mediumPriority = adminDocUpdate.mediumPriority - 1;
					} else if (report.priority === 'major') {
						adminDocUpdate.majorPriority = adminDocUpdate.majorPriority - 1;
					}
				}
				if (
					(updatedPriority && report.active) ||
					openedAction ||
					convertSupportToIssue
				) {
					let p = updatedPriority ? formData.updatedPriority : report.priority;
					if (p === 'minor') {
						adminDocUpdate.minorPriority = adminDocUpdate.minorPriority + 1;
					} else if (p === 'medium') {
						adminDocUpdate.mediumPriority = adminDocUpdate.mediumPriority + 1;
					} else if (p === 'major') {
						adminDocUpdate.majorPriority = adminDocUpdate.majorPriority + 1;
					}
				}
				if (updatedTopic || openedAction === false) {
					adminDocUpdate[report.topic] = adminDocUpdate[report.topic] - 1;
				}
				if ((updatedTopic && report.active) || openedAction) {
					adminDocUpdate[updatedTopic] = adminDocUpdate[updatedTopic] + 1;
				}

				if (Object.keys(adminDocUpdate).length > 0) {
					dispatch({ type: UPDATE_ADMIN_DOC_COUNT, payload: adminDocUpdate });
				}
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							updateReport({
								report,
								openDialog,
								collection,
								queriedReports,
								viewed,
								updatedPriority,
								updatedTopic,
								openedAction,
								viewedReport,
								stateInfringements,
								postData,
								adminDoc,
								convertSupportToIssue,
								createDevice,
								retry: true,
							}),
						);
					}
				} else {
					const errorMessage = err.response.data.error
						? err.response.data.error
						: err.message;
					dispatch({ type: SET_UPDATED_REPORT_ERROR, payload: errorMessage });
				}
			});
	};
export const getAccountReports =
	({ reportedId, userId, retry, isEmail }) =>
	(dispatch) => {
		let formData = {
			reporterId: userId,
		};
		if (isEmail) {
			formData.isEmail = isEmail;
		}
		if (reportedId) {
			formData.reportedId = reportedId;
		}
		dispatch({ type: LOADING_ACCOUNT_REPORTS });
		axios
			.post('/getAccountReports', formData)
			.then((res) => {
				dispatch({ type: SET_ACCOUNT_REPORTS, payload: res.data });
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							getAccountReports({ reportedId, userId, retry: true }),
						);
					}
				} else {
					const errorMessage = err.response.data.error
						? err.response.data.error
						: err.message;
					dispatch({ type: ERROR_SET_ACCOUNT_REPORTS, payload: errorMessage });
				}
			});
	};

export const clearAccountReports = () => (dispatch) => {
	dispatch({ type: CLEAR_ACCOUNT_REPORTS });
};

export const postInfringement =
	({ formData, postInfring, reportedUserId, retry }) =>
	(dispatch) => {
		dispatch({
			type: LOADING_POST_INFRINGEMENT,
			payload: formData.postId,
		});

		axios
			.post('/postInfringement', formData)
			.then((res) => {
				toast.success(res.data.message, {
					position: 'bottom-left',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,

					containerId: 'app',
				});
				let response = {
					postId: formData.postId,
					infringements: formData.increment,
					reportId: formData.reportId,
				};
				if (postInfring) {
					dispatch({ type: SET_POST_INFRINGEMENT, payload: response });
				} else {
					if (formData.increment) {
						if (formData.reportCollection === 'postAppeals') {
							dispatch({ type: ADD_POST_INFRINGEMENT });
						}
						if (reportedUserId) {
							dispatch({ type: ADD_REPORTED_POST_INFRINGEMENT });
						}
					} else {
						if (formData.reportCollection === 'postAppeals') {
							dispatch({ type: REMOVE_POST_INFRINGEMENT });
						}
						if (reportedUserId) {
							dispatch({ type: REMOVE_REPORTED_POST_INFRINGEMENT });
						}
					}

					dispatch({ type: SET_POST_REPORT_INFRINGEMENT, payload: response });
				}
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							postInfringement({
								formData,
								postInfring,
								reportedUserId,
								retry: true,
							}),
						);
					}
				} else {
					const errorMessage = err.response.data.error
						? err.response.data.error
						: err.message;
					dispatch({ type: ERROR_POST_INFRINGEMENT, payload: errorMessage });
				}
			});
	};

export const commentInfringement =
	({ formData, commentInfring, reportedUserId, retry }) =>
	(dispatch) => {
		dispatch({
			type: LOADING_COMMENT_INFRINGEMENT,
			payload: formData.commentId,
		});

		axios
			.post('/commentInfringement', formData)
			.then((res) => {
				toast.success(res.data.message, {
					position: 'bottom-left',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					containerId: 'app',
				});

				let response = {
					commentId: formData.commentId,
					postId: formData.postId,
					infringements: formData.increment,
				};
				if (formData.repliedId) response.repliedId = formData.repliedId;
				// ADD_COMMENT_REPLY_INFRINGEMENT;
				if (formData.repliedId) {
					dispatch({
						type: SET_COMMENT_REPLY_REPORT_INFRINGEMENT,
						payload: response,
					});
				}
				if (commentInfring) {
					dispatch({ type: SET_COMMENT_INFRINGEMENT, payload: response });
				} else {
					if (formData.reportCollection === 'commentAppeals') {
						if (formData.increment) {
							dispatch({ type: ADD_REPORTER_COMMENT_INFRINGEMENT });
						} else {
							dispatch({ type: REMOVE_REPORTER_COMMENT_INFRINGEMENT });
						}
					} else if (reportedUserId) {
						if (formData.increment) {
							dispatch({ type: ADD_REPORTED_COMMENT_INFRINGEMENT });
						} else {
							dispatch({ type: REMOVE_REPORTED_COMMENT_INFRINGEMENT });
						}
					}

					if (!formData.repliedId) {
						dispatch({
							type: SET_COMMENT_REPORT_INFRINGEMENT,
							payload: response,
						});
					}

					if (reportedUserId) {
						dispatch({
							type: SET_COMMENT_REPORT_COLLECTION_INFRINGEMENT,
							payload: response,
						});
					}
				}
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							commentInfringement({
								formData,
								commentInfring,
								reportedUserId,
								retry: true,
							}),
						);
					}
				} else {
					const errorMessage = err.response.data.error
						? err.response.data.error
						: err.message;
					dispatch({ type: ERROR_COMMENT_INFRINGEMENT, payload: errorMessage });
				}
			});
	};

export const setAdminReportId =
	(userId, reportId, collection, postId, commentId, repliedId, infringement) =>
	(dispatch) => {
		let formData = {
			userId,
			reportId,
			collection,
			postId,
			commentId,
			repliedId,
			infringement,
		};
		dispatch({ type: SET_ADMIN_REPORT, payload: formData });
	};

export const getPostAdmin =
	({
		postId,
		postCollection,
		commentId,
		commentCollection,
		limit,
		order,
		retry,
	}) =>
	async (dispatch) => {
		let formData = {
			postId,
			postCollection,
			commentCollection,
			order,
			limit: limit,
		};
		dispatch({ type: LOADING_POST });

		let path = '/getPostAdmin';

		if (commentId) {
			path = '/getPostAndCommentAdmin';
			formData.commentId = commentId;
		}

		try {
			const postDoc = await axios.post(path, formData);
			const post = postDoc.data;

			if (post.commentNotFound) {
				toast.error('Comment not found or deleted.', {
					containerId: 'app',
					autoClose: 5000,
				});
			}

			dispatch({
				type: SET_POST,
				payload: post,
			});
			// if (post.comments && post.comments.length > 0) {
			// 	dispatch({ type: SET_ADMIN_POST_COMMENTS, payload: post.comments });
			// }

			if (retry) {
				toast.success(`Post found on ${postCollection} collection`, {
					type: 'success',
					containerId: 'app',
					autoClose: 5000,
				});
			}
		} catch (err) {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(
						getPostAdmin({
							postId,
							postCollection,
							commentId,
							commentCollection,
							limit,
							order,
							retry: true,
						}),
					);
				}
			} else {
				if (
					!retry &&
					err.message !== undefined &&
					err.response &&
					err.response.status === 404
				) {
					if (postCollection === 'posts') {
						toast.error('Not found, trying with unreleasedPosts.', {
							containerId: 'app',
							autoClose: 3000,
						});
						dispatch(
							getPostAdmin({
								postId,
								postCollection: 'unreleasedPosts',
								commentId,
								commentCollection,
								limit,
								order,
								retry: true,
							}),
						);
					} else if (postCollection === 'removedPosts') {
						toast.error('Not found, trying with removedPosts.', {
							containerId: 'app',
							autoClose: 3000,
						});
						dispatch(
							getPostAdmin({
								postId,
								postCollection: 'removedPosts',
								commentId,
								commentCollection: 'removedComments',
								limit,
								order,
								retry: true,
							}),
						);
					} else {
						dispatch({
							type: ERROR_LOADING_POST,
							payload: err.message,
						});
					}
				} else {
					dispatch({ type: ERROR_LOADING_POST, payload: err.message });
				}
			}
		}

		//aaaa
	};

export const fetchCommentAdmin =
	(commentId, commentCollection, retry) => (dispatch) => {
		let formData = {
			commentId,
			commentCollection,
		};
		dispatch({ type: FETCHING_ADMIN_COMMENTS });
		axios
			.post('/fetchCommentAdmin', formData)
			.then((res) => {
				dispatch({
					type: SET_ADMIN_COMMENTS,
					payload: res.data,
				});
			})
			.catch(async (err) => {
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							fetchCommentAdmin(commentId, commentCollection, true),
						);
					}
				} else {
					dispatch({
						type: ERROR_FETCHING_ADMIN_COMMENTS,
						payload: err.message,
					});
				}
			});
	};
export const fetchOnlyUserComments =
	(formData, action, retry) => (dispatch) => {
		if (action === 'changeOrder') {
			dispatch({ type: LOADING_NEW_ORDER });
		}
		dispatch({ type: LOADING_ONLY_USER_COMMENTS });
		axios
			.post('/fetchOnlyUserComments', formData)
			.then((res) => {
				if (formData.key) {
					dispatch({ type: SET_MORE_ONLY_USER_COMMENTS, payload: res.data });
				} else {
					dispatch({ type: SET_ONLY_USER_COMMENTS, payload: res.data });
				}
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(fetchOnlyUserComments(formData, action, true));
					}
				} else {
					const errorMessage = err.response.data.error
						? err.response.data.error
						: err.message;
					dispatch({
						type: ERROR_LOADING_ONLY_USER_COMMENTS,
						payload: errorMessage,
					});
				}
			});
	};

export const getUserDataAdmin = (formData, retry) => (dispatch) => {
	let path;

	dispatch({ type: LOADING_PROFILE });

	if (formData.postCollection) {
		path = '/getProfileAndPostsAdmin';
	} else if (formData.commentCollection) {
		path = '/fetchOnlyUserCommentsAdmin';
	}

	axios
		.post(path, formData)
		.then((res) => {
			let response;
			if (formData.postCollection) {
				response = { posts: res.data.posts, user: res.data.user };
			} else if (formData.commentCollection) {
				response = { comments: res.data.comments, user: res.data.user };
			}

			dispatch({
				type: SET_VISITED_USER_AND_DATA,
				payload: response,
			});
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(getUserDataAdmin(formData, true));
				}
			} else {
				dispatch({
					type: ERROR_SET_USER_AND_DATA,
					payload: err.message,
				});
			}
		});
};
export const getMoreProfilePostsAdmin =
	(formData, postData, retry) => (dispatch) => {
		dispatch({ type: LOADING_PROFILE_POSTS });
		axios
			.post('/getMoreProfilePostsAdmin', formData)
			.then((res) => {
				let posts = res.data.map((post) => ({
					...post,
					...postData,
				}));
				dispatch({
					type: SET_MORE_USER_AND_DATA,
					payload: posts,
				});
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(getMoreProfilePostsAdmin(formData, postData, true));
					}
				} else {
					if (err.response.data.error !== undefined) {
						dispatch({
							type: ERROR_SET_USER_POSTS,
							payload: err.response.data.error,
						});
					} else {
						dispatch({ type: ERROR_SET_USER_POSTS, payload: err.message });
					}
				}
			});
	};
// fetch more post comments from db
export const fetchMoreOnlyUserCommentsAdmin =
	(formData, retry) => (dispatch) => {
		dispatch({ type: LOADING_MORE_COMMENTS });
		axios
			.post('/fetchOnlyUserCommentsAdmin', formData)
			.then((res) => {
				dispatch({ type: SET_MORE_ADMIN_COMMENTS, payload: res.data });
			})
			.catch(async (err) => {
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(fetchMoreOnlyUserCommentsAdmin(formData, true));
					}
				} else {
					// console.log(err);
					dispatch({
						type: ERROR_SET_MORE_COMMENTS,
						payload: err.response.data.error,
					});
				}
			});
	};

export const resetUserLikedData = () => (dispatch) => {
	dispatch({
		type: RESET_LIKED_POST_DATA,
	});
	dispatch({
		type: RESET_LIKED_COMMENT_DATA,
	});
	dispatch({
		type: RESET_LIKED_REPLY_DATA,
	});
};

export const fetchUserLikedData = (formData, retry) => async (dispatch) => {
	try {
		if (formData.sortBy === 'post') {
			dispatch({ type: LOADING_LIKED_POST_DATA });
		} else if (formData.sortBy === 'comment') {
			dispatch({ type: LOADING_LIKED_COMMENT_DATA });
		} else {
			dispatch({ type: LOADING_LIKED_REPLY_DATA });
		}

		let path = formData.admin
			? '/adminFetchUserLikedData'
			: '/fetchUserLikedData';
		formData.admin && delete formData.admin;
		let res = await axios.post(path, formData);

		if (formData.key) {
			if (formData.sortBy === 'post') {
				dispatch({ type: SET_MORE_LIKED_POST_DATA, payload: res.data });
			} else if (formData.sortBy === 'comment') {
				dispatch({ type: SET_MORE_LIKED_COMMENT_DATA, payload: res.data });
			} else {
				dispatch({ type: SET_MORE_LIKED_REPLY_DATA, payload: res.data });
			}
		} else {
			if (formData.sortBy === 'post') {
				dispatch({ type: SET_LIKED_POST_DATA, payload: res.data });
			} else if (formData.sortBy === 'comment') {
				dispatch({ type: SET_LIKED_COMMENT_DATA, payload: res.data });
			} else {
				dispatch({ type: SET_LIKED_REPLY_DATA, payload: res.data });
			}
		}
	} catch (err) {
		// console.log(err);
		if (
			err.response.status === 405 ||
			(err.response.status === 401 &&
				err.response.data.error === 'Unauthorized token.') ||
			(err.response.status === 403 &&
				err.response.data.error === 'Invalid Authentication Token.')
		) {
			const tokenRefreshed = await dispatch(unauthorizedToken());
			if (tokenRefreshed && !retry) {
				return dispatch(fetchUserLikedData(formData, true));
			}
		} else {
			const errorMessage = err.response.data.error
				? err.response.data.error
				: err.message;

			if (formData.sortBy === 'post') {
				dispatch({
					type: ERROR_SET_LIKED_POST_DATA,
					payload: errorMessage,
				});
			} else if (formData.sortBy === 'comment') {
				dispatch({
					type: ERROR_SET_LIKED_COMMENT_DATA,
					payload: errorMessage,
				});
			} else {
				dispatch({
					type: ERROR_SET_LIKED_REPLY_DATA,
					payload: errorMessage,
				});
			}
		}
	}
};

export const getOnlyPostComments = (formData, retry) => (dispatch) => {
	if (formData.limit > 0) {
		dispatch({ type: LOADING_POST_COMMENTS });
		axios
			.post('/post/getOnlyPostComments', formData)
			.then((res) => {
				dispatch({ type: SET_POST_COMMENTS, payload: res.data.comments });
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(getOnlyPostComments(formData, true));
					}
				} else {
					dispatch({ type: ERROR_LOADING_POST_COMMENTS, payload: err.message });
				}
			});
	}
};
export const getOnlyPostCommentsAdmin = (formData, retry) => (dispatch) => {
	if (formData.limit > 0) {
		dispatch({ type: LOADING_ADMIN_POST_COMMENTS });
		axios
			.post('/post/getOnlyPostCommentsAdmin', formData)
			.then((res) => {
				dispatch({ type: SET_ADMIN_POST_COMMENTS, payload: res.data });
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(getOnlyPostCommentsAdmin(formData, true));
					}
				} else {
					const errorMessage = err.response.data.error
						? err.response.data.error
						: err.message;
					dispatch({
						type: ERROR_LOADING_ADMIN_POST_COMMENTS,
						payload: errorMessage,
					});
					toast.error(errorMessage, {
						containerId: 'app',
					});
				}
			});
	}
};

export const clearAdminPostComments = () => (dispatch) => {
	dispatch({ type: CLEAR_ADMIN_POST_COMMENTS });
};
export const clearLoadingCommentsErrors = () => (dispatch) => {
	dispatch({ type: CLEAR_LOADING_MORE_COMMENTS_ERRORS });
};
export const clearLoadingPostCommentsErrors = () => (dispatch) => {
	dispatch({ type: CLEAR_LOADING_POST_COMMENTS_ERRORS });
};

export const setAllEmailRecipients = (recipients) => (dispatch) => {
	dispatch({ type: SET_ALL_EMAIL_RECIPIENTS, payload: recipients });
};

export const addRecipients = (recipientObj, from) => (dispatch) => {
	if (from === 'post') {
		dispatch({ type: SET_POST_RECIPIENTS, payload: recipientObj });
	} else if (from === 'letter') {
		dispatch({ type: SET_LETTER_RECIPIENTS, payload: recipientObj });
	} else if (from === 'email') {
		dispatch({ type: SET_EMAIL_RECIPIENTS, payload: recipientObj });
	}
};
export const fetchRecipientsData =
	(recipients, from, addMore, retry) => (dispatch) => {
		if (from === 'post') {
			dispatch({ type: LOADING_POST_RECIPIENTS });
		} else if (from === 'letterRecipients') {
			dispatch({ type: LOADING_LETTER_RECIPIENTS });
		} else if (from === 'letter') {
			dispatch({ type: LOADING_LETTER_SENDERS });
		}
		let formData = {
			postRecipients: recipients,
		};

		axios
			.post('/fetchRecipientsData', formData)
			.then((res) => {
				if (from === 'post') {
					dispatch({ type: SET_POST_RECIPIENTS_DATA, payload: res.data });
				}
				if (from === 'letterRecipients') {
					dispatch({ type: SET_LETTER_RECIPIENTS_DATA, payload: res.data });
				} else if (from === 'letter' && addMore) {
					dispatch({ type: SET_MORE_LETTER_SENDERS_DATA, payload: res.data });
				} else if (from === 'letter') {
					dispatch({ type: SET_LETTER_SENDERS_DATA, payload: res.data });
				}
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							fetchRecipientsData(recipients, from, addMore, true),
						);
					}
				} else {
					if (from === 'post') {
						dispatch({
							type: ERROR_LOADING_POST_RECIPIENTS,
							payload: err.message,
						});
					} else if (from === 'letterRecipients') {
						dispatch({
							type: ERROR_LOADING_LETTER_RECIPIENTS,
							payload: err.message,
						});
					} else if (from === 'letter') {
						dispatch({
							type: ERROR_LOADING_LETTER_SENDERS,
							payload: err.message,
						});
					}
				}
			});
	};
// postToEdit.recipients.map((userId) => {
// 	let recipient = {
// 		userId: userId,
// 		handle: 'FIXHANDLE',
// 		// imageUrl: postToEdit.userImage,
// 	};
// 	return this.props.setPostRecipients(recipient);
// });

export const appeal =
	(formData, socialComponent, disabledUserAppeal, retry) => (dispatch) => {
		dispatch({ type: SUBMITTING_APPEAL });
		axios
			.post('/appeal', formData)
			.then((res) => {
				if (disabledUserAppeal) {
					dispatch(
						logoutAllTabs({
							submittingAppealSuccess: res.data.message,
						}),
					);
				} else if (socialComponent === 'post') {
					dispatch({
						type: POST_APPEAL_SUCCESFULLY_COMMITTED,
						payload: res.data.message,
					});
				} else if (socialComponent === 'comment') {
					let payload = {
						message: res.data.message,
						commentId: formData.commentId,
					};
					dispatch({
						type: COMMENT_APPEAL_SUCCESFULLY_COMMITTED,
						payload: payload,
					});
				} else if (socialComponent === 'reply') {
					let payload = {
						message: res.data.message,
						commentId: formData.commentId,
						repliedId: formData.repliedId,
					};
					dispatch({
						type: COMMENT_REPLY_APPEAL_SUCCESFULLY_COMMITTED,
						payload: payload,
					});
				}
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							appeal(formData, socialComponent, disabledUserAppeal, true),
						);
					}
				} else {
					const errorMessage = err.response.data.error
						? err.response.data.error
						: err.message;
					dispatch({
						type: ERROR_SUBMITTING_APPEAL,
						payload: errorMessage,
					});
				}
			});
	};
export const clearAppealActions = () => (dispatch) => {
	dispatch({ type: CLEAR_APPEAL_ACTIONS });
};

export const deleteCommentFromStore = (commentId, repliedId) => (dispatch) => {
	if (repliedId) {
		dispatch({
			type: DELETE_ERROR_REPLY,
			payload: { commentId: commentId, repliedId: repliedId },
		});
	} else {
		dispatch({ type: DELETE_ERROR_COMMENT, payload: commentId });
	}
};

export const updateAppealableContent = (formData, retry) => (dispatch) => {
	dispatch({ type: UPDATING_APPEALABLE_CONTENT });

	axios
		.post('/updateAppealableContent', formData)
		.then((res) => {
			dispatch({
				type: SUCCESS_UPDATING_APPEALABLE_CONTENT,
				payload: { message: res.data.message, appealable: formData.appealable },
			});
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(updateAppealableContent(formData, true));
				}
			} else {
				dispatch({
					type: ERROR_UPDATING_APPEALABLE_CONTENT,
					payload: err.message,
				});
			}
		});
};

export const getCommentReplies =
	(formData, startFresh, action, retry) => (dispatch) => {
		if (action === 'changeOrder') {
			dispatch({ type: LOADING_NEW_ORDER });
		}
		// let id = formData.commentId;
		dispatch({ type: LOADING_COMMENT_REPLIES, payload: formData.commentId });

		axios
			.post('/commentReplies', formData)
			.then((res) => {
				if (formData.key || startFresh) {
					dispatch({
						type: SET_MORE_COMMENT_REPLIES,
						payload: { commentId: formData.commentId, replies: res.data },
					});
				} else {
					dispatch({
						type: SET_COMMENT_REPLIES,
						payload: { commentId: formData.commentId, replies: res.data },
					});
				}
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							getCommentReplies(formData, startFresh, action, true),
						);
					}
				} else if (err.response.data.error === 'Reply not found.' && !retry) {
					toast.error(
						t('removed_or_deleted', {
							content: t('reply'),
						}),
						{
							containerId: 'app',
							autoClose: 5000,
						},
					);
					let newData = formData;
					delete newData.repliedId;
					return dispatch(getCommentReplies(newData, false, '', true));
				} else {
					dispatch({
						type: ERROR_LOADING_COMMENT_REPLIES,
						payload: formData.commentId,
					});
				}
			});
	};
export const getCommentRepliesAdmin =
	(formData, startFresh, action, retry) => (dispatch) => {
		if (action === 'changeOrder') {
			dispatch({ type: LOADING_NEW_ORDER });
		}
		// let id = formData.repliedId ? formData.repliedId : formData.commentId;
		dispatch({ type: LOADING_COMMENT_REPLIES, payload: formData.commentId });

		axios
			.post('/commentRepliesAdmin', formData)
			.then((res) => {
				if (formData.key || startFresh) {
					dispatch({
						type: SET_MORE_COMMENT_REPLIES,
						payload: { commentId: formData.commentId, replies: res.data },
					});
				} else {
					dispatch({
						type: SET_COMMENT_REPLIES,
						payload: { commentId: formData.commentId, replies: res.data },
					});
				}
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							getCommentRepliesAdmin(formData, startFresh, action, true),
						);
					}
				} else {
					dispatch({
						type: ERROR_LOADING_COMMENT_REPLIES,
						payload: formData.commentId,
					});
				}
			});
	};

export const clearLoadingCommentRepliesErrors = () => (dispatch) => {
	dispatch({ type: CLEAR_ERROR_LOADING_COMMENT_REPLIES });
};

export const numberFormatter = (num, digits) => {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'G' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' },
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var item = lookup
		.slice()
		.reverse()
		.find(function (item) {
			return num >= item.value;
		});
	return item
		? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
		: '0';
};

// export const isIOS = () => {
// 	const browserInfo = navigator.userAgent.toLowerCase();

// 	if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
// 		return true;
// 	}
// 	if (
// 		[
// 			'iPad Simulator',
// 			'iPhone Simulator',
// 			'iPod Simulator',
// 			'iPad',
// 			'iPhone',
// 			'iPod',
// 		].includes(navigator.platform)
// 	) {
// 		return true;
// 	}
// 	return false;
// };

export const fetchFeedIds =
	(getPosts, followingFeedIds, retry) => (dispatch) => {
		dispatch({ type: LOADING_FEED_IDS });
		axios
			.post('/fetchFeedIds')
			.then((res) => {
				dispatch({ type: SET_FEED_IDS, payload: res.data });
				const newFeedIds = res.data;

				if (getPosts && !isEqual(newFeedIds, followingFeedIds)) {
					dispatch(
						fetchPosts({
							followingFeedIds: res.data.slice(0, 10),
							resetFollowingPosts: true,
						}),
					);
				}
			})
			.catch(async (err) => {
				// console.log(err);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(fetchFeedIds(getPosts, followingFeedIds, true));
					}
				} else {
					dispatch({ type: ERROR_LOADING_FEED_IDS });
				}
			});
	};

export const getUserDetailsByUserIdAdmin = (formData, retry) => (dispatch) => {
	axios
		.post('/getUserDetailsByUserIdAdmin', formData)
		.then((res) => {
			dispatch({
				type: SET_USER_ADMIN,
				payload: res.data,
			});
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(getUserDetailsByUserIdAdmin(formData, true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;

				toast.error(errorMessage, {
					containerId: 'app',
				});
			}
		});
};

export const generateRandomId = (length) => {
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let randomId = '';

	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		randomId += characters.charAt(randomIndex);
	}

	return randomId;
};

export const setActiveVideo = (postId) => (dispatch) => {
	dispatch({ type: SET_ACTIVE_VIDEO, payload: postId });
};
export const setOpenedDialog = (bool) => (dispatch) => {
	dispatch({ type: SET_OPENED_DIALOG, payload: bool });
};
export const muteActiveVideo = () => (dispatch) => {
	dispatch({ type: MUTE_ACTIVE_VIDEO });
};

export const setDateTimer = (date, reset) => (dispatch) => {
	if (reset) {
		dispatch({ type: RESET_DATE_VALUE, payload: date });
	} else {
		dispatch({ type: SET_DATE_VALUE, payload: date });
	}
};

export const setGoodbyeLetterData = (data) => (dispatch) => {
	dispatch({
		type: SET_GOODBYE_LETTER,
		payload: data,
	});
};

export const fetchGoodbyeLetter = (letterId, read, retry) => (dispatch) => {
	if (read) {
		dispatch({ type: LOADING_RECEIVED_GOODBYE_LETTER });
	} else {
		dispatch({ type: LOADING_GOODBYE_LETTER });
	}
	let formData = {
		letterId,
	};

	axios
		.post('/fetchGoodbyeLetter', formData)
		.then((res) => {
			if (read) {
				dispatch({ type: SET_RECEIVED_GOODBYE_LETTER, payload: res.data });
			} else {
				dispatch({
					type: SET_GOODBYE_LETTER,
					payload: res.data,
				});
			}
		})
		.catch(async (err) => {
			const errorMessage = err.response.data.error
				? err.response.data.error
				: err.message;
			// console.log(errorMessage);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(fetchGoodbyeLetter(letterId, read, true));
				}
			} else if (read) {
				dispatch({
					type: ERROR_LOADING_RECEIVED_GOODBYE_LETTER,
					payload: errorMessage,
				});
			} else {
				dispatch({ type: ERROR_LOADING_GOODBYE_LETTER, payload: errorMessage });
			}
		});
};

export const clearAllLetterErrros = () => (dispatch) => {
	dispatch({
		type: CLEAR_ALL_LETTER_ERRORS,
	});
};
export const clearSuccessUpdatingGoodbyeLetter = (payload) => (dispatch) => {
	dispatch({
		type: SUCCESS_UPDATING_GOODBYE_LETTER,
		payload: payload,
	});
};
export const uploadGoodbyeLetter = (data, letterId, retry) => (dispatch) => {
	dispatch({ type: UPDATING_GOODBYE_LETTER });
	let path = '';
	let formData = {};
	if (letterId) {
		path = '/editLetter';
		formData.editedLetter = { ...data, letterId };
	} else {
		formData.letter = data;
		path = '/uploadGoodbyeLetter';
	}

	axios
		.post(path, formData)
		.then((res) => {
			let letter = data;
			const id = letterId ? letterId : res.data.letterId;
			letter.letterId = id;
			if (res.data.letterData && Object.keys(res.data.letterData).length > 0) {
				letter = { ...letter, ...res.data.letterData };
			}
			// if (res.data.sent) {
			// 	letter.sent = res.data.};
			// }
			// if (res.data.releaseDate) {
			// 	letter.releaseDate = res.data.releaseDate;
			// }
			// if (res.data.sentTo) {
			// 	letter.sentTo = res.data.sentTo;
			// }
			// if (res.data.sentToEmails) {
			// 	letter.sentToEmails = res.data.sentToEmails;
			// }
			dispatch({
				type: SUCCESS_UPDATING_GOODBYE_LETTER,
				payload: {
					message: res.data.message,
					letter: letter,
				},
			});

			toast.success(res.data.message, {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				containerId: 'app',
			});
		})
		.catch(async (err) => {
			const errorMessage = err.response.data.error
				? err.response.data.error
				: err.message;
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(uploadGoodbyeLetter(letterId, retry, true));
				}
			} else {
				dispatch({
					type: ERROR_UPDATING_GOODBYE_LETTER,
					payload: errorMessage,
				});
			}
		});
};

export const clearErrorUpdatingLetter = () => (dispatch) => {
	dispatch({ type: CLEAR_ERROR_UPDATING_GOODBYE_LETTER });
};
export const deleteGoodbyeLetter =
	(letterId, collection, retry) => (dispatch) => {
		dispatch({ type: DELETING_GOODBYE_LETTER });
		let formData = {
			letterId,
			collection,
		};
		axios
			.post('/deleteGoodbyeLetter', formData)
			.then((res) => {
				dispatch({
					type: SUCCESS_DELETING_GOODBYE_LETTER,
					payload: res.data.message,
				});
				toast.success(res.data.message, {
					position: 'bottom-left',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,

					containerId: 'app',
				});
			})
			.catch(async (err) => {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(deleteGoodbyeLetter(letterId, collection, true));
					}
				} else {
					dispatch({
						type: ERROR_DELETING_GOODBYE_LETTER,
						payload: errorMessage,
					});
				}
			});
	};

export const sendFeedback = (feedback) => () => {
	let formData = {
		body: feedback,
	};
	axios
		.post('/sendFeedback', formData)
		.then((res) => {
			toast.success(res.data.message, {
				position: 'bottom-left',
				autoClose: 8000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				containerId: 'app',
			});
		})
		.catch(async (err) => {
			// console.log(err);
		});
};

export const restoreViewed = (viewedArray) => (dispatch) => {
	dispatch({ type: RESTORE_VIEWED_POSTS, payload: viewedArray });
};
export const addViewed = (postId) => (dispatch) => {
	let formData = { postId };
	axios
		.post('/addViewed', formData)
		.then(() => {
			dispatch({ type: ADD_VIEWED_POST, payload: postId });
		})
		.catch(async (err) => {
			// console.log(err);
		});
};

export const adminFetchGoodbyeLetter =
	(letterId, collection, retry) => (dispatch) => {
		dispatch({ type: LOADING_RECEIVED_GOODBYE_LETTER });
		let formData = {
			letterId,
			collection,
		};
		axios
			.post('/adminFetchGoodbyeLetter', formData)
			.then((res) => {
				dispatch({ type: SET_RECEIVED_GOODBYE_LETTER, payload: res.data });
			})
			.catch(async (err) => {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				// console.log(errorMessage);
				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							adminFetchGoodbyeLetter(letterId, collection, true),
						);
					}
				} else {
					dispatch({
						type: ERROR_LOADING_RECEIVED_GOODBYE_LETTER,
						payload: errorMessage,
					});
				}
			});
	};

export const resetImageFile = () => (dispatch) => {
	dispatch({
		type: CLEAR_EDITED_IMG_FILE,
	});
};
export const resetAudioFile = () => (dispatch) => {
	dispatch({
		type: RESET_EDITED_AUDIO_FILE,
	});
};
export const setAudioFile = (formData) => (dispatch) => {
	dispatch({
		type: EDITED_AUDIO_FILE,
		payload: formData,
	});
};
export const clearAudioFile = () => (dispatch) => {
	dispatch({
		type: CLEAR_AUDIO_FILE,
	});
};

export const launchConfetti = (seconds) => () => {
	var duration = seconds * 1000;
	var animationEnd = Date.now() + duration;
	var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
	function randomInRange(min, max) {
		return Math.random() * (max - min) + min;
	}
	var interval = setInterval(function () {
		var timeLeft = animationEnd - Date.now();

		if (timeLeft <= 0) {
			return clearInterval(interval);
		}

		var particleCount = 69 * (timeLeft / duration);
		// since particles fall down, start a bit higher than random
		confetti(
			Object.assign({}, defaults, {
				particleCount,
				origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
			}),
		);
		confetti(
			Object.assign({}, defaults, {
				particleCount,
				origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
			}),
		);
	}, 250);
};
export const toggleMode = (mode) => (dispatch) => {
	dispatch({
		type: TOGGLE_MODE,
		payload: mode,
	});
};

export const clearEmailVerificationMessage = () => (dispatch) => {
	dispatch({
		type: CLEAR_VERIFICATION_EMAIL_SUCCESS_MESSAGE,
	});
};

export const submitErrorBoundary = (error, info) => {
	let formData = { error, info };
	axios
		.post('/reportErrorBoundary', formData)
		.then((res) => {
			localStorage.removeItem('boundaryId');
			localStorage.setItem('boundaryId', res.data.boundaryId);
		})
		.catch(async (err) => {
			// console.log(err);
		});
};

export const setQueryOrder = (data) => (dispatch) => {
	dispatch({
		type: SET_QUERY_ORDER,
		payload: data,
	});
};

export const removeDeletedComment =
	(post, commentId, repliedId) => (dispatch) => {
		let payload = {
			data: post,
			deletedCommentId: commentId,
		};
		if (repliedId) {
			payload.repliedId = repliedId;
			dispatch({ type: DELETE_COMMENT_REPLY, payload: payload });
		}
		dispatch({ type: DELETE_COMMENT, payload: payload });
	};
export const shortenDate = (dateString) => {
	// Replace 'minutes' with 'min', 'hours' with 'hr', etc.
	return dateString
		.replace('ago', '')
		.replace('seconds', 'secs')
		.replace('minutes', 'min')
		.replace('minute', 'min')
		.replace('hours', 'hr')
		.replace('hour', 'hr')
		.replace('days', 'd')
		.replace('day', 'd')
		.replace('months', 'mo')
		.replace('month', 'mo')
		.replace('years', 'yr')
		.replace('year', 'yr');
};
export const trimFileName = (str) => {
	if (str.length > 14) {
		return str.substr(0, 7) + '...' + str.substr(-7);
	}
	return str;
};

export const clearUnreleasedPosts = () => (dispatch) => {
	dispatch({ type: CLEAR_UNRELEASED_POSTS });
};
