import { Component } from 'react';
import { connect } from 'react-redux';
import {
	fetchPosts,
	fetchFeedIds,
	clearFollowingPosts,
	fetchNewPosts,
	fetchNewHashtagPosts,
	queryPostsByHashtag,
	queryHashtags,
	setFeedData,
	scrollToTopFunc,
} from '../../redux/actions/dataActions';
import { Helmet } from 'react-helmet-async';
import { Grid, Box } from '@mui/material';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import Post from '../../components/Post/Post';
import Profile from '../../components/UserFeedProfile/Profile';
import { Navigate, Outlet } from 'react-router-dom';
import PostSkeleton from '../../components/Skeletons/PostSkeleton';
import { withRouter } from '../../redux/withRouter';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import UploadFileControls from '../../components/UploadFileActions/UploadFileControls';
import PullToRefresh from 'react-simple-pull-to-refresh';
import CustomButton from '../../components/SocialComponents/CustomButton';
import GroupIcon from '@mui/icons-material/Group';
import PublicIcon from '@mui/icons-material/Public';
// import Ads from '../../components/SocialComponents/Ads';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import {
	CircularProgress,
	FormControl,
	Grow,
	InputLabel,
	MenuItem,
	Select,
	Tab,
	Tabs,
} from '@mui/material';
import AutocompleteHashtag from '../../components/SocialComponents/AutocompleteHashtag';
import Media from 'react-media';
import { t } from 'i18next';

class Feed extends Component {
	state = {
		order: 'desc',
	};
	componentDidMount() {
		let props = this.props;
		window.addEventListener('scroll', this.handleScroll);

		const scrollPosition = localStorage.getItem('scrollPosition');
		if (scrollPosition) {
			window.scrollTo(0, scrollPosition);
		}
		if (
			!props.user.loadingLogout &&
			props.user.authenticated &&
			props.user.emailVerified &&
			props.data.posts.length === 0
		) {
			props.fetchPosts({});
		}

		if (props.data.filteredPosts.length > 0) {
			this.setState({
				order: props.data.hashtagOrder,
			});
		}

		if (
			this.props.user.credentials.followingUserIds &&
			this.props.user.credentials.followingUserIds.length === 0 &&
			this.props.data.followingPosts.length > 0
		) {
			this.props.clearFollowingPosts();
		}
	}

	handleScroll = () => {
		localStorage.setItem('scrollPosition', window.scrollY);
	};

	componentWillUnmount() {
		// Remove event listener when component is unmounted
		window.removeEventListener('scroll', this.handleScroll);
	}

	static getDerivedStateFromProps(nextProps, state) {
		if (nextProps.location.state?.hashtag) {
			nextProps.history(nextProps.location.pathname, {});
			if (nextProps.location.state?.hashtag !== nextProps.data.lastHashtag) {
				let queryData = {
					hashtag: nextProps.location.state?.hashtag,
					order: 'desc',
				};
				nextProps.queryPostsByHashtag(
					queryData,
					nextProps.data.filteredPosts.length > 0,
				);
				nextProps.setFeedData({
					showFeedFilterOptions: true,
					lastHashtag: nextProps.location.state?.hashtag,
					hashtagOption: nextProps.location.state?.hashtag,
					lastHashtagCount: 0,
				});
			}
			let update = { feedCollection: 'filtered' };

			if (nextProps.data.filteredPosts.length > 0) {
				update.showFeedFilterOptions = false;
			} else {
				update.showFeedFilterOptions = true;
			}
			nextProps.setFeedData(update);

			scrollToTopFunc('instant');

			return {
				order: 'desc',
			};
		}

		if (
			nextProps.user.followingCount > 0 &&
			nextProps.data.feedCollection === 'following' &&
			nextProps.data.followingFeedIds.length > 0 &&
			nextProps.data.followingPosts.length === 0 &&
			!nextProps.data.loadingFollowingPosts
		) {
			nextProps.fetchPosts({
				followingFeedIds: nextProps.data.followingFeedIds.slice(0, 10),
			});
		}
		return null;
	}

	getMorePosts = (lastPostKey) => {
		if (this.props.data.feedCollection === 'posts') {
			this.props.fetchPosts({ key: lastPostKey });
		} else if (this.props.data.feedCollection === 'following') {
			if (
				this.props.data.followingFeedIds.length >
				this.props.data.followingPosts.length
			) {
				this.props.fetchPosts({
					followingFeedIds: this.props.data.followingFeedIds.slice(
						this.props.data.followingPosts.length + 1,
						this.props.data.followingPosts.length + 11,
					),
				});
			}
		} else if (
			this.props.data.feedCollection === 'filtered' &&
			this.props.data.hashtagOption
		) {
			let queryData = {
				hashtag: this.props.data.hashtagOption,
				order: this.state.order,
				key: lastPostKey,
			};
			this.props.queryPostsByHashtag(queryData);
		}
	};

	refresh = async () => {
		let formData = {};
		if (this.props.data.feedCollection === 'posts') {
			await this.props.fetchNewPosts(this.props.data.posts[0].releaseDate);
		}
		if (this.props.data.feedCollection === 'following') {
			await this.props.fetchNewPosts(false, this.props.data.posts[0].postId);
		}
		if (this.props.data.feedCollection === 'filtered') {
			formData = {
				key: this.props.data.filteredPosts[0].releaseDate,
				hashtag: this.props.data.hashtagOption,
			};
			await this.props.fetchNewHashtagPosts(formData);
		}
	};

	setFilterOptions = () => {
		let update = { feedCollection: 'filtered' };
		if (this.props.data.filteredPosts.length > 0) {
			update.showFeedFilterOptions = !this.props.data.showFeedFilterOptions;
		} else {
			update.showFeedFilterOptions = true;
		}
		if (
			this.props.data.showFeedFilterOptions !== update.showFeedFilterOptions
		) {
			this.props.setFeedData(update);
		}
	};
	handleChange = (e) => {
		e.preventDefault();
		if (e.target.value === 'asc' || e.target.value === 'desc') {
			this.setState({
				order: e.target.value,
			});
		}
	};
	handlePanelChange = (e, value) => {
		if (
			value !== '' &&
			value !== this.props.data.feedCollection &&
			(value === 'posts' || value === 'filtered' || value === 'following')
		) {
			this.props.setFeedData({
				feedCollection: value,
				showFeedFilterOptions: false,
			});

			if (
				value === 'following' &&
				this.props.user.credentials.followingCount > 0 &&
				!this.props.data.errorLoadingFollowingFeedIds &&
				!this.props.data.loadingFollowingPosts &&
				!this.props.data.loadingFollowingFeedIds
			) {
				if (
					this.props.data.followingFeedIds.length > 0 &&
					this.props.data.followingPosts.length === 0
				) {
					this.props.fetchPosts({
						followingFeedIds: this.props.data.followingFeedIds.slice(0, 10),
					});
				} else {
					this.props.fetchFeedIds(true, this.props.data.followingFeedIds);
				}
			}
		}
	};

	filter = () => {
		if (this.props.data.feedCollection === 'filtered') {
			this.props.setFeedData({
				showFeedFilterOptions: false,
			});
		} else {
			this.props.setFeedData({ feedCollection: 'filtered' });
		}

		let state = this.state;
		if (this.props.data.hashtagOption && state.order) {
			let queryData = {
				hashtag: this.props.data.hashtagOption,
				order: state.order,
			};

			this.props.queryPostsByHashtag(
				queryData,
				this.props.data.filteredPosts.length > 0,
			);
			this.props.setFeedData({
				lastHashtag: this.props.data.hashtagOption,
				lastHashtagCount: this.props.data.lastHashtagCount,
			});
		}
	};

	render() {
		const {
			user: { authenticated, emailVerified },
			data: {
				posts,
				filteredPosts,
				followingPosts,
				loadingPosts,
				loadingFilteredPosts,
				loadingFollowingPosts,
				loadingNewFilteredPosts,
				loadingNewPosts,
				loadingNewFollowingPosts,
				errorLoadingPosts,
				errorLoadingFilteredPosts,
				feedCollection,
				showFeedFilterOptions,
				hashtagOption,
				loadingFollowingFeedIds,
				followingFeedIds,
				errorLoadingFollowingFeedIds,
			},
			UI: { mode },
		} = this.props;
		const { order } = this.state;
		const collection =
			feedCollection === 'following'
				? followingPosts
				: feedCollection === 'filtered'
				? filteredPosts
				: posts;
		const postLoader =
			feedCollection === 'following'
				? loadingFollowingPosts
				: feedCollection === 'filtered'
				? loadingFilteredPosts
				: loadingPosts;

		const error =
			feedCollection === 'following'
				? errorLoadingFollowingFeedIds
				: feedCollection === 'filtered'
				? errorLoadingFilteredPosts
				: errorLoadingPosts;

		const lastPostKey = collection[collection.length - 1]?.releaseDate;

		let feedPosts =
			!postLoader || collection.length > 0 ? (
				<InfiniteScroll
					dataLength={collection.length} //This is important field to render the next data
					scrollThreshold={0.7} // next will be called when user comes below 70% of the total height.
					next={() => this.getMorePosts(lastPostKey)}
					hasMore={
						(feedCollection === 'posts' && !errorLoadingPosts) ||
						(feedCollection === 'filtered' && !errorLoadingFilteredPosts) ||
						(feedCollection === 'following' && !errorLoadingFollowingFeedIds)
					}
					loader={
						postLoader && <PostSkeleton number={3} darkMode={mode === 'dark'} />
					}
					id='infiniteScrollUserFeed'
					endMessage={
						feedCollection === 'following' && (
							<p className='center bold'>{t('you_have_seen_it_all')}</p>
						)
					}
				>
					{feedCollection === 'following' &&
						loadingFollowingPosts &&
						followingPosts.length > 0 && (
							<PostSkeleton number={1} darkMode={mode === 'dark'} />
						)}
					{collection &&
						collection.map((post, index) => (
							<div key={index}>
								{/* {index > 0 && index % 5 === 0 && (
									<div>
										{index.toString()[0] % 2 === 0 ? (
											<Ads currentPath='feed' type='feed' />
										) : (
											<Ads currentPath='feed' type='article' />
										)}
									</div>
								)} */}
								<Post key={post.postId} post={post} />
							</div>
						))}
				</InfiniteScroll>
			) : (
				<PostSkeleton number={10} darkMode={mode === 'dark'} />
			);

		return (
			<div>
				<Helmet>
					<title>Goodbye App</title>
				</Helmet>
				<Media
					queries={{
						isMobile: '(max-width: 1064px)',
					}}
				>
					{(matches) => (
						<div>
							{authenticated && !emailVerified ? (
								<Navigate to='/verification' />
							) : authenticated ? (
								<div>
									<div className='userFeed__Container'>
										<Grid container>
											<Grid item md={3}></Grid>

											<Grid
												item
												xs={12}
												sm={12}
												md={6}
												className='centerPosts__MediaQuery'
											>
												<PullToRefresh
													onRefresh={this.refresh}
													isPullable={
														collection.length > 0 &&
														((feedCollection === 'posts' && !loadingNewPosts) ||
															(feedCollection === 'following' &&
																!loadingNewFollowingPosts) ||
															(feedCollection === 'filtered' &&
																!loadingNewFilteredPosts))
													}
													refreshingContent={
														<div id='circularProgressQuery'>
															<CircularProgress
																variant='indeterminate'
																id='queryUserAuthProgress'
																size={30}
																thickness={3}
															/>
														</div>
													}
													pullDownThreshold={50}
													resistance={1}
													pullingContent={
														<h3 className='center' id='mgt'>
															{t('pull_to_refresh')}
														</h3>
													}
												>
													<div
														className={`center ${matches.isMobile ? 'pdX' : ''}
															`}
														// 	${
														// 	uploadingPost ||
														// 	errorUploadingPost ||
														// 	uploadingImageProgress !== null ||
														// 	uploadingAudioProgress !== null ||
														// 	uploadingVideoProgress !== null ||
														// 	errorUploadingImage !== null ||
														// 	errorUploadingAudio !== null ||
														// 	errorUploadingVideo !== null
														// 		? ''
														// 		: 'flex'
														// }
													>
														<UploadFileControls feed />

														{/* <CustomButton
															onClick={this.setFilterOptions}
															btnText='Filter'
															variant='contained'
															id={
																showFeedFilterOptions
																	? 'filterButton'
																	: 'customCancelButton'
															}
															margin='1rem auto 0 auto'
															startIcon={<TuneIcon />}
														/> */}
													</div>

													<hr className='mg0 mg3px' />

													<Tabs
														value={feedCollection}
														onChange={this.handlePanelChange}
														centered
														selectionFollowsFocus
														variant='fullWidth'
														// fullWidth
													>
														<Tab
															sx={{ minHeight: '35px' }}
															id='tabs'
															icon={matches.isMobile ? '' : <PublicIcon />}
															iconPosition='start'
															label={t('world')}
															value='posts'
														/>
														{/* {
														(loadingFilteredPosts ||
															feedCollection === 'filtered' ||
															filteredPosts.length > 0) && ( */}
														<Tab
															sx={{ minHeight: '35px' }}
															id='tabs'
															icon={<TuneIcon />}
															iconPosition='start'
															// label='Filtered'
															value='filtered'
															onClick={
																feedCollection === 'filtered'
																	? this.setFilterOptions
																	: null
															}
														/>
														{/* )} */}

														<Tab
															sx={{ minHeight: '35px' }}
															icon={<GroupIcon />}
															id='tabs'
															iconPosition='start'
															label={
																<div className='flex'>
																	{followingFeedIds.length}
																	{loadingFollowingFeedIds && (
																		<div
																			style={{
																				position: 'absolute',
																				right: '6px',
																			}}
																		>
																			<CircularProgress
																				variant='indeterminate'
																				size={15}
																				thickness={3}
																			/>
																		</div>
																	)}
																</div>
															}
															value='following'
														/>
													</Tabs>

													{showFeedFilterOptions && (
														<div className='pdbExcept'>
															<AutocompleteHashtag feed />
															<Grow in={true} id='mgt'>
																<FormControl fullWidth size='small'>
																	<InputLabel>{t('order')}</InputLabel>
																	<Select
																		labelId='Notifications filter order options.'
																		value={order}
																		label={t('order')}
																		onChange={this.handleChange}
																	>
																		<MenuItem value='desc'>
																			{t('newest_on_top')}
																		</MenuItem>
																		<MenuItem value='asc'>
																			{t('first_on_top')}
																		</MenuItem>
																	</Select>
																</FormControl>
															</Grow>

															<CustomButton
																startIcon={<SearchIcon id='mediumIconBtn' />}
																onClick={this.filter}
																fullWidth
																btnText={t('search')}
																variant={
																	hashtagOption === '' ? 'text' : 'outlined'
																}
																margin='1rem 0'
																borderRadius='0'
																disabled={hashtagOption === ''}
															/>
														</div>
													)}
													{feedCollection === 'following' &&
														followingFeedIds.length === 0 && (
															<CustomAlerts
																success={true}
																message={t('following_posts_message')}
															/>
														)}
													{feedPosts}

													<CustomAlerts
														info={error}
														margin='0 0 1rem'
														alertId='smallMuiAlert'
													/>
												</PullToRefresh>
											</Grid>
											<Box
												component={Grid}
												item
												md={3}
												display={{
													xs: 'none',
													sm: 'none',
													md: 'block',
												}}
											>
												<Grid item>
													<div className='profile__Container'>
														<Profile />
													</div>
												</Grid>
											</Box>
										</Grid>
									</div>
								</div>
							) : (
								<Navigate to='/login' />
							)}
							<Outlet />
						</div>
					)}
				</Media>
				<div className='navMobileDiv'></div>
			</div>
		);
	}
}

Feed.propTypes = {
	fetchPosts: PropTypes.func.isRequired,
	fetchFeedIds: PropTypes.func.isRequired,
	clearFollowingPosts: PropTypes.func.isRequired,
	fetchNewPosts: PropTypes.func.isRequired,
	fetchNewHashtagPosts: PropTypes.func.isRequired,
	queryPostsByHashtag: PropTypes.func.isRequired,
	queryHashtags: PropTypes.func.isRequired,
	setFeedData: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
};

const mapActionsToProps = {
	fetchPosts,
	fetchFeedIds,
	clearFollowingPosts,
	fetchNewPosts,
	fetchNewHashtagPosts,
	queryPostsByHashtag,
	queryHashtags,
	setFeedData,
};
const mapStateToProps = (state) => ({
	data: state.data,
	user: state.user,
	UI: state.UI,
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Feed));
