import { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import EditDetails from './EditDetails';
import FollowButton from '../SocialComponents/FollowButton';
import {
	CircularProgress,
	Dialog,
	Grow,
	LinearProgress,
	Skeleton,
	Zoom,
} from '@mui/material';
import LocationOn from '@mui/icons-material/LocationOn';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CustomAlerts from '../SocialComponents/CustomAlerts';
import DottedMenu from '../SocialComponents/DottedMenu';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CalendarToday from '@mui/icons-material/CalendarToday';
import ImgSrc from '../SocialComponents/ImgSrc';
import { noImgUrl, numberFormatter } from '../../redux/actions/dataActions';
import { clearErrorUploadingImage } from '../../redux/actions/userActions';
import { connect } from 'react-redux';
import { withRouter } from '../../redux/withRouter';
import Media from 'react-media';
import CustomButton from '../SocialComponents/CustomButton';
import { useTranslation } from 'react-i18next';

const StaticProfile = ({
	profile,
	user,
	data,
	UI,
	clearErrorUploadingImage,
	history,
	params,
}) => {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const seeFollowers = (location) => {
		if (!user.adminPanel.adminPanelActive) {
			history(location);
		}
	};

	const expandImg = () => {
		setExpanded(!expanded);
	};

	const {
		handle,
		thumbnail,
		createdAt,
		imageUrl,
		biography,
		quote,
		name,
		location,
		followerCount,
		followingCount,
		isFollowingBack,
		userId,
		imageColor,
		admin,
		disabled,
		appealable,
	} = profile;
	const {
		credentials,
		uploadingUserDetails,
		adminPanel: { adminPanelActive },
	} = user;
	const {
		resetingImg,
		loadingProfile,
		adminReportCollection,
		setAccountReports: { reportedId },
	} = data;
	const { errorImageUpload, uploadProgress, errorResetImage } = UI;
	const paramsHandle = params.handle;
	const ownProfile = handle === credentials.handle;

	return (
		<Media
			queries={{
				larger900: '(min-width: 901px)',
				isMobile: '(max-width: 900px)',
			}}
		>
			{(matches) => (
				<div className='center fullWidth mgt2'>
					{adminPanelActive &&
					!loadingProfile &&
					Object.keys(profile).length === 0 ? (
						<div>
							<h3>Awaiting your query</h3>
						</div>
					) : (!ownProfile && handle !== params.handle && loadingProfile) ||
					  (handle !== paramsHandle && loadingProfile) ||
					  (adminPanelActive && loadingProfile) ? (
						<div className='flex center'>
							<Skeleton
								variant='circular'
								width={matches.isMobile ? '35vw' : '260px'}
								height={matches.isMobile ? '40.1vw' : '300px'}
							/>
						</div>
					) : (
						<div className='staticProfileContainer'>
							<div
								className={`staticSection1 ${
									(biography || quote || location || name) && matches.isMobile
										? 'textStart'
										: ''
								}`}
							>
								<div className='staticAction'>
									{matches.larger900 && (
										<div className='username-sp notranslate mgb'>
											{admin ? (
												<VerifiedUserIcon
													className='bottomTranslate3'
													id='usernameIcon'
												/>
											) : (
												<AccountCircleRoundedIcon
													className='bottomTranslate3'
													id='usernameIcon'
												/>
											)}{' '}
											{handle}
										</div>
									)}

									{location && (
										<div className='mgYH'>
											<LocationOn
												className='detailsIcon bottomTranslate3'
												fontSize='small'
											/>{' '}
											<span>{location}</span>
										</div>
									)}

									<div className='mgYH'>
										<CalendarToday
											className='detailsIcon bottomTranslate3 mgr3px'
											fontSize='small'
										/>{' '}
										<span>
											{t('joined')} {dayjs(createdAt).format('MMMM, YYYY')}
										</span>
									</div>

									<div id='profileAction'>
										{ownProfile ? (
											<EditDetails profile />
										) : !adminPanelActive ? (
											<FollowButton
												isFollowingBackBool={isFollowingBack}
												userHandle={handle}
												profile
												userId={userId}
												disabled={disabled}
											/>
										) : null}
									</div>
								</div>
							</div>
							<div className='staticSection2'>
								<ImgSrc
									onClick={expandImg}
									img={imageUrl}
									imgColor={imageColor}
									handle={handle}
									thumbnail={thumbnail}
									css='imgMainProfile'
									stronger
									fontSize={matches.isMobile ? '8vw' : '80px'}
								/>
								{expanded && (
									<Dialog
										open={true}
										fullWidth
										onClose={expandImg}
										className='center'
										TransitionComponent={Zoom}
									>
										<CustomAlerts
											info={
												<span className='capitalize bold notranslate'>
													{name ? name : handle}
												</span>
											}
											icon={false}
											noMargin
										/>
										<ImgSrc
											img={imageUrl}
											handle={handle}
											thumbnail={thumbnail}
											imgColor={noImgUrl() === imageUrl ? imageColor : '#'}
											css={
												noImgUrl() === imageUrl
													? 'noImgMainProfileExpanded'
													: 'imgMainProfileExpanded'
											}
											noMargin
											stronger
											fontSize='5vw'
										/>
										{ownProfile && (
											<div className='imgDialogEdit'>
												<EditDetails profile expanded />
											</div>
										)}
										<CustomAlerts
											info={`${t('joined')} ${dayjs(createdAt).format(
												'MMMM D, YYYY',
											)}`}
											icon={<AllInclusiveIcon />}
											noMargin
											severity='gray'
											alertId='smallMuiAlert'
										/>
									</Dialog>
								)}
								{matches.isMobile ? (
									<div className='mgt'>
										<span className='name-sp notranslate flex alignItemsCenter center'>
											{!name && (
												<AccountCircleRoundedIcon
													className='bottom2LeftTranslate6'
													fontSize='medium'
												/>
											)}
											{name ? (
												<span className='capitalize'>{name}</span>
											) : (
												handle
											)}
										</span>
										{name && (
											<div className='username-sp-mobile notranslate mgtH '>
												{admin ? (
													<VerifiedUserIcon
														className='mgr4px'
														id='usernameIcon'
													/>
												) : (
													<AccountCircleRoundedIcon
														className='mgr4px'
														id='usernameIcon'
													/>
												)}
												{handle}
											</div>
										)}
									</div>
								) : name ? (
									<div className='pdbExcept'>
										<span className='name-sp notranslate capitalize'>
											{name}
										</span>
									</div>
								) : (
									<div className='pdY'></div>
								)}

								{biography && (
									<div
										className={`mgtH ${
											matches.isMobile ? 'textStart pdl' : 'text'
										}`}
									>
										<FingerprintIcon
											className='detailsIcon bottomTranslate3 '
											fontSize='small'
										/>{' '}
										<span>{biography}</span>
									</div>
								)}

								{quote && (
									<div
										className={`mgtH ${
											matches.isMobile ? 'textStart pdl' : 'text'
										}`}
									>
										<FormatQuoteIcon
											className='detailsIcon bottomTranslate3'
											fontSize='small'
										/>{' '}
										<span>{quote}</span>
									</div>
								)}

								{ownProfile && (
									<div>
										{uploadingUserDetails && uploadProgress === null && (
											<LinearProgress id='mgb' />
										)}
										{uploadProgress !== null && !resetingImg && (
											<Grow
												in={uploadProgress !== null}
												style={{ transformOrigin: 'top' }}
												{...(uploadProgress !== null ? { timeout: 800 } : {})}
											>
												<div className='flex alignItemsCenter'>
													<div className='linearProgressP'>
														<LinearProgress
															variant='indeterminate'
															value={uploadProgress}
														/>
													</div>
													<div>
														<p className='linearProgressNumb'>
															{uploadProgress}%
														</p>
													</div>
												</div>
											</Grow>
										)}
										{resetingImg && (
											<Grow
												in={resetingImg}
												style={{ transformOrigin: 'top' }}
												{...(resetingImg ? { timeout: 800 } : {})}
											>
												<div className='flex alignItemsCenter mgt2'>
													<div className='linearProgressP'>
														<CircularProgress color='inherit' />
														<p>{t('deleting_image')}</p>
													</div>
												</div>
											</Grow>
										)}
										<CustomAlerts
											error={errorImageUpload}
											close
											onClose={clearErrorUploadingImage}
										/>
										<CustomAlerts error={errorResetImage} close />
									</div>
								)}
							</div>
							<div className='staticSection3'>
								<div className='staticFollowers center'>
									<CustomButton
										onClick={() => seeFollowers('followers')}
										variant='text'
										btnText={`${
											followerCount > 10000
												? numberFormatter(followerCount, 1)
												: followerCount
										} ${followerCount === 1 ? t('follower') : t('followers')}`}
										id='bold'
										margin={
											ownProfile && matches.isMobile
												? '0'
												: matches.isMobile
												? '0.3rem 0 0'
												: '0.5rem 0'
										}
										textTransform='capitalize'
									/>
									<CustomButton
										onClick={() => seeFollowers('following')}
										variant='text'
										btnText={`${
											followingCount > 10000
												? numberFormatter(followingCount, 1)
												: followingCount
										} ${t('following')}`}
										id='bold'
										margin={
											ownProfile && matches.isMobile
												? '0'
												: matches.isMobile
												? '0.3rem 0 0'
												: '0 0 0.5rem 0'
										}
										textTransform='capitalize'
									/>

									<DottedMenu
										dataHandle={handle}
										dataUserId={userId}
										profile={true}
										infringements={
											adminReportCollection === 'userAppeals'
												? disabled
												: reportedId?.disabled
												? reportedId?.disabled
												: false
										}
										disabled={disabled}
										appealable={appealable}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</Media>
	);
};

StaticProfile.propTypes = {
	profile: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	clearErrorUploadingImage: PropTypes.func.isRequired,
	history: PropTypes.func.isRequired,
	params: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI,
	data: state.data,
});

const mapActionsToProps = {
	clearErrorUploadingImage,
};

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(StaticProfile));
