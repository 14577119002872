import {
	SET_USER,
	ERROR_FOLLOWER_ACTION,
	IMG_UPLOAD_PROGRESS,
	LOADING_USER,
	MARK_ONE_NOTIFICATION_READ,
	FOLLOW_USER,
	UNFOLLOW_USER,
	ALLOW_NOTIFICATIONS,
	NOTIFICATION_REDIRECT_URL,
	SET_RESETED_IMAGE,
	ERROR_IMG_UPLOAD,
	ERROR_SET_RESETED_IMAGE,
	UPLOADING_USER_DETAILS,
	SET_USER_DETAILS,
	ERROR_SET_USER_DETAILS,
	ERROR_LOADING_POST,
	EMAIL_SUCCESFULLY_UPDATED,
	UPDATE_EMAIL_ACCOUNT_ACTION,
	UPDATE_PASSWORD_ACCOUNT_ACTION,
	UPDATE_USERNAME_ACCOUNT_ACTION,
	UPDATING_USERNAME_ACCOUNT_ACTION,
	USERNAME_UPDATED_SUCCESFULLY,
	ERROR_UPDATING_USERNAME_ACCOUNT_ACTION,
	CHECKING_USERNAME_AVAILABILTY,
	USERNAME_IS_AVAILABLE,
	ERROR_CHECKING_USERNAME_AVAILABILTY,
	CLEAR_CONFIG_SUCCESS,
	LOADING_FOLLOWER_ACTION_ID,
	DELETING_USER_DATA,
	DELETED_USER_DATA_SUCCESFULLY,
	ERROR_DELETING_USER_DATA,
	REPORTING_ISSUE,
	REPORTING_ISSUE_SUCCESS,
	ERROR_REPORTING_ISSUE,
	CLEAR_REPORT,
	SET_ADMIN_DATA,
	LOADING_ADMIN_DATA,
	LOADING_ACCOUNT_ACTION,
	SET_ACCOUNT_ACTION,
	ERROR_SET_ACCOUNT_ACTION,
	SET_ADMIN_PANEL_DATA,
	CLEAR_NEW_EMAIL_ACTIONS,
	CLEAR_ACCOUNT_ACTION_TYPES,
	CLEAR_ACCOUNT_RESTRICTION,
	SENDING_MESSAGE,
	SENDING_MESSAGE_SUCCESS,
	ERROR_SENDING_MESSAGE,
	CLEAR_MESSAGE_ACTIONS,
	LOADING_UNSENT_EMAILS,
	SET_UNSENT_EMAILS_SUCCESS,
	ERROR_LOADING_UNSENT_EMAILS,
	UPDATE_VISITED_PROFILE,
	FCM_DATA,
	LOADING_NEW_NOTIFICATION_PREFERENCE,
	REPORTING_FEATURE,
	REPORTING_FEATURE_SUCCESS,
	ERROR_REPORTING_FEATURE,
	SETTING_INACTIVITY_INFO,
	LOGGING_OUT_ALL_USER_SESSIONS,
	CLEAR_ERROR_IMG_UPLOAD,
	LOADING_AUDIO_SENDER_DETAILS,
	ERROR_LOADING_AUDIO_SENDER_DETAILS,
	SET_AUDIO_SENDER_DETAILS,
	SET_PROFILE_IMAGE,
	SET_DISABLED_USER,
	VERIFY_EMAIL_FIRST,
	REPORTING_SUPPORT,
	REPORTING_SUPPORT_SUCCESS,
	ERROR_REPORTING_SUPPORT,
	SET_MICROPHONE_ACCESS,
	SET_CAMERA_ACCESS,
	DENY_MESSAGING,
	CLEAR_AUTH_ERRORS,
	CLEAR_AUTH_MESSAGES,
} from '../types';
import axios from 'axios';
import {
	fetchNotificationToken,
	logoutAllTabs,
	refreshAllTabs,
	setUserListener,
	setUserNotificationListener,
	unauthorizedToken,
	unsubscribeListeners,
} from '../../firebase/firebaseInit';
import { toast } from 'react-toastify';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import history from '../../browserHistory';
import {
	fetchFeedIds,
	getFollowers,
	getPreviewHashtags,
	getProfilePosts,
	// isIOS,
	noImgUrl,
} from './dataActions';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { t } from 'i18next';

export const downloadPostFile =
	({ dataHandle, postId, postCollection, userId, email, file2, retry }) =>
	(dispatch) => {
		const toastIdDownload = toast.loading(t('preparing_file'), {
			position: 'bottom-left',
			containerId: 'app',
		});

		let queryData = {
			postId,
			postCollection,
		};
		axios
			.post('/post', queryData)
			.then((res) => {
				let post = res.data;

				if (
					post.fileUrl &&
					post.postType !== 'text' &&
					(post.userId === userId ||
						(post.livePost &&
							post.recipients &&
							post.recipients.includes(userId)) ||
						(post.livePost &&
							post.emailRecipients &&
							post.emailRecipients.includes(email)))
				) {
					const storage = getStorage();
					let file = file2 ? post.fileUrl2 : post.fileUrl;
					return getDownloadURL(ref(storage, file));
				} else {
					toast.update(toastIdDownload, {
						position: 'bottom-left',
						type: 'error',
						isLoading: false,
						autoClose: 0,
						containerId: 'app',
					});
					dispatch({
						type: ERROR_LOADING_POST,
						payload: t('denied_download_file'),
					});
					return;
				}
			})
			.then((url) => {
				if (url) {
					const xhr = new XMLHttpRequest();
					xhr.responseType = 'blob';
					xhr.onload = (event) => {
						const blob = xhr.response;

						var file = new File([blob], `${dataHandle}_GoodbyeApp`, {
							type: blob.type,
						});
						//grab the a tag
						var link = document.createElement('a');
						//set the download attribute of the a tag to the name stored in the file
						link.download = file.name;
						//generate a temp url to host the image for download
						link.href = URL.createObjectURL(file);
						link.click();
					};

					toast.update(toastIdDownload, {
						position: 'bottom-left',
						render: t('downloading'),
						type: 'success',
						isLoading: false,
						autoClose: 3000,
						containerId: 'app',
						icon: <CheckCircleIcon id='iconGreen' />,
					});
					xhr.open('GET', url);
					xhr.send();
				}
				return;
			})
			.catch(async (err) => {
				// console.log(err);
				toast.update(toastIdDownload, {
					position: 'bottom-left',
					render: t('err_download'),
					type: 'error',
					isLoading: false,
					autoClose: 6000,
					containerId: 'app',
				});

				if (
					err.response.status === 405 ||
					(err.response.status === 401 &&
						err.response.data.error === 'Unauthorized token.') ||
					(err.response.status === 403 &&
						err.response.data.error === 'Invalid Authentication Token.')
				) {
					const tokenRefreshed = await dispatch(unauthorizedToken());
					if (tokenRefreshed && !retry) {
						return dispatch(
							downloadPostFile({
								dataHandle,
								postId,
								postCollection,
								userId,
								file2,
								retry: true,
							}),
						);
					}
				} else {
					switch (err.code) {
						case 'storage/object-not-found':
							// console.log(err.message);
							// File doesn't exist
							break;
						case 'storage/unauthorized':
							// console.log(err.message);

							// User doesn't have permission to access the object
							break;
						case 'storage/canceled':
							// User canceled the upload
							// console.log(err.message);

							break;
						// ...
						case 'storage/unknown':
							// console.log(err.message);
							// Unknown error occurred, inspect the server response
							break;
						default:
							break;
					}
				}
			});
	};

export const getMinUserDetails = (userId) => (dispatch) => {
	dispatch({ type: LOADING_AUDIO_SENDER_DETAILS });
	axios
		.post('/getMinUserDetails', { userId })
		.then((res) => {
			dispatch({ type: SET_AUDIO_SENDER_DETAILS, payload: res.data });
		})
		.catch((err) => {
			const errorMessage = err.response.data
				? err.response.data.error
				: err.message;
			dispatch({
				type: ERROR_LOADING_AUDIO_SENDER_DETAILS,
				payload: errorMessage,
			});
		});
};
// getting likes notifications and credentials from logged in user
export const getUserData = (condition, userData, retry) => (dispatch) => {
	if (condition === 'loadUser' || condition === 'loadNewUser') {
		dispatch({ type: LOADING_USER, payload: true });
	}
	axios
		.post('/user/profile')
		.then((res) => {
			let credentials = res.data;

			if (credentials.disabled) {
				if (credentials.appealable && credentials.appeal) {
					credentials.canRecoverLetter = credentials.canRecoverLetter.sort(
						(a, b) => b.releaseDate.localeCompare(a.releaseDate),
					);
					credentials.letters = credentials.letters.sort((a, b) =>
						b.releaseDate.localeCompare(a.releaseDate),
					);
					credentials.unreleasedLetters = credentials.unreleasedLetters
						.slice()
						.reverse();
					dispatch({
						type: SET_USER,
						payload: { credentials },
					});
					history.replace('/appeal');
				} else {
					dispatch(
						logoutAllTabs({
							disabledMessage: t('account_disabled', {
								handle: credentials.handle,
							}),
							appeal: credentials.appeal,
							appealable: credentials.appealable,
						}),
					);
				}
			} else if (new Date(credentials.disabledFor) > Date.now()) {
				let formData = {};
				const sHandle = credentials.handle;
				const upercaseHandle =
					sHandle.charAt(0).toUpperCase() + sHandle.slice(1);
				formData.disabledMessage = t('account_disabled', {
					handle: upercaseHandle,
				});
				formData.disabledFor = t('disabled_for', {
					date: dayjs(credentials.disabledFor).fromNow(),
				});

				dispatch(logoutAllTabs(formData));
			} else {
				if (condition === 'loadUser' || condition === 'loadNewUser') {
					dispatch(setUserListener(credentials.userId));
					dispatch(setUserNotificationListener(credentials.userId));
					dispatch(fetchNotificationToken());
					dispatch(refreshAllTabs(true));
					let followingCount = credentials.followingCount;
					let followerCount = credentials.followerCount;
					let data = {
						user: credentials.handle,
					};
					if (condition === 'loadNewUser') {
						history.replace(`/users/${credentials.handle}`);
					}
					if (credentials.unreleasedPostCount > 0) {
						dispatch(
							getProfilePosts({
								userId: credentials.userId,
								postType: 'unreleasedPost',
								order: 'desc',
								// limit: 5,
							}),
						);
					}
					// if (credentials.postCount > 0) {
					// 	dispatch(
					// 		getProfilePosts({
					// 			postType: 'livePost',
					// 			order: 'desc',
					// 			// limit: 5,
					// 		}),
					// 	);
					// }
					dispatch(getPreviewHashtags());
					if (followingCount > 0) {
						data.limit = followingCount >= 5 ? 5 : followingCount;
						dispatch(getFollowers(data, 'tagList', true));
						dispatch(fetchFeedIds());
					} else if (followerCount > 0) {
						data.limit = followerCount >= 5 ? 5 : followerCount;
						dispatch(getFollowers(data, 'tagList', false));
					}

					if (
						userData &&
						credentials.email.toLowerCase() !== userData.email.toLowerCase() &&
						userData.isEmail
					) {
						//user has a new verified authenticated email
						dispatch(updateUserEmail(userData.email, credentials.email));
					}
				}

				if (credentials.admin) {
					dispatch(getAdminCollection());
				}

				credentials.canRecoverLetter = credentials.canRecoverLetter.sort(
					(a, b) => b.releaseDate.localeCompare(a.releaseDate),
				);
				credentials.letters = credentials.letters.sort((a, b) =>
					b.releaseDate.localeCompare(a.releaseDate),
				);
				credentials.unreleasedLetters = credentials.unreleasedLetters
					.slice()
					.reverse();
				dispatch({
					type: SET_USER,
					payload: { credentials: { ...credentials } },
				});
			}
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(getUserData(condition, userData, true));
				}
			} else if (err.response.status === 403) {
				dispatch(logoutAllTabs());
			} else {
				dispatch({ type: LOADING_USER, payload: false });
			}
		});
};

export const clearErrorUploadingImage = () => (dispatch) => {
	dispatch({ type: CLEAR_ERROR_IMG_UPLOAD });
};

export const uploadProfileImage = (formData, retry) => (dispatch) => {
	// for (const entry of formData.entries()) {
	// 	console.log(entry);
	// }
	dispatch({ type: IMG_UPLOAD_PROGRESS, payload: 0 });
	axios
		.post('/user/image', formData, {
			onUploadProgress: (data) => {
				let percentage = Math.round((data.loaded / data.total) * 100);
				dispatch({ type: IMG_UPLOAD_PROGRESS, payload: percentage });
			},
		})
		.then((res) => {
			dispatch({ type: IMG_UPLOAD_PROGRESS, payload: null });
			dispatch({ type: SET_PROFILE_IMAGE, payload: res.data });
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(uploadProfileImage(formData, true));
				}
			} else {
				const errorMessage = err.response.data
					? err.response.data.error
					: err.message;
				dispatch({
					type: ERROR_IMG_UPLOAD,
					payload: errorMessage,
				});
			}
		});
};

export const resetImage = (formData, retry) => (dispatch) => {
	dispatch({ type: IMG_UPLOAD_PROGRESS, payload: 0 });

	axios
		.post('/user/defaultImage', formData, {
			onUploadProgress: (data) => {
				let percentage = Math.round((data.loaded / data.total) * 100);
				dispatch({ type: IMG_UPLOAD_PROGRESS, payload: percentage });
			},
		})
		.then(() => {
			let imgUrl = noImgUrl();
			dispatch({ type: SET_RESETED_IMAGE, payload: imgUrl });
			dispatch({ type: IMG_UPLOAD_PROGRESS, payload: null });
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(resetImage(formData, true));
				}
			} else {
				const errorMessage = err.response.data
					? err.response.data.error
					: err.message;
				dispatch({
					type: ERROR_SET_RESETED_IMAGE,
					payload: errorMessage,
				});
				toast.error(
					<div className='toastDiv'>
						<h4>{t('err_deleting_img')}</h4>
						<h5>{errorMessage}</h5>
					</div>,
					{
						containerId: 'B',
					},
				);
			}
		});
};

export const editUserDetails = (userDetails, retry) => (dispatch) => {
	dispatch({
		type: UPLOADING_USER_DETAILS,
	});
	axios
		.post('/user/details', userDetails)
		.then((res) => {
			dispatch({
				type: SET_USER_DETAILS,
				payload: res.data,
			});
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(editUserDetails(userDetails, true));
				}
			} else {
				const errorMessage = err.response.data
					? err.response.data.error
					: err.message;
				dispatch({
					type: ERROR_SET_USER_DETAILS,
					payload: errorMessage,
				});
				toast.error(
					<div className='toastDiv'>
						<h4>{t('err_editing_details')}</h4>
						<h5>{errorMessage}</h5>
					</div>,
					{
						containerId: 'B',
					},
				);
			}
		});
};

let notsToRead = [];
export const markNotificationsRead =
	(notificationIds, action, groupedNotificationIds) => (dispatch) => {
		if (action === 'markOneNotRead') {
			dispatch({
				type: MARK_ONE_NOTIFICATION_READ,
				payload: notificationIds[0],
			});
			notsToRead.push(notificationIds[0]);

			if (groupedNotificationIds) {
				notsToRead.concat(
					groupedNotificationIds.filter((id) => !notsToRead.includes(id)),
				);
			}
		} else {
			let newArr = notsToRead.concat(notificationIds);
			if (newArr.length > 0) {
				notsToRead = [];
				axios
					.post('/notifications', newArr)
					.then((res) => {
						return;
					})
					.catch((err) => {
						// console.log(err)
					});
			}
		}
	};
export const setAuthorizationHeader = (token) => () => {
	const FBIdToken = `Bearer ${token}`;
	localStorage.setItem('FBIdToken', FBIdToken);
	axios.defaults.headers.common['Authorization'] = FBIdToken;
};

export const follow = (userId, location, retry) => (dispatch) => {
	dispatch({ type: LOADING_FOLLOWER_ACTION_ID, payload: userId });
	let formData = {
		userId: userId,
	};
	axios
		.post('/user/follow', formData)
		.then((res) => {
			dispatch({
				type: FOLLOW_USER,
				payload: res.data,
			});

			if (location === 'users') {
				dispatch({
					type: UPDATE_VISITED_PROFILE,
					payload: { user: res.data },
				});
			}
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(follow(userId, location, true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				toast.error(errorMessage, {
					containerId: 'app',
				});
				dispatch({ type: ERROR_FOLLOWER_ACTION, payload: userId });
			}
		});
};
export const unfollow = (userId, location, retry) => (dispatch) => {
	dispatch({ type: LOADING_FOLLOWER_ACTION_ID, payload: userId });

	let formData = {
		userId: userId,
	};

	axios
		.post('/user/unfollow', formData)
		.then((res) => {
			dispatch({
				type: UNFOLLOW_USER,
				payload: res.data,
			});
			if (location === 'users') {
				dispatch({
					type: UPDATE_VISITED_PROFILE,
					payload: { user: res.data },
				});
			}
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(unfollow(userId, location, true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				toast.error(errorMessage, {
					containerId: 'app',
				});
				dispatch({ type: ERROR_FOLLOWER_ACTION, payload: userId });
			}
		});
};

export const setNotificationToken = (token) => () => {
	return axios
		.post('/user/notificationToken', { token: token })
		.then((res) => {
			return;
		})
		.catch((err) => {
			// console.log(err);
		});
};

export const denyBackgroundMessaging = () => (dispatch) => {
	dispatch({
		type: DENY_MESSAGING,
		payload: true,
	});
};

export const allowNots = (boolean) => (dispatch) => {
	dispatch({
		type: ALLOW_NOTIFICATIONS,
		payload: boolean,
	});
};
export const setNotificationRedirect = (notification) => (dispatch) => {
	dispatch({ type: NOTIFICATION_REDIRECT_URL, payload: notification });
};

export const updateUserEmail = (newEmail, email) => (dispatch) => {
	let formData = {
		newEmail: newEmail,
		email: email,
	};
	axios
		.post('/updateNewUserEmail', formData)
		.then((res) => {
			let data = {
				email: newEmail,
				message: res.data.message,
			};
			dispatch({ type: EMAIL_SUCCESFULLY_UPDATED, payload: data });
			history.replace('/settings', { expanded: 'emailAddressPanel' });
		})
		.catch((err) => {
			// console.log(err);
		});
};
export const setNewEmailToUpdate = () => (dispatch) => {
	dispatch({ type: UPDATE_EMAIL_ACCOUNT_ACTION });
	history.replace('/accountActions');
};
export const setNewPasswordToUpdate = (email) => (dispatch) => {
	dispatch({ type: UPDATE_PASSWORD_ACCOUNT_ACTION, payload: email });
	history.replace('/accountActions');
};
export const setNewUsernameToUpdate = (username) => (dispatch) => {
	dispatch({ type: UPDATE_USERNAME_ACCOUNT_ACTION, payload: username });
	history.replace('/accountActions');
};
export const checkUsernameAvailability = (handle, retry) => (dispatch) => {
	dispatch({ type: CHECKING_USERNAME_AVAILABILTY, payload: handle });
	let formData = {
		handle: handle,
	};

	axios
		.post('/checkUsernameAvailability', formData)
		.then((res) => {
			dispatch({
				type: USERNAME_IS_AVAILABLE,
				payload: res.data.message,
			});
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(checkUsernameAvailability(handle, true));
				}
			} else {
				dispatch({
					type: ERROR_CHECKING_USERNAME_AVAILABILTY,
					payload: err.response.data.message,
				});
			}
		});
};
export const changeUsername = (handle, retry) => (dispatch) => {
	dispatch({ type: UPDATING_USERNAME_ACCOUNT_ACTION, payload: handle });
	let formData = {
		handle: handle,
	};

	axios
		.post('/changeUsername', formData)
		.then((res) => {
			dispatch({
				type: USERNAME_UPDATED_SUCCESFULLY,
				payload: {
					message: res.data.message,
					nextUsernameChange: res.data.nextUsernameChange,
					handle: handle,
				},
			});
			history.replace('/settings', { expanded: 'usernamePanel' });
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(changeUsername(handle, true));
				}
			} else {
				dispatch({
					type: ERROR_UPDATING_USERNAME_ACCOUNT_ACTION,
					payload: err.response.data.error,
				});
			}
		});
};
export const clearSuccessMessages = (component) => (dispatch) => {
	if (component === 'config') {
		dispatch({ type: CLEAR_CONFIG_SUCCESS });
	}
};

export const deleteUser = (retry) => (dispatch) => {
	unsubscribeListeners();
	dispatch({ type: DELETING_USER_DATA });
	const toastAction = toast.loading(t('deleting_account'), {
		position: 'bottom-left',
		containerId: 'app',
	});
	axios
		.delete('/deleteUser')
		.then((res) => {
			dispatch({
				type: DELETED_USER_DATA_SUCCESFULLY,
				payload: res.data.message,
			});
			toast.update(toastAction, {
				position: 'bottom-left',
				render: t('account_delete_success'),
				type: 'success',
				isLoading: false,
				autoClose: 6000,
				closeOnClick: true,
				containerId: 'app',
			});
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(deleteUser(true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				dispatch({ type: ERROR_DELETING_USER_DATA, payload: errorMessage });
				toast.update(toastAction, {
					position: 'bottom-left',
					render: errorMessage,
					type: 'error',
					isLoading: false,
					autoClose: 6000,
					containerId: 'app',
				});
			}
		});
};

export const reportIssue = (formData, auth, retry) => (dispatch) => {
	dispatch({ type: REPORTING_ISSUE });
	const path = auth ? '/reportIssueAuth' : '/reportIssue';
	axios
		.post(path, formData)
		.then((res) => {
			dispatch({
				type: REPORTING_ISSUE_SUCCESS,
				payload: res.data.message,
			});
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(reportIssue(formData, auth, true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				dispatch({
					type: ERROR_REPORTING_ISSUE,
					payload: errorMessage,
				});
			}
		});
};
export const support = (formData, auth, retry) => (dispatch) => {
	dispatch({ type: REPORTING_SUPPORT });
	const path = auth ? '/supportAuth' : '/support';

	axios
		.post(path, formData)
		.then((res) => {
			dispatch({
				type: REPORTING_SUPPORT_SUCCESS,
				payload: res.data.message,
			});
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(support(formData, auth, true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				dispatch({
					type: ERROR_REPORTING_SUPPORT,
					payload: errorMessage,
				});
			}
		});
};
export const reportFeature = (formData, retry) => (dispatch) => {
	dispatch({ type: REPORTING_FEATURE });
	axios
		.post('/reportFeature', formData)
		.then((res) => {
			dispatch({
				type: REPORTING_FEATURE_SUCCESS,
				payload: res.data.message,
			});
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(reportFeature(formData, true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				dispatch({
					type: ERROR_REPORTING_FEATURE,
					payload: errorMessage,
				});
			}
		});
};

export const clearReport = () => (dispatch) => {
	dispatch({ type: CLEAR_REPORT });
};

export const getAdminCollection = (retry) => (dispatch) => {
	dispatch({ type: LOADING_ADMIN_DATA });
	axios
		.post('/getAdminCollection')
		.then((res) => {
			dispatch({ type: SET_ADMIN_DATA, payload: res.data });
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(getAdminCollection(true));
				}
			}
		});
};

export const setAdminPanelData = (formData) => (dispatch) => {
	dispatch({ type: SET_ADMIN_PANEL_DATA, payload: formData });
};
export const adminResetImage = (formData, retry) => (dispatch) => {
	const toastAction = toast.loading(t(t('updating')), {
		position: 'bottom-left',
		containerId: 'app',
	});
	axios
		.post('/admin/defaultImage', formData)
		.then((res) => {
			toast.update(toastAction, {
				position: 'bottom-left',
				render: 'Image reseted.',
				type: 'success',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(adminResetImage(formData, true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;

				toast.update(toastAction, {
					position: 'bottom-left',
					render: errorMessage,
					type: 'error',
					isLoading: false,
					autoClose: 5000,
					containerId: 'app',
				});
			}
		});
};
export const disableAccount = (formData, retry) => (dispatch) => {
	dispatch({ type: LOADING_ACCOUNT_ACTION });
	axios
		.post('/disableAccount', formData)
		.then((res) => {
			dispatch({
				type: SET_ACCOUNT_ACTION,
				payload: { message: res.data.message, accountRestriction: true },
			});
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(disableAccount(formData, true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				dispatch({
					type: ERROR_SET_ACCOUNT_ACTION,
					payload: errorMessage,
				});
			}
		});
};
export const enableAccount = (formData, retry) => (dispatch) => {
	dispatch({ type: LOADING_ACCOUNT_ACTION });
	axios
		.post('/enableAccount', formData)
		.then((res) => {
			dispatch({
				type: SET_ACCOUNT_ACTION,
				payload: { message: res.data.message, accountRestriction: false },
			});
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(enableAccount(formData, true));
				}
			} else {
				const errorMessage = err.response.data.error
					? err.response.data.error
					: err.message;
				dispatch({
					type: ERROR_SET_ACCOUNT_ACTION,
					payload: errorMessage,
				});
			}
		});
};
export const clearAccountRestriction = () => (dispatch) => {
	dispatch({ type: CLEAR_ACCOUNT_RESTRICTION });
};
export const clearAccountActionTypes = () => (dispatch) => {
	dispatch({ type: CLEAR_ACCOUNT_ACTION_TYPES });
};

export const clearNewEmailData = () => (dispatch) => {
	dispatch({ type: CLEAR_NEW_EMAIL_ACTIONS });
};

export const clearVerifyEmailFirst = () => (dispatch) => {
	dispatch({
		type: VERIFY_EMAIL_FIRST,
		payload: '',
	});
};
export const setCameraAccess = (bool) => (dispatch) => {
	dispatch({
		type: SET_CAMERA_ACCESS,
		payload: bool,
	});
};
export const setMicrophoneAccess = (bool) => (dispatch) => {
	dispatch({
		type: SET_MICROPHONE_ACCESS,
		payload: bool,
	});
};

export const createMessage = (formData, retry) => (dispatch) => {
	dispatch({ type: SENDING_MESSAGE });
	axios
		.post('/createMessage', formData)
		.then((res) => {
			dispatch({ type: SENDING_MESSAGE_SUCCESS, payload: res.data.message });
		})
		.catch(async (err) => {
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(createMessage(formData, true));
				}
			} else {
				dispatch({ type: ERROR_SENDING_MESSAGE, payload: err.message });
			}
		});
};

export const clearMessageAction = () => (dispatch) => {
	dispatch({ type: CLEAR_MESSAGE_ACTIONS });
};
export const clearDisabledUser = () => (dispatch) => {
	dispatch({ type: SET_DISABLED_USER, payload: {} });
};

export const unsentEmails = (formData, retry) => (dispatch) => {
	dispatch({ type: LOADING_UNSENT_EMAILS });
	axios
		.post('/retrySendingMails', formData)
		.then((res) => {
			dispatch({
				type: SET_UNSENT_EMAILS_SUCCESS,
				payload: res.data.message,
			});
		})
		.catch(async (err) => {
			// console.log(err);
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(unsentEmails(formData, true));
				}
			} else {
				const errorMessage = err.response.data
					? err.response.data.error
					: err.message;
				dispatch({
					type: ERROR_LOADING_UNSENT_EMAILS,
					payload: errorMessage,
				});
				toast.error(errorMessage, {
					containerId: 'app',
				});
			}
		});
};

export const setSpammedUserAdmin = (formData) => () => {
	const toastAction = toast.loading('Setting spammed data.', {
		position: 'bottom-left',
		containerId: 'app',
	});
	axios
		.post('/setSpammedUserAdmin', formData)
		.then((res) => {
			toast.update(toastAction, {
				position: 'bottom-left',
				render: res.data.message,
				type: 'success',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		})
		.catch((err) => {
			// console.log(err);
			toast.update(toastAction, {
				position: 'bottom-left',
				render: err.message,
				type: 'error',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		});
};

export const updateUserFields = (formData) => () => {
	const toastAction = toast.loading(t('updating'), {
		position: 'bottom-left',
		containerId: 'app',
	});
	axios
		.post('/updateUserFields', formData)
		.then((res) => {
			toast.update(toastAction, {
				position: 'bottom-left',
				render: res.data.message,
				type: 'success',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		})
		.catch((err) => {
			toast.update(toastAction, {
				position: 'bottom-left',
				render: err.message,
				type: 'error',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		});
};

export const updateUserUid = (formData) => () => {
	const toastAction = toast.loading(t('updating'), {
		position: 'bottom-left',
		containerId: 'app',
	});
	axios
		.post('/updateUserUid', formData)
		.then((res) => {
			toast.update(toastAction, {
				position: 'bottom-left',
				render: res.data.message,
				type: 'success',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		})
		.catch((err) => {
			// console.log(err);
			toast.update(toastAction, {
				position: 'bottom-left',
				render: err.response.data.error,
				type: 'error',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		});
};

export const setFCMData = (formData) => (dispatch) => {
	dispatch({ type: FCM_DATA, payload: formData });
};
export const setNotificationPreferences = (formData, retry) => (dispatch) => {
	dispatch({ type: LOADING_NEW_NOTIFICATION_PREFERENCE, payload: true });

	axios
		.post('/setNotificationPreferences', formData)
		.then((res) => {
			if (formData.disabledPush !== null) {
				dispatch({ type: FCM_DATA, payload: formData });
			}
			toast.success(res.data.message, {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				containerId: 'app',
				toastId: 'setNotificationPreferences',
			});
		})
		.catch(async (err) => {
			// console.log(err);
			dispatch({ type: LOADING_NEW_NOTIFICATION_PREFERENCE, payload: false });
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(setNotificationPreferences(formData, true));
				}
			} else {
				const errorMessage = err.response.data
					? err.response.data.error
					: err.message;
				toast.error(errorMessage, {
					position: 'bottom-left',
					autoClose: 10000,
					toastId: 'setNotificationPreferences',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					containerId: 'app',
				});
			}
		});
};

export const setInactivityData = (formData, retry) => (dispatch) => {
	dispatch({ type: SETTING_INACTIVITY_INFO, payload: true });

	axios
		.post('/setInactivityData', formData)
		.then((res) => {
			dispatch({ type: SETTING_INACTIVITY_INFO, payload: false });
			if (formData.message !== undefined) {
			}
			toast.success(res.data.message, {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				containerId: 'app',
				toastId: 'setInactivityData',
			});
		})
		.catch(async (err) => {
			// console.log(err);
			dispatch({ type: SETTING_INACTIVITY_INFO, payload: false });
			if (
				err.response.status === 405 ||
				(err.response.status === 401 &&
					err.response.data.error === 'Unauthorized token.') ||
				(err.response.status === 403 &&
					err.response.data.error === 'Invalid Authentication Token.')
			) {
				const tokenRefreshed = await dispatch(unauthorizedToken());
				if (tokenRefreshed && !retry) {
					return dispatch(setInactivityData(formData, true));
				}
			} else {
				const errorMessage = err.response.data
					? err.response.data.error
					: err.message;
				toast.error(errorMessage, {
					containerId: 'app',
				});
			}
		});
};

export const revokeAllTokenSessionsAdmin = (formData) => (dispatch) => {
	const toastAction = toast.loading('Revoking all sessions.', {
		position: 'bottom-left',
		containerId: 'app',
	});
	axios
		.post('/revokeAllTokenSessionsAdmin', formData)
		.then((res) => {
			toast.update(toastAction, {
				position: 'bottom-left',
				render: res.data.message,
				type: 'success',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		})
		.catch((err) => {
			// console.log(err);
			toast.update(toastAction, {
				position: 'bottom-left',
				render: err.message,
				type: 'error',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		});
};

export const logoutAllSessions = () => (dispatch) => {
	dispatch({ type: LOGGING_OUT_ALL_USER_SESSIONS, payload: true });
	axios
		.post('/logoutAllSessions')
		.then((res) => {
			dispatch({ type: LOGGING_OUT_ALL_USER_SESSIONS, payload: false });
			toast.success(res.data.message, {
				position: 'bottom-left',
				autoClose: 8000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				containerId: 'C',
			});
		})
		.catch((err) => {
			// console.log(err);
			dispatch({ type: LOGGING_OUT_ALL_USER_SESSIONS, payload: false });

			const errorMessage = err.response.data.error
				? err.response.data.error
				: err.message;
			toast.error(errorMessage, {
				containerId: 'C',
			});
		});
};
export const logoutAllSessionsAdmin = (formData) => () => {
	const toastAction = toast.loading('Logging out all sessions.', {
		position: 'bottom-left',
		containerId: 'app',
	});
	axios
		.post('/logoutAllSessionsAdmin', formData)
		.then((res) => {
			toast.update(toastAction, {
				position: 'bottom-left',
				render: res.data.message,
				type: 'success',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		})
		.catch((err) => {
			// console.log(err);
			toast.update(toastAction, {
				position: 'bottom-left',
				render: err.message,
				type: 'error',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		});
};

export const setAdminPriviledge = (formData) => () => {
	const toastAction = toast.loading('Setting priviledge', {
		position: 'bottom-left',
		containerId: 'app',
	});
	axios
		.post('/setAdminPriviledge', formData)
		.then((res) => {
			toast.update(toastAction, {
				position: 'bottom-left',
				render: res.data.message,
				type: 'success',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		})
		.catch((err) => {
			// console.log(err);
			toast.update(toastAction, {
				position: 'bottom-left',
				render: err.message,
				type: 'error',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		});
};
export const reduceUserDetailsAdmin = (formData) => () => {
	const toastAction = toast.loading('Updating user details', {
		position: 'bottom-left',
		containerId: 'app',
	});
	axios
		.post('/reduceUserDetailsAdmin', formData)
		.then((res) => {
			toast.update(toastAction, {
				position: 'bottom-left',
				render: res.data.message,
				type: 'success',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		})
		.catch((err) => {
			// console.log(err);
			toast.update(toastAction, {
				position: 'bottom-left',
				render: err.message,
				type: 'error',
				isLoading: false,
				autoClose: 5000,
				containerId: 'app',
			});
		});
};

export const clearAuthErrors = () => (dispatch) => {
	dispatch({ type: CLEAR_AUTH_ERRORS });
};

export const clearAuthMessages = () => (dispatch) => {
	dispatch({ type: CLEAR_AUTH_MESSAGES });
};
