import gbaLogo from '../../util/Images/gbaLogo.png';
import logo from '../../util/Images/logo.svg';
export default function FooterLetterLogo({ mode }) {
	return (
		<div className={`${mode === 'dark' && 'letterFooter'} pdl`}>
			<div className='flex spaceBetween alignItemsCenter'>
				<div className='backgroundLogo mgr'>
					<img className='logo' src={logo} alt='Logo' />
				</div>
				<img
					className={`navbarGBLogo ${mode !== 'dark' && 'invert'}`}
					src={gbaLogo}
					alt=''
				/>
			</div>
		</div>
	);
}
