import { Component } from 'react';
import CustomAlerts from './CustomAlerts';
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import { t } from 'i18next';

export default class DataNotFound extends Component {
	render() {
		let error = this.props.error;
		return (
			<div>
				<CustomAlerts error={error} noMargin />
				<CustomAlerts
					info={this.props.PostDialog}
					message={t('content_deleted', {
						content: t('post'),
					})}
					noMargin
					icon={<BlurOnRoundedIcon />}
				/>
			</div>
		);
	}
}
