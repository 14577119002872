import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HttpsIcon from '@mui/icons-material/Https';
import MailIcon from '@mui/icons-material/Mail';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ConfirmationDialog from '../SocialComponents/ConfirmationDialog';
import SecurityIcon from '@mui/icons-material/Security';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import {
	setNewEmailToUpdate,
	setNewPasswordToUpdate,
	setNewUsernameToUpdate,
	clearSuccessMessages,
	setNotificationPreferences,
	setInactivityData,
	logoutAllSessions,
} from '../../redux/actions/userActions';
import {
	logoutAllTabs,
	isMessagingSupported,
} from '../../firebase/firebaseInit';
import {
	deleteRecipients,
	clearQueriedUsers,
	sendFeedback,
	dim,
} from '../../redux/actions/dataActions';
import Confetti from '../SocialComponents/Confetti';
import PasswordIcon from '@mui/icons-material/Password';
import ChatIcon from '@mui/icons-material/Chat';
import AnimatedText from 'react-animated-text-content';
import TimerCountdown from '../PostTimer/TimerCountdown';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import {
	Alert,
	Badge,
	FormControl,
	FormControlLabel,
	LinearProgress,
	MenuItem,
	Select,
} from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import { withRouter } from '../../redux/withRouter';
import CustomButton from '../SocialComponents/CustomButton';
import { Helmet } from 'react-helmet-async';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LogoutIcon from '@mui/icons-material/Logout';
import CustomInput from '../SocialComponents/CustomInput';
import CustomAlerts from '../SocialComponents/CustomAlerts';
import Switch from '../SocialComponents/Switch';
import SendIcon from '@mui/icons-material/Send';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import IOSlider from '../SocialComponents/Slider';
import Footer from '../Footer/Footer';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import TimerIcon from '@mui/icons-material/Timer';
import Media from 'react-media';
import { toast } from 'react-toastify';
import NotificationPermission from '../SocialComponents/NotificationPermission';
import { t } from 'i18next';
class Settings extends Component {
	state = {
		expanded: false,
		changeUsernameDialog: false,
		changeEmailDialog: false,
		changePasswordDialog: false,
		animationEmail: false,
		animationUsername: false,
		deleteUserAccountDialog: false,
		receiveNotifications: '',
		inactivityThreshold: 45,
		feedback: '',
		toggleInactivity: false,
		logoutAllSessionsDialog: false,
		messagingSupported: false,
	};
	componentDidMount() {
		if (this.props.location.state?.expanded) {
			this.changePanel(this.props.location.state?.expanded);
		}

		if (this.props.isMessagingSupported()) {
			this.setState({ messagingSupported: true });
			if (
				typeof Notification !== 'undefined' &&
				Notification.permission === 'granted' &&
				!this.props.user.credentials.disabledPush
			) {
				this.setState({ receiveNotifications: 'allAppNotifications' });
			} else {
				this.setState({ receiveNotifications: 'inAppNotifications' });
			}
		} else {
			this.setState({ receiveNotifications: 'inAppNotifications' });
		}
	}

	changePanel = (panel) => {
		if (this.props.user.emailUpdatedSuccess && !this.state.animationEmail) {
			this.setState({
				animationEmail: true,
			});
		}
		if (
			this.props.user.newUsernameUpdatedSuccess &&
			!this.state.animationUsername
		) {
			this.setState({
				animationUsername: true,
			});
		}

		if (
			panel === 'inactivityPanel' &&
			this.props.user.credentials.inactivityThreshold
		) {
			if (
				this.props.user.credentials.inactivityThreshold !==
				this.state.inactivityThreshold
			) {
				this.setState({
					toggleInactivity: true,
					inactivityThreshold: this.props.user.credentials.inactivityThreshold,
				});
			}
		}

		this.setState({
			expanded: this.state.expanded !== panel ? panel : false,
		});
	};

	handleChange = (e) => {
		e.preventDefault();

		if (
			this.props.user.allowNotifications &&
			e.target.value === 'allAppNotifications'
		) {
			this.setState({ receiveNotifications: e.target.value });
		} else if (e.target.value === 'inAppNotifications') {
			this.setState({ receiveNotifications: 'inAppNotifications' });
		} else {
			this.setState({
				[e.target.name]: e.target.value,
			});
		}
	};

	handleConfirmationDialog = (boolean, dialog) => {
		if (dialog === 'changeUsernameDialog') {
			this.setState({ changeUsernameDialog: boolean });
		}
		if (dialog === 'changeEmailDialog') {
			this.setState({ changeEmailDialog: boolean });
		}
		if (dialog === 'changePasswordDialog') {
			this.setState({ changePasswordDialog: boolean });
		}
		if (dialog === 'deleteUserAccountDialog') {
			this.setState({ deleteUserAccountDialog: boolean });
		}

		if (dialog === 'logoutAllSessions') {
			this.setState({ logoutAllSessionsDialog: boolean });
		}
	};

	handleSetNewUsernameToUpdate = () => {
		this.props.setNewUsernameToUpdate(this.props.user.credentials.handle);
	};

	handleSetNewEmailToUpdate = () => {
		this.props.setNewEmailToUpdate();
	};

	handleSetNewPasswordToUpdate = () => {
		this.props.setNewPasswordToUpdate(this.props.user.credentials.email);
	};

	handleSetNewAccountToDelete = () => {
		if (this.state.feedback !== '') {
			this.props.sendFeedback(this.state.feedback);
		}

		this.props.logoutAllTabs({
			deleteAccount: this.props.user.credentials.handle,
			signInEmail: this.props.user.credentials.email,
		});
	};

	componentWillUnmount() {
		if (
			this.props.user.emailUpdatedSuccess !== null ||
			this.props.user.newUsernameUpdatedSuccess !== null
		) {
			this.props.clearSuccessMessages('config');
		}
	}

	updateNotPreferences = () => {
		let bool =
			this.state.receiveNotifications === 'inAppNotifications' ? true : false;
		this.props.setNotificationPreferences({ disabledPush: bool });
	};

	moveSlider = (e, newValue) => {
		if (newValue !== this.state.inactivityThreshold) {
			this.setState({ inactivityThreshold: newValue });
		}
	};

	delRecipient = (recipient) => {
		this.props.deleteRecipients(recipient);
	};

	handleSwitch = (e, query) => {
		if (query === 'toggleInactivity') {
			if (this.state.toggleInactivity) {
				this.setInactivity('reset');
			} else {
				this.setState((prevState) => ({
					toggleInactivity: !prevState.toggleInactivity,
				}));
			}
		} else if (query === 'popupNotifications') {
			let bool = this.props.user.credentials.popupNotifications;
			this.props.setNotificationPreferences({ popupNotifications: !bool });
		}
	};

	scrollIntoView = () => {
		const element = document.getElementById('searchbar');
		element.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
			inline: 'start',
		});
	};

	logoutAllSessions = () => {
		this.setState({ logoutAllSessionsDialog: false });
		this.props.logoutAllSessions();
	};

	setInactivity = (action) => {
		let formData = {};
		if (action === 'reset') {
			this.setState({
				toggleInactivity: false,
				inactivityThreshold: 45,
			});
			formData.deactivate = true;
		} else if (
			this.state.inactivityThreshold !==
			this.props.user.credentials.inactivityThreshold
		) {
			formData.inactivityThreshold = this.state.inactivityThreshold;
		} else {
			this.setState({
				expanded: false,
			});
			toast.success(t('all_up_to_date'), {
				isLoading: false,
				autoClose: 6000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				progress: undefined,
				containerId: 'app',
				toastId: 'setInactivity',
			});
		}

		if (Object.values(formData).length > 0) {
			this.props.setInactivityData(formData);
		}
	};
	render() {
		const {
			expanded,
			changeEmailDialog,
			changePasswordDialog,
			changeUsernameDialog,
			animationEmail,
			animationUsername,
			deleteUserAccountDialog,
			receiveNotifications,
			inactivityThreshold,
			toggleInactivity,
			feedback,
			logoutAllSessionsDialog,
		} = this.state;
		const {
			user: {
				credentials: {
					handle,
					email,
					nextUsernameChange,
					userId,
					inactivityRelease,
					popupNotifications,
					notPreferencesSetToday,
					inactivityInfoSetToday,
					unreleasedPostCount,
					unreleasedLetters,
				},
				allowNotifications,
				emailUpdatedSuccess,
				allowUsernameChange,
				newUsernameUpdatedSuccess,
				fcmData,
				loadingNewNotificationPreference,
				logginOutAllUserSessions,
				settingInactivity,
			},
		} = this.props;

		return (
			<div>
				<Helmet>
					<title>Settings - Goodbye App</title>
				</Helmet>

				<Media
					queries={{
						isMobile: '(max-width: 1064px)',

						semiSmall: '(max-width: 450px)',
						small: '(max-width: 312px)',
					}}
				>
					{(matches) => (
						<div className='skyBackground'>
							<div className='pdAccordion'>
								<Alert
									severity='info'
									icon={<SettingsIcon id='iconSettings' />}
									sx={{ fontSize: '1.4rem', borderRadius: 0 }}
								>
									{t('settings')}
								</Alert>

								{emailUpdatedSuccess || newUsernameUpdatedSuccess ? (
									<Confetti duration={1} />
								) : null}
								<Accordion
									slotProps={{ transition: { unmountOnExit: true } }}
									expanded={expanded === 'usernamePanel'}
									onChange={() => this.changePanel('usernamePanel')}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls='usernamePanelbh-content'
									>
										<Typography
											sx={{
												width: matches.semiSmall ? '100%' : '33%',
											}}
											id='typographySettings'
										>
											<AccountCircleRoundedIcon id='iconAccordion' />
											{t('username')}
										</Typography>
										{!matches.semiSmall && (
											<b className='notranslate' id='typographySecondary'>
												{newUsernameUpdatedSuccess && !animationUsername ? (
													<AnimatedText
														type='chars' // animate words or chars
														animationType='lights'
														interval={0.06}
														duration={0.8}
													>
														{handle}
													</AnimatedText>
												) : (
													handle
												)}
											</b>
										)}
									</AccordionSummary>
									<hr className='mg0' />

									<AccordionDetails>
										{matches.semiSmall && (
											<b className='notranslate'>
												{newUsernameUpdatedSuccess && !animationUsername ? (
													<AnimatedText
														type='chars'
														animationType='lights'
														interval={0.06}
														duration={0.8}
													>
														{handle}
													</AnimatedText>
												) : (
													handle
												)}
											</b>
										)}
										<CustomAlerts
											success={newUsernameUpdatedSuccess}
											margin='1rem 0 0 0'
											close
											onClose={() => this.props.clearSuccessMessages('config')}
											alertId='smallMuiAlert'
										/>

										<ul className='pdl'>
											<li className='text bold'>
												{t('usernames_can_be_changed_every_30_days')}
											</li>
										</ul>
										{allowUsernameChange === true && (
											<ConfirmationDialog
												open={changeUsernameDialog}
												openIconOrString={t('change_username')}
												customButtonId='customInfoButton'
												idOpenIconOrString='customInfoButton'
												closeFunc={() =>
													this.handleConfirmationDialog(
														false,
														'changeUsernameDialog',
													)
												}
												openFunc={() =>
													this.handleConfirmationDialog(
														true,
														'changeUsernameDialog',
													)
												}
												actionBtnMargin='1rem 0 0.5rem 0'
												initialBtnIcon={<ModeEditIcon />}
												actionBtnText={t('change')}
												startIcon={<ModeEditIcon />}
												action={this.handleSetNewUsernameToUpdate}
												phrase={handle}
												contentTitle={t('change_username_question')}
											/>
										)}

										{!allowUsernameChange &&
											// nextUsernameChange &&
											expanded === 'usernamePanel' && (
												<CustomAlerts
													info={true}
													message={
														<TimerCountdown
															releaseDate={nextUsernameChange}
															changeUsernameTimer
															selectorId={userId}
														/>
													}
													variant='outlined'
													margin='0'
													alertId='smallMuiAlert'
												/>
											)}
									</AccordionDetails>
								</Accordion>
								<Accordion
									slotProps={{ transition: { unmountOnExit: true } }}
									expanded={expanded === 'emailAddressPanel'}
									onChange={() => this.changePanel('emailAddressPanel')}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls='emailAddressPanelbh-content'
									>
										<Typography
											sx={{
												width: matches.semiSmall ? '100%' : '33%',
											}}
											id='typographySettings'
										>
											<MailIcon id='iconAccordion' /> {t('email_address')}
										</Typography>

										{!matches.semiSmall && (
											<div
												id='typographySecondary'
												className='notranslate breakWord'
											>
												{emailUpdatedSuccess && !animationEmail ? (
													<AnimatedText
														type='chars'
														animationType='float'
														interval={0.06}
														duration={0.8}
														includeWhiteSpaces
													>
														{email}
													</AnimatedText>
												) : (
													email
												)}
											</div>
										)}
									</AccordionSummary>
									<hr className='mg0' />

									<AccordionDetails>
										{matches.semiSmall && (
											<div className='notranslate center breakWord'>
												{emailUpdatedSuccess && !animationEmail ? (
													<AnimatedText
														type='chars'
														animationType='float'
														interval={0.06}
														duration={0.8}
														includeWhiteSpaces
													>
														{email}
													</AnimatedText>
												) : (
													email
												)}
											</div>
										)}
										<CustomAlerts
											success={emailUpdatedSuccess}
											margin='1rem 0 0 0'
											close
											onClose={() => this.props.clearSuccessMessages('config')}
											alertId='smallMuiAlert'
										/>

										<ul className='pdl'>
											<li className='text'>{t('email_change_desc')}</li>
										</ul>
										<ConfirmationDialog
											open={changeEmailDialog}
											openIconOrString={t('change_email_address')}
											actionBtnText={t('change')}
											startIcon={<ModeEditIcon />}
											initialBtnIcon={<ModeEditIcon />}
											actionBtnMargin='1rem 0 0.5rem 0'
											customButtonId='customInfoButton'
											idOpenIconOrString='customInfoButton'
											action={this.handleSetNewEmailToUpdate}
											closeFunc={() =>
												this.handleConfirmationDialog(
													false,
													'changeEmailDialog',
												)
											}
											openFunc={() =>
												this.handleConfirmationDialog(true, 'changeEmailDialog')
											}
											phrase={email}
											contentTitle={t('change_email_question')}
										/>
									</AccordionDetails>
								</Accordion>
								<Accordion
									slotProps={{ transition: { unmountOnExit: true } }}
									expanded={expanded === 'passwordPanel'}
									onChange={() => this.changePanel('passwordPanel')}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls='passwordPanelbh-content'
									>
										<Typography
											sx={{
												width: '33%',
											}}
											id='typographySettings'
										>
											<HttpsIcon id='iconAccordion' /> {t('password')}
										</Typography>
										{!matches.semiSmall && <PasswordIcon color='success' />}
									</AccordionSummary>
									<hr className='mg0' />
									<AccordionDetails>
										<ul className='pdl'>
											<li className='text'>{t('password_security_change')}</li>
										</ul>

										<ConfirmationDialog
											open={changePasswordDialog}
											openIconOrString={t('change_password')}
											customButtonId='customInfoButton'
											idOpenIconOrString='customInfoButton'
											actionBtnText={t('change')}
											startIcon={<ModeEditIcon />}
											initialBtnIcon={<ModeEditIcon />}
											actionBtnMargin='1rem 0 0.5rem 0'
											action={this.handleSetNewPasswordToUpdate}
											closeFunc={() =>
												this.handleConfirmationDialog(
													false,
													'changePasswordDialog',
												)
											}
											openFunc={() =>
												this.handleConfirmationDialog(
													true,
													'changePasswordDialog',
												)
											}
											phrase='Password'
											contentTitle={t('change_password_question')}
										/>
									</AccordionDetails>
								</Accordion>
								<Accordion
									slotProps={{ transition: { unmountOnExit: true } }}
									expanded={expanded === 'commentsPanel'}
									onChange={() => this.changePanel('commentsPanel')}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls='commentsPanelbh-content'
									>
										<Typography
											sx={{
												width: matches.semiSmall ? '100%' : '33%',
											}}
											id='typographySettings'
										>
											<ThreePOutlinedIcon id='iconAccordion' />{' '}
											{t('comments_replies')}
										</Typography>
										{!matches.semiSmall && (
											<Typography id='typographySecondary'>
												{t('view_all_interactions')}
											</Typography>
										)}
									</AccordionSummary>
									<hr className='mg0' />
									<AccordionDetails>
										<ul className='pdl'>
											<li className='text'>{t('comments_replies_desc_1')}</li>
										</ul>
										<Link to='/settings/comments'>
											<CustomButton
												variant='contained'
												btnText={t('review_comments_and_replies')}
												id='customInfoButton'
												startIcon={<ChatIcon />}
												margin={'0'}
											/>
										</Link>
										<ul className='pdl'>
											<li className='text'>{t('comments_replies_desc_2')}</li>
										</ul>
										<Link to='/settings/liked'>
											<CustomButton
												variant='contained'
												btnText={t('review_liked_comments_and_replies')}
												id='customCancelButton'
												startIcon={
													<Badge
														badgeContent={
															<ThumbUpIcon sx={{ fontSize: '0.8rem' }} />
														}
														color='primary'
													>
														<ChatIcon />
													</Badge>
												}
												margin={'0'}
											/>
										</Link>
									</AccordionDetails>
								</Accordion>

								<Accordion
									slotProps={{ transition: { unmountOnExit: true } }}
									expanded={expanded === 'notificationPanel'}
									onChange={() => this.changePanel('notificationPanel')}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls='reportPanelbh-content'
									>
										<Typography
											sx={{
												width: matches.semiSmall ? '100%' : '33%',
											}}
											id='typographySettings'
										>
											<NotificationsIcon id='iconAccordion' />{' '}
											{t('notifications')}
										</Typography>
										{!matches.semiSmall && (
											<Typography id='typographySecondary'>
												{t('preferences_for_display')}
											</Typography>
										)}
									</AccordionSummary>
									<hr className='mg0' />
									<AccordionDetails>
										{notPreferencesSetToday >= 30 && (
											<div>
												<CustomAlerts
													severity='warning'
													info={t('you_have_exceeded_daily_limit_action')}
													alertId='smallMuiAlert'
												/>
												<CustomAlerts
													info={t('security_anti_spam_measures')}
													icon={<SecurityIcon />}
													margin='0'
													alertId='smallMuiAlert'
												/>
											</div>
										)}
										<div style={dim(notPreferencesSetToday >= 30)}>
											{loadingNewNotificationPreference && <LinearProgress />}
											<p>
												{t('notifications_toggle_action', {
													action: popupNotifications
														? t('disable')
														: t('enable'),
												})}
											</p>
											<FormControlLabel
												disabled={notPreferencesSetToday >= 30}
												control={
													<Switch
														value='popupNotifications'
														handleSwitchChange={(e) =>
															this.handleSwitch(e, 'popupNotifications')
														}
														checked={popupNotifications}
														name='popupNotifications'
														disabled={loadingNewNotificationPreference}
													/>
												}
												label={popupNotifications ? t('active') : t('activate')}
												labelPlacement='bottom'
											/>
											<p>{t('notifications_desc_2')}</p>

											<NotificationPermission settings />
											<FormControl
												fullWidth
												disabled={notPreferencesSetToday >= 30}
											>
												<Select
													value={receiveNotifications}
													name='receiveNotifications'
													onChange={this.handleChange}
												>
													<MenuItem
														value='allAppNotifications'
														disabled={!allowNotifications}
													>
														{t('notifications_list_1')}
													</MenuItem>
													<MenuItem value='inAppNotifications'>
														{t('notifications_list_2')}
													</MenuItem>
												</Select>
											</FormControl>
											{((receiveNotifications === 'allAppNotifications' &&
												fcmData.disabledPush) ||
												(receiveNotifications === 'inAppNotifications' &&
													!fcmData.disabledPush)) && (
												<CustomButton
													btnText={t('update')}
													onClick={this.updateNotPreferences}
													variant='contained'
													id='customCancelButton'
													margin='1.3rem 0 0.5rem 0'
													loading={loadingNewNotificationPreference}
													startIcon={<CloudUploadIcon />}
													disabled={notPreferencesSetToday >= 30}
												/>
											)}
										</div>
									</AccordionDetails>
								</Accordion>
								<Accordion
									slotProps={{ transition: { unmountOnExit: true } }}
									expanded={expanded === 'reportPanel'}
									onChange={() => this.changePanel('reportPanel')}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls='reportPanelbh-content'
									>
										<Typography
											sx={{
												width: matches.semiSmall ? '100%' : '33%',
											}}
											id='typographySettings'
										>
											<SummarizeIcon id='iconAccordion' /> {t('report')}
										</Typography>
										{!matches.semiSmall && (
											<Typography id='typographySecondary'>
												{t('report_bug_or_issue')}
											</Typography>
										)}
									</AccordionSummary>
									<hr className='mg0' />
									<AccordionDetails>
										{matches.semiSmall && <p>{t('report_bug_or_issue')}</p>}
										<ul className='pdl'>
											<li className='text'>{t('report_desc_1')}</li>
										</ul>
										<p>{t('report_desc_2')}</p>
										<Link to='/report'>
											<CustomButton
												variant='contained'
												btnText={t('report_issue')}
												startIcon={<NewReleasesIcon />}
												id='customDangerButton'
												margin='1rem 0 0.5rem 0'
											/>
										</Link>
									</AccordionDetails>
								</Accordion>
								<Accordion
									slotProps={{ transition: { unmountOnExit: true } }}
									expanded={expanded === 'advancedSettingsPanel'}
									onChange={() => this.changePanel('advancedSettingsPanel')}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls='advancedSettingsPanelbh-content'
									>
										<Typography
											sx={{
												width: matches.semiSmall ? '100%' : '33%',
											}}
											id='typographySettings'
										>
											<SettingsSuggestIcon id='iconAccordion' /> {t('feature')}
										</Typography>
										{!matches.semiSmall && (
											<Typography id='typographySecondary'>
												{t('submit_feature_request')}
											</Typography>
										)}
									</AccordionSummary>
									<hr className='mg0' />
									<AccordionDetails>
										{matches.semiSmall && <p>{t('submit_feature_request')}</p>}
										<ul className='pdl'>
											<li className='text'>{t('feature_desc_1')}</li>
										</ul>
										<p className='mg0'>{t('feature_desc_2')}</p>
										<Link to='/feature'>
											<CustomButton
												variant='contained'
												btnText={t('feature_request')}
												id='customSuccessButton'
												margin='1rem 0 0.5rem 0'
												startIcon={<TipsAndUpdatesIcon />}
											/>
										</Link>
									</AccordionDetails>
								</Accordion>

								<Accordion
									slotProps={{ transition: { unmountOnExit: true } }}
									expanded={expanded === 'inactivityPanel'}
									onChange={() => this.changePanel('inactivityPanel')}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls='reportPanelbh-content'
									>
										<Typography
											sx={{
												width: matches.semiSmall ? '100%' : '33%',
											}}
											id='typographySettings'
										>
											<TimerIcon id='iconAccordion' />{' '}
											{t('inactivity_threshold')}
										</Typography>
										{!matches.semiSmall && (
											<Typography id='typographySecondary'>
												{t('release_due_to_inactivity')}
											</Typography>
										)}
									</AccordionSummary>
									<hr className='mg0' />
									<AccordionDetails>
										{matches.semiSmall && (
											<p>{t('release_due_to_inactivity')}</p>
										)}
										<ul className='pdl'>
											<li className='text bold'>{t('inactivity_desc_1')}</li>
										</ul>
										<p>{t('inactivity_desc_2')}</p>
										<p>{t('inactivity_desc_3')}</p>
										<p className='mgbOnly'>{t('inactivity_desc_4')}</p>

										{!inactivityRelease &&
											unreleasedPostCount <= 0 &&
											unreleasedLetters.length === 0 && (
												<CustomAlerts
													info={t('inactivity_requirement')}
													margin='0'
													alertId='smallMuiAlert'
												/>
											)}
										{inactivityRelease && (
											<CustomAlerts
												success={t('posts_and_letters_released')}
												margin='0'
												alertId='smallMuiAlert'
											/>
										)}

										{inactivityInfoSetToday >= 30 && (
											<div>
												<CustomAlerts
													severity='warning'
													info={t('you_have_exceeded_daily_limit_action')}
													margin='0'
													alertId='smallMuiAlert'
												/>
												<CustomAlerts
													info={t('security_anti_spam_measures')}
													icon={<SecurityIcon />}
													alertId='smallMuiAlert'
												/>
											</div>
										)}

										<div className='center pdt'>
											<FormControlLabel
												disabled={
													settingInactivity ||
													(!toggleInactivity &&
														unreleasedPostCount === 0 &&
														unreleasedLetters.length === 0)
												}
												control={
													<Switch
														value='toggleInactivity'
														handleSwitchChange={(e) =>
															this.handleSwitch(e, 'toggleInactivity')
														}
														checked={toggleInactivity}
														name='toggleInactivity'
														disabled={inactivityInfoSetToday >= 30}
													/>
												}
												// label={
												// 	inactivityRelease && toggleInactivity
												// 		? 'Completed'
												// 		: toggleInactivity &&
												// 		  this.props.user.credentials.inactivityThreshold
												// 		? 'Active'
												// 		: toggleInactivity &&
												// 		  !this.props.user.credentials.inactivityThreshold
												// 		? 'Save to activate'
												// 		: 'Activate'
												// }
												labelPlacement='bottom'
											/>
										</div>

										<div
											style={dim(
												!toggleInactivity || inactivityInfoSetToday >= 30,
											)}
										>
											<p className={`${inactivityRelease ? 'center' : ''} mgb`}>
												{t('inactivity_days', { days: inactivityThreshold })}
											</p>
											{!inactivityRelease && (
												<div className='mgXHalf'>
													<IOSlider
														aria-label={t('inactivity_label')}
														onChange={this.moveSlider}
														value={
															inactivityThreshold ? inactivityThreshold : 30
														}
														valueLabelDisplay='auto'
														step={1}
														marks
														min={3}
														max={45}
														disabled={
															!toggleInactivity || inactivityInfoSetToday >= 30
														}
														top={-6}
													/>
												</div>
											)}
											{!inactivityRelease && (
												<div className='flex'>
													<CustomButton
														btnText={
															inactivityThreshold ===
																this.props.user.credentials
																	.inactivityThreshold ||
															this.props.user.credentials.inactivityThreshold
																? t('update')
																: t('save')
														}
														onClick={this.setInactivity}
														variant='contained'
														id={
															inactivityThreshold ===
															this.props.user.credentials.inactivityThreshold
																? 'customInfoButton'
																: 'customSuccessButton'
														}
														loading={settingInactivity}
														startIcon={<CloudUploadIcon />}
														margin='1rem auto'
														disabled={
															!toggleInactivity || inactivityInfoSetToday >= 30
														}
													/>
												</div>
											)}
										</div>
									</AccordionDetails>
								</Accordion>

								<Accordion
									slotProps={{ transition: { unmountOnExit: true } }}
									expanded={expanded === 'revokeUserSessions'}
									onChange={() => this.changePanel('revokeUserSessions')}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls='advancedSettingsPanelbh-content'
									>
										<Typography
											sx={{
												width: matches.semiSmall ? '100%' : '33%',
											}}
											id='typographySettings'
										>
											<AdminPanelSettingsIcon id='iconAccordion' />
											{t('sign_out_all_sessions')}
										</Typography>
										{!matches.semiSmall && (
											<Typography id='typographySecondary'>
												{t('security_measure_subtitle')}
											</Typography>
										)}
									</AccordionSummary>

									<hr className='mg0' />
									<AccordionDetails>
										{!matches.semiSmall ? (
											<p>{t('security_measure_subtitle')}</p>
										) : (
											<p>
												<u> {t('security')} </u>
											</p>
										)}
										<p>{t('sign_out_desc_1')}</p>
										<ConfirmationDialog
											loading={logginOutAllUserSessions}
											open={logoutAllSessionsDialog}
											openIconOrString={t('sign_out_all_sessions')}
											actionBtnText={t('sign_out')}
											startIcon={<LogoutIcon />}
											initialBtnIcon={<AdminPanelSettingsIcon />}
											actionBtnMargin='1rem 0 1.5rem'
											customButtonId='customInfoButton'
											idOpenIconOrString='customCancelButton'
											action={this.logoutAllSessions}
											closeFunc={() =>
												this.handleConfirmationDialog(
													false,
													'logoutAllSessions',
												)
											}
											openFunc={() =>
												this.handleConfirmationDialog(true, 'logoutAllSessions')
											}
											phrase={t('sign_out_all_sessions')}
											contentTitle={t('sign_out_all_sessions_question')}
										/>
										<hr />
										<p className='bold'>{t('protect_your_password')}</p>
										<p>{t('protect_your_password_desc')}</p>
										<ConfirmationDialog
											open={changePasswordDialog}
											openIconOrString={t('change_password')}
											customButtonId='customInfoButton'
											idOpenIconOrString='customInfoButton'
											actionBtnText={t('send_reset_pass_link')}
											startIcon={<SendIcon />}
											initialBtnIcon={<ModeEditIcon />}
											actionBtnMargin='1rem 0'
											action={this.handleSetNewPasswordToUpdate}
											closeFunc={() =>
												this.handleConfirmationDialog(
													false,
													'changePasswordDialog',
												)
											}
											openFunc={() =>
												this.handleConfirmationDialog(
													true,
													'changePasswordDialog',
												)
											}
											phrase={t('change_password')}
											contentTitle={t('change_password_question')}
										/>
									</AccordionDetails>
								</Accordion>
								<Accordion
									slotProps={{ transition: { unmountOnExit: true } }}
									expanded={expanded === 'deleteAccountPanel'}
									onChange={() => this.changePanel('deleteAccountPanel')}
								>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography
											sx={{
												width: '100%',
											}}
											id='typographySettings'
										>
											<DeleteOutlineIcon id='iconAccordion' />{' '}
											{t('delete_option', {
												option: t('account'),
											})}
										</Typography>
									</AccordionSummary>
									<hr className='mg0' />
									<AccordionDetails>
										<ul className='pdl'>
											<li className='text'>{t('delete_account_question')}</li>
										</ul>
										<p>{t('delete_account_desc_1')}</p>
										<ConfirmationDialog
											open={deleteUserAccountDialog}
											openIconOrString={t('delete_option', {
												option: t('account'),
											})}
											customButtonId='customDangerButton'
											actionBtnMargin='1rem 0 0.5rem 0'
											idOpenIconOrString='customDangerButton'
											actionBtnText={t('proceed')}
											initialBtnIcon={<AccountCircleRoundedIcon />}
											startIcon={<AccountCircleRoundedIcon />}
											action={this.handleSetNewAccountToDelete}
											closeFunc={() =>
												this.handleConfirmationDialog(
													false,
													'deleteUserAccountDialog',
												)
											}
											openFunc={() =>
												this.handleConfirmationDialog(
													true,
													'deleteUserAccountDialog',
												)
											}
											phrase={t('delete_option', { option: handle })}
											componentTitle={
												<CustomAlerts
													error={true}
													margin='0'
													icon={<AccountCircleRoundedIcon />}
													message={t('delete_account_desc_1')}
													alertId='smallMuiAlert'
												/>
											}
											contentTitle={t('delete_account_desc_2')}
											component={
												<div className='pdX'>
													<CustomInput
														minRows={3}
														multiline
														maxRows={14}
														type='text'
														titleLabel={t('feedback')}
														variant='outlined'
														placeholder={t('type_here')}
														name='feedback'
														helperText={`${feedback.length}/1500`}
														onChange={this.handleChange}
														inputValue={feedback}
														maxLength={1500}
														fullWidth
													/>
												</div>
											}
										/>
									</AccordionDetails>
								</Accordion>
							</div>
							<Outlet />
							<Footer />
						</div>
					)}
				</Media>
			</div>
		);
	}
}
Settings.propTypes = {
	user: PropTypes.object.isRequired,
	setNewEmailToUpdate: PropTypes.func.isRequired,
	setNewPasswordToUpdate: PropTypes.func.isRequired,
	setNewUsernameToUpdate: PropTypes.func.isRequired,
	clearSuccessMessages: PropTypes.func.isRequired,
	logoutAllTabs: PropTypes.func.isRequired,
	isMessagingSupported: PropTypes.func.isRequired,
	setNotificationPreferences: PropTypes.func.isRequired,
	deleteRecipients: PropTypes.func.isRequired,
	clearQueriedUsers: PropTypes.func.isRequired,
	setInactivityData: PropTypes.func.isRequired,
	logoutAllSessions: PropTypes.func.isRequired,
	sendFeedback: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
});

const mapActionsToProps = {
	setNewEmailToUpdate,
	setNewPasswordToUpdate,
	setNewUsernameToUpdate,
	clearSuccessMessages,
	logoutAllTabs,
	isMessagingSupported,
	setNotificationPreferences,
	deleteRecipients,
	clearQueriedUsers,
	setInactivityData,
	logoutAllSessions,
	sendFeedback,
};

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(Settings));
